import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

const defaultProps = {
  type: 'text',
  value: '',
  fractionDigits: 0,
  allowOnlyNumbers: false,
  preventDecimals: false
}

const propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  fractionDigits: PropTypes.number,
  allowOnlyNumbers: PropTypes.bool,
  preventDecimals: PropTypes.bool
}

const Input = ({type, value, onChange, fractionDigits, allowOnlyNumbers, preventDecimals, ...props}) => {
  const [numericValue, setNumericValue] = useState('')

  // Update the new value when input value changes
  useEffect(() => {
    if (value || value === '') setNumericValue(value)
  }, [value])

  const handleChange = (e) => {
    e.preventDefault()
    let val = e.target.value.replace(/[^0-9]/g, '')

    if (fractionDigits) {
      const exp = '(\\..{0,' + fractionDigits + '}).*'
      const trimFractionsRegExp = new RegExp(exp, 'g')

      val = e.target.value
        .replace(/[^0-9.]/g, '')                // Remove all characters except 0-9 & .
        .replace(/(\..*?)\..*/g, '$1')          // Remove any repeating . or . followed by values
        .replace(trimFractionsRegExp, '$1')     // Trim the fraction digits
    }

    if(preventDecimals){
      val = e.target.value

      const decimalIndex = val.indexOf('.');
      if (decimalIndex !== -1) {
        val = val.slice(0, decimalIndex);
      }
    }

    setNumericValue(val)
    if (onChange && typeof onChange === 'function') onChange(e, val)
  }

  return (
    <>
      {type === 'number' || (type === 'text' && allowOnlyNumbers) ? (
        <input
          {...props}
          type={type}
          value={numericValue}
          onChange={handleChange}
        />
      ) : (
        <input
          {...props}
          type={type}
          value={value}
          onChange={onChange}
        />
      )}
    </>
  )
}

Input.defaultProps = defaultProps
Input.propTypes = propTypes

export default Input
