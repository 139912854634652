import React from 'react'

const CancelButton = (props) => {
  return (
    <button
      className="btn btn-default back cancel clickable"
      tabIndex="11"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      Cancel
    </button>
  )
}

export default CancelButton
