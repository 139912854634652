import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import qs from 'qs'

import {routes} from '../../routes'

import {checkGTUserRegistered,} from '../../redux/actions'

const GuidedtrackLandingPage = ({history, location}) => {
  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
  const gtDataId = queryParams.id
  const utmSource = queryParams.utm_source
  const utmMedium = queryParams.utm_medium
  const utmCampaign = queryParams.utm_campaign

  const dispatch = useDispatch()

  const {
    isUserRegistered,
    projectId,
    status,
    isTokenValidating,
    errors
  } = useSelector(state => ({
    isUserRegistered: state.checkGtUserRegistered?.isRegistered,
    projectId: state.checkGtUserRegistered?.projectId,
    status: state.checkGtUserRegistered?.status,
    isTokenValidating: state.isTokenValidating,
    errors: state.checkGtUserRegistered?.errors
  }), shallowEqual)

  const handleResponse = () => {
    if (projectId) return history.push(`${routes.RUN_CREATE}?project_id=${projectId}&gt_data_id=${gtDataId}`)

    if (isUserRegistered) return history.push(`${routes.SIGN_IN}?gt_data_id=${gtDataId}`)

    history.push(`${routes.SIGN_UP}?gt_data_id=${gtDataId}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`)
  }

  // Check if GT user is registered in Positly
  useEffect(() => {
    if (gtDataId) dispatch(checkGTUserRegistered(gtDataId))
  }, [dispatch, gtDataId])

  useEffect(() => {
    if (!isTokenValidating && status === 'success') handleResponse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isTokenValidating])

  return (
    <div className="row">
      <div className={`box po-text ${status === 'error' ? 'po-text-danger' : ''}`}>
        <h3>
          {status === 'error'
            ? `${errors.join(', ')}`
            : 'Please wait...'
          }
        </h3>
      </div>
    </div>
  )
}

export default GuidedtrackLandingPage
