import {Link} from "react-router-dom";
import React from "react";
import {getDownloadURL} from '../../common/Helpers'

export const BulkActionLinks = (props) => {

  const getDownloadPath = () => {
    return`/assignments/download?project_id=${props.projectId}&platform_id=${props.platformId}`
  }

  return (
    <div className="wrapper_link right bulk-actions">
      {props.isMturk() && (
        <>
          <Link
            to=""
            className="popup-content send_active"
            onClick={(e) => props.openPopup('message-popup', props, e)}>
            Send Messages
          </Link>
          <Link
            to=""
            className="popup-content give_active"
            onClick={(e) => props.openPopup('bonus-popup', props, e)}>
            Give Bonuses
          </Link>
          <Link
            to=""
            className="popup-content set_active"
            onClick={(e) => props.openPopup('set-attribute-popup', props, e)}>
            Set Attributes
          </Link>
        </>
      )}
      {!props.searchQuery && (
        <a
          className="btn down cursor-pointer"
          href={getDownloadURL(getDownloadPath())}
        >
          Download
        </a>
      )}
    </div>
  )
}
