import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

import Spinner from '../common/Spinner'
import FormField from '../common/FormField'
import ResearchTypesInput from '../common/ResearchTypesInput'
import FormErrors from '../FormErrors'

import {routes} from '../../routes'

import {researchTypesFetch, checkGTUserRegistered} from '../../redux/actions'
import {userSignUpFetch} from '../../redux/actionss/userActions'
import {parseInt} from "lodash/string";


const SignUp = props => {
  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const urlParams = new URLSearchParams(window.location.search)
  const gtDataId = queryParams.gt_data_id
  const utm_parameters = {
    utm_id: urlParams.get('utm_id') || queryParams.utm_id,
    utm_content: urlParams.get('utm_content') || queryParams.utm_content,
    utm_medium: urlParams.get('utm_medium') || queryParams.utm_medium,
    utm_source: urlParams.get('utm_source') || queryParams.utm_source,
    utm_campaign: urlParams.get('utm_campaign') || queryParams.utm_campaign,
    utm_term: urlParams.get('utm_term') || queryParams.utm_term
  }
  const signInLink = gtDataId ? `${routes.SIGN_IN}?gt_data_id=${gtDataId}` : routes.SIGN_IN
  const accountTypes = {
    researcher: 0,
    participant: 1
  }
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      full_name: '',
      nick_name: '',
      email: props.gtUserEmail || '',
      password: '',
      notify: true,
      gt_data_id: gtDataId,
      utm_parameters: utm_parameters,
      account_type: accountTypes.researcher
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
      research_types: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      props.userSignUpFetch(values)
    }
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const onAccountTypeChange = (e) => {
    formik.setFieldValue('account_type', parseInt(e.target.value))
  }

  useEffect(() => {
    props.researchTypesFetch()

    if (gtDataId) props.checkGTUserRegistered(gtDataId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtDataId])

  if (props.currentUser) {
    if (gtDataId && props.currentUser.project_id) {
      return <Redirect to={`${routes.RUN_CREATE}?project_id=${props.currentUser.project_id}&gt_data_id=${gtDataId}`}/>
    } else {
      return <Redirect to={routes.HOME}/>
    }
  }

  return (
    <div className={`sign-up-box ${gtDataId ? 'gt-sign-up' : ''}`}>
      {!props.currentUser && (
        <div>
          {props.researchTypesList?.isLoading && <Spinner/>}
          {!props.researchTypesList?.isLoading && (
            <div className="container">
              <div className="d-flex add_platform justify-content-center">
                <div className="width-533">
                  {gtDataId && (
                    <div className="box bg-white alert bg-info po-text-dark po-text">
                      <h3 className="font-size-16">
                        You’re on your way to recruiting participants for your GuidedTrack program!<br/><br/>
                        You’ll need a free Positly account to get started.
                      </h3>
                    </div>
                  )}

                  <div className="panel panel-default">
                    <div className="panel-body">
                      <form onSubmit={formik.handleSubmit}>
                        <FormErrors errors={props.errors}/>
                        <FormField
                          label="Email"
                          type="email"
                          id="email"
                          required={true}
                          formik={formik}
                          className="mt-2"/>
                        <FormField
                          label="Password"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          required={true}
                          formik={formik}
                          className="mt-3"
                          helpBlock={() => <p className="po-text mt-1">Minimum 8 characters</p>}
                          inputAppend={() => (
                            <div className="input-group-append">
                              <i
                                className={showPassword ? 'fa fa-eye-slash cursor-pointer' : 'fa fa-eye cursor-pointer'}
                                onClick={togglePasswordVisibility}/>
                            </div>
                          )}/>
                        <div className="mt-3 account-type">
                          <label>
                            I am looking to:
                          </label>
                          <div className="d-flex">
                            <input type="radio"
                                   id="researcher"
                                   name="account_type"
                                   onChange={onAccountTypeChange}
                                   value={accountTypes.researcher}
                                   defaultChecked={true}
                            />
                            <label htmlFor="researcher" className="po-text">Recruit participants for my research</label>
                          </div>
                          <div className="d-flex">
                            <input type="radio"
                                   id="participant"
                                   name="account_type"
                                   onChange={onAccountTypeChange}
                                   value={accountTypes.participant}
                            />
                            <label htmlFor="participant" className="po-text">Earn money by participating in other people's studies</label>
                          </div>
                        </div>

                        {formik.values.account_type === 0 && (
                          <>
                            <div className="mt-3"/>
                            <ResearchTypesInput formik={formik} name="research_types"/>
                          </>
                        )}

                        <div className="form-group">
                          <div className="wrap_link flex-row mt-4">
                            <button
                              id="btn-sign-up"
                              type="submit"
                              className={`btn btn-primary no-border mr-3 cursor-pointer sign-up-btn ${props.isLoading ? 'btn-grey cursor-disabled' : ''}`}
                              disabled={props.isLoading}
                            >
                              {gtDataId
                                ? props.isLoading ? 'Please wait...' : 'Create my free account'
                                : props.isLoading ? 'Please wait...' : 'Sign Up'
                              }
                            </button>
                            <span className="mr-2">or</span>
                            <Link
                              className={props.isLoading ? 'pe-none sign-in-link' : 'sign-in-link'}
                              to={signInLink}
                            >
                              Sign In
                            </Link>
                          </div>
                        </div>

                        <div className="form-group mt-4 pb-1 font-size-13">
                          <label className="link-visited">
                            By creating an account you are agreeing to our&nbsp;
                            <a href="https://www.positly.com/terms/" target="_blank" rel="noreferrer">
                              Terms of Use
                            </a>
                            {' and '}
                            <a href="https://www.positly.com/privacy/" target="_blank" rel="noreferrer">
                              Privacy Policy
                            </a>
                            . We may also send you occasional email updates, which you can unsubscribe from with one
                            click.
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.signUp,
  researchTypesList: state.researchTypesList,
  selectedResearchTypes: state.selectedResearchTypes || [],
  currentUser: state.currentUser,
  gtUserEmail: state.checkGtUserRegistered?.email,
})

const mapDispatchToProps = dispatch => ({
  checkGTUserRegistered: (gtDataId) => dispatch(checkGTUserRegistered(gtDataId)),
  userSignUpFetch: (user) => dispatch(userSignUpFetch(user)),
  researchTypesFetch: () => dispatch(researchTypesFetch())
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
