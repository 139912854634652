import React from 'react'

const QualityAttributes = props => {
  const POSITLY_PASS_RATE_LABEL = {
    '0': 'No restrictions',
    '1': '70%',
    '2': '99%'
  }

  const minimumPlatformApprovalRate = parseInt(props.minimum_platform_approval_rate || '0')
  const minimumPlatformTasksCompleted = parseInt(props.minimum_platform_tasks_completed || '0')
  const minimumPositlyApprovals = parseInt(props.minimum_positly_approvals || '0')

  return (
    <table className="table table-bordered">
      <tbody>
      {props.collects_pii === true && (
        <tr>
          <td>Require PII of participants</td>
          <td>Yes</td>
        </tr>
      )}

      <>
        {props.is_isp_check_enabled === 'true' && (
          <tr>
            <td>Block suspicious IP addresses</td>
            <td>Yes</td>
          </tr>
        )}
        {props.isMturk && props.is_country_check_enabled === 'true' && (
          <tr>
            <td>Restrict IP address geolocation by country</td>
            <td>Yes</td>
          </tr>
        )}
        {props.is_attention_check_enabled === 'true' && (
          <tr>
            <td>Require attentive participants</td>
            <td>Yes</td>
          </tr>
        )}
        {props.isMturk && props.is_hq_participants_only === 'true' && (
          <tr>
            <td>Require high approval rate and experience</td>
            <td>Yes</td>
          </tr>
        )}
        {props.isMturk && props.is_very_hq_enabled === 'true' && (
          <tr>
            <td>Restrict to only the very highest quality standards</td>
            <td>Yes</td>
          </tr>
        )}
        {props.isMturk && minimumPlatformApprovalRate > 0 && (
          <tr>
            <td>Minimum platform activity approval rate</td>
            <td>{props.minimum_platform_approval_rate}%</td>
          </tr>
        )}
        {props.isMturk && minimumPlatformTasksCompleted > 0 && (
          <tr>
            <td>Minimum prior platform tasks completed</td>
            <td>{props.minimum_platform_tasks_completed}</td>
          </tr>
        )}
        {props.isMturk && props.minimum_positly_pass_rate && (
          <tr>
            <td>Minimum Positly attention check pass rate</td>
            <td>{POSITLY_PASS_RATE_LABEL[props.minimum_positly_pass_rate]}</td>
          </tr>
        )}
        {props.isMturk && minimumPositlyApprovals > 0 && (
          <tr>
            <td>Minimum prior Positly approvals</td>
            <td>{props.minimum_positly_approvals}</td>
          </tr>
        )}
      </>

      {props.countryName && (
        <tr>
          <td>Participants Country</td>
          <td>{props.countryName}</td>
        </tr>
      )}
      </tbody>
    </table>
  )
}

export default QualityAttributes
