import React from 'react'
import './Alert.scss'

export const Alert = (props) => {
  return (
    <p className={`alert ${props.type} po-text font-size-15 po-text-bold`}>
      {props.message}
    </p>
  )
}
