import React, {useEffect} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import qs from 'qs'

// 3rd party
import {connect} from 'react-redux'
import {
  confirmEmailFetch,
} from '../../redux/actions'
import {routes} from "../../routes";

const ConfirmEmail = (props) => {
  const location = useHistory().location
  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
  const confirmationToken = queryParams?.confirmation_token

  const isLinkExpired = () => {
    const expiredError = props.errors?.find((error) => error.indexOf('please request a new one') > -1)

    return typeof expiredError !== 'undefined'
  }

  const isEmailAlreadyVerified = () => (
    props.errors?.find((error) => error.indexOf('Your E-mail is already verified. Please try signing in.') > -1)
  )

  useEffect(() => {
    if (confirmationToken) {
      props.confirmEmailFetch(confirmationToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationToken])

  if(props.confirmedEmail) {
    return <Redirect to={routes.PROJECT_LIST}/>
  }

  return (
    <div className="metrics filter participants clear-h3-margin">
      <div className="box">
        {props.isLoading && (
          <h3>Please wait...</h3>
        )}
        {!confirmationToken && (
          <h3 className="text-danger">
            Invalid confirmation link.
          </h3>
        )}
        {isLinkExpired() && (
          <h3 className="text-danger">
            Confirmation link expired. Sign in to get new one.
          </h3>
        )}
        {!isLinkExpired() && props.errors?.length && (
          <h3 className={`${isEmailAlreadyVerified() ? 'text-success' : 'text-danger'}`}>
            {props.errors}
          </h3>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.confirmEmail,
    currentUser: state.currentUser,
    resendEmail: state.resendEmail
  }
}

const mapDispatchToProps = dispatch => ({
  confirmEmailFetch: (confirmationToken) => dispatch(confirmEmailFetch(confirmationToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
