import {isMturkPlatform} from "../../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../common/Spinner";
import React from "react";
import {AssignmentListItem} from "./AssignmentListItem";
import {ProjectSelection} from "./ProjectSelection";
import {BulkActionLinks} from "./BulkActionLinks";
import {ParticipantSearch} from "./ParticipantSearch";
import {NoParticipants} from "./NoParticipants";

export const PlatformTabContent = (props) => {
  const isMturk = () => (isMturkPlatform(props.platform?.platform_type))

  const noRunsPublishedMessage = () => {
    return 'Once you launch your first run of a study or survey in this project through the ' + props.platformName + ' platform, ' +
           'your participants will appear here.'
  }

  const searchQueryNoMatchMessage = () => {
    return 'No participants found with ID matching \'' + props.searchQuery + '\''
  }

  return <div id="assignments">
    <div className="run-statistics">
      <div className="row">
        <div className="col-md-4">
          <ProjectSelection {...props}/>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-6">
          {(props.assignments.length > 0 || props.searchQuery) && (
            <ParticipantSearch {...props}/>
          )}
        </div>
      </div>

      {props.assignments.length > 0 ? (
        <>
          <BulkActionLinks {...props} isMturk={isMturk}/>

          <div className="wrap_projects vertical-space-md">
            <div className="project_detail">
              <div className="runlist">
                <div className="row list-item list-heading justify-content-between">
                  <div className='col-md-9 row'>
                    <div className="col-md-2">Participant ID</div>
                    <div className="col-md-2">Assignment ID</div>
                    <div className="col-md-2">Status</div>
                    <div className="col-md-3">Accepted At</div>
                    <div className="col-md-3">Project</div>
                  </div>
                  <div className="col-md-3 mb-10-md">Run</div>
                </div>
                <InfiniteScroll
                  dataLength={props.assignments.length || 0}
                  next={props.loadProjectAssignments}
                  hasMore={props.hasMore}
                  loader={<Spinner/>}>
                  {props.assignments.map((assignment, index) =>
                    <AssignmentListItem
                      key={index}
                      {...assignment}
                      openPopup={props.openPopup}
                      isMturk={isMturk}
                      paymentPerSubmission={props.payment_per_submission}
                      currentUser={props.currentUser}
                      isScreeningEnabled={props.agree_screening_guidelines}
                      projectId={props.projectId}
                    />
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </>
      ) : (
      <>
        { !props.isAssignmentsLoading && (
          (!props.searchQuery) ? (
            props.publishedRunsCountInPlatform === 0 ? (
                <NoParticipants message={noRunsPublishedMessage()}/>
              ) : (
                <NoParticipants/>
              )

          ) : (
            <NoParticipants message={searchQueryNoMatchMessage()}/>
          )
        )}
      </>
      )}

      { props.isAssignmentsLoading && props.assignments.length === 0 && <Spinner/> }
    </div>
  </div>
}
