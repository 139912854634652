import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import {enableNotificationForWorkerFetch} from '../../redux/actions'

const SurveySubscription = props => {
  const dispatch = useDispatch()

  const {assignment} = useSelector((state) => ({
    assignment: state?.postSurvey?.feedbackStarted?.assignment
  }))

  const formik = useFormik({
    initialValues: {
      notify_by: '',
      email: ''
    },
    validationSchema: Yup.object().shape({
      notify_by: Yup.string().required(),
      email: Yup.string().when('notify_by', {
        is: (value) => value === 'email',
        then: Yup.string()
          .max(100, 'Maximun allowed char limit is 100')
          // eslint-disable-next-line
          .matches(/^([a-zA-Z0-9_+\.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/, 'Please enter valid email')
          .required('Email is required')
      })
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      if (formik.isValid) {
        if (values.notify_by === 'false') { // Disable notification for the worker
          const params = {worker_id: assignment?.worker_uid, notify: false}
          dispatch(enableNotificationForWorkerFetch(params, false, props.setSubscriptionCompleted))
        } else { // Enable notification for the worker
          const params = {
            worker_id: assignment?.worker_uid,
            email: values.email,
            notify_by: values.notify_by
          }
          dispatch(enableNotificationForWorkerFetch(params, true, props.setSubscriptionCompleted))
        }
      }
    }
  })

  const isSubscriptionFormValid = () => formik.isValid

  return (
    <>
      {props.isFeedbackCompleted() && props.isMturk ? (
        <>
          <div className="wrap_title row align-items-center">
            <h1>Email subscribe</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 left">
              <form name="subscriptionForm" onSubmit={formik.handleSubmit} noValidate>
                <div className="box">
                  <h2>
                    Would you like us to notify you when you are eligible for other new or related activities?
                  </h2>
                  <div className="my-2 wrap_radio">
                    <label>
                      <input name="notify_by" onChange={formik.handleChange} value={'email'} type="radio"/>
                      <span className="po-text">Yes, by email</span>
                    </label>
                    <label>
                      <input name="notify_by" onChange={formik.handleChange} value={'mturk'} type="radio"/>
                      <span className="po-text">Yes, by Mechanical Turk Messages</span>
                    </label>
                    <label>
                      <input name="notify_by" onChange={formik.handleChange} value={'false'} type="radio"/>
                      <span className="po-text">No, thank you</span>
                    </label>
                  </div>
                  {formik.values.notify_by === 'email' && (
                    <div className={`form-group mt-3 ${!formik.isValid && 'has-error'}`}>
                      <h2>Please enter your email</h2>
                      <input
                        className="form-control subs-email"
                        name="email"
                        onChange={formik.handleChange}
                        placeholder="Your email address must be here"
                        type="text"/>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="wrap_link wrap_link_filter d-flex">
                      <button
                        className={`btn btn-primary clickable no-border ${!isSubscriptionFormValid() && 'cursor-disabled  btn-grey'}`}
                        type="submit">
                        {formik.values.notify_by === 'false' ? 'Submit' : 'Subscribe'} and Complete
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 col-md-12 left">
              <div className="notice ml-0">
                <p>
                  We cannot guarantee that all HITs you are notified about will be available by the time you see them
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default SurveySubscription
