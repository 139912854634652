import React from 'react'

const SurveyProgressIndicator = props => {
  return (
    <div className="row">
      <div className="col-md-8">
        {Array.from({length: 20}, (n, index) =>
          <span key={index} className={`fill-box ${index < props.value?.trim().length ? 'fill-box-green' : ''}`}/>
        )}
      </div>
      <div className="col-md-4 text-right po-text">Minimum 20 characters</div>
    </div>
  )
}

export default SurveyProgressIndicator
