import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getStorageItem, setStorageItem} from './common/Helpers'

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([])
  const activeAnnouncements = useSelector(state => state.announcements?.data)

  const hideAnnouncement = (announcementId) => {
    setAnnouncements(prevState => prevState.filter(a => a.id !== announcementId))

    const hiddenAnnouncements = getStorageItem('hiddenAnnouncements') || []
    setStorageItem('hiddenAnnouncements', [...hiddenAnnouncements, announcementId])
  }

  // Initialize state
  useEffect(() => {
    if (!activeAnnouncements) return setAnnouncements([])

    const hiddenAnnouncements = getStorageItem('hiddenAnnouncements') || []
    setAnnouncements(activeAnnouncements.filter(a => !hiddenAnnouncements.includes(a.id)))
  }, [activeAnnouncements])

  return <>
    {announcements.length ? (
      <div className="announcement-wrapper">
        {announcements.map(({id, message}) =>
          <div className="announcement-wrapper-inner">
            <div key={id} className="container announcement">
              <i className="fa fa-bullhorn" aria-hidden="true"/>
              <span className="mx-2">{message}</span>
              <i
                className="fa fa-times-circle ml-auto cursor-pointer"
                aria-hidden="true"
                onClick={() => hideAnnouncement(id)}
              />
            </div>
          </div>
        )}
      </div>
    ) : null}
  </>
}

export default Announcements
