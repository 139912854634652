import {Link} from "react-router-dom";
import SentMessages from "../../SentMessages";
import SentBonuses from "../../SentBonuses";
import React from "react";
import {getAssignmentStatusClassName, getStatusReadable} from "../../common/helpers/AssignmentListItemHelper";

export const AssignmentListItem = (props) => {
  return (
    <div className="row list-item infinitely-loaded justify-content-between assignment-list-item">
      <div className='col-md-9 row align-items-center'>
        <div className="col-md-2 mb-10-md">
          <span className="list-item-label po-text-bold pr-1">Participant ID:</span>
          {props.reason_to_block_worker === 'Blocked due to spam' && (
            <div className="d-inline-block po-tooltip no-q icon po-text ml-1 mb-1 top-0">
              <i className="fas fa-user-slash text-danger"/>
              <span className="po-tooltiptext po-tooltip-position">
              {props.reason_to_block_worker}
            </span>
            </div>
          )}
          {props.worker_uid}
        </div>
        <div className="col-md-2">
          <span className="list-item-label po-text-bold pr-1">Assignment ID:</span>
          {props.id}
        </div>

        <div className="col-md-2">
          <span className="list-item-label po-text-bold pr-1">Status:</span>
          <div className={'d-inline-block ' + getAssignmentStatusClassName(props.status_readable)}>
            {getStatusReadable(props.isScreeningEnabled, props.status_readable)}
          </div>
          {props.status_readable === 'Completed' && props.isMturk() && (
            <div className="d-inline-block po-tooltip ml-1">
          <span className="po-tooltiptext po-tooltip-position">
            Scheduled for automatic approval within 6 hours
          </span>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <span className="list-item-label po-text-bold pr-1">Accepted At:</span>
          {props.started_at}
        </div>
        <div className="col-md-3">
          <span className="list-item-label po-text-bold pr-1">Project:</span>
          <div className='d-inline-block right-space'>{props.project_name}</div>
        </div>
      </div>
      <div className="col-md-3 align-items-center">
        <span className="list-item-label po-text-bold pr-1">Run:</span>
        <div className="d-inline-block right-space">
          {props.run_name}
          <br/>
          (Run ID: {props.run_id})
        </div>
      </div>
      {props.isMturk() && (
        <div className="action user_block pl-0" id="dropDownMenu">
          <div className="dropdown wrap_img_user">
            <button
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenu1"
              type="button"
            >
              <span/>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-right action action-tr menu_dashed approve-tooltip align-menu-items">
              <li>
                <div
                  to=""
                  className="id_top">
                  {props.participant_id}
                </div>
              </li>
              {props.is_messageble && (
                <>
                  <li
                    className="popup-content cursor-pointer"
                    onClick={(e) => props.openPopup('message-popup', props, e)}>
                    <Link
                      className="send"
                      to="">
                      Send Message
                    </Link>
                  </li>
                  <SentMessages
                    run_id={props.run_id}
                    participant_id={props.participant_id}
                    assignment_id={props.id}
                    projectId={props.projectId}
                  />
                  <li
                    className="popup-content give cursor-pointer"
                    onClick={(e) => props.openPopup('bonus-popup', props, e)}>
                    <Link
                        to=""
                        className="give">
                      Give Bonus
                    </Link>
                  </li>
                  <SentBonuses
                      participant_id={props.participant_id}
                      assignment_id={props.id}
                      run_id={props.run_id}
                      platform_type={props.platform_type}
                      projectId={props.projectId}
                  />
                </>
              )}
              <li
                className="popup-content cursor-pointer"
                onClick={(e) => props.openPopup('set-attribute-popup', props, e)}>
                <Link
                    to=""
                    className="set_dashed_ca">
                  Set Custom Attribute
                </Link>
              </li>
              </ul>
            </div>
          </div>
      )}
    </div>
  )
}
