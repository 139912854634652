import React from 'react'

const ExplainerVideoPopup = ({videoStartTimeInSecs = 0}) => {
  return (
    <div id="watch-demo-popup" className="white-popup demo-video-container mfp-hide">
      <div className="iframe-container">
        <iframe
          title="positly demo"
          allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
          allowFullScreen
          frameBorder="0"
          src={`https://www.youtube.com/embed/SVoEEmWgPsY?start=${videoStartTimeInSecs}`}
        />
      </div>
    </div>
  )
}

export default ExplainerVideoPopup
