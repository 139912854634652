const ParticipantHome = () => {

  return (
    <>
      <div className={'row m-auto'}>
        <div className="box">
          <div className="po-text-bold p-4 text-center font-size-16">
            Sorry, we are not currently recruiting participants for research via Positly. We will be in contact if in the future this changes.
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantHome
