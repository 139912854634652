import React, {useState} from 'react'

import Input from '../../common/Input'
import {formatAsCurrency, isGuidedTrackLink, screeninLink, screenoutLink} from './RunUtils'
import Spinner from '../../common/Spinner'
import {resetLinkVerification, runFormOverviewUpdate} from '../../../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import PositlyLink from '../../common/PositlyLink'
import CopyLink from '../../common/CopyLink'
import CopyCode from '../../common/CopyCode'

const $ = window.$

const MTurkScreeningSetupPopup = (
  {
    formik,
    costEstimate,
    setScreeninRate,
    validateAndDispatchForm,
    isQuoteLoading,
    setShowBudgetChangedWarning,
    isScreeningLinkSameAsActivityLink
  }) => {
  const [showPreviewLinkWarning, setShowPreviewLinkWarning] = useState(false)
  const maxSubmissionsPerPerson = useSelector(state => state.runFormOverview.task_parameters.max_submissions_per_person)
  const taskId = useSelector(state => state.runFormOverview.task_id)

  const dispatch = useDispatch()

  const handleChange = (e, value) => {
    const fieldName = e.target.name
    formik.setFieldValue(fieldName, value)

    if (fieldName.includes('est_incidence_rate'))
      setScreeninRate(value)
  }

  const handleMaxBudgetBlur = (e) => {
    const {name, value} = e.target
    const formattedMaxBudget = parseFloat(value).toFixed(2)
    formik.setFieldValue(name, formattedMaxBudget)
  }

  const handleScreeningLinkChange = (e) => {
    formik.handleChange(e)

    const screeningUrl = e.target.value
    const isScreeningUrlInvalid = formik.errors.task_parameters
      ?.screening_activity_link
      ?.includes('Screening activity link is invalid')
    setShowPreviewLinkWarning(!isScreeningUrlInvalid && screeningUrl.includes('preview'))
  }

  const handleScreeningLinkInputBlur = () => {
    dispatch(resetLinkVerification({isScreeningUrlChanged: true}))
    dispatch(runFormOverviewUpdate({
      screen_in_link_verification_id: null,
      screen_in_link_verified_at: null,
      screen_out_link_verification_id: null,
      screen_out_link_verified_at: null
    }))
  }

  const isFormInvalid = Object.keys(formik.errors).length
  const disableForm = isQuoteLoading || !costEstimate || isFormInvalid || !formik.values.agree_screening_guidelines || isScreeningLinkSameAsActivityLink

  const closeScreeningSetupPopup = (e) => {
    e.preventDefault()
    formik.setFieldValue('screening_activity_link', '')
    formik.setFieldValue('est_screening_duration', 0)
    formik.setFieldValue('est_screening_duration_type', 'minutes')
    formik.setFieldValue('est_incidence_rate', 0)
    formik.setFieldValue('est_max_budget', 0)
    formik.setFieldValue('agree_screening_guidelines', false)
    $.magnificPopup.close()
  }

  const acceptUpdatedCost = (e) => {
    e.preventDefault()
    setShowBudgetChangedWarning(false)
    validateAndDispatchForm()
    $.magnificPopup.close()
  }

  const showCopyCodeButton = isGuidedTrackLink(formik.values.task_parameters.screening_activity_link)
    && maxSubmissionsPerPerson?.toString() === '1'

  return (
    <div id="mturk-screening" className="metrics filter activity mfp-hide modal fade screening-setup-popup">

      <div className="box po-text">
        <h2 className="header mb-4">Screening Activity Setup</h2>

        <div>
          <div className="notice notice-wo-icon mt-3">
            <span>
              <span className="text-bold">Important:</span>
              {' '}To use screening features on Positly, your screening activity must be brief.
              All participants who complete your screening activity must reach either the Screen-In
              or Screen-Out redirect link.
            </span>
          </div>

          <div className="wrap_info pt-2">
            <div className="radio-checkbox">
              <label>
                <input
                  name="agree_screening_guidelines"
                  className="checkbox"
                  checked={formik.values.agree_screening_guidelines}
                  onChange={formik.handleChange}
                  type="checkbox"
                />
                <span className="checkbox-custom"/>
                <span
                  className={`label text-required ${!formik.values.agree_screening_guidelines ? 'text-danger' : ''}`}
                >
                  I agree to adhere to{' '}
                  <a
                    className="pii-link"
                    href="https://www.positly.com/support/positlys-screening-guidelines/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Positly’s screening guidelines
                  </a>
                </span>
              </label>
            </div>
          </div>

          <div className="form-group ">
            <label>
              <h2 className="text-required">
                Screening activity link (the URL where participants will find your screening activity)
              </h2>
            </label>
            <div className="row">
              <div className="wrap_select w-100 mw-100">
                <input
                  name="task_parameters.screening_activity_link"
                  type="text"
                  className="form-control mb-0"
                  placeholder="e.g., https://screeningstudy.com/url"
                  value={formik.values.task_parameters.screening_activity_link}
                  onChange={handleScreeningLinkChange}
                  onBlur={handleScreeningLinkInputBlur}
                  disabled={!formik.values.agree_screening_guidelines}
                />
              </div>
            </div>
          </div>
          <div className="wrap_info_form inline pt-1">
            <span>Use your favourite online study tool</span>
          </div>
          {(formik.values.task_parameters?.screening_activity_link && formik.errors.task_parameters?.screening_activity_link
            && !formik.errors.task_parameters.screening_activity_link.includes('required')
          ) ? <div className="po-text-error pt-1">{formik.errors.task_parameters.screening_activity_link}</div>
            : null
          }
          {showPreviewLinkWarning && (
            <div className="preview-link-warning pt-1">
                <span className="warning">
                  <span><strong className="text-orange">You may have accidentally entered a preview link instead of the real link to your run</strong></span>
                </span>
            </div>
          )}
          {isScreeningLinkSameAsActivityLink && (
            <div className="po-text-error lh-1 pt-1">
              You provided the link to your Main Activity. Your Screening Activity must be a separate activity with its
              own URL.
            </div>
          )}

          <div className="form-group mt-3">
            <label>
              <h2 className="text-required">Estimated time to complete screening activity:</h2>
            </label>
            <div className="row">
              <div className="wrap_select est-time col-md-5">
                <Input
                  name="task_parameters.est_screening_duration"
                  type="text"
                  className="form-control est-screening-time mb-0"
                  value={formik.values.task_parameters.est_screening_duration}
                  onChange={handleChange}
                  allowOnlyNumbers
                  disabled={!formik.values.agree_screening_guidelines}
                />
                <select
                  name="task_parameters.est_screening_duration_type"
                  className="est-screening-time-type"
                  value={formik.values.task_parameters.est_screening_duration_type}
                  onChange={formik.handleChange}
                  disabled={!formik.values.agree_screening_guidelines}
                >
                  <option value="minutes">Minutes</option>
                  <option value="seconds">Seconds</option>
                </select>
              </div>
            </div>
          </div>

          {(formik.values.task_parameters?.est_screening_duration && formik.errors.task_parameters?.est_screening_duration
            && !formik.errors.task_parameters.est_screening_duration.includes('required')
          ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_screening_duration}</div>
            : null
          }
        </div>

        <div className="form-group mt-4 row">
          <label>
            <h2 className="text-required mb-0">
              Estimated percent of participants who will screen in (i.e. be eligible for the rest of your activity)
            </h2>
          </label>
          <div className="wrap_info_form help-text mt-1 mb-2">
            <span>
              This estimated rate will be used to estimate the cost of your run. The actual cost of your run
              depends on true screen-in rates once your activity is published.
            </span>
          </div>
          <div className="col-md-5 est_incidence_rate">
            <Input
              id="est_incidence_rate"
              type="text"
              name="task_parameters.est_incidence_rate"
              className="form-control mb-0"
              value={formik.values.task_parameters.est_incidence_rate}
              onChange={handleChange}
              allowOnlyNumbers
              maxLength={3}
              disabled={!formik.values.agree_screening_guidelines}
            />
          </div>
          <br/>
        </div>
        {(formik.values.task_parameters?.est_incidence_rate && formik.errors.task_parameters?.est_incidence_rate
          && !formik.errors.task_parameters.est_incidence_rate.includes('required')
        ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_incidence_rate}</div>
          : null
        }

        <div className="form-group mt-4">
          <span className="text-dark text-bold">
            Your screening activity must send each study participant either to this screen-in link
          </span>
          {' '}(if, based on their answers in the screening activity, you want them to participate in the main activity
          - Positly will then automatically direct these participants to your main activity),{' '}
          <span className="text-dark text-bold">or this screen-out link</span>
          {' '}(if you don't want them to participate in the main study):
        </div>

        <div className="po-text-bold mt-2 pt-1">Screen-In Link</div>
        <div className="po-text mb-2">
          Participants who are <span className="po-text-bold">eligible</span> to continue to your main activity
          should reach this link.
        </div>
        <div className="wrap_input_text d-flex screening-link-wrapper">
          <PositlyLink
            id="mturk-screen-in-link-popup"
            className="copy_link_tooltip completion_link_input correct_link text-overflow-ellipsis"
            path={`/#/start?task_id=${taskId}`}
          />
          <CopyLink
            label="Copy link"
            className="pull-left btn-clipboard feedback copy_link mx-sm-1"
            idToCopy="mturk-screen-in-link-popup"
          />
          {showCopyCodeButton && (
            <>
              <div className="wrap_info_form inline">
                <span>or</span>
              </div>
              <CopyCode
                label="Copy code"
                className="pull-left btn-clipboard copy_code"
                textToCopy={`*goto:${screeninLink(taskId)}`}
                tooltipText="Redirects participants to Screen-In Link for GuidedTrack users"
              />
            </>
          )}
        </div>

        <div className="po-text-bold mt-2 pt-1">Screen-Out Link</div>
        <div className="po-text mb-2">
          Participants who are <span className="po-text-bold">not eligible</span> for your main activity should
          reach this link.
        </div>

        <div className="wrap_input_text d-flex screening-link-wrapper">
          <PositlyLink
            id="cint-screening-link-popup"
            className="copy_link_tooltip completion_link_input text-overflow-ellipsis"
            path="/#/end"
          />
          <CopyLink
            label="Copy link"
            className="pull-left btn-clipboard feedback copy_link mx-sm-1"
            idToCopy="cint-screening-link-popup"
          />
          {showCopyCodeButton && (
            <>
              <div className="wrap_info_form inline">
                <span>or</span>
              </div>
              <CopyCode
                label="Copy code"
                className="pull-left btn-clipboard copy_code"
                textToCopy={`*goto:${screenoutLink}`}
                tooltipText="Redirects participants to Screen-Out Link for GuidedTrack users"
              />
            </>
          )}
        </div>

        <div className="form-group mt-4">
          <label>
            <h2 className="d-flex align-items-center font-size-16">
              Updated cost estimate with screening enabled:
              {isQuoteLoading && formik.values.task_parameters.est_incidence_rate && formik.values.agree_screening_guidelines
                ? <Spinner className="pad-vert-0 ml-4"/>
                : (costEstimate && !formik.errors.task_parameters?.est_incidence_rate && formik.values.agree_screening_guidelines
                    ? <span className="ml-4 po-text">{formatAsCurrency(costEstimate)}</span>
                    : null
                )
              }
            </h2>
          </label>
        </div>

        <div className="light-gray-box mt-4 mb-2 pb-4">
          <h2 className="text-required font-size-16">Set Max Run Budget</h2>
          <div className="wrap_info_form help-text">
              <span>
                This is the amount of credits Positly requires you to have in order to publish your activity.<br/>
                Positly will deduct credits from your account as participants complete your activity.<br/><br/>

                The amount you are charged will depend on actual eligibility rates once your activity is published,
                but you will never be charged more than your Max Budget. Your activity will automatically stop
                when your Max Budget is reached. If you spend less than your Max Budget, the balance will remain
                as available credits, or you can request a refund.{' '}
                <a
                  href="https://www.positly.com/support/how-to-use-positlys-screening-and-filtering-features/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-orange text-underline"
                  tabIndex={-1}
                >
                  Learn more.
                </a>
              </span>
          </div>

          <div className="form-group run-detail mt-3 row">
            <div className="col-md-5 est_max_budget mt-2">
              <Input
                name="task_parameters.est_max_budget"
                type="text"
                className="form-control border-grey mb-0"
                value={!isQuoteLoading && !formik.errors.task_parameters?.est_incidence_rate && formik.values.agree_screening_guidelines
                  ? formik.values.task_parameters.est_max_budget
                  : ''
                }
                onChange={handleChange}
                allowOnlyNumbers
                fractionDigits={2}
                disabled={isQuoteLoading
                  || formik.errors.task_parameters?.est_incidence_rate
                  || !formik.values.agree_screening_guidelines
                }
                onBlur={handleMaxBudgetBlur}
              />
            </div>
          </div>

          {(formik.errors.task_parameters?.est_max_budget
            && !formik.errors.task_parameters.est_max_budget.includes('required')
            && !formik.errors.task_parameters.est_max_budget.includes('cast from the value')
            && formik.values.agree_screening_guidelines
          ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_max_budget}</div>
            : null
          }

          <div className="wrap_link run-mt-3 ml-auto">
            <button
              type="button"
              className="btn btn-default cancel clickable"
              onClick={closeScreeningSetupPopup}
            >
              Cancel setup
            </button>
            <button
              className={`btn btn-primary refresh clickable no-border ${disableForm ? 'btn-grey cursor-disabled' : ''}`}
              onClick={acceptUpdatedCost}
              disabled={disableForm}
            >
              Accept updated cost
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MTurkScreeningSetupPopup
