import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import swal from 'sweetalert'

import {calculateCost, handleCharge} from '../common/Helpers'

import {runUnpauseFetch} from '../../redux/actionss/runActions'

const UnpauseRun = (props) => {
  const [isUnpausing, setIsUnpausing] = useState(false)

  useEffect(() => {
    if (isUnpausing && (props.status === 'success' || props.status === 'error')) {
      swal.close()
      setIsUnpausing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  const isUnPausable = ['Paused', 'Paused Due To Complaints'].includes(props.labelName) &&
    (
      !props.isExpired &&
      !props.pausedForQualityInCint &&
      !props.pausedForRepricingInCint &&
      !props.closedByCint &&
      props.reasonForPause !== 'This run has stopped because you reached your Max Budget. You can create a new run or duplicate this one.' &&
      props.reasonForPause !== 'Throttling' &&
      props.reasonForPause !== 'This run is paused due to quality issues. To proceed, you can create a new run or duplicate this one.' &&
      !props.reasonForPause?.includes('paused due to the lower screen-in rate') &&
      !props.reasonForPause?.includes('paused due to the lower completion rate') &&
      !props.reasonForPause?.includes('paused due to the lower acceptance rate')
    )

  const handleUnPause = (e) => {
    e.preventDefault()
    unpauseRun()
  }

  const unpauseRun = (currentUser = props.currentUser) => {
    const pausedAssignmentsCount = props.unused_submissions
    const assignmentsCost = calculateCost(pausedAssignmentsCount, props.paymentPerSubmission, props.service_fee_percent)

    handleCharge({
      totalCost: assignmentsCost,
      platformType: props.platform_type,
      currentUser: currentUser,
      callback: confirmAndUnpauseRun,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: unpauseRun
    })
  }

  const confirmAndUnpauseRun = () => {
    if (props.reasonForPause === 'Negative feedback' || props.reasonForPause === 'Issues reported by participants') {
      swal({
        title: 'Do you want to unpause run?',
        icon: 'warning',
        text: 'This run is paused due to the frequent negative feedback. Please confirm that you have resolved the feedback',
        buttons: {
          cancel: 'Cancel',
          confirm: {text: 'I\'ve resolved the feedback', className: 'btn-primary'},
          closeModal: false,
        }
      }).then((confirmed) => {
        if (!confirmed) return

        setIsUnpausing(true)
        props.runUnpauseFetch(props.id, props.page)
      })
    } else {
      swal({
        title: 'Do you want to unpause run?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          Unpause: {className: 'btn-primary'},
          closeModal: false,
        }
      }).then((confirmed) => {
        if (!confirmed) return

        setIsUnpausing(true)
        props.runUnpauseFetch(props.id, props.page)
      })
    }
  }

  return (
    <>
      {isUnPausable && props.unpauseUI !== 'button' && (
        <li className="cursor-pointer dropdown-item" onClick={handleUnPause}>
          <span>
            <i className="fa fa-play fa-fw"/>
              {' '}Unpause Run
          </span>
        </li>
      )}

      {isUnPausable && props.unpauseUI === 'button' && (
        <Link
          to=""
          className="btn btn-primary transition-0 clickable pull-right ml-2 mr-2"
          onClick={handleUnPause}
        >
          <i className="fa fa-play"/>
          <span className="fa-text">Unpause Run</span>
        </Link>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  ...state.runUnPause
})

const mapDispatchToProps = dispatch => ({
  runUnpauseFetch: (id, page) => dispatch(runUnpauseFetch(id, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(UnpauseRun)
