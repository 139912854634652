import React, {useState} from 'react'
import {connect} from 'react-redux'

import DatePicker from 'react-datepicker'
import moment from 'moment'

import {
  transactionListClear,
  transactionListFetch,
  transactionListInit,
  transactionListSubmit
} from '../../redux/actions'

const $ = window.$

const TransactionFilter = props => {

  let [startDate, setStartDate] = useState('')
  let [endDate, setEndDate] = useState('')
  const isFormInvalid = () => (!startDate || !endDate)

  const openTransactionFilterPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.open({
      items: {src: '#billing-filter-popup'},
      type: 'inline',
      modal: true
    })
  }
  const closeTransactionFilterPopup = e => {
    e.preventDefault()
    if (!props.isFilterSelected) {
      setStartDate('')
      setEndDate('')
    }
    $.magnificPopup.close()
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    startDate = moment(startDate).format('MM/DD/YYYY')
    endDate = moment(endDate).format('MM/DD/YYYY')

    props.transactionListSubmit()
    props.transactionListFetch(1, startDate, endDate)
    $.magnificPopup.close()
  }
  const launchCalendar = e => {
    e.preventDefault()
    $(e.target).parent().parent().find('input').trigger('click')
  }

  const clearFilters = (e) => {
    e.preventDefault()
    setStartDate('')
    setEndDate('')
    props.transactionListClear()
    props.transactionListFetch(1)
  }

  return (
    <>
      {props.isFilterSelected && (
        <div className="wrapper_link">
          <a
            href="/"
            className="btn btn-primary clickable mr-3 subs-complete pl-2"
            onClick={clearFilters}
          >
            Clear Filter
          </a>
        </div>
      )}
      <div className="wrapper_link mr-15">
        <a
          href="/"
          className="line popup-content cursor-pointer"
          onClick={openTransactionFilterPopup}
        >
          Filter
          {props.isFilterSelected && (
            <span className="number date-filter-selected"/>
          )}
        </a>
      </div>

      <div className="filter white-popup mfp-hide" id="billing-filter-popup">
        <h2>Filter Transactions</h2>
        <form
          className="wrap_info"
          id="billingFilterForm"
          name="billingFilterForm"
          onSubmit={handleSubmit}>
          <div className="input-group date" id="datetimepicker1">
            <label className="mt-2 text-required" htmlFor="fromDate">From Date</label>
            <DatePicker
              filterDate={d => new Date() > d}
              className="form-control startDate"
              id="startDate"
              dateFormat="yyyy/MM/dd"
              placeholderText="yyyy/mm/dd"
              selected={startDate}
              startDate={startDate}
              maxDate={endDate}
              onChange={date => setStartDate(date)}
              autoComplete="off"
              strictParsing
            />
            <span className="input-group-addon">
              <span className="far fa-calendar-alt" onClick={launchCalendar}/>
            </span>
          </div>
          <div className="input-group date" id="datetimepicker2">
            <label className="mt-2 text-required" htmlFor="toDate">To Date</label>
            <DatePicker
              id="endDate"
              dateFormat="yyyy/MM/dd"
              placeholderText="yyyy/mm/dd"
              selected={endDate}
              startDate={endDate}
              minDate={startDate}
              filterDate={d => new Date() > d}
              onChange={date => setEndDate(date)}
              autoComplete="off"
              strictParsing
            />
            <span className="input-group-addon">
              <span className="far fa-calendar-alt" onClick={launchCalendar}/>
            </span>
          </div>
          <div className="wrap_link flex-row">
            <button
              className={`btn btn-primary refresh clickable no-border btn-add-card ${isFormInvalid() ? 'cursor-disabled btn-grey' : ''}`}
              type="submit"
              disabled={isFormInvalid()}
            >
              Apply
            </button>
            <button
              type="button"
              className="btn btn-default cansel clickable"
              onClick={closeTransactionFilterPopup}>
              Close
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({...state.transactionList})

const mapDispatchToProps = dispatch => ({
  transactionListInit: () => dispatch(transactionListInit()),
  transactionListSubmit: () => dispatch(transactionListSubmit()),
  transactionListClear: () => dispatch(transactionListClear()),
  transactionListFetch: (pageNumber, startDate, endDate) => dispatch(transactionListFetch(pageNumber, startDate, endDate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFilter)
