import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import QuotaFeasibilityCount from './QuotaFeasibilityCount'
import NumericInput from '../../../common/NumericInput'

const QuotaListItem = (
  {
    quotaId,
    optionId,
    numberOfSubmissions,
    onOptionChange,
    minAge,
    maxAge,
    gender,
    setShowCensusSource,
    setIsQuotaOptionModified
  }
) => {
  const isDetailsFormValid = useSelector(state => state.runFormOverview.isDetailsFormValid)
  const cintQuotas = useSelector(state => state.runFormOverview.cint_quotas) || []
  const censusData = useSelector(state => state.censusData?.census_data) || []
  const censusOption = censusData.find(option => option.id === optionId) || {}
  const quota = cintQuotas.find(quota => quota.id === quotaId) || {}
  const options = quota.options || []
  const {percent, participants, name} = options.find(option => option.id === optionId) || {}

  const [optionPercent, setOptionPercent] = useState('')
  const [optionParticipants, setOptionParticipants] = useState('')

  const handlePercentChange = (e) => {
    setOptionPercent(e.target.value)
    if (setShowCensusSource) {
      setShowCensusSource(false)
    }
    if (setIsQuotaOptionModified) {
      setIsQuotaOptionModified(true)
    }
  }

  const handleParticipantChange = (e) => {
    setOptionParticipants(e.target.value)
    if (setShowCensusSource) {
      setShowCensusSource(false)
    }
    if (setIsQuotaOptionModified) {
      setIsQuotaOptionModified(true)
    }
  }

  const handleBlur = (e) => {
    const inputValue = e.target.value
    const field = e.target.name.split('_')[0]

    updateCensusForm(inputValue, field)
  }

  const updateCensusForm = (inputValue, field) => {
    if (!numberOfSubmissions || !inputValue) {
      setOptionPercent('0.0')
      setOptionParticipants('0')
      onOptionChange(optionId, 0, 0)
      return
    }

    let percentValue
    let participantsValue
    if (field === 'percent') {
      participantsValue = Math.round(parseFloat(inputValue) * 0.01 * numberOfSubmissions)
    } else {
      participantsValue = parseInt(inputValue)
    }
    percentValue = participantsValue * 100 / numberOfSubmissions

    const formattedPercentValue = percentValue.toFixed(1)
    const formattedParticipantsValue = String(participantsValue)
    setOptionPercent(formattedPercentValue)
    setOptionParticipants(formattedParticipantsValue)
    onOptionChange(optionId, percentValue, participantsValue)
  }


  useEffect(() => {
    if (percent === undefined) return

    const formattedPercent = percent.toFixed(1)
    setOptionPercent(formattedPercent)
  }, [percent])

  useEffect(() => {
    if (participants === undefined) return

    const formattedParticipantsValue = String(participants)
    setOptionParticipants(formattedParticipantsValue)
  }, [participants])

  useEffect(() => {
    if (isNaN(censusOption.participants)) return

    const value = censusOption.participants
    setOptionParticipants(value)
    updateCensusForm(value, 'participants')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [censusOption.participants])

  return (
    <tr>
      <td>{name}</td>
      <td className="percent-wrapper">
        <NumericInput
          name={`percent_${optionId}`}
          value={optionPercent}
          onChange={handlePercentChange}
          onBlur={handleBlur}
          disabled={!isDetailsFormValid}
          allowDecimals={true}
        />
        <span>%</span>
      </td>
      <td>
        <NumericInput
          name={`participants_${optionId}`}
          value={optionParticipants}
          onChange={handleParticipantChange}
          onBlur={handleBlur}
          disabled={!isDetailsFormValid}
        />
      </td>
      <td>
        <QuotaFeasibilityCount
          quotaId={quotaId}
          optionId={optionId}
          minAge={minAge}
          maxAge={maxAge}
          gender={gender}
        />
      </td>
    </tr>
  )
}

export default QuotaListItem
