import React from 'react'
import {Link} from 'react-router-dom'

import ExplainerVideoPopup from '../ExplainerVideoPopup'
import RequestDemoPopup from '../RequestDemoPopup'
import {GA_EVENTS, sendGAEvent} from '../../utils/helper'
import {useSelector} from 'react-redux'

const $ = window.$

const DemoButtons = ({videoStartTimeInSecs = 0, page}) => {
  const positly_user_id = useSelector(state => state.currentUser?.uid)

  const openWatchDemoPopup = (e) => {
    e.preventDefault()
    sendGAEvent(GA_EVENTS.CLICK_EXPLAINER_VIDEO, {positly_user_id, page})
    $.magnificPopup.open({
      items: {src: '#watch-demo-popup'},
      type: 'inline'
    })
  }

  const openRequestADemoPopup = (e) => {
    e.preventDefault()
    sendGAEvent(GA_EVENTS.CLICK_REQUEST_DEMO, {positly_user_id, page})
    $.magnificPopup.open({
      items: {src: '#request-demo-popup'},
      type: 'inline',
      closeOnBgClick: false
    })
  }

  return (
    <>
      <div className="help-btns">
        <Link to="/" className="outline-btn" onClick={openRequestADemoPopup}>
          Get expert help
          <i className="far fa-hand-paper ml-2 mt-1 font-size-16"></i>
        </Link>
        <Link to="/" className="outline-btn" onClick={openWatchDemoPopup}>
          Explainer Video
          <i className="far fa-play-circle ml-2 mt-1 font-size-16"></i>
        </Link>
      </div>

      <ExplainerVideoPopup videoStartTimeInSecs={videoStartTimeInSecs}/>
      <RequestDemoPopup/>
    </>
  )
}

export default DemoButtons
