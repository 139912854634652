import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

// 3rd part components
import Select from 'react-select'

// Custom components
import SaveAsDraftButton from '../SaveAsDraftButton'
import CancelButton from './CancelButton'
import {getActivityLanguageById} from './RunUtils'
import {TABS} from './EnhancedRunForm'

// Redux action
import {
  runFormOverviewAddAdditionalAttribute,
  runFormOverviewRemoveAdditionalAttribute,
} from '../../../redux/actions'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'

const RunFormAdditionalAttributesTab = (props) => {
  // Variables
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedAdditionalAttributes, setSelectedAdditionalAttributes] = useState([])
  const dispatch = useDispatch()

  const createOption = () => {
    if (!props.questions) return []

    let options = []
    Object.keys(props.questions).forEach((key) => {
      const isSelectedAdditionalAttribute = selectedAdditionalAttributes.find((attribute) => {
        return attribute.question_text === props.questions[key].question_text
      })

      const isSelectedDemographic = props.selectedDemographicAttributes.find((attribute) => (
        attribute.question_text === props.questions[key].question_text
      ))

      if (!isSelectedAdditionalAttribute && !isSelectedDemographic) {
        options.push({
          label: props.questions[key].question_text,
          value: props.questions[key],
        })
      }
    })

    return options
  }

  const showLabel = (questionText) => {
    const attribute = props.additionalAttributes.find((attribute) => attribute.question_text === questionText)
    return !props.isMturk || !attribute?.is_demographic
  }

  const isActivityLanguageEnglish = () => (
    props.activityLanguageId && getActivityLanguageById(props.languages, props.activityLanguageId).name === 'English'
  )


  // Event handlers
  const handleQuestionSelection = (option) => {
    if (option && option.value)
      setSelectedOption(option.value)
    else
      setSelectedOption(null)
  }

  const handleAddAdditionalAttribute = () => {
    setSelectedAdditionalAttributes([
      ...selectedAdditionalAttributes,
      selectedOption
    ])
    dispatch(runFormOverviewAddAdditionalAttribute(selectedOption))
    setSelectedOption(null)
  }

  const removeAdditionalAttribute = (e, attributeToRemove) => {
    e.preventDefault()
    const updatedAttributes = selectedAdditionalAttributes
      .filter(attribute => attribute.question_text !== attributeToRemove.question_text)
    setSelectedAdditionalAttributes(updatedAttributes)
    dispatch(runFormOverviewRemoveAdditionalAttribute(attributeToRemove))
  }

  const gotoActivityTab = () => {
    sendGAEvent(GA_EVENTS.CLICK_DESCRIBE_YOUR_ACTIVITY, {positly_user_id: props.userId})
    props.setActiveTab(TABS.ACTIVITY)
  }


  // Side effects
  useEffect(() => {
    if (
      props.questions && props.questions.length
      && props.selectedAdditionalAttributes && props.selectedAdditionalAttributes.length
    ) {
      let selectedAttributes = []
      const selectedAttributesQuestionText = props.selectedAdditionalAttributes.map(attribute => attribute.question_text)

      props.questions.forEach((attribute) => {
        const match = selectedAttributesQuestionText.includes(attribute.question_text)
        if (match) {
          selectedAttributes.push(attribute)
          dispatch(runFormOverviewAddAdditionalAttribute(attribute))
        }
      })

      setSelectedAdditionalAttributes(selectedAttributes)
    }
  }, [dispatch, props.selectedAdditionalAttributes, props.questions])

  useEffect(() => {
    if (props.additionalAttributes && props.additionalAttributes.length !== selectedAdditionalAttributes.length) {
      setSelectedAdditionalAttributes([...props.additionalAttributes])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.additionalAttributes])


  // UI template
  return (
    <>
      {isActivityLanguageEnglish()
        ? (
          <div
            className={`create ${props.isTabActive(TABS.ADDITIONAL_ATTRIBUTES) ? 'active' : 'hide'}`}
            name="runFormAdditionalAttr"
          >
            <div
              className="box gender education select"
              id="filter-additional-attribute"
            >
              <div className="wrap_info_form">
                <span>
                  You will automatically learn the following about your participants since these variables will be passed into your study via the URL
                </span>
              </div>
              <div className="mt-3">
                <div className="wrap_info">
                  <div className="wrap_item">
                    <div className="radio-toolbar">
                      {['Age', 'Gender', 'Income', 'Education'].map((data, index) => (
                        <label key={index} className="success label-additional-attr no-cursor">
                          {data}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box education">
              <h2 className="big mb-1">
                What else do you want to learn about your participants?
              </h2>
              <p className="po-text mb-3">Variables you select will be passed into your study</p>
              {selectedAdditionalAttributes && selectedAdditionalAttributes.length > 0 ? (
                <div className="mt-3">
                  <div className="wrap_info">
                    <div className="radio-toolbar">
                      {selectedAdditionalAttributes.map((question, index) => (
                          showLabel(question.question_text) && (
                            <label key={index} className="po-additional-attribute-checked">
                              <input
                                name={question.filter_label}
                                value={question.filter_label}
                                defaultChecked={true}
                                disabled={true}
                                type="checkbox"
                              />
                              <span>{question.filter_label}</span>
                              <a
                                href="/"
                                className="ml-2"
                                onClick={(e) => removeAdditionalAttribute(e, question)}
                              >
                                <i aria-hidden="true" className="fa fa-times"/>
                              </a>
                            </label>
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {!!createOption().length && (
                <div>
                  <Select
                    className="form-control filter-attributes full-width"
                    id="additional_attribute"
                    name="additional_attribute"
                    options={createOption()}
                    noOptionsMessage={() => 'No results found'}
                    isSearchable
                    value={selectedOption}
                    onChange={handleQuestionSelection}
                    placeholder="Search to add a variable"
                    styles={{
                      container: (styles) => ({
                        ...styles,
                        width: '100%',
                        height: 40,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        appearance: 'none',
                        color: 'var(--input-text-color)',
                        fontSize: 14,
                        letterSpacing: -0.4,
                        fontFamily: 'Muli-SemiBold',
                      }),
                      valueContainer: (styles) => ({...styles, height: 40, paddingLeft: 20}),
                      control: (styles) => ({...styles, border: '1px solid #e2e9ef'}),
                      indicatorSeparator: () => ({display: 'none'}),
                      input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
                      singleValue: (styles) => ({
                        ...styles,
                        color: 'var(--input-text-color)',
                        width: 'calc(100% - 20px)'
                      }),
                      menuList: (styles) => ({...styles, maxHeight: 200}),
                      option: (styles) => ({...styles, paddingLeft: 20}),
                      noOptionsMessage: (styles) => ({...styles, paddingLeft: 20, textAlign: 'left'}),
                      placeholder: (styles) => ({...styles, color: 'var(--placeholder-color)',}),
                    }}
                  />

                  <div className="wrap_link mt-3">
                    <button
                      onClick={handleAddAdditionalAttribute}
                      disabled={!selectedOption}
                      className={`btn btn-primary no-border clickable mb-3 ${selectedOption ? '' : ' cursor-disabled btn-grey'}`}
                      tabIndex="3"
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="row run-form-buttons">
              <div className="col">
                <div className="wrap_link wrap_link_filter d-flex">
                  <CancelButton
                    disabled={props.isSaving || props.isPublishing}
                    onClick={props.handleCancel}
                  />
                  <SaveAsDraftButton
                    isSaving={props.isSaving}
                    disabled={props.isSaving || props.isPublishing}
                    isMturk={props.isMturk}
                    onClick={props.saveAsDraft}
                    setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                    showBudgetChangedWarning={props.showBudgetChangedWarning}
                    isAnyQuotaInvalid={props.isAnyQuotaInvalid}
                  />
                  <button
                    className="btn btn-default back clickable"
                    id="run-participants"
                    tabIndex="15"
                    disabled={props.isSaving || props.isPublishing}
                    onClick={() => props.setActiveTab(TABS.PARTICIPANTS)}
                  >
                    <i className="icon ion-android-arrow-back"/>
                    {TABS.PARTICIPANTS.navigationText}
                  </button>
                  <button
                    className="btn btn-primary no-border clickable transition-0"
                    tabIndex="16"
                    disabled={props.isSaving || props.isPublishing}
                    onClick={gotoActivityTab}
                  >
                    {TABS.ACTIVITY.navigationText}
                    <i className="ml-2 icon ion-android-arrow-forward"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`box ${props.isTabActive(TABS.ADDITIONAL_ATTRIBUTES) ? 'active' : 'hide'}`}>
            <span className="mb-2 po-text text-danger">
              Additional attributes are available only for studies conducted in English
            </span>
          </div>
        )}
    </>
  )
}

const mapStateToProps = state => {
  if (!state.runForm || !state.runFormOverview || !state.additionalAttrQuestionsList)
    return {}

  return {
    questions: state.additionalAttrQuestionsList.questions || [],
    selectedDemographicAttributes: state.runFormOverview.filters || [],
    selectedAdditionalAttributes: state.runForm.additional_attributes || [],
    additionalAttributes: state.runFormOverview.additional_attributes || [],
    languages: state.activityLanguages?.languages,
    activityLanguageId: state.runFormOverview.activity_language_id,
    userId: state.currentUser?.uid
  }
}

export default connect(mapStateToProps)(RunFormAdditionalAttributesTab)
