import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

// 3rd party
import {connect} from 'react-redux'
import {useFormik} from 'formik'

// React components
import CircleProgress from '../CircleProgress'
import Spinner from '../common/Spinner'
import TruncateText from '../common/TruncateText'
import Currency from '../common/Currency'
import RequestDemoPopup from '../RequestDemoPopup'
import ExplainerVideoPopup from '../ExplainerVideoPopup'

// Redux Actions
import {projectListFetch, projectListInit} from '../../redux/actionss/projectActions'

// Assets
import p100 from '../../images/p100.svg'
import {routes} from '../../routes'
import {GA_EVENTS, sendGAEvent} from '../../utils/helper'

const $ = window.$

const ProjectListItem = (props) => {
  const projectListItemUrl = props.runs_count === 0
    ? `/runs/create?project_id=${props.id}`
    : `/projects/show?id=${props.id}`

  return (
    <Link to={projectListItemUrl}>
      <div className="row list-item infinitely-loaded cursor-pointer">
        <div className="col-md-4">
          <span className="list-item-label po-text-bold pr-1">Project:</span>
          <TruncateText text={props.project_name} max={50}/>
        </div>
        <div className="col-md-2">
          <span className="list-item-label po-text-bold pr-1">Runs:</span>
          {props.runs_count > 0 && props.runs_count}
        </div>

        <div className="col-md-4 completed text-center circle-progress-align d-flex">
          <span className="list-item-label po-text-bold pr-1">Completed:</span>
          <div id="circle">
            {props.completion_percentage === 100 && <img alt="" src={p100}/>}
            {props.completion_percentage < 100 && props.total_submissions > 0 && (
              <CircleProgress
                percent={props.completion_percentage}
                color={props.is_running ? '#5cc74d' : '#b2bbc5'}
                size={20}
              />
            )}
          </div>
          {props.total_submissions > 0 && (
            <p>
              {props.number_of_submissions_completed} of {props.total_submissions + ' '}
              ({props.completion_percentage}%)
            </p>
          )}
        </div>
        <div className="col-md-2">
          <p>
            <span className="list-item-label po-text-bold pr-1">Spent:</span>
            {props.total_spent > 0 ? <Currency value={props.total_spent}/> : ''}
          </p>
        </div>
      </div>
    </Link>

  )
}

const ProjectList = (props) => {
  const tasksPerPage = parseInt(((window.innerHeight - 220) / 50).toString()) + 5

  useEffect(() => {
    props.projectListInit()
    props.projectListFetch(1, tasksPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isSearched, setIsSearched] = useState(false)
  const formik = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: values => {
      setIsSearched(true)
      props.projectListInit()
      props.projectListFetch(1, tasksPerPage, values.query)
    }
  })

  const handleClear = () => {
    if (isSearched === true && formik.values.query === '') {
      props.projectListFetch(1, tasksPerPage)
      setIsSearched(false)
    }
  }

  const openWatchDemoPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.open({
      items: {src: '#watch-demo-popup'},
      type: 'inline'
    })
    sendGAEvent(GA_EVENTS.CLICK_EXPLAINER_VIDEO, {positly_user_id: props.currentUser.uid, page: 'project_list'})
  }

  const openRequestADemoPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.open({
      items: {src: '#request-demo-popup'},
      type: 'inline',
      closeOnBgClick: false
    })
    sendGAEvent(GA_EVENTS.CLICK_REQUEST_DEMO, {positly_user_id: props.currentUser.uid, page: 'project_list'})
  }

  const fetchProjects = () => props.projectListFetch(
    props.pageNumber + 1,
    tasksPerPage,
    formik.values.query
  )

  const handleCreateProjectBtnClick = () => {
    sendGAEvent(GA_EVENTS.CLICK_CREATE_PROJECT, {positly_user_id: props.currentUser.uid})
  }

  const isProjectListEmpty = props.projects?.length === 0 && !isSearched && !props.isLoading
  const isSearchResultEmpty = props.projects?.length === 0 && isSearched && !props.isLoading

  return (
    <>
      {!props.currentUser?.is_confirmation_time_expired && (
        <div>
          {props.currentUser && (
            <div>
              {isProjectListEmpty && (
                <div className="po-text text-dark pt-2 mt-3 font-size-16">
                  Create your first project to explore pricing and targeting options
                </div>
              )}
              <div className={`project-list-btns ${isProjectListEmpty ? 'mt-4 pt-1' : 'mt-5'}`}>
                <div>
                  <div className="btns">
                    <Link to="/projects/create" className="primary-btn" onClick={handleCreateProjectBtnClick}>
                      Create Project
                      <i className="fas fa-plus ml-2 mt-1 font-size-16"></i>
                    </Link>
                    <Link to="/" className="outline-btn" onClick={openWatchDemoPopup}>
                      Explainer Video
                      <i className="far fa-play-circle ml-2 mt-1 font-size-18"></i>
                    </Link>
                    <Link to="/" className="outline-btn" onClick={openRequestADemoPopup}>
                      Get expert help
                      <i className="far fa-hand-paper ml-2 mt-1 font-size-18"></i>
                    </Link>
                  </div>
                  <div className="po-text mt-2">
                    Or you can view the{' '}
                    <a
                      href="https://www.positly.com/knowledge-base/"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Knowledge Base
                    </a>
                    {' '}or contact{' '}
                    <Link to={routes.CONTACT_US} className="link">Customer Support</Link>
                  </div>
                </div>
                {(props.projects?.length > 0 || isSearched) && (
                  <div className="row task-list-header justify-content-end m-0 project-search ml-auto">
                    <form onSubmit={formik.handleSubmit} className="project-search">
                      <div className="input-group">
                        <input
                          type="text"
                          name="query"
                          id="query"
                          placeholder="Search projects"
                          className="form-control input-box"
                          onChange={formik.handleChange}
                          value={formik.values.query}
                          onBlur={handleClear}
                        />
                        <input
                          type="submit"
                          disabled={props.isLoading}
                        />
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          )}

          {props.isLoading && <Spinner/>}

          {props.projects?.length > 0 && (
            <div className="wrap_projects mt-4">
              <div className="row list-item list-heading">
                <div className="col-md-4 text-left">Project</div>
                <div className="col-md-2 text-left">Runs</div>
                <div className="col-md-4">Completed</div>
                <div className="col-md-2">Spent</div>
              </div>
              <InfiniteScroll
                dataLength={props.projects ? props.projects.length : 0}
                next={fetchProjects}
                hasMore={props.hasMore}
                loader={<Spinner/>}>
                {props.projects?.map(project => <ProjectListItem key={project.id} {...project} />)}
              </InfiniteScroll>
            </div>
          )}

          {isProjectListEmpty && (
            <div className="wrap_projects mt-4">
              <div className="row list-item list-heading">
                <div className="col-md-4 text-left">Project</div>
                <div className="col-md-2 text-left">Runs</div>
                <div className="col-md-4">Completed</div>
                <div className="col-md-2">Spent</div>
              </div>
              <div className="row po-text p-3 justify-content-center">
                Your projects will appear here
              </div>
            </div>
          )}

          {isSearchResultEmpty && (
            <div
              className="project_block no_data mt-4">
              <div className="wrap_top">
                <p>
                  No results found
                </p>
              </div>
            </div>
          )}

          {/* Explainer video popup */}
          <ExplainerVideoPopup/>

          {/* Request demo popup */}
          <RequestDemoPopup/>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    ...state.projectList,
    currentUser: state.currentUser

  }
}

const mapDispatchToProps = dispatch => ({
  projectListInit: () => dispatch(projectListInit()),
  projectListFetch: (pageNumber, tasksPerPage, query) => dispatch(projectListFetch(pageNumber, tasksPerPage, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)
