import React from 'react'

const LowEligibleParticipantsWarning =
  ({
     isLoading,
     isMturk,
     eligibleParticipantsCountForMturk,
     eligibleParticipantsCountForCint,
     expiresAfter,
     numberOfSubmissions,
     maxTimePerSubmission,
     maxInterviewLength,
     className
   }) => {

    const showLowEligibleParticipantsWarning = () => {
      if (!numberOfSubmissions || isLoading) return false

      if (isMturk) return eligibleParticipantsCountForMturk < numberOfSubmissions

      if (!isMturk && (!maxTimePerSubmission || !expiresAfter)) return false

      if (!isMturk && maxTimePerSubmission > maxInterviewLength) return false

      return eligibleParticipantsCountForCint < numberOfSubmissions
    }

    return (
      <>
        {showLowEligibleParticipantsWarning() && (
          <div className={`notice ml-0 mr-0 pb-2 highlight-box ${className ? className : ''}`}>
          <span>
            Warning: Your desired number of activity completions exceeds the eligible participants for the
            study. To ensure your study can be completed, please adjust your number of participants or
            modify/remove filters.
          </span>
          </div>
        )}
      </>
    )
  }

export default LowEligibleParticipantsWarning
