import React from 'react'

const FormField = (props) => {
  const id = props.id
  const formik = props.formik

  return (
    <div className={`form-group ${props.className}`}>
      {props.type !== 'checkbox' && (
        <label
          className={props.required ? 'text-required' : ''}
          htmlFor={props.id}>
          {props.label}
        </label>
      )}
      {props.type === 'checkbox' && (
        <div className="radio-checkbox">
          <label>
            <input
              type="checkbox"
              id={props.id}
              className="checkbox"
              onChange={formik.handleChange}
              value={formik.values[props.id]}
            />
            <span className="checkbox-custom"/>
            <span className="label">{props.label}</span>
          </label>
        </div>
      )}
      {props.type !== 'checkbox' && (
        <div className="input-group">
          <input
            className="po-text mb-0"
            id={props.id}
            type={props.type}
            onChange={formik.handleChange}
            autoComplete={props.autocomplete ? props.autocomplete : ''}
            value={formik.values[id]}
            {...(props.input || {})}
          />
          {props.inputAppend && props.inputAppend()}
        </div>
      )}
      {props.helpBlock && props.helpBlock()}
      {(formik.touched[id]) && formik.errors[id] ? (
        <p className="po-text text-red-soft">{formik.errors[id]}</p>
      ) : null}
    </div>
  )
}

export default FormField
