import React from 'react'

import QualityAttributes from './QualityAttributes'
import DemographicAttributes from './DemographicAttributes'
import CustomAttributes from './CustomAttributes'
import CintAttributes from './CintAttributes'
import CintQuotas from './CintQuotas'

const ParticipantAttributes = props => {
  return (
    <div className="row middle_box">
      <div className="col-lg-12">
        <h3 className="no-margin">Participant Attributes</h3>

        <QualityAttributes {...props}/>

        {!!props.demographicAttributes.length && props.isMturk && (
          <DemographicAttributes attributes={props.demographicAttributes}/>
        )}

        {!!props.customAttributes.length && props.isMturk && (
          <CustomAttributes attributes={props.customAttributes}/>
        )}

        {(!!props.cintAttributes.length || props.age_min || props.age_max || props.gender) && (
          <CintAttributes {...props} attributes={props.cintAttributes}/>
        )}

        <CintQuotas quotas={props.cintQuotas}/>
      </div>
    </div>
  )
}

export default ParticipantAttributes
