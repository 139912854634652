import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import UpdateSpendingLimit from '../UpdateSpendingLimit'

const $ = window.$

const SpendingLimitWarning = ({showSpendingLimitWarning, setShowSpendingLimitWarning, message}) => {
  const defaultMessage = 'The cost of this run is higher than your current spending limit. The spending limit is only ' +
    'there to protect you from accidentally spending more than you intended. You can change your spending limit in the ' +
    'Billing section of your account.'

  const openSpendingLimitWarningPopup = () => {
    $.magnificPopup.open({
      items: {src: `#spending-limit-popup`},
      type: 'inline',
      closeOnBgClick: false
    })
  }

  const closeSpendingLimitWarningPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  useEffect(() => {
    if (showSpendingLimitWarning) openSpendingLimitWarningPopup()

    return () => setShowSpendingLimitWarning(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpendingLimitWarning])

  return (
    <div className="white-popup alert mfp-hide" id="spending-limit-popup">
      <div className="wrap_info po-text">
        <div className="wrap_item">
          <div className="form-group input-group">
            {message
              ? message
              : defaultMessage
            } {' '}
            <UpdateSpendingLimit ui="showOnlyPopup" needsConfirmation={false}/>
          </div>
        </div>
        <div className="wrap_link text-center">
          <Link
            to=""
            className="btn btn-primary clickable"
            onClick={closeSpendingLimitWarningPopup}
          >
            Okay
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SpendingLimitWarning