import React from 'react'
import {useSelector} from 'react-redux'

const QuotaTotal = ({isTotalPercentInvalid, isTotalParticipantsInvalid, total, showSource = false}) => {
  const censusSource = useSelector(state => state.censusData?.census_source) || false

  return (
    <tr className="footer mt-2 text-dark">
      <td className="text-align-right">
        {showSource && (
          <span
            className="float-left font-size-13 po-text"
          >
            Census source: <b className="text-dark">{censusSource}</b>
          </span>
        )}
        <span className="pr-2">Totals</span>
      </td>
      <td className={isTotalPercentInvalid ? 'text-red-soft' : null}>
        {total.percent.toFixed()}%
      </td>
      <td className={isTotalParticipantsInvalid ? 'text-red-soft' : null}>
        {total.participants}
      </td>
      <td></td>
    </tr>
  )
}

export default QuotaTotal
