import {runActions} from '../actionss/runActions'

const runReducer = (state, action) => {
  switch (action.type) {
    case runActions.RUN_INIT:
      return {...state, runDetail: {isLoading: true}}
    case runActions.RUN_SUCCESS:
      return {...state, runDetail: {isLoading: false, run: action.run}}
    case runActions.RUN_ERROR:
      return {...state, runDetail: {isLoading: false, errors: action.errors}}

    case runActions.RUN_PROGRESS_INIT:
      return {...state, runProgress: {isLoading: true}}
    case runActions.RUN_PROGRESS_SUCCESS:
      return {...state, runProgress: {isLoading: false, ...action.run}}
    case runActions.RUN_PROGRESS_ERROR:
      return {...state, runProgress: {isLoading: false, errors: action.errors}}

    case runActions.RUN_FEEDBACK_INIT:
      return {...state, runFeedback: {isLoading: true, feedbacks: []}}
    case runActions.RUN_FEEDBACK_SUCCESS:
      return {
        ...state,
        runFeedback: {
          isLoading: false,
          feedbacks: [...state.runFeedback.feedbacks, ...action.feedbacks],
          hasMore: action.hasMore,
          pageNumber: action.pageNumber
        }
      }
    case runActions.RUN_FEEDBACK_ERROR:
      return {
        ...state,
        runFeedback: {
          isLoading: false,
          feedbacks: state.runFeedback.feedbacks,
          errors: action.errors
        }
      }

    case runActions.RUN_PARTICIPANTS_INIT:
      return {...state, runParticipants: {isLoading: true, assignments: []}}
    case runActions.RUN_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        runParticipants: {
          isLoading: false,
          assignments: [...state.runParticipants?.assignments || [], ...action.assignments],
          hasMore: action.hasMore,
          pageNumber: action.pageNumber
        }
      }
    case runActions.RUN_PARTICIPANTS_ERROR:
      return {
        ...state,
        runParticipants: {
          isLoading: false,
          assignments: state.runParticipants?.assignments || [],
          errors: action.errors
        }
      }

    case runActions.RUN_REJECTED_ASSIGNMENTS_INIT:
      return {...state, rejectedAssignmentsList: {isLoading: true}}
    case runActions.RUN_REJECTED_ASSIGNMENTS_SUCCESS:
      return {...state, rejectedAssignmentsList: {isLoading: false, rejectedAssignments: action.rejectedAssignments}}
    case runActions.RUN_REJECTED_ASSIGNMENTS_ERROR:
      return {
        ...state,
        rejectedAssignmentsList: {
          isLoading: false,
          rejectedAssignments: state.rejectedAssignmentsList.rejectedAssignments,
          errors: action.errors
        }
      }

    case runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_INIT:
      return {...state, qualityRejectedAssignmentsList: {isLoading: true}}
    case runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        qualityRejectedAssignmentsList: {
          isLoading: false,
          qualityRejectedAssignments: action.qualityRejectedAssignments
        }
      }
    case runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_ERROR:
      return {
        ...state,
        qualityRejectedAssignmentsList: {
          isLoading: false,
          qualityRejectedAssignments: state.qualityRejectedAssignmentsList.qualityRejectedAssignments,
          errors: action.errors
        }
      }

    case runActions.RUN_REJECTED_ASSIGNMENTS_UPDATE:
      if (!state.rejectedAssignmentsList)
        return state

      let updatedAssignments = state.rejectedAssignmentsList?.rejectedAssignments || []
      updatedAssignments = updatedAssignments.map(assignment => assignment.id === action.rejectedAssignment.id ? action.rejectedAssignment : assignment)

      const isExists = updatedAssignments.some(assignment => assignment.id === action.rejectedAssignment.id)
      if (!isExists)
        updatedAssignments = [...updatedAssignments, action.rejectedAssignment]

      return {
        ...state,
        rejectedAssignmentsList: {
          ...state.rejectedAssignmentsList,
          rejectedAssignments: [...updatedAssignments]
        }
      }

    case runActions.RUN_SEND_BONUS_INIT:
      return {...state, sendBonus: {}}
    case runActions.RUN_SEND_BONUS_SUBMIT:
      return {...state, sendBonus: {isLoading: true}}
    case runActions.RUN_SEND_BONUS_SUCCESS: {
      return {
        ...state,
        sendBonus: {isLoading: false, status: 'success'}
      }
    }
    case runActions.RUN_SEND_BONUS_ERROR:
      return {
        ...state,
        sendBonus: {
          isLoading: false,
          errors: action.errors,
          missingIds: action.missingIds,
          fresh_participant_ids: action.fresh_participant_ids
        }
      }

    case runActions.RUN_BONUS_SENT_INIT:
      return {...state, bonusesSent: {isLoading: true}}
    case runActions.RUN_BONUS_SENT_SUCCESS:
      return {
        ...state,
        bonusesSent: {
          status: 'success',
          bonuses: action.bonuses
        }
      }
    case runActions.RUN_BONUS_SENT_ERROR:
      return {...state, bonusesSent: {errors: action.errors}}

    case runActions.RUN_SEND_MESSAGE_INIT:
      return {
        ...state,
        participantSendMessage: {}
      }
    case runActions.RUN_SEND_MESSAGE_SUBMIT:
      return {
        ...state,
        participantSendMessage: {
          isLoading: true
        }
      }
    case runActions.RUN_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        participantSendMessage: {
          status: 'success'
        }
      }
    case runActions.RUN_SEND_MESSAGE_ERROR:
      return {
        ...state,
        participantSendMessage: {
          errors: action.errors,
          fresh_participant_ids: action.fresh_participant_ids,
          missingIds: action.missingIds
        }
      }

    case runActions.RUN_SENT_MESSAGES_INIT:
      return {...state, sentMessages: {isLoading: true}}
    case runActions.RUN_SENT_MESSAGES_SUCCESS:
      return {
        ...state,
        sentMessages: {
          isLoading: false,
          status: 'success',
          messages: action.sentMessages
        }
      }
    case runActions.RUN_SENT_MESSAGES_ERROR:
      return {...state, sentMessages: {isLoading: false, errors: action.errors}}

    case runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_INIT:
      return {
        ...state,
        participantApproveAssignment: {isLoading: true}
      }
    case runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        participantApproveAssignment: {
          status: 'success',
          isLoading: false
        }

      }
    case runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_ERROR:
      return {
        ...state,
        participantApproveAssignment: {
          status: 'error',
          errors: action.errors
        }
      }
      
    case runActions.RUN_ASSIGNMENT_STATUS_UPDATE:
      const assignments = [...state.runParticipants?.assignments]
      const index = assignments.findIndex(assignment => assignment.id === action.id)
      if (action.assignment) {
        assignments[index] = {...action.assignment}
      } else {
        assignments[index].status_readable = action.status_readable
        assignments[index].status = action.status
      }

      const rejectedAssignments = [...(state.rejectedAssignmentsList?.rejectedAssignments || [])]
      if (action.status === 'approved_after_rejection') {
        const rejectIndex = rejectedAssignments.findIndex(assignment => assignment.id === action.id)
        rejectedAssignments.splice(rejectIndex, 1)
      }

      return {
        ...state,
        rejectedAssignmentsList: {
          ...state.rejectedAssignmentsList,
          rejectedAssignments: rejectedAssignments
        },
        runParticipants: {
          ...state.runParticipants,
          assignments: assignments
        }
      }
      
    case runActions.RUN_FEEDBACK_ASSIGNMENT_STATUS_UPDATE: {
      const feedbacks = [...state.runFeedback?.feedbacks]
      const index = feedbacks.findIndex(feedback => feedback.assignment_id === action.id)
      feedbacks[index].assignment_status = action.status

      return {
        ...state,
        runFeedback: {
          ...state.runFeedback,
          feedbacks: feedbacks
        }
      }
    }

    case runActions.RUN_REJECT_PARTICIPANTS_INIT: {
      let localState
      if (action.is_quality_rejection)
        localState = {qualityRejectParticipants: {isLoading: true}}
      else
        localState = {rejectParticipants: {isLoading: true}}

      return {...state, ...localState}
    }
    case runActions.RUN_REJECT_PARTICIPANTS_SUCCESS: {
      let localState
      let runLabel = {}
      if (action.run_status)
        runLabel = {label_name: action.run_status}

      if (action.is_quality_rejection) {
        localState = {
          runDetail: {
            ...state.runDetail,
            run: {
              ...state.runDetail.run,
              ...runLabel,
              rejected_due_to_low_quality_count: (state.runDetail.run.rejected_due_to_low_quality_count - action.rejected_assignment_count)
            }
          },
          qualityRejectParticipants: {isLoading: false, status: 'success'}
        }
      } else {
        localState = {
          runDetail: {
            ...state.runDetail,
            run: {
              ...state.runDetail.run,
              ...runLabel,
              rejected_count: (state.runDetail.run.rejected_count - action.rejected_assignment_count)
            }
          },
          rejectParticipants: {isLoading: false, status: 'success'}
        }
      }

      return {...state, ...localState}
    }
    case runActions.RUN_REJECT_PARTICIPANTS_ERROR: {
      let localState
      if (action.is_quality_rejection)
        localState = {qualityRejectParticipants: {isLoading: false, errors: action.errors}}
      else
        localState = {rejectParticipants: {isLoading: false, errors: action.errors}}

      return {...state, ...localState}
    }

    case runActions.RUN_REJECT_ASSIGNMENT_INIT:
      return {
        ...state,
        rejectAssignment: {isLoading: true}
      }
    case runActions.RUN_REJECT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        rejectAssignment: {isLoading: false},
      }
    case runActions.RUN_REJECT_ASSIGNMENT_FAIL:
      return {
        ...state,
        rejectAssignment: {
          isLoading: false,
          errors: action.errors
        }
      }

    case runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_INIT:
      return {
        ...state,
        blockParticipantAndRejectAssignment: {isLoading: true}
      }
    case runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        blockParticipantAndRejectAssignment: {isLoading: false},
      }
    case runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_FAIL:
      return {
        ...state,
        blockParticipantAndRejectAssignment: {
          isLoading: false,
          errors: action.errors
        }
      }

    case runActions.RUN_INCREASE_VISIBILITY_INIT:
      return {...state, runIncreaseVisibility: {isLoading: true}}
    case runActions.RUN_INCREASE_VISIBILITY_SUCCESS:
      return {...state, runIncreaseVisibility: {isLoading: false, status: 'success'}}
    case runActions.RUN_INCREASE_VISIBILITY_ERROR:
      return {...state, runIncreaseVisibility: {isLoading: false, status: 'error'}}

    case runActions.RUN_PAUSE_INIT:
      return {...state, runPause: {}}
    case runActions.RUN_PAUSE_SUBMIT:
      return {...state, runPause: {isLoading: true}}
    case runActions.RUN_PAUSE_SUCCESS:
      return {...state, runPause: {status: 'success', runId: action.id}}
    case runActions.RUN_PAUSE_ERROR:
      return {...state, runPause: {status: 'error', runId: action.id}}

    case runActions.RUN_UNPAUSE_INIT:
      return {...state, runUnPause: {isLoading: true}}
    case runActions.RUN_UNPAUSE_SUCCESS:
      return {...state, runUnPause: {status: 'success', runId: action.id}}
    case runActions.RUN_UNPAUSE_ERROR:
      return {...state, runUnPause: {status: 'error', runId: action.id}}

    case runActions.RUN_EXTEND_INIT:
      return {...state, runExtend: {}}
    case runActions.RUN_EXTEND_SUBMIT:
      return {...state, runExtend: {isLoading: true, status: 'loading'}}
    case runActions.RUN_EXTEND_SUCCESS:
      if (action.updateRunDetails) {
        return {
          ...state,
          runDetail: {
            ...state.runDetail,
            run: action.run
          },
          runExtend: {isLoading: false, status: 'success'}
        }
      } else {
        let runs = state.projectRuns.runs
        let runIndex = runs.findIndex((run) => run.id === action.run.id)
        if (runIndex > -1)
          runs[runIndex].number_of_submissions = action.run.number_of_submissions
        return {
          ...state,
          projectRuns: {
            ...state.projectRuns,
            runs: [...state.projectRuns.runs],
          },
          runExtend: {isLoading: false, status: 'success'}
        }
      }
    case runActions.RUN_EXTEND_ERROR:
      return {...state, runExtend: {status: 'error', errors: action.errors}}

    case runActions.RUN_DELETE_INIT:
      return {...state, runDelete: {}}
    case runActions.RUN_DELETE_SUBMIT:
      return {...state, runDelete: {isLoading: true, status: 'submit'}}
    case runActions.RUN_DELETE_SUCCESS:
      let runs = state.projectRuns?.runs || []
      runs = runs.filter(run => run.id !== action.id)
      return {
        ...state,
        runDelete: {
          isLoading: false,
          isRunDeleted: true,
        },
        projectRuns: {
          runs: runs
        }
      }
    case runActions.RUN_DELETE_ERROR:
      return {...state, runDelete: {isLoading: false, errors: action.errors}}

    case runActions.RUN_STATUS_UPDATE:
      if (action.page === 'project') {
        const runs = [...state.projectRuns.runs]
        const runIndex = runs.findIndex(run => run.id === action.id)
        runs[runIndex].label_name = action.status

        return {
          ...state,
          projectRuns: {
            ...state.projectRuns,
            runs: runs
          }
        }
      } else {
        return {
          ...state,
          runDetail: {
            ...state.runDetail,
            run: {
              ...state.runDetail.run,
              label_name: action.status
            }
          }
        }
      }

    case runActions.RUN_UPDATE_STATE_CHANGE:
      if (action.ui === 'project') {
        const runs = [...state.projectRuns.runs]
        const runIndex = runs.findIndex(run => run.id === action.run.id)
        const runToUpdate = runs.find(run => run.id === action.run.id)
        runs[runIndex] = {...runToUpdate, ...action.run}

        return {
          ...state,
          projectRuns: {
            ...state.projectRuns,
            runs: runs
          }
        }
      } else {
        return {
          ...state,
          runDetail: {
            ...state.runDetail,
            run: {
              ...state.runDetail.run,
              ...action.run
            }
          },
          runProgress: {
            ...state.runProgress,
            ...action.run
          }
        }
      }

    case runActions.RUN_PARTICIPANT_ATTRIBUTES_INIT:
      return {
        ...state,
        participantAttributes: {
          isLoading: true
        },
        participantCurrentAttributes: {},
        participantSetAttribute: {}
      }
    case runActions.RUN_PARTICIPANT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        participantAttributes: {
          isLoading: false,
          ...action.attributes
        },
      }
    case runActions.RUN_PARTICIPANT_ATTRIBUTES_ERROR:
      return {
        ...state,
        participantAttributes: {
          isLoading: false,
          errors: action.errors
        }
      }

    case runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_INIT:
      return {
        ...state,
        participantCurrentAttributes: {
          isLoading: true
        }
      }
    case runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        participantCurrentAttributes: {
          isLoading: false,
          ...action.attributes
        }
      }
    case runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_ERROR:
      return {
        ...state,
        participantCurrentAttributes: {
          isLoading: true,
          errors: action.errors
        }
      }

    case runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_INIT:
      return {
        ...state,
        participantSetAttribute: {isLoading: true}
      }
    case runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        participantSetAttribute: {
          isLoading: false,
          status: 'success'
        },
        participantCurrentAttributes: {}
      }
    case runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_ERROR:
      return {
        ...state,
        participantSetAttribute: {
          status: 'error',
          errors: action.errors,
          missingIds: action.missingIds
        }
      }

    case runActions.RUN_RESET:
      return {
        ...state,
        runDetail: null,
        runProgress: null,
        runFeedback: null,
        runParticipants: null,
        rejectedAssignmentsList: null,
        qualityRejectedAssignmentsList: null,
        filtersList: null,
      }

    default:
      return state
  }
}

export default runReducer
