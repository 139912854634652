import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import qs from 'qs'

import {checkSlotAvailability} from '../redux/actions'

const CheckSlotAvailability = ({location}) => {
  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
  let {id} = queryParams

  // Truncate any extra characters
  if (id && id.length > 8) id = id.substr(0, 8)

  const dispatch = useDispatch()

  const {
    isLoading,
    status,
    redirectUrl,
    errors
  } = useSelector(state => state.checkSlotAvailability || {})

  useEffect(() => {
    if (!id) return

    dispatch(checkSlotAvailability(id))
  }, [dispatch, id])

  useEffect(() => {
    if (status !== 'success') return

    if (redirectUrl) window.location.href = redirectUrl
  }, [redirectUrl, status])

  return (
    <div className="row">
      <div className="box po-text">
        {isLoading
          ? <h3>Please wait...</h3>
          : (status === 'success'
              ? <h3>Redirecting to activity...</h3>
              : <h3 className="text-danger">{errors}</h3>
          )
        }
      </div>
    </div>
  )
}

export default CheckSlotAvailability
