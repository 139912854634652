import {useEffect, useState} from 'react'

const getFormattedCountDownValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return {days, hours, minutes, seconds}
}

const useCountdown = (targetDate, onCountDownExpired) => {
  const targetTime = new Date(targetDate).getTime()
  const getCountDownTime = () => targetTime - new Date().getTime()

  const [countDown, setCountDown] = useState(getCountDownTime())

  useEffect(() => {
    if (countDown < 0) return onCountDownExpired()

    const interval = setInterval(() => {
      const countDownTime = getCountDownTime()
      if (countDownTime < 0) return onCountDownExpired()

      setCountDown(countDownTime)
    }, 1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetTime])

  return getFormattedCountDownValues(countDown)
}

export {useCountdown}
