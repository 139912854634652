import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'

const TestLinkVerificationStatus = ({isMturk, isScreeningEnabled, isModal = false}) => {
  const {
    screenInLinkVerifiedAt,
    screenOutLinkVerifiedAt,
    completionLinkVerifiedAt
  } = useSelector(state => ({
    screenInLinkVerifiedAt: state.runFormOverview.screen_in_link_verified_at,
    screenOutLinkVerifiedAt: state.runFormOverview.screen_out_link_verified_at,
    completionLinkVerifiedAt: state.runFormOverview.completion_link_verified_at,
  }))

  const getClassName = (linkVerifiedAt) => linkVerifiedAt ? 'success-tick-filled' : ''


  const lastTestedAt = useMemo(() => {
    const verifiedAt = []
    if (completionLinkVerifiedAt) verifiedAt.push(completionLinkVerifiedAt)
    if (isScreeningEnabled && screenOutLinkVerifiedAt) verifiedAt.push(screenOutLinkVerifiedAt)
    if (isMturk && isScreeningEnabled && screenInLinkVerifiedAt) verifiedAt.push(screenInLinkVerifiedAt)

    return verifiedAt.sort()[verifiedAt.length - 1] // Get the last verified timestamp
  }, [
    isMturk,
    isScreeningEnabled,
    screenInLinkVerifiedAt,
    screenOutLinkVerifiedAt,
    completionLinkVerifiedAt
  ])


  return (
    <>
      <div className="po-text mt-3 test-status po-text-bold pb-2">
        {isMturk && isScreeningEnabled && (
          <div>
            <span className={getClassName(screenInLinkVerifiedAt)}>Screen-in link</span>
          </div>
        )}
        {isScreeningEnabled && (
          <div>
            <span className={getClassName(screenOutLinkVerifiedAt)}>Screen-out link</span>
          </div>
        )}
        <div>
          <span className={getClassName(completionLinkVerifiedAt)}>Completion link</span>
        </div>
      </div>

      {lastTestedAt && !isModal && (
        <div className="po-text text-center mt-4">
          Last tested: {lastTestedAt}
        </div>
      )}

      {isModal && screenInLinkVerifiedAt && screenOutLinkVerifiedAt && completionLinkVerifiedAt && (
        <div className="po-text text-center text-success mt-3">
          Your links are all present! You can close this test. <br/>
          If you make changes to your activity, don’t forget to test again.
        </div>
      )}
    </>
  )
}

export default TestLinkVerificationStatus
