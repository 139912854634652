import React from 'react'
import {useCountdown} from '../../hooks/useCountDown'
import {formatTime} from '../../utils/helper'

const Countdown = ({targetDate = new Date(), className = '', onCountDownExpired}) => {
  const {days, hours, minutes, seconds} = useCountdown(targetDate, onCountDownExpired)

  const formattedDays = formatTime(days)
  const formattedHours = formatTime(hours)
  const formattedMinutes = formatTime(minutes)
  const formattedSeconds = formatTime(seconds)

  return (
    <div className={`countdown ${className}`}>
      {/* Days */}
      {days > 0 && (
        <>
          <div className="days countdown-value">
            <span>{formattedDays}</span>
            <span className="countdown-label">{days < 2 ? 'day' : 'days'}</span>
          </div>
          <div className="separator">:</div>
        </>
      )}

      {/* Hours */}
      {hours > 0 && (
        <>
          <div className="hours countdown-value">
            <span>{formattedHours}</span>
            <span className="countdown-label">{hours < 2 ? 'hr' : 'hrs'}</span>
          </div>
          <div className="separator">:</div>
        </>
      )}

      {/* Minutes*/}
      <div className="minutes countdown-value">
        <span>{formattedMinutes}</span>
        <span className="countdown-label">{minutes < 2 ? 'min' : 'mins'}</span>
      </div>
      <div className="separator">:</div>

      {/* Seconds */}
      <div className="seconds countdown-value">
        <span>{formattedSeconds}</span>
        <span className="countdown-label">{seconds < 2 ? 'sec' : 'secs'}</span>
      </div>
    </div>
  )
}

export default Countdown
