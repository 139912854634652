import React from 'react'

const DemographicAttributes = props => {
  const formatFilterName = name => {
    let formattedName = name.replace('_', '').replace(/([A-Z])/g, ' $1')
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1).toLowerCase()
  }

  const isDemographicFiltersValid = () => {
    const checkboxFilters = props.attributes
      .filter(filter => filter.comparator === 'in')
      .map(filter => filter.values.length)

    if (props.attributes.length !== checkboxFilters.length)
      return true

    return !checkboxFilters.some(count => count === 0)
  }

  return (
    <>
      {isDemographicFiltersValid() ? (
        <div className="mt-3">
          <h4>Demographic Attributes</h4>
          {props.attributes.map((filter, index) =>
            <div key={index} className="po-text mb-3 light-color-icon filter-box">
              {filter.comparator === 'in' && filter.values.length > 0 && (
                <span>
                  <h4 className="mt-2 participant-attribute-title">
                    {filter.needs_processing ? formatFilterName(filter.name) : filter.label}
                  </h4>
                  <div className="radio-toolbar">
                    {filter.values.map((value, index) =>
                      <label key={index} className="success label-demo no-cursor">{value}</label>
                    )}
                  </div>
                </span>
              )}
              {filter.comparator === 'between' && (
                <span>
                  <h4 className="mt-2 mb-1 participant-attribute-title">
                    {filter.needs_processing ? formatFilterName(filter.name) : filter.label}
                  </h4>
                  <span>
                    {filter.min_value} - {filter.max_value}
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default DemographicAttributes
