import React, {useEffect, useRef, useState} from 'react'
import Tagify from '@yaireo/tagify'
import {errorToast} from '../helpers/notification'

const ReactTagify = props => {
  const [value, setValue] = useState([])
  const [tagifyState, setTagifyState] = useState(null)
  const input = useRef()

  const closeCustomDropdown = (tagify) => {
    tagify.dropdown.hide.call(tagify)
    if (tagify.DOM.dropdown.parentNode === tagify.DOM.scope.parentNode)
      tagify.DOM.scope.parentNode.removeChild(tagify.DOM.dropdown)
  }

  const openCustomDropdown = (tagify) => {
    tagify.dropdown.show.call(tagify)
  }

  const initializeTagify = () => {
    let dropdown = null
    if (props.dropdown) {
      dropdown = {
        ...props.options,
        whitelist: props.selectOptions,
        dropdown: {
          enabled: 0,
          maxItems: 500,
          position: 'manual',
          highlightFirst: true,
          closeOnSelect: true,
          classname: 'po-text color-blue custom-tagify-suggestions'
        },
        delimiters: ',',
        templates: {
          tag(tagData) {
            let value = tagData.value, title = '', id = ''
            if (props.splitDelimiter) {
              let splitStr = value.split(props.splitDelimiter)
              title = `${splitStr[0].substr(0, 32)}...`
              id = splitStr[1]?.replace(/\)/g, '')
            }
            return `<tag title="${(tagData.title || tagData.value)}"
                         contenteditable='false'
                         spellcheck='false'
                         tabIndex="-1"
                         class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
                         ${this.getAttributes(tagData)}
                      >
                         <x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
                        <div>
                          ${props.splitDelimiter ? `<strong>${title}</strong>
                          <span class='pl-2 text-secondary'>(ID: ${id})</span>` : `<span>${value}</span>`}
                        </div>
                      </tag>`
          },
          dropdownItem(item) {
            return `<div class='${this.settings.classNames.dropdownItem}'
                         tabindex="0"
                         role="option"
                         ${this.getAttributes(item)}
                    >
                      ${item.value.replace(/>/g, '&gt;').replace(/</g, '&lt;')}
                    </div>`
          }
        }
      }
    }

    if (input.current) {
      const tagify = new Tagify(input.current, dropdown)

      tagify.on('add', e => {
        let valueState = value
        let index = valueState.indexOf(e.detail.data.value)
        if(index < 0){
          valueState.push(e.detail.data.value)
          setValue(valueState)
          props.onChange(value)
        }
      })

      tagify.on('remove', e => {
        let valueState = value
        let index = valueState.indexOf(e.detail.data.value)
        valueState.splice(index, 1)
        setValue(valueState)
        props.onChange(value)
      })

      tagify.on('invalid', e => {
        if (e.detail.message === 'number of tags exceeded') {
          errorToast(`Maximum ${props.options.maxTags} options allowed!`)
        }
      })

      if (props.dropdown) {
        tagify.on('blur', () => closeCustomDropdown(tagify))
        tagify.on('focus', () => openCustomDropdown(tagify))
        tagify.on('dropdown:show', (e) => {
          tagify.DOM.scope.parentNode.appendChild(tagify.DOM.dropdown)
        })
      }

      setTagifyState(tagify)
    }
  }

  /* Added this function just to remove console error */
  const onChange = () => {
  }

  useEffect(() => {
    initializeTagify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add tagify tags if any values present initially
  useEffect(() => {
    if (tagifyState) {
      tagifyState.addTags(props.defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagifyState])

  return (
    <div className="form-group">
      {props.label && <label>{props.label}</label>}
      <input
        ref={input}
        id={props.id}
        className={props.className}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        onChange={onChange}
      />
    </div>
  )
}

export default ReactTagify
