import React from 'react'
import {connect} from 'react-redux'

import Currency from './common/Currency'

import {
  bonusesSentFetch,
  bonusesSentInit
} from '../redux/actionss/runActions'

const $ = window.$

const Bonus = props => {
  return (
    <div className="row list-item infinitely-loaded bonus-list">
      <div className="col-md-3 pr-3">
        <Currency value={props.amount_in_dollars}/>
      </div>
      <div className="col-md-6 pr-3">
        {props.message}
      </div>
      <div className="col-md-3">
        {props.sent_on}
      </div>
    </div>
  )
}

const SentBonuses = props => {
  const openBonusesSentPopup = (e) => {
    e.preventDefault()
    props.bonusesSentInit()
    props.bonusesSentFetch(props.assignment_id, props.participant_id, props.run_id, props.projectId)
    $.magnificPopup.open({items: {src: `#bonuses-sent-popup-${props.assignment_id}`, type: 'inline'}})
  }

  const closeBonusesSentPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  return (
    <>
      <li className="cursor-pointer dropdown-item" onClick={openBonusesSentPopup}>
        <a
          href="/"
          className="popup-content relative"
          data-mfp-src="#bonuses-sent-popup">
          <i className="fa fa-eye fa-icons-dropdown"/>
          View Any Bonuses
        </a>
      </li>

      <div className="wrap_text">
        <div className="white-popup mfp-hide translate-y-35" id={`bonuses-sent-popup-${props.assignment_id}`}>
          <h2 className="bonus-header">
            Bonuses sent to participant {props.participant_id}
          </h2>
          {props.bonuses?.length > 0 && (
            <div className="wrap_info">
              <div className="panel panel-default wrap_projects no-shadow no-margin">
                <div className="panel-body project_detail">
                  <div className="row list-item list-heading">
                    <div className="col-md-3 pr-3">Amount</div>
                    <div className="col-md-6 pr-3">Message</div>
                    <div className="col-md-3">Sent on</div>
                  </div>
                  {props.bonuses?.map(bonus => <Bonus key={bonus.id} {...bonus}/>)}
                </div>
              </div>
              <div className="mt-3 warning">Amount includes service fee</div>
            </div>
          )}

          {props.errors && (
            <div className="wrap_info text-danger">
              {props.errors.join(', ')}
            </div>
          )}

          {props.isLoading && (
            <div className="wrap_info">
              <div className="spinner vertical-space-md"/>
            </div>
          )}

          {props.bonuses?.length === 0 && !props.errors?.length && (
            <div className="wrap_info p-4 text-orange">
              No bonus sent to this participant
            </div>
          )}

          <div className="pb-3 pl-4">
            <button
              className="btn btn-primary clickable mr-3 no-border"
              onClick={closeBonusesSentPopup}
              type="button">Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.bonusesSent
})

const mapDispatchToProps = dispatch => ({
  bonusesSentInit: () => dispatch(bonusesSentInit()),
  bonusesSentFetch: (assignment_id, participant_id, run_id, project_id) => {
    dispatch(bonusesSentFetch(assignment_id, participant_id, run_id, project_id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SentBonuses)
