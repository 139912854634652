import React from 'react'
import {connect} from 'react-redux'

import {errorToast} from '../helpers/notification'

const SaveAsDraftButton = (props) => {
  const filtersCount = props.isMturk ? props.mturkFiltersCount : props.cintFiltersCount
  const isBillable = ['Positly', 'Cint'].includes(props.platform.platform_type)

  const handleClick = () => {
    if (props.totalRunCost > props.spending_limit && isBillable) {
      props.setShowSpendingLimitWarning(true)
    } else if (filtersCount > 14) {
      errorToast('Could not save run as too many filters applied.')
    } else if (props.showBudgetChangedWarning) {
      errorToast('Your cost estimate has changed. Please update your screening setup.')
    } else if (props.isAnyQuotaInvalid) {
      errorToast('Please correct the errors in your quotas before proceeding.')
    } else {
      props.onClick()
    }
  }

  return (
    <button
      className="btn btn-default back clickable btn-save-as-draft"
      id="btn-save-as-draft"
      tabIndex="12"
      disabled={props.disabled}
      onClick={handleClick}
    >
      {props.isSaving ? 'Saving...' : 'Save Draft'}
    </button>
  )
}

const mapStateToProps = (state) => {
  return {
    totalRunCost: state.runFormOverview.totalRunCost || 0,
    spending_limit: state.currentUser?.spending_limit,
    mturkFiltersCount: state.runFormOverview.filters.length,
    cintFiltersCount: state.runFormOverview.cint_filters.length,
    platform: state.runFormOverview.platform
  }
}

export default connect(mapStateToProps)(SaveAsDraftButton)
