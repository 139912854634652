import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import * as Sentry from '@sentry/react'
import App from './App'
import GoogleTag from './integrations/GoogleTag'
import LinkedinInsights from './integrations/LinkedinInsights'
import * as serviceWorker from './serviceWorker'
import {disableReactDevTools} from '@fvilers/disable-react-devtools'

import {store} from './redux/store'

import TagManager from 'react-gtm-module'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'animate.css'

import './css/ionicons.min.css'
import './css/material-design-iconic-font.min.css'
import './css/select2.min.css'
import './css/tagify.css'
import './css/all.min.css'

import './sass/select2-override.scss'
import './sass/tooltips.scss'
import './sass/positly.scss'
import './sass/popup.scss'
import './sass/run-progress.scss'
import './sass/switch.scss'
import './sass/alert.scss'
import './sass/spinner.scss'
import './sass/transactions.scss'
import './sass/bootstrap-extended.scss'
import './sass/react-datepicker-overrides.scss'
import './sass/custom-select.scss'
import './sass/SignUp.scss'

import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper'
import 'bootstrap/dist/js/bootstrap'
import {injectStore} from './components/helpers/fetchInterceptor'
import {HashRouter} from 'react-router-dom'

/* Disable React Dev tools */
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

/* Initialize sentry */
const sentryDSN = process.env.REACT_APP_SENTRY_DSN

Sentry.init({
  dsn: sentryDSN,
  maxBreadcrumbs: 50,
  debug: true,
})

/* Initialize Google Tag Manager (GTM) */
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)

/* Initialize redux store */
injectStore(store)

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <GoogleTag/>
      <LinkedinInsights/>
      <App/>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
