import React, {useEffect, useRef, useState} from 'react'

// 3rd party components
import CreatableSelect from 'react-select/creatable'

// Custom components
import {capitalize} from '../../common/Helpers'
import Select from '../../common/Select'
import {createSelectOption, reactSelectStyles} from './RunUtils'

const CustomAttributeTextFilter = (props) => {
  // Variables
  const stringComparators = [
    {
      label: 'equal to',
      value: 'equal to'
    },
    {
      label: 'not equal to',
      value: 'not equal to'
    },
    {
      label: 'one of',
      value: 'in'
    },
    {
      label: 'blank',
      value: 'blank'
    },
    {
      label: 'not blank',
      value: 'not blank'
    }
  ]
  const [mode, setMode] = useState(props.filter.mode || 'Include only')
  const [comparator, setComparator] = useState(props.filter.comparator || 'equal to')
  const [filterData, setFilterData] = useState(props.filter.filter_datas || [])
  const [value, setValue] = useState(props.filter.value || '')
  const [values, setValues] = useState(props.filter.values || [])
  const filterRef = useRef(null)

  const getFilterValues = () => {
    const filterData = props.filter.filter_datas || []
    const filterValues = props.filter.values || []
    let options = [...filterData, ...filterValues]
    options = options.reduce(function (a, b) {
      if (a.indexOf(b) < 0)
        a.push(b)

      return a
    }, [])

    return options
  }

  const filterOptions = getFilterValues()

  // Init
  useEffect(() => {
    updateFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Event Handlers
  const handleModeChange = (e) => {
    setMode(e.target.value)
    updateFilter({mode: e.target.value})
  }

  const handleComparatorChange = (e) => {
    setComparator(e.target.value)
    updateFilter({comparator: e.target.value})
  }

  const handleValueChange = (selectedOption) => {
    setValue(selectedOption.value)
    updateFilter({value: selectedOption.value})

    if (selectedOption.__isNew__)
      setFilterData([...filterData, selectedOption.value])
  }

  const handleChange = (values) => {
    setValues(values.map(option => option.value))
    updateFilter({values: values.map(option => option.value)})
  }

  // Private method
  const updateFilter = (updates) => {
    const updatedFilter = {
      ...props.filter,
      mode: mode,
      comparator: comparator,
      value: value,
      values: [...values],
      ...updates
    }
    const isValid = validateFilter(updatedFilter)
    props.updateFilter('custom_attribute', {...updatedFilter, is_filter_valid: isValid})
  }

  const validateFilter = (filter) => {
    if (!filter.mode || !filter.comparator)
      return false

    if (filter.comparator === 'in' && !filter.values.length)
      return false

    if (filter.comparator.includes('equal') && !filter.comparator.includes('blank') && !filter.value)
      return false

    return true
  }

  const transformOptions = (options) => {
    if (options) {
      return options.map(option => ({value: option, label: option}))
    } else {
      return []
    }
  }

  const removeFilter = () => {
    if (!filterRef.current) return

    filterRef.current.classList.add('remove-filter')
    setTimeout(() => {
      props.removeFilter('custom_attribute', props.filter.name)
    }, 210)
  }

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.add('highlight-filter')
    setTimeout(() => {
      if (!filterRef.current) return

      filterRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  // UI template
  return (
    <div className={`box age ${props.className}`} ref={filterRef}>
      <h2 className="big word-break-all pr-4">
        {capitalize(props.filter.name).replace(/_/g, ' ')}
        <span className="float-right">
					<i className="ion-close text-sm" onClick={removeFilter}/>
				</span>
      </h2>
      <div className="my-2">
        <Select
          name={`${props.filter.name}.mode`}
          onChange={handleModeChange}
          value={mode}
          options={['Include only', 'Exclude all']}
          tabIndex="4"
        />
        <div className="my-2 po-text word-break-all pr-3">
          participants where {props.filter.name} is
        </div>

        <div className="ca-string-input" id={`${props.filter.name}_filter`}>
          <div className="form-group ca-text-filter">
            <Select
              className="mb-2"
              name={`${props.filter.name}.comparator`}
              onChange={handleComparatorChange}
              value={comparator}
              options={stringComparators}
              tabIndex="4"
            />

            {comparator.includes('equal') && !comparator.includes('blank') && (
              <>
                <CreatableSelect
                  className="filter-attributes "
                  id={`custom_attributes.${props.filter.name}.value`}
                  name={`custom_attributes.${props.filter.name}.value`}
                  value={createSelectOption(value)}
                  options={createSelectOption(filterOptions)}
                  noOptionsMessage={() => null}
                  onChange={selectedOption => handleValueChange(selectedOption)}
                  isSearchable
                  placeholder="Search by value"
                  onInputChange={(value) => {
                    return value.replace(/[^0-9a-zA-Z ]/g, '')
                  }}
                  styles={{
                    ...reactSelectStyles,
                    container: (styles) => ({
                      ...reactSelectStyles.container(styles),
                      maxWidth: 251,
                    }),
                    clearIndicator: (styles) => ({
                      ...styles,
                      display: 'none',
                    }),
                  }}
                />
                {!value && <p className="text-red po-text ml-2 mt-2">* required</p>}
              </>
            )}
          </div>

          {comparator === 'in' && !comparator.includes('blank') && (
            <>
              <CreatableSelect
                isMulti
                className="ca-text-filter-oneOf-value-select"
                id={`custom_attributes.${props.filter.name}.oneOfValue`}
                name={`custom_attributes.${props.filter.name}.oneOfValue`}
                defaultValue={createSelectOption(props.filter.values)}
                options={transformOptions(filterOptions)}
                noOptionsMessage={() => null}
                onChange={handleChange}
                placeholder="Search by value"
                styles={{
                  container: styles => ({
                    ...styles,
                    width: '100%',
                    height: 'auto',
                    borderRadius: 4,
                    backgroundColor: '#fff',
                    color: 'var(--input-text-color)',
                    fontSize: 14,
                    fontFamily: 'Muli-SemiBold',
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    padding: '5px',
                    cursor: 'text',
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    fontSize: 16
                  }),
                  indicatorsContainer: () => ({
                    display: 'none'
                  }),
                  control: (styles) => ({...styles, border: '1px solid #e2e9ef', height: 'auto'}),
                  input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
                  option: (styles) => ({...styles, paddingLeft: 10}),
                  multiValueRemove: (styles) => ({...styles, cursor: 'pointer'}),
                  placeholder: (styles) => ({...styles, color: 'var(--placeholder-color)',}),
                }}
              />
              {(!values || !values.length) && <p className="text-red po-text ml-1 mt-1">* required</p>}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomAttributeTextFilter
