import React from 'react'
import {connect} from 'react-redux'

import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'

const CreatableSelectStyles = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    height: 'auto',
    borderRadius: 4,
    backgroundColor: '#fff',
    color: 'var(--input-text-color)',
    fontSize: 14,
    fontFamily: 'Muli-SemiBold'
  }),
  valueContainer: (styles) => ({...styles, cursor: 'text', padding: '0'}),
  multiValue: (styles) => ({...styles, fontSize: 16, marginLeft: '10px'}),
  indicatorsContainer: () => ({display: 'none'}),
  control: (styles) => ({...styles, border: '1px solid #e2e9ef', height: 'auto'}),
  input: (styles) => ({...styles, padding: '0 0 0 10px', margin: '0px'}),
  option: (styles) => ({...styles, paddingLeft: 10, cursor: 'default'}),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--placeholder-color)',
    paddingLeft: '10px'
  }),
  multiValueRemove: (styles) => ({...styles, cursor: 'pointer'}),
}

const ResearchTypesInput = (props) => {
  const animatedComponents = makeAnimated()

  const transformOptions = (options) => {
    if (options) {
      return options.map(option => ({value: option, label: option}))
    } else {
      return []
    }
  }

  let researchTypes = []
  if (props.researchTypes) {
    researchTypes = transformOptions(props.researchTypes)
  }

  const handleChange = (values) => {
    props.formik.setFieldValue(props.name, values.map(option => option.value))
  }

  return (
    <div>
      {props.researchTypes && (
        <div className="wrap_label research-type-container">
          <label>Optional: Which of these best describes the research you do?</label>
          <CreatableSelect
            isMulti
            openMenuOnFocus={false}
            tabSelectsValue={false}
            options={researchTypes}
            noOptionsMessage={() => null}
            components={animatedComponents}
            onChange={handleChange}
            defaultValue={transformOptions(props.selectedResearchTypes)}
            placeholder="Type to search or click to select"
            classNamePrefix="react-select"
            styles={CreatableSelectStyles}
          />
          <span className="po-text pt-1">If none of these describe your research, please add your own.</span>
        </div>
      )}
    </div>
  )

}

const mapStateToProps = (state) => ({
  researchTypes: state.researchTypesList?.researchTypes,
})

export default connect(mapStateToProps)(ResearchTypesInput)
