import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {getStorageItem} from '../../common/Helpers'
import {LINK_VERIFICATION_TYPES} from '../../../utils/constants'

import {markLinkVerifiedFetch} from '../../../redux/actions'

const ScreenOutPlaceHolder = () => {
  const expectedTaskId = getStorageItem(`lv_task_id`)
  const linkVerificationId = getStorageItem(`lv_${expectedTaskId}_${LINK_VERIFICATION_TYPES.SCREEN_OUT_LINK}_id`)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(markLinkVerifiedFetch({id: linkVerificationId}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When link verification data not available
  if (!expectedTaskId) {
    return (
      <div className="box po-text-bold">
        <h3 className="px-4 py-2">
          This is the Screen-Out page for your Screening Activity. When the participant is ineligible for the Main
          activity you must direct participants to this page so that they can receive their payment for the Screening
          Activity only.
        </h3>
      </div>
    )
  }

  return (
    <div className="box po-text col-md-12 py-5">
      <div className="text-center pb-3">
        <p className="font-size-16 text-bold text-dark mb-4">Thank you for completing this activity!</p>
        <p className="font-size-18 mb-4"> Please be sure to submit your open HIT so you can be paid.</p>
        <p className="font-size-16">If you ran into any problems, please let us know on the HIT page!</p>
      </div>
    </div>
  )
}

export default ScreenOutPlaceHolder
