import React from 'react'

const Spinner = ({className = '', style = {}, disablePadding = false}) => {
  const spinnerClassName = disablePadding ? className : `py-3 ${className}`

  return (
    <div className={spinnerClassName} style={style} data-testid="loader">
      <div className="spinner"/>
    </div>
  )
}

export default Spinner
