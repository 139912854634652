import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import FormErrors from './FormErrors'
import {calculateCost, handleCharge} from './common/Helpers'

import {runExtendFetch, runExtendInit} from '../redux/actionss/runActions'
import Currency from "./common/Currency";

const $ = window.$

const ExtendRun = (props) => {
  const [additionalCost, setAdditionalCost] = useState(0)

  const formik = useFormik({
    initialValues: {
      number_of_submissions: ''
    },
    validationSchema: Yup.object({
      number_of_submissions: Yup.number()
        .required('Number of completions is required')
    }),
    onSubmit: () => {
    },
    enableReinitialize: true,
  })

  const showExtendRunPopup = (e) => {
    e.preventDefault()

    formik.resetForm()
    props.runExtendInit()
    $.magnificPopup.open({
      items: {src: `#extend-popup-${props.id}`},
      type: 'inline',
      modal: true
    })
  }

  const handleExtend = (e) => {
    e.preventDefault()

    if (formik.isValid)
      confirmAndExtendRun()
  }

  const confirmAndExtendRun = (currentUser = props.currentUser) => {

    handleCharge({
      totalCost: additionalCost,
      platformType: props.platformType,
      currentUser: currentUser,
      callback: extend,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: confirmAndExtendRun
    })
  }

  const extend = () => {
    props.runExtendFetch(props.id, formik.values.number_of_submissions, props.updateRunDetails)
  }

  const handleCancel = (e) => {
    e.preventDefault()
    formik.resetForm()
    $.magnificPopup.close()
  }

  const showCost = () => {
    return additionalCost > 0
  }

  const shouldDisableExtend = () => {
    return !formik.isValid || props.isLoading || formik.values.number_of_submissions === '' ||
            formik.values.number_of_submissions <= 0
  }

  useEffect(() => {
    const cost  = calculateCost(formik.values.number_of_submissions, props.payment_per_submission, props.serviceFeePercent)

    setAdditionalCost(cost)
  }, [formik.values.number_of_submissions]);

  return (
    <>
      <li className='cursor-pointer dropdown-item' onClick={showExtendRunPopup}>
        <span
          className="clickable popup-content btn-run-extend">
        <i className="fa fa-expand-alt fa-fw"/>
          {' '}Extend Run
      </span>
      </li>
      <div className="white-popup mfp-hide" id={`extend-popup-${props.id}`}>
        <h2 className="message-header">
          Extend run {props.id}
        </h2>
        <form name="extend-form" className="wrap_info">
        <div>
            {props.errors?.length && <FormErrors errors={props.errors}/>}
            <div className="wrap_item">
              <label className="text-required">
                Additional desired number of activity completions
              </label>
              <input
                type="text"
                className="card_number"
                name="number_of_submissions"
                onChange={formik.handleChange}
                value={formik.values.number_of_submissions}
              />
              {formik.dirty && formik.errors.number_of_submissions
                ? <p className="po-text text-red-soft">{formik.errors.number_of_submissions}</p>
                : null
              }
            </div>
            {showCost() && (
              <p className="po-text">
                <strong>
                  Additional cost for the extended submissions:
                  <br/>
                  <span className="text-danger">
                    <Currency value={additionalCost}/>
                  </span>
                </strong>
                <strong className="text-cost">
                  {' '}= ( <Currency value={props.payment_per_submission}/> + {props.serviceFeePercent}% fee )
                  <span className="po-text">
                    {' '}* {formik.values.number_of_submissions}
                    {formik.values.number_of_submissions === 1 ? ' submissions' : ' submissions'}
                  </span>
                </strong>
              </p>
            )}

            <div className="wrap_link">
              <button
                id="extend-run-button"
                className={`btn btn-primary clickable no-border cursor-pointer ${shouldDisableExtend() ? 'cursor-disabled btn-grey' : ''}`}
                type="submit"
                disabled={shouldDisableExtend()}
                onClick={handleExtend}
              >
                {props.isLoading ? 'Extending...' : 'Extend'}
              </button>
              <button
                className="btn btn-default clickable"
                onClick={handleCancel}
                disabled={props.isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    ...state.runExtend,
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => ({
  runExtendInit: () => dispatch(runExtendInit()),
  runExtendFetch: (id, number_of_submissions, updateRunDetails) => dispatch(runExtendFetch(id, number_of_submissions, updateRunDetails))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendRun)
