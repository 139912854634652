import React from 'react'

import Input from '../../common/Input'
import {formatAsCurrency, isGuidedTrackLink, screenoutLink} from './RunUtils'
import Spinner from '../../common/Spinner'
import PositlyLink from '../../common/PositlyLink'
import CopyLink from '../../common/CopyLink'
import CopyCode from '../../common/CopyCode'
import {useSelector} from 'react-redux'

const $ = window.$

const ScreeningSetupPopup = (
  {
    formik,
    costEstimate,
    setScreeninRate,
    validateAndDispatchForm,
    isQuoteLoading,
    setShowBudgetChangedWarning
  }) => {
  const taskUrl = useSelector(state => state.runFormOverview.task_parameters.task_url)

  const handleChange = (e, value) => {
    const fieldName = e.target.name
    formik.setFieldValue(fieldName, value)

    if (fieldName.includes('est_incidence_rate'))
      setScreeninRate(value)
  }

  const isFormInvalid = Object.keys(formik.errors).length
  const disableForm = isQuoteLoading || !costEstimate || isFormInvalid || !formik.values.agree_screening_guidelines

  const closeScreeningSetupPopup = (e) => {
    e.preventDefault()
    formik.setFieldValue('est_screening_duration', 0)
    formik.setFieldValue('est_screening_duration_type', 'minutes')
    formik.setFieldValue('est_incidence_rate', 0)
    formik.setFieldValue('est_max_budget', 0)
    formik.setFieldValue('agree_screening_guidelines', false)
    $.magnificPopup.close()
  }

  const acceptUpdatedCost = (e) => {
    e.preventDefault()
    setShowBudgetChangedWarning(false)
    validateAndDispatchForm()
    $.magnificPopup.close()
  }

  const showCopyCodeButton = () => {
    return isGuidedTrackLink(taskUrl)
  }

  return (
    <div id="cint-screening" className="metrics filter activity mfp-hide modal fade screening-setup-popup">

      <div className="box po-text">
        <h2 className="header mb-4">Set up Screening</h2>

        <div>
          <div className="notice notice-wo-icon mt-3">
            <span>
              <span className="text-bold">Important:</span>
              {' '}Screening questions must be brief and occur only at the beginning of your activity.
            </span>
          </div>

          <div className="wrap_info pt-2">
            <div className="radio-checkbox">
              <label>
                <input
                  name="agree_screening_guidelines"
                  className="checkbox"
                  checked={formik.values.agree_screening_guidelines}
                  onChange={formik.handleChange}
                  type="checkbox"
                />
                <span className="checkbox-custom"/>
                <span
                  className={`label text-required ${!formik.values.agree_screening_guidelines ? 'text-danger' : ''}`}
                >
                  I agree to adhere to{' '}
                  <a
                    className="pii-link"
                    href="https://www.positly.com/support/positlys-screening-guidelines/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Positly’s screening guidelines
                  </a>
                </span>
              </label>
            </div>
          </div>

          <div className="form-group ">
            <label>
              <h2 className="text-required">Estimated time for participants to complete screening questions</h2>
            </label>
            <div className="row">
              <div className="wrap_select est-time col-md-5">
                <Input
                  name="task_parameters.est_screening_duration"
                  type="text"
                  className="form-control est-screening-time mb-0"
                  value={formik.values.task_parameters.est_screening_duration}
                  onChange={handleChange}
                  allowOnlyNumbers
                  disabled={!formik.values.agree_screening_guidelines}
                />
                <select
                  name="task_parameters.est_screening_duration_type"
                  className="est-screening-time-type"
                  value={formik.values.task_parameters.est_screening_duration_type}
                  onChange={formik.handleChange}
                  disabled={!formik.values.agree_screening_guidelines}
                >
                  <option value="minutes">Minutes</option>
                  <option value="seconds">Seconds</option>
                </select>
              </div>
            </div>
          </div>

          {(formik.values.task_parameters?.est_screening_duration && formik.errors.task_parameters?.est_screening_duration
            && !formik.errors.task_parameters.est_screening_duration.includes('required')
          ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_screening_duration}</div>
            : null
          }
        </div>

        <div className="form-group mt-4 row">
          <label>
            <h2 className="text-required mb-0">
              Estimated percentage of participants who will screen in and be eligible for the rest of your activity
            </h2>
          </label>
          <div className="wrap_info_form help-text mt-1 mb-2">
            <span>
              Positly uses this screen-in rate to estimate the cost of your run. The actual cost of your run will
              depend on true screen-in rates once your activity is published.
            </span>
          </div>
          <div className="col-md-5 est_incidence_rate">
            <Input
              id="est_incidence_rate"
              type="text"
              name="task_parameters.est_incidence_rate"
              className="form-control mb-0"
              value={formik.values.task_parameters.est_incidence_rate}
              onChange={handleChange}
              allowOnlyNumbers
              maxLength={3}
              disabled={!formik.values.agree_screening_guidelines}
            />
          </div>
          <br/>
        </div>
        {(formik.values.task_parameters?.est_incidence_rate && formik.errors.task_parameters?.est_incidence_rate
          && !formik.errors.task_parameters.est_incidence_rate.includes('required')
        ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_incidence_rate}</div>
          : null
        }

        <div className="form-group mt-5">
          <span className="text-dark text-bold">
            Your activity must send any people who you don't want to participate to this screen-out link
          </span>
          {' '} (this must occur right near the beginning of your activity, immediately after asking participants your
          screening questions):
        </div>

        <div className="po-text mt-2 pt-1 mb-2">
          Participants who are <span className="po-text-bold">ineligible</span> for the rest of your activity
          should reach this link only. <br/>
          Participants who reach this link do not count towards your completion count.
        </div>

        <div className="wrap_input_text d-flex screening-link-wrapper">
          <PositlyLink
            id="cint-screening-link"
            className="copy_link_tooltip completion_link_input text-overflow-ellipsis"
            path="/#/end"
          />
          <CopyLink
            label="Copy link"
            className="pull-left btn-clipboard feedback copy_link mx-sm-1"
            idToCopy="cint-screening-link"
          />
          {showCopyCodeButton() && (
            <>
              <div className="wrap_info_form inline">
                <span>or</span>
              </div>
              <CopyCode
                label="Copy code"
                className="pull-left btn-clipboard copy_code"
                textToCopy={`*goto:${screenoutLink}`}
                tooltipText="Redirects participants to Screen-Out Link for GuidedTrack users"
              />
            </>
          )}
        </div>

        <div className="form-group mt-4">
          <label>
            <h2 className="d-flex align-items-center font-size-16">
              Updated cost estimate with screening enabled:
              {isQuoteLoading && formik.values.task_parameters.est_incidence_rate && formik.values.agree_screening_guidelines
                ? <Spinner className="pad-vert-0 ml-4"/>
                : (costEstimate && !formik.errors.task_parameters?.est_incidence_rate && formik.values.agree_screening_guidelines
                    ? <span className="ml-4 po-text">{formatAsCurrency(costEstimate)}</span>
                    : null
                )
              }
            </h2>
          </label>
        </div>

        <div className="light-gray-box mt-4 mb-2 pb-4">
          <h2 className="text-required font-size-16">Set Max Budget</h2>
          <div className="wrap_info_form help-text">
              <span>
                This is the amount of credits Positly requires you have to publish your activity.<br/>
                Positly will deduct credits from your account based on actual eligibility rates.
                The credits you spend will never exceed your Max Budget. Your activity will automatically
                stop if your Max Budget is reached. If you spend less than your Max Budget,
                the balance will remain as available credits, or you can request a refund.{' '}
                <a
                  href="https://www.positly.com/support/how-to-use-positlys-screening-and-filtering-features/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-orange text-underline"
                  tabIndex={-1}
                >
                  Learn more.
                </a>
              </span>
          </div>

          <div className="form-group run-detail mt-3 row">
            <div className="col-md-5 est_max_budget mt-2">
              <Input
                name="task_parameters.est_max_budget"
                type="text"
                className="form-control border-grey mb-0"
                value={!isQuoteLoading && !formik.errors.task_parameters?.est_incidence_rate && formik.values.agree_screening_guidelines
                  ? formik.values.task_parameters.est_max_budget
                  : ''}
                onChange={handleChange}
                allowOnlyNumbers
                fractionDigits={2}
                disabled={isQuoteLoading
                  || formik.errors.task_parameters?.est_incidence_rate
                  || !formik.values.agree_screening_guidelines
                }
              />
            </div>
          </div>

          {(formik.errors.task_parameters?.est_max_budget
            && !formik.errors.task_parameters.est_max_budget.includes('required')
            && !formik.errors.task_parameters.est_max_budget.includes('cast from the value')
            && formik.values.agree_screening_guidelines
          ) ? <div className="text-red-soft po-text">{formik.errors.task_parameters.est_max_budget}</div>
            : null
          }

          <div className="wrap_link run-mt-3 ml-auto">
            <button
              type="button"
              className="btn btn-default cancel clickable"
              onClick={closeScreeningSetupPopup}
            >
              Cancel setup
            </button>
            <button
              className={`btn btn-primary refresh clickable no-border ${disableForm ? 'btn-grey cursor-disabled' : ''}`}
              onClick={acceptUpdatedCost}
              disabled={disableForm}
            >
              Accept updated cost
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreeningSetupPopup
