import React from 'react'

import {TABS} from '../EnhancedRunForm'
import {useSelector} from 'react-redux'

const QuotaError = ({isTotalPercentInvalid, isTotalParticipantsInvalid, setActiveTab}) => {
  const isDetailsFormValid = useSelector(state => state.runFormOverview.isDetailsFormValid)

  const navigateToDetailsTab = (e) => {
    e.preventDefault()
    setActiveTab(TABS.DETAILS)
  }

  return (
    <div className="mt-2 d-flex flex-column gap-0-5">
      {isDetailsFormValid ? (
        <>
          {isTotalPercentInvalid && (
            <div className="po-text text-red-soft lh-1 my-1">
              Your percentages within this quota must add to 100%. Please adjust your values or click Reset.
            </div>
          )}
          {isTotalParticipantsInvalid && (
            <div className="po-text text-red-soft lh-1 my-1">
              Your total participants for this quota must equal the number of desired participants you set for this run.
              Please adjust your values or click Reset. You can also adjust your desired participants by returning to
              the{' '}
              <a
                href="/"
                className="po-text text-red-soft clickable text-underline"
                onClick={navigateToDetailsTab}
              >
                {TABS.DETAILS.navigationText} page
              </a>
              .
            </div>
          )}
        </>
      ) : (
        <div className="po-text text-red-soft lh-1">
          Before you set up quotas, you’ll need to set your total desired participants and estimated time to complete
          the activity for this run on the{' '}
          <a
            href="/"
            className="po-text text-red-soft clickable text-underline"
            onClick={navigateToDetailsTab}
          >
            {TABS.DETAILS.navigationText} page
          </a>
          .
        </div>
      )}
    </div>
  )
}

export default QuotaError
