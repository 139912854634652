import React, {useEffect, useState, useRef} from 'react'
import {OTHER_CATEGORY_LABEL} from './RunFormHelpers'

const $ = window.$

const CustomSelect = (
  {
    options = {},
    optionKey = 'name',
    onChange,
    placeholder = 'Select an option or type to search',
    noOptionsMessage = 'No options available',
    noSearchResultsMessage = 'No options available',
    className = '',
    resetSelectedOption = false,
    setResetSelectedOption,
    refreshSearchResult = false,
    setRefreshSearchResult,
    switchToPanelPlatform,
    scrollToScreenerForm,
    setOpenSelectByDefault,
    openSelectByDefault,
  }
) => {
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [searchResults, setSearchResults] = useState({})
  const [searchText, setSearchText] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)

  const pcsContainerRef = useRef(null)
  const searchInputRef = useRef(null)
  const optionGroupContainerRef = useRef(null)

  const noOptionsText = Object.keys(options).length
    ? searchText && Object.keys(searchResults).length
      ? null : noSearchResultsMessage
    : noOptionsMessage

  const optionsToDisplay = searchText ? searchResults : options

  const handleGroupSelection = (key) => {
    setSelectedGroup(key)
    setSelectedOption(null)
    onChange({})
  }

  const handleOptionSelection = (option) => {
    if(option['category'] === OTHER_CATEGORY_LABEL) {
      if(option['switchToPanelPlatform'] === true) {
        switchToPanelPlatform()
        setOpenSelectByDefault(true)
      } else if(option['scrollToScreener'] === true) {
        scrollToScreenerForm()
      } else if(option['openSupportForm'] === true) {
        openSupportForm()
      }
    } else {
      setSelectedOption(option)
      onChange(option)
      setShowDropdown(false)
      searchInputRef.current.value = ''
    }
  }

  const handleSearchInputFocus = () => {
    setShowDropdown(true)
  }

  const updateSearchResult = () => {
    if (!Object.keys(options).length || !searchInputRef.current) return

    const searchInputText = searchInputRef.current.value.trim().toLowerCase()
    setSearchText(searchInputText)

    let filteredData = {}
    Object.keys(options).forEach(key => {
      const isKeyMatching = key.toLowerCase().includes(searchInputText)
      const matchingItems = options[key]
        .filter(item => item.name.toLowerCase().includes(searchInputText))

      if (isKeyMatching) {
        filteredData[key] = options[key]
      } else if (matchingItems.length) {
        filteredData[key] = matchingItems
      }
    })

    setSearchResults(filteredData)

    // Set default group
    const availableOptions = filteredData[selectedGroup]
    if (!availableOptions) {
      if (Object.keys(filteredData).length) {
        setSelectedGroup(Object.keys(filteredData)[0])
        if (optionGroupContainerRef.current) optionGroupContainerRef.current.scrollTop = 0
      }
    }
    setSelectedOption(null)

    onChange({})
  }

  const isDemoRequestFormHidden = () => {
    const demoRequestElement = document.getElementById('request-demo-popup')

    if (demoRequestElement) {
      return demoRequestElement.classList.contains('mfp-hide')
    } else {
      return true
    }
  }

  const isDemoRequestFormAction = (e) => {
    const elementName = e.target.name

    return (elementName === 'demoRequestSubmit' || elementName === 'demoRequestCancel')
  }

  const canHideDropdown = (e) => {
    return pcsContainerRef.current &&
    !pcsContainerRef.current.contains(e.target) &&
    !isDemoRequestFormAction(e) &&
    isDemoRequestFormHidden() // exclude the clicks on demo request popup
  }

  const hideDropdown = (e) => {
    if (canHideDropdown(e)) {
      setShowDropdown(false)
      setSelectedGroup('')
      setSelectedOption(null)
      onChange({})
    }
  }

  // Set default group on initialization
  if (!selectedGroup && Object.keys(options).length) {
    setSelectedGroup(Object.keys(options)[0])
    if (optionGroupContainerRef.current) optionGroupContainerRef.current.scrollTop = 0
  }

  const openSupportForm = () => {
    $.magnificPopup.open({
      items: {src: '#request-demo-popup'},
      type: 'inline',
      closeOnBgClick: false
    })
  }

  // Clear selection and close dropdown once filter is added
  useEffect(() => {
    if (!resetSelectedOption) return

    setSelectedOption(null)
    setResetSelectedOption(false)
    updateSearchResult()
    searchInputRef.current.blur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelectedOption])

  // Refresh search result when already added filter is removed
  useEffect(() => {
    if (!refreshSearchResult) return

    updateSearchResult()
    setRefreshSearchResult(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSearchResult])

  // Hide dropdown when clicked outside
  useEffect(() => {
    window.addEventListener('mouseup', hideDropdown)

    return () => window.removeEventListener('mouseup', hideDropdown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (openSelectByDefault) {
      handleSearchInputFocus()
    }
  }, [openSelectByDefault])

  return (
    <div className={className}>
      <div className="cs-wrapper" ref={pcsContainerRef}>
        <div className="cs-search-container">
          <i className="fa fa-search" aria-hidden="true"></i>
          <input
            type="text"
            name="cs-search-input"
            id="cs-search-input"
            className="cs-search-input"
            placeholder={placeholder}
            onChange={updateSearchResult}
            ref={searchInputRef}
            onFocus={handleSearchInputFocus}
          />
          {showDropdown && (
            <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => setShowDropdown(false)}/>
          )}
          {!showDropdown && (
            <i className="fa fa-chevron-down" aria-hidden="true" onClick={() => setShowDropdown(true)}/>
          )}
        </div>
        {showDropdown && (
          <div className="cs-outer-container">
            {Object.keys(optionsToDisplay).length ? (
              <>
                <div className="cs-left-container" ref={optionGroupContainerRef}>
                  {Object.keys(optionsToDisplay).map((optionGroup, index) => (
                    <div
                      key={index}
                      id={`cs-group-${optionGroup}`}
                      className={`cs-group-item ${optionGroup === selectedGroup ? 'active' : ''}`}
                      onClick={() => handleGroupSelection(optionGroup)}
                    >
                      {optionGroup}
                    </div>
                  ))}
                </div>

                {selectedGroup && optionsToDisplay[selectedGroup] ? (
                  <div className="cs-right-container">
                    {optionsToDisplay[selectedGroup].map((option, index) => (
                      <div key={index}>
                        { option['category'] === OTHER_CATEGORY_LABEL ? (
                          <>
                            { option['isOtherTitle'] === true ? (
                              <p className='text-center my-3 po-text-bold'>
                                Can't find the filters you're looking for?<br/> Try these options:
                              </p>
                            ) : (
                              <div
                                className='cs-group-item'
                                onClick={() => handleOptionSelection(option)}>
                                <span className="fa fa-hand-point-right"></span>
                                <span>{option[optionKey]}</span>
                              </div>
                            ) }
                          </>
                        ) : (
                          <div
                            className={`cs-group-item ${selectedOption && selectedOption[optionKey] === option[optionKey] ? 'active' : ''}`}
                            onClick={() => handleOptionSelection(option)}
                          >
                            {option[optionKey]}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : (
                  <div className="cs-no-options">
                    {noOptionsText}
                  </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomSelect
