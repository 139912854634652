import React, {useEffect} from 'react'

import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import FormErrors from './FormErrors'
import {
  contactFetch,
  contactInit,
  contactReset,
  contactSubmit
} from '../redux/actions'

const Contact = (props) => {
  let initialValues = {
    subject: '',
    message: ''
  }

  let validationSchema = {
    subject: Yup.string()
      .required('Subject is required'),
    message: Yup.string()
      .required('Message is required')
  }

  if (!props.currentUser) {
    initialValues = {
      name: '',
      email: '',
      ...initialValues,
    }
    validationSchema.name = Yup.string().required('Name is required')
    validationSchema.email = Yup.string()
      .required('Email is required')
      .email('Invalid email address')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      props.contactInit()
      props.contactSubmit()
      if (props.currentUser) {
        values.email = props.currentUser.email
      }
      props.contactFetch(values)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (props.status === 'success') {
      props.contactReset()
      props.history.push('/projects/list')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  return (
    <div className="contact-us-box mt-5">
      <div className="d-flex add_platform form_style justify-content-center">
        <div className="width-533">
          <div className="box">
            <form onSubmit={formik.handleSubmit} className="contact-form">
              <FormErrors errors={props.errors}/>
              {!props.currentUser && (
                <>
                  <div className="form-group mb-2">
                    <label htmlFor="name" className="text-required">Name</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control mb-0"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {(formik.touched.name) && formik.errors.name ? (
                      <p className="po-text text-red-soft">{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="email" className="text-required">Email</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="form-control mb-0"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {(formik.touched.email) && formik.errors.email ? (
                      <p className="po-text text-red-soft">{formik.errors.email}</p>
                    ) : null}
                  </div>
                </>
              )}

              <div className="form-group mb-2">
                <label htmlFor="subject" className="text-required">Subject</label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  className="form-control mb-0"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {(formik.touched.subject) && formik.errors.subject ? (
                  <p className="po-text text-red-soft">{formik.errors.subject}</p>
                ) : null}
              </div>

              <div className="form-group mb-2">
                <label htmlFor="message" className="text-required">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="message form-control contact-us-textarea"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
                {(formik.touched.message) && formik.errors.message ? (
                  <p className="po-text text-red-soft">{formik.errors.message}</p>
                ) : null}
              </div>

              <div className="wrap_link mt-4">
                <button
                  id="btn-contact"
                  type="submit"
                  className="btn btn-primary no-border clickable"
                  disabled={props.isLoading}
                >
                  {props.isLoading ? 'Submitting...' : 'Submit'}
                </button>
                <button
                  type="button"
                  className="btn btn-default clickable"
                  disabled={props.isLoading}
                  onClick={() => props.history.push('/projects/list')}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.contact,
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => ({
  contactInit: () => dispatch(contactInit()),
  contactSubmit: () => dispatch(contactSubmit()),
  contactFetch: (contact) => dispatch(contactFetch(contact)),
  contactReset: () => dispatch(contactReset())
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
