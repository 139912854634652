import React from 'react'

import {SCREENOUT_CONTEXTS} from '../utils/constants'

const Declined = () => {
  return (
    <>
      <div className="mb-4">You declined our bonus activity. No problem-- thanks for your time!</div>
      <div className="font-size-18 text-dark mb-4">
        Please be sure to submit your open HIT so you can be paid <br/>for your participation in the first activity.
      </div>
      <div>If you ran into any problems, please let us know on the HIT page!</div>
    </>
  )
}

const DeclinedTimeout = () => {
  return (
    <>
      <div className="mb-3">You declined our bonus activity. No problem-- thanks for your time!</div>
      <div>
        If you ran into any problems, please let us know at{' '}
        <a className="link" href="mailto:hi@positly.com">hi@positly.com</a>.
      </div>
    </>
  )
}

const ActivityFull = () => {
  return (
    <>
      <div className="font-size-22 ls mb-4 pb-2">
        Unfortunately, this bonus activity is not available <br/>due to an error.
      </div>
      <div className="font-size-18 mb-4">We apologize for the trouble!</div>
      <div className="font-size-18 po-text-bold text-dark mb-4">
        Please submit your open HIT to be paid for the activity <br/>you just completed.
      </div>
      <div className="lh">
        Our support team has been notified about this error. If you have any <br/>
        questions, please contact us at <a className="link" href="mailto:hi@positly.com">hi@positly.com</a>.
      </div>
    </>
  )
}

const ActivityTimeout = () => {
  return (
    <>
      <div className="font-size-18 text-dark mb-4">
        Unfortunately, this bonus activity is not available <br/>because the time limit expired.
      </div>
      <div className="font-size-16">We hope to offer you another activity soon!</div>
    </>
  )
}

const Screenout = () => {
  return (
    <>
      <div className="mb-4 text-bold text-dark">Thank you for completing this activity!</div>
      <div className="font-size-18 mb-4"> Please be sure to submit your open HIT so you can be paid.</div>
      <div>If you ran into any problems, please let us know on the HIT page!</div>
    </>
  )
}

const ContextScreenoutMessage = ({context, className = ''}) => {
  const getScreenoutMessage = () => {
    switch (context) {
      case SCREENOUT_CONTEXTS.DECLINED:
        return <Declined/>
      case SCREENOUT_CONTEXTS.DECLINED_TIMEOUT:
        return <DeclinedTimeout/>
      case SCREENOUT_CONTEXTS.ACTIVITY_FULL:
        return <ActivityFull/>
      case SCREENOUT_CONTEXTS.ACTIVITY_TIMEOUT:
        return <ActivityTimeout/>
      case SCREENOUT_CONTEXTS.SCREENOUT:
      default:
        return <Screenout/>
    }
  }

  return (
    <div className={className}>{getScreenoutMessage()}</div>
  )
}

export default ContextScreenoutMessage
