import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

const ZohoChat = () => {

  const zohoWidgetID = process.env.REACT_APP_ZOHO_WIDGET_ID
  const zohoWidgetUrl = process.env.REACT_APP_ZOHO_WIDGET_URL

  const chatDisplayPaths = [
    "/projects/list",
    "/users/contact-us",
    "/users/sign-in",
    "/users/sign-up",
    "/users/confirm-email",
    "/users/forgot-password",
    "/users/reset-password",
    "/projects/create",
    "/projects/show",
    "/runs/show",
    "/runs/create",
    "/runs/update",
    "/platforms/list",
    "/platforms/create",
    "/users/profile",
    "/users/billing"
  ]

  if (chatDisplayPaths.indexOf(useLocation().pathname) > -1) {
    return <Helmet >
      <script type='text/javascript' id='zsiqchat'>
        {`
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: '${zohoWidgetID}',
            values: {},
            ready: function() {
              function getId() {
                return window.location.href.split('id=')[1].split('&')[0];
              }

              $zoho.salesiq.visitor.chat(function() {
                if (window.location.href.includes('runs/show')) {
                   $zoho.salesiq.visitor.info({'Run ID' : getId(), 'Project ID': ''});
                } else if (window.location.href.includes("projects/show")) {
                   $zoho.salesiq.visitor.info({'Project ID': getId(), 'Run ID': ''});
                } else {
                   $zoho.salesiq.visitor.info({'Project ID' : '', 'Run ID' : ''});
                }

                $zoho.salesiq.visitor.info({
                  'Account Name': localStorage.getItem('full_name'),
                  'Account Email': localStorage.getItem('email')
                });
              });
            }
          };
        var d=document;
        s=d.createElement("script");
        s.type="text/javascript";
        s.id="zsiqscript";
        s.defer=true;
        s.src='${zohoWidgetUrl}';
        t=d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s,t);
      `}
      </script>
    </Helmet>
  } else {
    return null
  }
}

export default ZohoChat
