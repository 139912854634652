import React, {useEffect} from 'react'
import {connect} from "react-redux"

import {useFormik} from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

import FormErrors from '../FormErrors'
import FormField from '../common/FormField'

import {
  userResetPasswordFetch,
  userResetPasswordInit,
  userResetPasswordSubmit
} from '../../redux/actionss/userActions'

const ResetPassword = (props) => {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password should be minimum of 8 characters'),
      confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .test('passwords-match', 'Password and confirm password do not match', function (confirmPwd) {
          return this.parent.password === confirmPwd
        })
    }),
    onSubmit: values => {
      values.reset_password_token = qs.parse(props.location?.search, {ignoreQueryPrefix: true}).reset_password_token

      props.userResetPasswordSubmit()
      props.userResetPasswordFetch(values)
    }
  })

  useEffect(() => {
    props.userResetPasswordInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.status === 'success') {
      if (props.currentUser) {
        props.history.push('/projects/list')
      } else {
        props.history.push('/users/sign-in')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status, props.currentUser])

  return (
    <div className="reset-password-box">
      <div className="d-flex metrics project_detail justify-content-center m-0">
        <div className="wrap_title m-0">
          <h1>Reset Password</h1>
        </div>
      </div>

      <div className="d-flex add_platform metrics activity justify-content-center">
        <div className="col-lg-6 left">
          <div className="panel panel-default">
            <div className="panel-body">
              <form onSubmit={formik.handleSubmit}>
                <FormErrors errors={props.errors}/>
                <FormField
                  label="Password"
                  id="password"
                  type="password"
                  className="form-control mt-2"
                  autocomplete="new-password"
                  required={true}
                  formik={formik}
                  helpBlock={() => <p className="help-block">Minimum 8 characters</p>}/>
                <FormField
                  label={"Password Confirmation"}
                  id="confirmPassword"
                  type="password"
                  className="mt-3"
                  autocomplete="new-password"
                  required={true}
                  formik={formik}/>
                <div className="wrap_link mt-3">
                  <button
                    className={`btn btn-primary no-border cursor-pointer ${props.isLoading ? 'btn-grey cursor-disabled' : ''}`}
                    id='btn-change-password'
                    type="submit"
                    disabled={props.isLoading}>
                    {props.isLoading ? 'Changing password' : 'Change Password'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  ...state.resetPassword,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  userResetPasswordInit: () => dispatch(userResetPasswordInit()),
  userResetPasswordSubmit: () => dispatch(userResetPasswordSubmit()),
  userResetPasswordFetch: (user) => dispatch(userResetPasswordFetch(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
