import React from 'react'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'

import swal from 'sweetalert'

const DuplicateRun = (props) => {

  let history = useHistory()

  const duplicate = (event) => {
    event.preventDefault()
    if (props.labelName === 'Paused Due To Complaints') {
      confirmAndDuplicatePaused()
    } else {
      redirectRunDuplicate(props.runId)
    }
  }

  const confirmAndDuplicatePaused = () => {
    swal({
      title: 'Are you sure?',
      text: 'This run was previously paused due to complaints. Please ensure that the activity is fixed prior to publishing.',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          text: 'Duplicate',
          className: 'btn-primary'
        }
      }
    }).then((value) => {
      if (value) {
        redirectRunDuplicate(props.runId)
      }
    })
  }

  const redirectRunDuplicate = (runId) => {
    return history.push(`/runs/create?id=${runId}`)
  }

  return (
    <>
      {props.ui !== 'button' && (
        <li className='cursor-pointer dropdown-item' onClick={duplicate}>
          <span>
            <i className="far fa-copy fa-fw"/>
            &nbsp;Duplicate Run
          </span>
        </li>
      )}

      {props.ui === 'button' && (
        <Link to=""
              className="btn btn-primary transition-0 clickable pull-right"
              title="Duplicate run"
              onClick={duplicate}>
          <i className="far fa-copy fa-fw"/>
          <span className="fa-text">Duplicate Run</span>
        </Link>
      )}
    </>
  )
}

export default DuplicateRun
