import React, {useEffect, useRef, useState} from 'react'

import {allowOnlyNumbers, preventPaste} from '../../../common/Helpers'
import {validateAgeRange} from '../RunUtils'
import {useDispatch, useSelector} from 'react-redux'
import {cloneDeep, isEqual} from 'lodash'
import {cintQuotaAddOrUpdate, cintQuotaRemove, getCensusData} from '../../../../redux/actions'
import QuotaListItem from './QuotaListItem'
import QuotaError from './QuotaError'
import {GENDER} from '../../../../utils/constants'
import QuotaTotal from './QuotaTotal'
import Spinner from '../../../common/Spinner'

const CensusQuota = (
  {
    quota,
    className = '',
    removeCensusQuota,
    setActiveTab,
    removeCensusInterlock,
    hasCensusData
  }) => {
  const quotaId = quota.id
  const [from, setFrom] = useState(parseInt(quota.from))
  const [to, setTo] = useState(quota.to)
  const [isAgeRangeValid, setIsAgeRangeValid] = useState(true)
  const [quotaOptions, setQuotaOptions] = useState(null)
  const [total, setTotal] = useState({
    percent: 0,
    participants: 0
  })

  const [shouldUnshiftArray, setShouldUnshiftArray] = useState(quota.options && quota.options.length && quota.options[0].percent >= 0)

  const quotaRef = useRef(null)
  const quotaOptionsRef = useRef(null)
  const dispatch = useDispatch()

  const number_of_submissions = useSelector(state => state.runFormOverview.task_parameters.number_of_submissions)
  const numberOfSubmissions = number_of_submissions ? parseInt(number_of_submissions) : 0

  const isDetailsFormValid = useSelector(state => state.runFormOverview.isDetailsFormValid)

  const interlockedQuotas = useSelector(state => state.runFormOverview.cint_interlocked_quotas) || []
  const isQuotaInterlocked = interlockedQuotas.includes(String(quotaId))

  const quotaClassName = `box gender education custom-filter ${className} ${isQuotaInterlocked ? 'interlocked-quota' : ''}`
  const isTotalPercentInvalid = numberOfSubmissions && Math.round(total.percent) !== 100
  const isTotalParticipantsInvalid = numberOfSubmissions && total.participants !== numberOfSubmissions
  const country_name = useSelector(state => state.runFormOverview.task_parameters.country_name)
  const isCensusLoading = useSelector(state => state.censusData?.isLoading) || false
  const currentCensusParams = useRef(null)
  const [showCensusSource, setShowCensusSource] = useState(false)

  const handleRemoveQuota = () => {
    dispatch(cintQuotaRemove(quotaId))

    quotaRef.current.classList.add('remove-filter')
    setTimeout(() => {
      removeCensusQuota(quotaId)
    }, 210)
  }

  const handleAgeChange = (e) => {
    const {name, value} = e.target
    if (name === 'from') {
      setFrom(value)
    } else {
      setTo(value)
    }
  }

  const handleAgeBlur = () => {
    const isRangeValid = validateAgeRange(from, to, quota)
    setIsAgeRangeValid(isRangeValid)
    if (!isRangeValid) return setQuotaOptions(null)

    generateCensusQuota()
  }

  const handleOptionChange = (optionId, percent, participants) => {
    setQuotaOptions(prevState => prevState.map(option => {
      if (option.id !== optionId) return option

      return {
        ...option,
        percent,
        participants
      }
    }))
  }

  const generateCensusQuota = () => {
    if (quota.options && shouldUnshiftArray) {
      return setQuotaOptions(quota.options)
    }

    const parsedFrom = parseInt(from)
    const parsedTo = parseInt(to)

    const range = parsedTo - parsedFrom + 1
    const bucketRange = range >= 10 ? 5 : 1
    const remainder = range % bucketRange
    const noOfGenders = Object.keys(GENDER).length
    let noOfBuckets = Math.ceil(range / bucketRange)
    if (remainder === 1) {
      noOfBuckets = noOfBuckets - 1
    }
    noOfBuckets = noOfBuckets * noOfGenders
    const submissionsPerBucket = Math.floor(numberOfSubmissions / noOfBuckets)
    let errorNumber = numberOfSubmissions - (submissionsPerBucket * noOfBuckets)
    const options = []

    for (let i = 0; i < (noOfBuckets / noOfGenders); i++) {
      let toValue = parsedTo - (bucketRange * i)
      let fromValue = toValue - bucketRange + 1

      const isLastBucket = i + 1 === (noOfBuckets / noOfGenders)
      if (fromValue < parsedFrom || (isLastBucket && remainder === 1)) {
        fromValue = parsedFrom
      }

      const ageGenderBuckets = []
      /* eslint-disable no-loop-func */
      Object.keys(GENDER).forEach(gender => {
        const participants = errorNumber > 0 ? submissionsPerBucket + 1 : submissionsPerBucket
        const percent = participants * 100 / numberOfSubmissions
        errorNumber = errorNumber - 1

        const ageRange = fromValue === toValue ? fromValue : `${fromValue} - ${toValue}`
        ageGenderBuckets.unshift({
          id: `${fromValue}-${toValue}-${gender}`,
          from: fromValue,
          to: toValue,
          percent: numberOfSubmissions ? percent : 0,
          participants: numberOfSubmissions ? participants : 0,
          gender,
          name: `${ageRange} and ${GENDER[gender].name}`
        })
      })
      /* eslint-enable no-loop-func */

      options.unshift(...ageGenderBuckets)
    }
    setQuotaOptions(options)
  }

  const requestCensusData = (isReload = false) => {
    if (quotaOptions) {
      const censusGroups = []

      quotaOptions.forEach(option => {
        censusGroups.push({
          min_age: option.from,
          max_age: option.to,
          gender: option.gender
        })
      })

      const newCensusParams = {country_name: country_name, census_groups: censusGroups}
      if (isReload || !isEqual(newCensusParams, currentCensusParams.current)) {
        currentCensusParams.current = newCensusParams
        dispatch(getCensusData(newCensusParams, {numberOfSubmissions: numberOfSubmissions}))
        setShowCensusSource(true)
      }
    }
  }

  const removeInterlock = () => {
    handleRemoveQuota()
    removeCensusInterlock(from, to, quotaOptions)
  }

  const reloadCensusData = () => {
    requestCensusData(true)
  }

  // Initialize census quota
  useEffect(() => {
    handleAgeBlur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmissions])

  useEffect(() => {
    const isQuotaModified = !isEqual(quotaOptions, quotaOptionsRef.current)
    if (!quotaOptions || !isQuotaModified) return

    quotaOptionsRef.current = cloneDeep(quotaOptions)

    const quotaTotal = quotaOptions.reduce((pv, cv) => ({
      percent: pv.percent + cv.percent,
      participants: pv.participants + cv.participants
    }), {percent: 0, participants: 0})
    setTotal(quotaTotal)

    const isQuotaValid = numberOfSubmissions && Math.round(quotaTotal.percent) === 100
      && quotaTotal.participants === numberOfSubmissions
    dispatch(cintQuotaAddOrUpdate({
      id: quotaId,
      name: 'Census quota: Age and Gender',
      options: quotaOptions,
      isValid: isQuotaValid,
      from: parseInt(from),
      to: parseInt(to)
    }, shouldUnshiftArray))
    setShouldUnshiftArray(false)

    if (hasCensusData) {
      requestCensusData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotaOptions])

  useEffect(() => {
    if (!quotaRef.current) return

    quotaRef.current.classList.add('highlight-filter')

    setTimeout(() => {
      if (!quotaRef.current) return

      quotaRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotaRef.current])

  return (
    <div className={quotaClassName} ref={quotaRef} id={`quota_${quotaId}`}>
      <h2 className="big mb-3 d-flex align-items-center flex-wrap gap-0-5">
        <div className="pr-4 mr-auto">Census Quota: Age and Gender</div>
        <div className="wrap_link pr-4">
          <button
            className="btn btn-default btn-sm back cancel clickable mr-1"
            onClick={removeInterlock}>
            Remove interlock
          </button>

          {hasCensusData && !showCensusSource && (
            <button
              className="btn btn-default btn-sm back cancel clickable mr-0"
              onClick={reloadCensusData}
            >
              Reset to census
            </button>
          )}
        </div>
        <span className="float-right">
          <i className="ion-close" onClick={handleRemoveQuota}/>
        </span>
      </h2>

      <div className="help-text mb-3">
        <div className="po-text">Based on the questions: "How old are you?" and "What is your gender?"</div>
        {hasCensusData && (
          <div className="po-text">
            By default we will match the age and gender distribution of{' '}
            <span className="po-text-bold">{country_name}</span>
          </div>
        )}
      </div>

      <div className={`mb-2 po-text d-flex align-items-center gap-0-5 mb-3 ${isAgeRangeValid ? '' : 'mb-4'}`}>
        <div>Age range:</div>
        <div>
          <input
            name="from"
            required
            type="number"
            min={quota.min_value}
            max={quota.max_value}
            value={from}
            onChange={handleAgeChange}
            onBlur={handleAgeBlur}
            onKeyPress={allowOnlyNumbers}
            onPaste={preventPaste}
            placeholder="From"
            className="mb-0"
            disabled={!isDetailsFormValid}
          />
        </div>
        <div>to</div>
        <div>
          <input
            name="to"
            required
            type="number"
            min={quota.min_value}
            max={quota.max_value}
            value={to}
            onChange={handleAgeChange}
            onBlur={handleAgeBlur}
            onKeyPress={allowOnlyNumbers}
            onPaste={preventPaste}
            placeholder="To"
            className="mb-0"
            disabled={!isDetailsFormValid}
          />
        </div>
        {!isAgeRangeValid && <div className="po-text text-red-soft mt-0">Age range is invalid</div>}
      </div>

      {isCensusLoading ? (
        <Spinner style={{scale: '0.8'}}/>
      ) : (
        <>
          {quotaOptions ? (
            <div className="quota-table mb-3">
              <table className="po-text">
                <tbody>
                <tr className="header text-dark">
                  <td></td>
                  <td>Percent</td>
                  <td>Participants</td>
                  <td className="pb-2">Feasible</td>
                </tr>
                {quotaOptions.map(option => {
                  return (
                    <QuotaListItem
                      key={option.id}
                      quotaId={quotaId}
                      optionId={option.id}
                      numberOfSubmissions={numberOfSubmissions}
                      onOptionChange={handleOptionChange}
                      minAge={option.from}
                      maxAge={option.to}
                      gender={option.gender}
                      setShowCensusSource={setShowCensusSource}
                    />
                  )
                })}
                <QuotaTotal
                  isTotalParticipantsInvalid={isTotalParticipantsInvalid}
                  isTotalPercentInvalid={isTotalPercentInvalid}
                  total={total}
                  showSource={showCensusSource}
                />
                </tbody>
              </table>
              <QuotaError
                isTotalParticipantsInvalid={isTotalParticipantsInvalid}
                isTotalPercentInvalid={isTotalPercentInvalid}
                setActiveTab={setActiveTab}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default CensusQuota
