import React from 'react'
import {getCurrentRoute} from './helpers/utils'
import {openRoutes} from '../routes'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const route = getCurrentRoute()
  const disableSelection = openRoutes.includes(route)

  return (
    <footer>
      <div className={`container ${disableSelection ? 'po-ns' : ''}`}>
        <div className="row">
          <div className="col">
            <p className="copyright">
              © Copyright Positly {currentYear}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
