import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import Select from 'react-select'

import {isCintPlatform, isMturkPlatform} from '../../utils/constants'

const CountryPlatformSelect = (props) => {
  const countries = useSelector(state => state.countriesList?.countries)
  const maxInterviewLength = useSelector(state => state.countriesList?.maxInterviewLength)
  const platforms = useSelector(state => state.platformList?.platforms)
  const {availablePlatforms, setAvailablePlatforms} = props

  const [initPlatform, setInitPlatform] = useState(true)
  const platformSelectRef = useRef(null)

  // Private functions
  const createOption = (options) => {
    if (options) {
      if (options.length && typeof options === 'object') {
        return options.map(option => ({
          label: option,
          value: option
        }))
      } else {
        return {
          label: options,
          value: options
        }
      }
    }
  }

  const isCintOnlyCountry = (country) => (
    [
      'United States',
      'India',
      'Canada',
      'Brazil',
      'United Kingdom',
      'France',
      'Germany',
      'Italy',
      'Mexico',
      'Spain'
    ].indexOf(country) === -1
  )

  const isMturkDefaultCountry = (country) => (
    [
      'United States'
    ].includes(country)
  )

  const sortPlatforms = (availablePlatforms) => {
    const sortOrder = ['Positly', 'Cint', 'Amazon Mechanical Turk']

    return availablePlatforms.sort((a, b) => {
      if (a.platform_type === b.platform_type)
        return a.platform_type.localeCompare(b.platform_type)
      else
        return sortOrder.indexOf(a.platform_type) - sortOrder.indexOf(b.platform_type)
    })
  }

  const getAvailablePlatforms = (countryName) => {
    let _availablePlatforms
    const _isCintOnlyCountry = isCintOnlyCountry(countryName)

    if (_isCintOnlyCountry)
      _availablePlatforms = platforms.filter(platform => isCintPlatform(platform.platform_type))
    else
      _availablePlatforms = sortPlatforms(platforms)

    return _availablePlatforms
  }

  const getDefaultPlatform = (country) => {
    const isCintOnly = isCintOnlyCountry(country)
    const isMturkDefault = isMturkDefaultCountry(country)

    if (isCintOnly) {
      return platforms.find(platform => isCintPlatform(platform.platform_type))
    } else if (isMturkDefault) {
      const platform = platforms.find(platform => platform.is_default)
      if (isMturkPlatform(platform.platform_type))
        return platform
      else
        return platforms.find(platform => platform.platform_type === 'Positly')
    } else {
      return platforms.find(platform => isCintPlatform(platform.platform_type))
    }
  }

  const findPlatform = (platformId) => (
    platforms.find(platform => platform.id === platformId)
  )

  const setupPlatform = (isCountryChanged, countryName) => {
    const _availablePlatforms = getAvailablePlatforms(countryName)
    const platformId = props.platformFieldValue
    let platform

    if (platformId && !isCountryChanged) {
      const _platform = findPlatform(platformId)
      if (_platform)
        platform = _platform
      else
        platform = getDefaultPlatform(countryName, _availablePlatforms)
    } else {
      platform = getDefaultPlatform(countryName, _availablePlatforms)
    }

    setAvailablePlatforms(_availablePlatforms)
    props.formik.setFieldValue(props.platformFieldName, platform.id)

    if (typeof props.onDefaultPlatformChange === 'function') {
      props.onDefaultPlatformChange(platform)
    }

    if (isCountryChanged) {
      if (typeof props.onCountryChange === 'function') {
        props.onCountryChange(countryName, platform)
      }
    }
  }

  const getMaxInterviewLength = (country) => {
    if (maxInterviewLength) {
      const selectedCountry = maxInterviewLength.find(c => c.name === country)
      return selectedCountry.max_interview_length
    }
  }

  const getValidIncidenceRate = (country) => {
    if (!maxInterviewLength) return {}

    const selectedCountry = maxInterviewLength.find(c => c.name === country)
    let {min, max} = selectedCountry.valid_incidence_rate
    if (max === 100) {
      max = 99
    }

    return {min, max}
  }

  const onPlatformChange = (e) => {
    props.formik.handleChange(e)
    const platform = platforms.find(p => p.id === e.target.value)

    if (typeof props.onPlatformChange === 'function') {
      props.onPlatformChange(platform)
    }

    if (typeof props.onDefaultPlatformChange === 'function') {
      props.onDefaultPlatformChange(platform)
    }
  }

  // Event Handlers
  const handleCountryChange = (option) => {
    const country = option.value
    const maxInterviewLength = getMaxInterviewLength(country)
    const {min: minIncidenceRate, max: maxIncidenceRate} = getValidIncidenceRate(country)

    if (typeof props.setMaxInterviewLength === 'function')
      props.setMaxInterviewLength(maxInterviewLength)

    if (typeof props.setMinIncidenceRate === 'function')
      props.setMinIncidenceRate(minIncidenceRate)

    if (typeof props.setMaxIncidenceRate === 'function')
      props.setMaxIncidenceRate(maxIncidenceRate)

    props.formik.setFieldValue(props.countryFieldName, country)
    setupPlatform(true, country)
  }

  const closeDropdown = () => {
    if (!platformSelectRef.current) return

    platformSelectRef.current.blur()
  }

  // Use Effect
  useEffect(() => {
    if (countries && platforms && props.countryFieldValue && initPlatform) {
      setInitPlatform(false)
      setupPlatform(false, props.countryFieldValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, platforms, props.countryFieldValue, initPlatform])

  useEffect(() => {
    if (props.countryFieldValue && typeof props.setMaxInterviewLength === 'function') {
      const maxIntLength = getMaxInterviewLength(props.countryFieldValue)
      props.setMaxInterviewLength(maxIntLength)

      const {min: minIncidenceRate, max: maxIncidenceRate} = getValidIncidenceRate(props.countryFieldValue)

      if (typeof props.setMinIncidenceRate === 'function')
        props.setMinIncidenceRate(minIncidenceRate)

      if (typeof props.setMaxIncidenceRate === 'function')
        props.setMaxIncidenceRate(maxIncidenceRate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countryFieldValue])


  // UI Template
  return (
    <>
      <div className={`${props.countryClassName ? props.countryClassName : 'form-group run-mt-1'}`}>
        <label>
          <h2 className="text-required">Country we should source your participants from</h2>
        </label>
        <Select
          name={props.countryFieldName}
          className="full-width run-country"
          value={createOption(props.countryFieldValue)}
          onChange={handleCountryChange}
          options={createOption(countries)}
          noOptionsMessage={() => 'No results found'}
          placeholder="Select Country"
          classNamePrefix="react-select"
          tabIndex={props.tabIndex}
          isSearchable
          styles={{
            container: styles => ({
              ...styles,
              width: '100%',
              borderRadius: 4,
              backgroundColor: '#fff',
              appearance: 'none',
              color: 'rgba(50, 60, 71, 0.7)',
              fontSize: 14,
              letterSpacing: -0.4,
              fontFamily: 'Muli-SemiBold',
            }),
            valueContainer: (styles) => ({...styles, padding: '0 0 0 20px'}),
            control: (styles) => ({...styles, border: '1px solid #e2e9ef'}),
            indicatorSeparator: () => ({display: 'none'}),
            input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
            singleValue: (styles) => ({
              ...styles,
              color: 'var(--input-text-color)',
              width: 'calc(100% - 20px)',
            }),
            menuList: (styles) => ({...styles, maxHeight: 200}),
            option: (styles) => ({...styles, paddingLeft: 20}),
            noOptionsMessage: (styles) => ({...styles, paddingLeft: 20, textAlign: 'left'}),
            placeholder: (styles) => ({
              ...styles,
              color: 'var(--placeholder-color)',
            }),
          }}
        />
      </div>

      {props.isAccountsPage && (
        <div className={`${props.platformClassName ? props.platformClassName : 'form-group run-mt-1 pt-2'}`}>
          <label>
            <h2>
              Platform
              <span className="text-required"/>

              <div className="d-inline-block po-tooltip activity-language-tooltip ml-1" onMouseEnter={closeDropdown}>
              <span className="po-tooltiptext po-tooltip-position">
                Depending on the country you choose, you may see two options for platforms to use. The two
                platforms are similar in many ways but have important differences.<br/><br/>

                Positly’s Microtasker platform allows you to choose how much to pay each participant, and also
                allows you to send messages, bonus payments, and follow-up studies (via custom qualifications)
                to participants. However, the Microtasker platform is only available in a few countries,
                including the U.S.<br/><br/>

                Positly’s Panel Participants platform works in over 130 countries, offers you a wider range of
                participant filters, and allows you to use your own screening questions. However,
                it does not allow sending messages or bonus payments.<br/><br/>

                More information about each platform{' '}
                <a
                  className="po-link"
                  href="https://www.positly.com/support/choosing-a-platform-on-positly/"
                  target="_blank"
                  rel="noreferrer"
                >
                  is available here
                </a>
                .
              </span>
              </div>
            </h2>
          </label>
          <select
            ref={platformSelectRef}
            className="run-platform"
            name={props.platformFieldName}
            onChange={onPlatformChange}
            value={props.platformFieldValue}
            tabIndex="2"
          >
            {availablePlatforms.map(platform =>
              <option
                key={platform.id}
                value={platform.id}
                id={platform.id}>
                {platform.full_name}
              </option>
            )}
          </select>
        </div>
      )}
    </>
  )
}

export default CountryPlatformSelect
