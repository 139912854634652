import {authHeaders} from '../../components/common/Helpers'
import {errorToast, successToast} from '../../components/helpers/notification'
import {setupRunFormFromProject} from '../actions'
import {LINKEDIN_CONVERSION_IDS} from '../../utils/constants'
import {GA_EVENTS, sendGAEvent, triggerLinkedinInsightEvent} from '../../utils/helper'

export const projectActions = {
  PROJECT_LIST_INIT: 'PROJECT_LIST_INIT',
  PROJECT_LIST_SUCCESS: 'PROJECT_LIST_SUCCESS',
  PROJECT_LIST_ERROR: 'PROJECT_LIST_ERROR',

  PROJECT_INIT: 'PROJECT_INIT',
  PROJECT_SUCCESS: 'PROJECT_SUCCESS',
  PROJECT_ERROR: 'PROJECT_ERROR',

  PROJECT_RUNS_INIT: 'PROJECT_RUNS_INIT',
  PROJECT_RUNS_LOADING_MORE: 'PROJECT_RUNS_LOADING_MORE',
  PROJECT_RUNS_SUCCESS: 'PROJECT_RUNS_SUCCESS',
  PROJECT_RUNS_ERROR: 'PROJECT_RUNS_ERROR',

  PROJECT_CREATE_INIT: 'PROJECT_CREATE_INIT',
  PROJECT_CREATE_SUBMIT: 'PROJECT_CREATE_SUBMIT',
  PROJECT_CREATE_SUCCESS: 'PROJECT_CREATE_SUCCESS',
  PROJECT_CREATE_ERROR: 'PROJECT_CREATE_ERROR',

  PROJECT_EDIT_INIT: 'PROJECT_EDIT_INIT',
  PROJECT_EDIT_SUBMIT: 'PROJECT_EDIT_SUBMIT',
  PROJECT_EDIT_CANCEL: 'PROJECT_EDIT_CANCEL',
  PROJECT_EDIT_SUCCESS: 'PROJECT_EDIT_SUCCESS',
  PROJECT_EDIT_ERROR: 'PROJECT_EDIT_ERROR',
  PROJECT_EDIT_RESET: 'PROJECT_EDIT_RESET',

  PROJECT_DELETE_INIT: 'PROJECT_DELETE_INIT',
  PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
  PROJECT_DELETE_ERROR: 'PROJECT_DELETE_ERROR',

  PROJECT_GENERATE_BILLING_STATEMENT_INIT: 'PROJECT_GENERATE_BILLING_STATEMENT_INIT',
  PROJECT_GENERATE_BILLING_STATEMENT_SUCCESS: 'PROJECT_GENERATE_BILLING_STATEMENT_SUCCESS',
  PROJECT_GENERATE_BILLING_STATEMENT_ERROR: 'PROJECT_GENERATE_BILLING_STATEMENT_ERROR',
}

export const projectListInit = () => ({
  type: projectActions.PROJECT_LIST_INIT
})

export const projectListFetch = (pageNumber, tasksPerPage = 20, query = '') => {
  return dispatch => {
    return fetch(`/api/v1/getTasks?page_number=${pageNumber}&tasks_per_page=${tasksPerPage}&query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(projectListError(errors))
        } else {
          dispatch(projectListSuccess(data, pageNumber, tasksPerPage))
        }
      })
  }
}

const projectListSuccess = (projects, pageNumber, tasksPerPage) => {
  const hasMore = projects.length === tasksPerPage
  return {
    type: projectActions.PROJECT_LIST_SUCCESS,
    projects,
    pageNumber,
    hasMore
  }
}

const projectListError = (errors) => ({
  type: projectActions.PROJECT_LIST_ERROR,
  errors
})

const projectInit = () => ({
  type: projectActions.PROJECT_INIT,
})

export const projectFetch = (id, page) => {
  return dispatch => {
    dispatch(projectInit())
    return fetch('/api/v1/getTask/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(projectError(errors))
          errorToast(errors.join(', '))
        } else {
          if (page === 'form') {
            dispatch(setupRunFormFromProject(data))
          } else {
            dispatch(projectSuccess(data))
          }
        }
      })
  }
}

const projectSuccess = (project) => ({
  type: projectActions.PROJECT_SUCCESS,
  project,
})

const projectError = (errors) => ({
  type: projectActions.PROJECT_ERROR,
  errors
})

export const projectRunsInit = () => ({
  type: projectActions.PROJECT_RUNS_INIT
})

export const projectRunsLoadingMore = () => ({
  type: projectActions.PROJECT_RUNS_LOADING_MORE
})

export const projectRunsFetch = (id, pageNumber, getRunningOnly = false, query = '') => {
  return dispatch => {
    return fetch(`/api/v1/getRuns?page_number=${pageNumber}&page_size=15&task_id=${id}&get_running_only=${getRunningOnly}&query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(projectRunsError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(projectRunsSuccess(data, pageNumber))
        }
      })
  }
}

const projectRunsSuccess = (runs, pageNumber) => {
  const hasMore = runs.length === 15

  return {
    type: projectActions.PROJECT_RUNS_SUCCESS,
    runs,
    pageNumber,
    hasMore
  }
}

const projectRunsError = (errors) => ({
  type: projectActions.PROJECT_RUNS_ERROR,
  errors
})

export const projectCreateInit = () => ({
  type: projectActions.PROJECT_CREATE_INIT
})

const projectCreateSubmit = () => ({
  type: projectActions.PROJECT_CREATE_SUBMIT
})

const projectCreateSuccess = (project) => ({
  type: projectActions.PROJECT_CREATE_SUCCESS,
  project
})

const projectCreateError = (errors) => ({
  type: projectActions.PROJECT_CREATE_ERROR,
  errors
})

export const projectCreateFetch = project => {
  return dispatch => {
    dispatch(projectCreateSubmit())

    return fetch('/api/v1/createTask', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(project)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          errorToast(errors.join(', '))
          dispatch(projectCreateError(errors))
        } else {
          successToast('Project created successfully!')
          dispatch(projectCreateSuccess(data))
          const eventData = {positly_user_id: data.user.id, project_id: data.id}
          if(data.is_sparkwave_researcher === false) {
            sendGAEvent(GA_EVENTS.PROJECT_CREATED, eventData)
          }
          triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.PROJECT_CREATED, data.user.utm_parameters)

          if (data.user.projects_count === 1 && data.is_sparkwave_researcher === false) {
            sendGAEvent(GA_EVENTS.FIRST_PROJECT_CREATED, eventData)
            triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.FIRST_PROJECT_CREATED, data.user.utm_parameters)
          }
        }
      })
  }
}

export const projectEditInit = () => ({
  type: projectActions.PROJECT_EDIT_INIT
})

export const projectEditSubmit = () => ({
  type: projectActions.PROJECT_EDIT_SUBMIT
})

export const projectEditFetch = (id, project) => {
  return dispatch => {
    return fetch('/api/v1/modifyTask/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(project)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(projectEditError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(projectEditSuccess(project))
          dispatch(projectEditReset())
        }
      })
  }
}

const projectEditError = errors => ({
  type: projectActions.PROJECT_EDIT_ERROR,
  errors
})

const projectEditSuccess = (project) => ({
  type: projectActions.PROJECT_EDIT_SUCCESS,
  project
})

export const projectEditCancel = () => ({
  type: projectActions.PROJECT_EDIT_CANCEL
})

export const projectEditReset = () => ({
  type: projectActions.PROJECT_EDIT_RESET
})

export const projectDeleteInit = () => ({
  type: projectActions.PROJECT_DELETE_INIT
})

export const projectDelete = (id) => {
  return dispatch => {
    return fetch('/api/v1/removeTask/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(projectDeleteError())
        } else {
          dispatch(projectDeleteSuccess())
        }

        dispatch(projectDeleteInit())
      })
  }
}

const projectDeleteSuccess = () => ({
  type: projectActions.PROJECT_DELETE_SUCCESS
})

const projectDeleteError = () => ({
  type: projectActions.PROJECT_DELETE_ERROR
})


const generateBillingStatementInit = () => ({
  type: projectActions.PROJECT_GENERATE_BILLING_STATEMENT_INIT
})

const generateBillingStatementSuccess = (text) => ({
  type: projectActions.PROJECT_GENERATE_BILLING_STATEMENT_SUCCESS,
  text
})

const generateBillingStatementError = (errors) => ({
  type: projectActions.PROJECT_GENERATE_BILLING_STATEMENT_ERROR,
  errors
})

export const generateBillingStatement = (projectId) => {
  return dispatch => {
    dispatch(generateBillingStatementInit())

    return fetch(`/api/v1/generateBillingStatement/${projectId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(generateBillingStatementError(errors))
        } else {
          dispatch(generateBillingStatementSuccess(data))
        }
      })
  }
}

