import {authHeaders} from '../../components/common/Helpers'
import {errorToast, successToast} from '../../components/helpers/notification'

export const platformActions = {
  PLATFORM_LIST_INIT: 'PLATFORM_LIST_INIT',
  PLATFORM_LIST_SUCCESS: 'PLATFORM_LIST_SUCCESS',
  PLATFORM_LIST_ERROR: 'PLATFORM_LIST_ERROR',

  PLATFORM_ADD_INIT: 'PLATFORM_ADD_INIT',
  PLATFORM_ADD_SUBMIT: 'PLATFORM_ADD_SUBMIT',
  PLATFORM_ADD_SUCCESS: 'PLATFORM_ADD_SUCCESS',
  PLATFORM_ADD_ERROR: 'PLATFORM_ADD_ERROR',

  PLATFORM_DELETE_INIT: 'PLATFORM_DELETE_INIT',
  PLATFORM_DELETE_SUCCESS: 'PLATFORM_DELETE_SUCCESS',
  PLATFORM_DELETE_ERROR: 'PLATFORM_DELETE_ERROR',
}

/* Platform list */
const platformListInit = () => ({
  type: platformActions.PLATFORM_LIST_INIT
})

export const platformListSuccess = (platforms) => ({
  type: platformActions.PLATFORM_LIST_SUCCESS,
  platforms
})

const platformListError = (errors) => ({
  type: platformActions.PLATFORM_LIST_ERROR,
  errors
})

export const platformListFetch = () => {
  return dispatch => {
    dispatch(platformListInit())
    return getPlatforms()
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(platformListError(errors))
        } else {
          dispatch(platformListSuccess(data))
        }
      })
  }
}

/* Platform add */
export const platformAddInit = () => ({
  type: platformActions.PLATFORM_ADD_INIT
})

export const platformAddFetch = platform => {
  return dispatch => {
    return fetch('/api/v1/addPlatform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(platform)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(platformAddError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(platformAddSuccess(data))
          successToast('Platform added successfully!')
        }
      })
  }
}

const platformAddSuccess = (platform) => ({
  type: platformActions.PLATFORM_ADD_SUCCESS,
  platform
})

const platformAddError = (errors) => ({
  type: platformActions.PLATFORM_ADD_ERROR,
  errors
})

export const platformAddSubmit = () => ({
  type: platformActions.PLATFORM_ADD_SUBMIT
})

/* Platform delete */
export const platformDeleteInit = () => ({
  type: platformActions.PLATFORM_DELETE_INIT
})

const platformDeleteSuccess = (id) => ({
  type: platformActions.PLATFORM_DELETE_SUCCESS,
  id
})

const platformDeleteError = (errors) => ({
  type: platformActions.PLATFORM_DELETE_ERROR,
  errors
})

export const platformDeleteFetch = (id) => {
  return dispatch => {
    return fetch('/api/v1/removePlatform/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(platformDeleteError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(platformDeleteSuccess(id))
          successToast('Platform deleted successfully!')
        }
      })
  }
}

/* Get platform for run form */
export const getPlatforms = () => {
  return fetch('/api/v1/getAbstractPlatforms', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}
