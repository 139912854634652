import React, {useRef, useState} from 'react'

const $ = window.$

const PasswordVisibilityToggler = (props) => {
  const eyeRef = useRef()
  const [icon, setIcon] = useState('fa fa-eye cursor-pointer')

  const togglePasswordVisibility = () => {
    const type = $(props.input).attr('type')
    if (type === 'text') {
      $(props.input).attr('type', 'password')
      setIcon('fa fa-eye cursor-pointer')
    } else {
      $(props.input).attr('type', 'text')
      setIcon('fa fa-eye-slash cursor-pointer')
    }
  }

  return (
    <span className="input-group-append">
       <i ref={eyeRef} className={icon}
          onClick={togglePasswordVisibility}/>
    </span>
  )
}

export default PasswordVisibilityToggler
