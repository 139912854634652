import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import qs from 'qs'

import {getStorageItem} from '../../common/Helpers'
import {LINK_VERIFICATION_TYPES} from '../../../utils/constants'
import {getFormattedActivityUrl} from './RunUtils'

import PositlyLink from '../../common/PositlyLink'
import CopyLink from '../../common/CopyLink'

import {markLinkVerifiedFetch} from '../../../redux/actions'

const ScreenInPlaceHolder = () => {
  const history = useHistory()
  const queryParams = qs.parse(history.location.search, {ignoreQueryPrefix: true})
  const taskId = queryParams.task_id
  const expectedTaskId = getStorageItem(`lv_task_id`)
  const activityDetails = getStorageItem(`lv_${expectedTaskId}_activity_details`) || {}
  const {
    screeningUrl = '',
    activityUrl = '',
    activityTitle = '',
    activityDescription = '',
    activityAdditionalInfo = '',
    activityDuration = '',
    activityPay = ''
  } = activityDetails
  const linkVerificationId = getStorageItem(`lv_${expectedTaskId}_${LINK_VERIFICATION_TYPES.SCREEN_IN_LINK}_id`)

  const dispatch = useDispatch()

  const openMainActivity = (e) => {
    e.preventDefault()
    window.location = getFormattedActivityUrl(activityUrl, expectedTaskId)
  }

  const openScreeningActivity = (e) => {
    e.preventDefault()
    window.location = screeningUrl
  }

  useEffect(() => {
    if (!expectedTaskId || !taskId || expectedTaskId !== taskId || !linkVerificationId)
      return

    dispatch(markLinkVerifiedFetch({id: linkVerificationId}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When link verification data not available
  if (!expectedTaskId) {
    return (
      <div className="box po-text-bold">
        <h3 className="px-4 py-2">
          This is the Screen-In page for your Screening Activity. When the participant is eligible for the Main activity
          you must direct participants to this page so that they can confirm their completion and receive their payment
          for the Screening Activity. Also they will be informed about their eligibility for the Main Activity.
        </h3>
      </div>
    )
  }

  return (
    <>
      {expectedTaskId && taskId && expectedTaskId === taskId ? (
        <div className="box po-text col-md-12 py-4">
          <div className="text-center pb-3">
            <h1 className="po-header-lite mt-3">
              You qualify for a bonus study from this researcher!
            </h1>

            <div className="text-left w-50 m-auto pt-4 px-4">
              <div className="mb-2 pb-1 lh-1">
                <span className="po-text-bold">Activity:</span> {activityTitle}
              </div>
              <div className="mb-2 pb-1 lh-1">
                <span className="po-text-bold">Description:</span> {activityDescription}
              </div>
              {activityAdditionalInfo && (
                <div className="mb-2 pb-1 lh-1">
                  <span className="po-text-bold">Additional Info:</span> {activityAdditionalInfo}
                </div>
              )}
              <div className="mb-2 pb-1 lh-1">
                <span className="po-text-bold">Time required:</span> {activityDuration}
              </div>
              <div className="mb-2 pb-1 lh-1">
                <span className="po-text-bold">Bonus pay:</span> ${activityPay}
              </div>
            </div>

            <div className="mt-4 pt-2 text-center font-size-18">
              This opportunity will expire in 10 minutes.
            </div>
            <div className="font-size-11 mt-0">After that, your slot will go to another worker.</div>

            <div className="wrap_link mt-5 d-inline-flex">
              <button
                type="button"
                className="btn btn-default cancel clickable"
                onClick={openScreeningActivity}
              >
                No thanks
              </button>
              <button
                className={`btn btn-primary refresh clickable no-border`}
                onClick={openMainActivity}
              >
                Start bonus activity
              </button>
            </div>
          </div>
        </div>
      ) : (
        /* When link verification data is not matching */
        <div className="box py-4 po-text">
          <div className="row">
            <div className="col-md-8">
              <label className="po-label">
                <p className="po-text-error error-o mb-2">Your Screen-In link setup is incorrect.</p>
                <p className="po-p mb-2">Please change your Screen-In link at your study to be:</p>
                <div className="wrap_input_text form_style copy_completion_link_wrapper">
                  <PositlyLink
                    id="screen_in_link"
                    className="mw-none correct_link"
                    path={`/#/start?task_id=${expectedTaskId}`}
                  />
                  <CopyLink
                    label="Copy Screen-In Link"
                    idToCopy="screen_in_link"
                    className="btn btn-clipboard copy_link feedback mx-sm-1"
                  />
                </div>
                <p className="po-p mt-2">instead of:</p>
                <p className="po-p mb-2">{window.location.href}</p>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ScreenInPlaceHolder
