import {authHeaders} from '../../components/common/Helpers'

export const quoteActions = {
  GET_QUOTE_FOR_BONUS_LOADING: 'GET_QUOTE_FOR_BONUS_LOADING',
  GET_QUOTE_FOR_BONUS_SUCCESS: 'GET_QUOTE_FOR_BONUS_SUCCESS',
  GET_QUOTE_FOR_BONUS_ERROR: 'GET_QUOTE_FOR_BONUS_ERROR',
  GET_QUOTE_FOR_BONUS_RESET: 'GET_QUOTE_FOR_BONUS_RESET'
}

export const getQuoteForMturk = (params) => {
  return dispatch => {
    dispatch(getQuoteForMturkLoading())

    let searchParams = new URLSearchParams(params).toString()

    return fetch(`/api/v1/getQuoteForMturk?${searchParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          dispatch(getQuoteForMturkError(errors))
        } else {
          dispatch(getQuoteForMturkSuccess(data))
        }
      })
  }
}

const getQuoteForMturkLoading = () => ({
  type: quoteActions.GET_QUOTE_FOR_BONUS_LOADING
})

const getQuoteForMturkSuccess = (data) => ({
  type: quoteActions.GET_QUOTE_FOR_BONUS_SUCCESS,
  data
})

const getQuoteForMturkError = (errors) => ({
  type: quoteActions.GET_QUOTE_FOR_BONUS_ERROR,
  errors
})

export const getQuoteForMturkReset = () => ({
  type: quoteActions.GET_QUOTE_FOR_BONUS_RESET
})
