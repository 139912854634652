import {projectActions} from '../actionss/projectActions'

const projectReducer = (state, action) => {
  switch (action.type) {
    case projectActions.PROJECT_LIST_INIT:
      return {...state, projectList: {isLoading: true, projects: []}}
    case projectActions.PROJECT_LIST_SUCCESS:
      const projects = action.pageNumber === 1 ? action.projects : [...state.projectList.projects, ...action.projects]
      return {
        ...state,
        projectList: {
          isLoading: false,
          projects: projects,
          pageNumber: action.pageNumber,
          hasMore: action.hasMore
        }
      }
    case projectActions.PROJECT_LIST_ERROR:
      return {...state, projectList: {isLoading: false, errors: action.errors}}

    case projectActions.PROJECT_INIT:
      return {...state, projectDetail: {isLoading: true}}
    case projectActions.PROJECT_SUCCESS:
      if (action.model === 'form') {
        return {...state, runForm: {...state.runForm, isLoading: false, ...action.project}}
      } else {
        return {...state, projectDetail: {isLoading: false, project: action.project}}
      }
    case projectActions.PROJECT_ERROR:
      return {...state, projectDetail: {isLoading: false, errors: action.errors}}
    case projectActions.PROJECT_RUNS_INIT:
      return {...state, projectRuns: {isLoading: true, runs: []}}
    case projectActions.PROJECT_RUNS_LOADING_MORE:
      return {...state, projectRuns: {...state.projectRuns, isLoadingMoreRuns: true}}
    case projectActions.PROJECT_RUNS_SUCCESS:
      return {
        ...state,
        projectRuns: {
          isLoading: false,
          isLoadingMoreRuns: false,
          runs: [...state.projectRuns.runs, ...action.runs],
          pageNumber: action.pageNumber,
          hasMore: action.hasMore
        }
      }
    case projectActions.PROJECT_RUNS_ERROR:
      return {...state, projectRuns: {...state.projectRuns, isLoading: false, errors: action.errors}}

    case projectActions.PROJECT_CREATE_INIT:
      return {...state, projectCreate: {}}
    case projectActions.PROJECT_CREATE_SUBMIT:
      return {...state, projectCreate: {isLoading: true}}
    case projectActions.PROJECT_CREATE_SUCCESS:
      return {...state, projectCreate: {isLoading: false, project: action.project}}
    case projectActions.PROJECT_CREATE_ERROR:
      return {...state, projectCreate: {isLoading: false, errors: action.errors}}

    case projectActions.PROJECT_EDIT_INIT:
      return {...state, projectDetail: {...state.projectDetail, isEditing: true}}
    case projectActions.PROJECT_EDIT_CANCEL:
      return {...state, projectDetail: {...state.projectDetail, isEditing: false}}
    case projectActions.PROJECT_EDIT_SUBMIT:
      return {...state, projectDetail: {...state.projectDetail, isUpdating: true}}
    case projectActions.PROJECT_EDIT_SUCCESS:
      return {
        ...state,
        projectDetail: {
          project: {
            ...state.projectDetail?.project,
            project_name: action.project.project_name
          },
          isUpdated: true,
        }
      }
    case projectActions.PROJECT_EDIT_ERROR:
      return {...state, projectDetail: {...state.projectDetail, isUpdating: false, errors: action.errors}}
    case projectActions.PROJECT_EDIT_RESET:
      return {...state, projectDetail: {...state.projectDetail, isUpdated: false}}

    case projectActions.PROJECT_DELETE_INIT:
      return {...state, projectDelete: {}}
    case projectActions.PROJECT_DELETE_SUCCESS:
      return {...state, projectDelete: {status: 'success'}}
    case projectActions.PROJECT_DELETE_ERROR:
      return {...state, projectDelete: {status: 'error'}}

    default:
      return state
  }
}

export default projectReducer
