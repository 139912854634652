import React from 'react'

import quality_score_icon from '../../images/metrics_5.svg'

const QualityScore = ({score, label}) => {
  const getClass = () => {
    let className = ''

    if(!score)
      return

    if (score >= 0 && score < 5) {
      className = 'text-red-soft'
    } else if (score >= 5 && score <= 6) {
      className = 'text-amber'
    } else if (score > 6 && score < 9) {
      className = 'text-yellow'
    } else if (score >= 9) {
      className = 'text-green'
    }

    return className
  }

  return (
    <div className="box">
      <div className="wrap_img d-flex">
        <img src={quality_score_icon} alt="icon" data-testid="quality-score-icon"/>
        <div className={`number number-lifted ${getClass()}`}>
          <span data-testid="quality-score">
            {score > 0 ? score : 'N/A'}
          </span>
        </div>
      </div>
      <div className="title">{label}</div>
    </div>
  )
}

export default QualityScore
