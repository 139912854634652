import React from 'react'

const DotProgressBar = ({label, currentStep, totalSteps, currentCount, totalCount, isLastStep, showToolTip}) => {
  const getClassName = (index) => {
    const step = index + 1
    if (isLastStep) return 'last'
    if (step === currentStep) return 'current'
    return ''
  }

  const renderSteps = () => {
    const width = `${100 / totalSteps}%`

    return Array(totalSteps)
      .fill('')
      .map((_, i) => (
        <li key={i} className={getClassName(i)} style={{width}}>
          <span/>
        </li>
      ))
  }

  return (
    <div className="row list-item dot-progress">
      <div className="col-md-5 dot-progress-bar">
        <div className="flex-d">
          <span data-testid="dot-progress-label">{label}</span>&nbsp;&nbsp;
          {showToolTip && (
            <div className="d-inline-block po-tooltip participants-attributes-tooltip">
              <span className="po-tooltiptext po-tooltip-position">
                Scheduled for automatic approval within 6 hours
              </span>
            </div>
          )}
        </div>
        <ul data-testid="steps-container">
          {renderSteps()}
        </ul>
      </div>
      <div className="col-3 current-status">
        {currentCount}
      </div>
      <div className="col-4">
        {totalCount}
      </div>
    </div>
  )
}

export default DotProgressBar
