import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useSelector} from 'react-redux'

import {errorToast, successToast} from './helpers/notification'
import {authHeaders} from './common/Helpers'
import {GA_EVENTS, sendGAEvent} from '../utils/helper'

const $ = window.$

const RequestDemoPopup = () => {
  const [isLoading, setIsLoading] = useState(false)

  const {full_name = '', email = ''} = useSelector(state => state.currentUser) || {}
  const [firstName, ...lastNames] = full_name?.split(' ') || []
  const positly_user_id = useSelector(state => state.currentUser?.uid)

  const closeRequestDemoPopup = (e, skipGAEvent = false) => {
    e?.preventDefault()

    if (!skipGAEvent) {
      sendGAEvent(GA_EVENTS.CLICK_CANCEL_REQUEST_DEMO, {positly_user_id})
    }

    formik.resetForm()
    setTimeout(async () => {
      await formik.validateForm()
      $.magnificPopup.close()
    }, 0)
  }

  const submitDemoRequest = async (params) => {
    try {
      setIsLoading(true)
      const response = await fetch('/api/v1/users/requestDemo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...authHeaders()
        },
        body: JSON.stringify(params)
      })
      const data = await response.json()
      const errors = data.errors

      if (errors && errors.length > 0) {
        errorToast(errors.join(', '))
      } else {
        successToast('Thanks, your request submitted successfully!')
        closeRequestDemoPopup(null, true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const initialValues = {
    first_name: firstName || '',
    last_name: lastNames?.join(' ') || '',
    email: email,
    organization: '',
    job_title: '',
    purpose_of_demo: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required('First Name is required'),
      last_name: Yup.string()
        .required('Last Name is required'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email'),
      organization: Yup.string(),
      job_title: Yup.string(),
      purpose_of_demo: Yup.string()
        .required('Purpose of demo is required')
    }),
    onSubmit: async (values) => {
      if (!formik.isValid || isLoading) return

      await submitDemoRequest(values)
      sendGAEvent(GA_EVENTS.CLICK_SUBMIT_REQUEST_DEMO, {positly_user_id})
    },
    validateOnMount: true,
  })

  return (
    <div id="request-demo-popup" className="white-popup mfp-hide">
      <h2>Get expert help</h2>
      <form
        noValidate
        name="request_demo"
        className="wrap_info"
        onSubmit={formik.handleSubmit}
      >
        <div className="po-text">
          Fill out the form below and a member of our team will be in touch very soon.
        </div>
        <div className="wrap_item mt-3">
          <div className="row gap-1">
            <div className="col">
              <label className="text-required">
                First Name
              </label>
              <div className="form-group input-group">
                <input
                  type="text"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <label className="text-required">
                Last Name
              </label>
              <div className="form-group input-group">
                <input
                  type="text"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="wrap_item mt-3">
          <div className="row gap-1">
            <div className="col">
              <label className="text-required">
                Work Email
              </label>
              <div className="form-group input-group">
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <label>
                Organization/Company
              </label>
              <div className="form-group input-group">
                <input
                  type="text"
                  name="organization"
                  value={formik.values.organization}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="wrap_item mt-3">
          <div className="row gap-1">
            <div className="col">
              <label>
                Job Title
              </label>
              <div className="form-group input-group">
                <input
                  type="text"
                  name="job_title"
                  value={formik.values.job_title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col"/>
          </div>
        </div>
        <div className="wrap_item mt-3">
          <div className="row gap-1">
            <div className="col">
              <label className="text-required">
                Tell us how we can help or what you would like to recruit participants for
              </label>
              <textarea
                name="purpose_of_demo"
                rows={3}
                value={formik.values.purpose_of_demo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>

        <div className="wrap_link run-mt-3 ml-auto flex-row">
          <button
            name="demoRequestSubmit"
            className={`btn btn-primary refresh clickable no-border ${!formik.isValid || isLoading ? 'btn-grey cursor-disabled' : ''}`}
            disabled={!formik.isValid || isLoading}
            type="submit"
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            name="demoRequestCancel"
            type="button"
            className="btn btn-default cancel clickable"
            onClick={closeRequestDemoPopup}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default RequestDemoPopup
