import React from 'react'
import Helmet from 'react-helmet'

const GoogleTag = () => {
  const GOOGLE_TAG_ID = process.env.REACT_APP_GOOGLE_TAG_ID

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GOOGLE_TAG_ID}');
        `}
      </script>
    </Helmet>
  )
}

export default GoogleTag
