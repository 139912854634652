import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

// Custom components
import UnconfirmedEmail from './user/UnconfirmedEmail'
import Spinner from './common/Spinner'


const ProtectedRoute = ({component: Component, isTokenValidating, currentUserUid, ...rest}) => {
  return (
    <>
      <UnconfirmedEmail/>
      <Route {...rest} render={
        props => {
          if (currentUserUid) {
            return <Component {...rest} {...props} />
          } else if (isTokenValidating) {
            return <Spinner/>
          } else {
            return <Redirect to="/users/sign-in"/>
          }
        }
      }/>
    </>
  )
}

const mapStateToProps = state => ({
  isTokenValidating: state.isTokenValidating,
  currentUserUid: state.currentUser?.uid,
})

export default connect(mapStateToProps)(ProtectedRoute)
