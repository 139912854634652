export const getEvenDistributionForQuota = (options = [], numberOfSubmissions, shouldUnshiftArray, filterOptions = []) => {
  const selectedFilterOptions = []
  filterOptions.forEach(option => {
    if (!option.is_checked) return

    selectedFilterOptions.push(option.id || option.title)
  })

  const numberOfOptions = selectedFilterOptions.length || options.length
  const participantsPerOption = Math.floor(numberOfSubmissions / numberOfOptions)
  let errorNumber = numberOfSubmissions - (participantsPerOption * numberOfOptions)

  return options.map(option => {
    const skipOption = selectedFilterOptions.length && !selectedFilterOptions.includes(option.id)
    let percent
    let participants
    if (!numberOfSubmissions || skipOption) {
      percent = 0
      participants = 0
    } else if (option.percent !== undefined && option.percent !== null && shouldUnshiftArray) {
      percent = option.percent
      participants = option.participants
    } else {
      participants = errorNumber > 0 ? participantsPerOption + 1 : participantsPerOption
      percent = participants * 100 / numberOfSubmissions
      errorNumber = errorNumber - 1
    }

    return {
      ...option,
      percent,
      participants
    }
  })
}
