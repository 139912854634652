import React from 'react'

const PreScreenSelected = props => {
  const {prescreenData: psData, prescreenRuns: psRuns} = props

  const getPreviousParticipationText = () => {
    let displayText = ''

    if (psData.ps_type === 'project_include') {
      displayText = 'Only allow participants who have completed other runs in this project'
    } else if (psData.ps_type === 'project_exclude') {
      displayText = 'Exclude all participants who have started other runs in this project'
    } else if (psData.ps_type === 'custom') {
      let modeText = psData.ps_mode === 'include' ? 'Only allow ' : 'Exclude '
      let levelText = getLevelText(psData.ps_level)

      displayText = `${modeText} participants who have ${psData.ps_status} ${psData.ps_comparator} of ${levelText}`
    }

    return displayText
  }

  const getLevelText = (psLevel) => {
    switch (psLevel) {
      case 'project':
        return 'the other runs in this project'
      case 'runs':
        return 'these specific runs'
      case 'projects':
        return 'the other runs in these projects'
      case 'user':
        return 'the other runs in my account'
      default:
        return ''
    }
  }

  const isPrescreenApplied = () => {
    return (
      psData
      && psData.ps_type
      && psData.ps_type !== 'does_not_matter'
      && !(
        (psData.ps_level === 'runs' || psData.ps_level === 'projects')
        && psRuns.length === 0
      )
    )
  }

  return (
    <>
      {isPrescreenApplied() && (
        <div className="row bottom_box">
          <div className="col-lg-12">
            <div>
              <div className="filters">
                <h3 className="clear-margin">Previous participation</h3>
              </div>
            </div>
            <p>
              {getPreviousParticipationText()}
            </p>
            <table className="table table-bordered my-2">
              <tbody>
              {psRuns.map((run, index) => (
                <tr key={index}>
                  <td>
                    {run}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default PreScreenSelected
