import React from 'react'
import {useSelector} from 'react-redux'

const InterlockQuotas = ({handleInterlockQuotas, removeInterlock, interlockedQuotaLabels, isQuotasInterlocked}) => {
  const quotas = useSelector(state => state.runFormOverview?.cint_quotas) || []

  const disableInterlockButton = quotas.some(quota => quota.isValid === false)

  return (
    <>
      <div className="box">
        <h2 className="big mb-2">
          Interlock quotas &nbsp;

          <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
            <span className="po-tooltiptext po-tooltip-position">
              If you already have quotas set, for example gender and education, you can interlock them to control
              what % of participants have a combination of both traits simultaneously.
              <br/><br/>
              For example you can decide that you want 25% of your participants to be women with college degrees and
              15% of them to be men with college degrees.
            </span>
          </div>
        </h2>

        <div className="wrap_info_form help-text">
          <span>
            If you have two or more quotas, you can combine them to create one interlocked quota where
            each group is defined by multiple traits.
          </span>
        </div>

        <div className="wrap_link mt-3 d-flex justify-content-center">
          {isQuotasInterlocked && (
            <button
              className="btn btn-default btn-outline-danger clickable"
              onClick={removeInterlock}
            >
              Remove interlock
            </button>
          )}
          <button
            className={`btn btn-primary no-border clickable ${disableInterlockButton ? 'cursor-disabled btn-grey' : ''}`}
            onClick={handleInterlockQuotas}
            disabled={disableInterlockButton}
          >
            {isQuotasInterlocked ? 'Update interlock' : 'Interlock quotas'}
          </button>
        </div>

        {isQuotasInterlocked && (
          <div className="d-flex justify-content-center mt-3 po-text">
            <label className="screening-setup success-tick-filled">&nbsp;</label>
            <span className="text-dark">{interlockedQuotaLabels.slice(0, -1).join(', ')}</span>&nbsp;and&nbsp;
            <span className="text-dark">{interlockedQuotaLabels.slice(-1)}</span>&nbsp;are interlocked
          </div>
        )}
      </div>
    </>
  )
}

export default InterlockQuotas
