import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {markFeedbackStartedFallbackFetch} from '../../redux/actions'

const FeedbackFallback = (props) => {
  const [workerId, setWorkerId] = useState('')
  const dispatch = useDispatch()

  const {
    isLoading,
    status,
    errors
  } = useSelector(state => ({
    isLoading: state.feedbackStartedFallback?.isLoading,
    status: state.feedbackStartedFallback?.status,
    errors: state.feedbackStartedFallback?.errors
  }), shallowEqual)

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (!workerId) return

    const params = {worker_id: workerId, task_id: props.taskId}
    dispatch(markFeedbackStartedFallbackFetch(params))
  }

  return (
    status === 'success' ? (
      <div className="metrics filter participants clear-h3-margin">
        <div className="box">
          <h3 className="text-success">
            Thanks for participating! You must now submit this HIT on Mechanical Turk so that you can be paid.
          </h3>
        </div>
      </div>
    ) : (
      <>
        <div className="metrics project_detail filter participants activity mt-5">
          <div className="box">
            <h2>
              If you are a task participant, please enter your worker ID below so that we can confirm your completion of
              this task.
            </h2>

            <form name="workerIdForm" onSubmit={handleFormSubmit}>
              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="form-group">
                    <label><h2 className="text-required">Worker ID</h2></label>
                    <input
                      name="workerId"
                      required={true}
                      type="text"
                      placeholder="e.g., A3UBYFM4A1GER6"
                      onChange={e => setWorkerId(e.target.value)}
                    />
                  </div>

                  <div className="wrap_link my-3">
                    <button
                      className={`btn btn-primary no-border clickable ${isLoading || !workerId ? 'cursor-disabled btn-grey' : ''}`}
                      disabled={isLoading || !workerId}
                      type="submit"
                    >
                      {isLoading ? 'Please wait ...' : 'Submit and Continue'}
                    </button>
                  </div>
                </div>
              </div>
              {status === 'error' ? (
                <div className="po-text po-text-danger my-1">{errors.join(', ')}</div>
              ) : null}
            </form>
          </div>
        </div>
      </>
    )
  )
}

export default FeedbackFallback
