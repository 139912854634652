import React from 'react'

const CustomAttributes = props => {
  const parsedFilterValue = (filter) => {
    let parsedValue = ''
    if (filter.comparator === 'between')
      parsedValue = filter.min_value + ' and ' + filter.max_value
    else if (filter.comparator === 'in')
      parsedValue = filter.values.join(', ')
    else
      parsedValue = filter.value

    return parsedValue
  }

  return (
    <div className="mt-3">
      <h4>Custom Attributes</h4>
      {props.attributes.map(filter =>
        <p className="po-text mb-1 word-break-all" key={filter.name}>
          {filter.mode} people with&nbsp;
          <span className="text-dark">{filter.name}</span>&nbsp;
          {filter.comparator}&nbsp;
          <span className="text-dark">{parsedFilterValue(filter)}</span>
        </p>
      )}
    </div>
  )
}

export default CustomAttributes
