import React from 'react'

const CharsLeft = (props) => {
  let remainingChars = props.max
  let helpText

  if (props.chars && props.chars.length > 0) {
    remainingChars = props.max - props.chars.length
  }

  if (remainingChars === 0) {
    helpText = ''
  } else if (remainingChars < 0) {
    helpText = `${props.label ? props.label : 'Text'} is too long`
  } else {
    helpText = `${remainingChars} characters left`
  }

  return (
    <span className={`${remainingChars < 0 ? 'text-danger' : 'po-text'}`}>
      {helpText}
    </span>
  )
}

export default CharsLeft
