import {toast, Slide} from 'react-toastify'

const getAutoCloseDuration = (message) => {
  const duration =  message.length * 70;

  if (duration < 2500) {
    return 2500;
  } else {
    return duration;
  }
}

export const defaultToast = (message, options = {}) => {
  toast(message, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    pauseOnHover: false,
    autoClose: getAutoCloseDuration(message),
    transition: Slide,
    ...options
  })
}

export const errorToast = (message, options = {}) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    pauseOnHover: false,
    autoClose: getAutoCloseDuration(message),
    transition: Slide,
    draggable: false,
    closeOnClick: false,
    ...options
  })
}

export const successToast = (message, options = {}) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    pauseOnHover: false,
    autoClose: getAutoCloseDuration(message),
    transition: Slide,
    ...options
  })
}
