import React, {useEffect, useState} from 'react'
import {cloneDeep, merge} from 'lodash'
import {useSelector} from 'react-redux'

import {ageFilterForCint, censusQuota, genderFilterForCint, genderQuota} from './enhanced-run-form/RunUtils'

const CintQuotas = () => {
  const [quotas, setQuotas] = useState([])

  const {
    cintQuotas,
    cintFilters,
    interlockedQuotas
  } = useSelector(state => ({
    cintQuotas: state.runDetail?.run?.cint_quotas,
    cintFilters: state.filtersList?.cintProfiling?.values,
    interlockedQuotas: state.runDetail?.run?.cint_interlocked_quotas
  }))

  const getClassName = (quotaId) => {
    return interlockedQuotas.includes(String(quotaId))
      ? 'po-text light-color-icon quota interlocked-quota'
      : "po-text light-color-icon quota"
  }

  const setupQuotas = () => {
    let updatedQuotas = []
    cintQuotas.forEach(quota => {
      let quotaToAdd
      if (quota.id === ageFilterForCint.id) {
        quotaToAdd = cloneDeep(ageFilterForCint)
        quotaToAdd.from = quota.from
        quotaToAdd.to = quota.to
        quotaToAdd.options = cloneDeep(quota.options)
      } else if (quota.id === genderFilterForCint.id) {
        quotaToAdd = cloneDeep(genderQuota)
        quotaToAdd.options = quotaToAdd.variables
        merge(quotaToAdd.options, quota.options)
      } else if (quota.id === censusQuota.id) {
        quotaToAdd = cloneDeep(censusQuota)
        quotaToAdd.from = quota.from
        quotaToAdd.to = quota.to
        quotaToAdd.options = cloneDeep(quota.options)
      } else {
        const filter = cintFilters.find(filter => filter.id === quota.id)
        quotaToAdd = cloneDeep(filter)
        quotaToAdd.options = quotaToAdd.variables
        merge(quotaToAdd.options, quota.options)
      }
      quotaToAdd.total = quotaToAdd.options.reduce((pv, cv) => ({
        percent: pv.percent + cv.percent,
        participants: pv.participants + cv.participants
      }), {percent: 0, participants: 0})
      updatedQuotas.unshift(quotaToAdd)
    })
    setQuotas(updatedQuotas)
  }

  useEffect(() => {
    if (!cintQuotas || !cintFilters) return

    setupQuotas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cintQuotas, cintFilters])

  return (
    quotas && quotas.length ? (
      <div className="mt-3">
        <h4 className="mb-0">Quotas</h4>

        {quotas.map(quota => (
          <div className={getClassName(quota.id)} key={quota.id}>
            <h4 className="mb-0 pb-0 pt-2 participant-attribute-title">
              {quota.name}
            </h4>
            <div className="quota-table form_style mb-3">
              <table className="po-text">
                <tbody>
                <tr className="header text-dark">
                  <td></td>
                  <td>Percent</td>
                  <td>Participants</td>
                </tr>
                {quota.options && quota.options.map(option => (
                  <tr key={option.id}>
                    <td>{option.name}</td>
                    <td className="percent-wrapper">
                      <input
                        type="text"
                        name={`percent_${option.id}`}
                        value={option.percent.toFixed(2)}
                        disabled
                      />
                      <span>%</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        name={`participants_${option.id}`}
                        value={option.participants}
                        disabled
                      />
                    </td>
                  </tr>
                ))}
                <tr className="footer mt-2 text-dark">
                  <td className="text-align-right">
                    {quota.id === 'censusQuota' && (
                      <span className="float-left font-size-12 po-text mt-0">Census source: UN Population</span>
                    )}
                    <span className="pr-2">Totals</span>
                  </td>
                  <td>{quota.total.percent.toFixed(2)}%</td>
                  <td>{quota.total.participants}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    ) : null
  )
}

export default CintQuotas
