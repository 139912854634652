import React, {useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'

// third-party plugins
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

// custom components
import FormField from '../common/FormField'
import FormErrors from '../FormErrors'

import {routes} from '../../routes'

// redux actions
import {userSignInInit, userSignInFetch} from '../../redux/actionss/userActions'
import {checkGTUserRegistered} from '../../redux/actions'

const SignIn = (props) => {
  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const gtDataId = queryParams.gt_data_id
  const signUpLink = gtDataId ? `${routes.SIGN_UP}?gt_data_id=${gtDataId}` : routes.SIGN_UP
  const forgotPasswordLink = gtDataId ? `${routes.FORGOT_PASSWORD}?gt_data_id=${gtDataId}` : routes.FORGOT_PASSWORD

  const formik = useFormik({
    initialValues: {
      username: props.gtUserEmail || '',
      password: '',
      gt_data_id: gtDataId
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Email/Username is required'),
      password: Yup.string()
        .required('Password is required')
    }),
    enableReinitialize: true,
    onSubmit: values => {
      props.userSignInFetch(values)
    }
  })

  useEffect(() => {
    if (gtDataId) props.checkGTUserRegistered(gtDataId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtDataId])

  useEffect(() => {
    props.userSignInInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.currentUser) {
    if (gtDataId && props.currentUser.project_id) {
      return <Redirect to={`${routes.RUN_CREATE}?project_id=${props.currentUser.project_id}&gt_data_id=${gtDataId}`}/>
    } else {
      return <Redirect to={routes.HOME}/>
    }
  }

  return (
    <div className="sign-in-box">
      <div className="d-flex add_platform justify-content-center">
        <div className="col-lg-6">
          <div className="panel panel-default">
            <div className="panel-body">
              <form onSubmit={formik.handleSubmit}>
                <FormErrors errors={props.errors}/>
                <FormField
                  label="Email/Username"
                  id="username"
                  type="text"
                  required={true}
                  formik={formik}
                  className="form-control po-text"/>
                <FormField
                  label="Password"
                  id="password"
                  type="password"
                  className="form-control po-text mt-3"
                  required={true}
                  formik={formik}/>
                <div className="wrap_link mt-4">
                  <button
                    id="btn-sign-in"
                    type="submit"
                    className={`btn btn-primary no-border cursor-pointer ${props.isLoading ? 'btn-grey cursor-disabled' : ''}`}
                    disabled={props.isLoading}>
                    {props.isLoading ? 'Signing in...' : 'Sign In'}
                  </button>
                </div>
              </form>
              <label className="mt-4 link-visited">
                Don't have an account?&nbsp;
                <Link className={`${props.isLoading ? 'pe-none' : ''}`} to={signUpLink}>Sign Up</Link>
              </label>
              <label className="mt-3 link-visited">
                Forgot password?&nbsp;
                <Link
                  className={`${props.isLoading ? 'pe-none' : ''}`}
                  to={forgotPasswordLink}
                >
                  Get New Password
                </Link>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    errors: state.signIn?.errors,
    isLoading: state.signIn?.isLoading,
    currentUser: state.currentUser,
    gtUserEmail: state.checkGtUserRegistered?.email,
    isGTUserRegistered: state.checkGtUserRegistered?.isRegistered
  }
}

const mapDispatchToProps = dispatch => ({
  checkGTUserRegistered: (gtDataId) => dispatch(checkGTUserRegistered(gtDataId)),
  userSignInFetch: (user) => dispatch(userSignInFetch(user)),
  userSignInInit: () => dispatch(userSignInInit())
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
