import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

// 3rd party components
import {useFormik} from 'formik'
import * as Yup from 'yup'

// Custom components
import CharsLeft from '../../common/CharsLeft'
import Tagify from '../../common/Tagify'
import SaveAsDraftButton from '../SaveAsDraftButton'
import CancelButton from './CancelButton'
import {
  urlRegex,
  isGuidedTrackLink
} from './RunUtils'
import {TABS} from './EnhancedRunForm'
import guidedTrackLogo from '../../../images/guided-track.png'
import {GA_EVENTS, sendGAEvent, triggerLinkedinInsightEvent} from '../../../utils/helper'
import {LINKEDIN_CONVERSION_IDS} from '../../../utils/constants'

// Redux actions
import {accountUpdateFetch} from '../../../redux/actionss/userActions'
import {resetLinkVerification, runFormOverviewUpdate} from '../../../redux/actions'

const RunFormActivityTab = (props) => {
  // Variables
  const titleLimit = props.isMturk ? 110 : 100
  const descriptionLimit = 2000
  const [showInstructionsInput, setShowInstructionsInput] = useState(false)
  const [showKeywordsInput, setShowKeywordsInput] = useState(false)
  const [isFormInitialized, setIsFormInitialized] = useState(false)
  const [isActivityLinkSameAsScreeningLink, setIsActivityLinkSameAsScreeningLink] = useState(false)

  const {
    show_gt_promo: showGTPromo,
    email,
    username,
    uid: positly_user_id,
    utm_parameters
  } = props.currentUser || {}

  const dispatch = useDispatch()

  let validations = {
    title: Yup.string()
      .required('Activity title is required')
      .max(titleLimit, 'Activity title is too long'),
    task_url: Yup.string()
      .required('Activity link is required')
      .matches(urlRegex, 'Activity link is invalid'),
    task_data_url: Yup.string()
      .matches(urlRegex, 'Activity data link is invalid'),
  }

  if (props.isMturk) {
    validations = {
      ...validations,
      description: Yup.string()
        .required('Activity description is required.')
        .max(
          descriptionLimit,
          'Activity description is too long.'
        ),
    }
  }

  const formik = useFormik({
    initialValues: {
      task_parameters: {
        title: props.task_parameters.title || '',
        task_url: props.task_parameters.task_url || '',
        task_data_url: props.task_parameters.task_data_url || '',
        description: props.task_parameters.description || '',
        instructions: props.task_parameters.instructions || '',
        keywords: props.task_parameters.keywords ? props.task_parameters.keywords.split(' ') : []
      },
    },
    validationSchema: Yup.object({
      task_parameters: Yup.object().shape(validations),
    }),
    onSubmit: () => {
    },
    validateOnMount: true
  })

  /*  const positlySpend = (() => {
      const spentOnPositlyInLast30Days = props.spentOnPositlyInLast30Days?.replace('$', '') || 0

      let estimatedSpendForCurrentRun
      if (isPlatformBillable(props.platformType)) {
        estimatedSpendForCurrentRun = (props.agreeScreeningGuidelines ? props.estMaxBudget : props.formattedTotalRunCost) || 0
      } else {
        estimatedSpendForCurrentRun = 0
      }

      return Math.round(parseFloat(spentOnPositlyInLast30Days) + parseFloat(estimatedSpendForCurrentRun))
    })()*/

  const gtPromoUrl = (() => {
    // return `https://www.guidedtrack.com/pricing?positly_spend=${positlySpend}`
    return 'https://www.guidedtrack.com/pricing?utm_source=cta&utm_medium=positly_website&utm_campaign=unlock_free_plan'
  })()

  // Event handlers
  const handleBlur = (e) => {
    if (e.target.name === 'task_parameters.task_url') {
      dispatch(resetLinkVerification({isActivityUrlChanged: true}))
      dispatch(runFormOverviewUpdate({
        completion_link_verification_id: null,
        completion_link_verified_at: null,
      }))
    }

    validateFormAndDispatchValues()
  }

  const handleActivityLinkChange = (e) => {
    formik.handleChange(e)

    const taskUrl = e.target.value
    if (!taskUrl.includes('guidedtrack.com'))
      formik.setFieldValue('task_parameters.task_data_url', '')
  }

  const handleTagify = (value) => {
    formik.setFieldValue('task_parameters.keywords', value)

    const updatedFormikValues = {
      ...formik.values,
      task_parameters: {
        ...formik.values.task_parameters,
        keywords: value
      }
    }
    validateFormAndDispatchValues({formValues: updatedFormikValues})
  }

  const handleInstructionsChange = () => {
    if (showInstructionsInput) {
      formik.setFieldValue('task_parameters.instructions', '')

      const resetInstructionsInRedux = {
        ...formik.values,
        task_parameters: {
          ...formik.values.task_parameters,
          instructions: ''
        }
      }
      validateFormAndDispatchValues({formValues: resetInstructionsInRedux})
    }
    setShowInstructionsInput(!showInstructionsInput)
  }

  const handleKeywordsChange = () => {
    if (showKeywordsInput) {
      formik.setFieldValue('task_parameters.keywords', [])

      const resetKeywordsInRedux = {
        ...formik.values,
        task_parameters: {
          ...formik.values.task_parameters,
          keywords: []
        }
      }
      validateFormAndDispatchValues({formValues: resetKeywordsInRedux})
    }
    setShowKeywordsInput(!showKeywordsInput)
  }

  const isDataLinkValid = () => (
    document.activeElement.name !== 'task_parameters.task_data_url' && formik.errors.task_parameters?.task_data_url
  )

  const getPreviousTab = () => (
    props.isActivityLanguageEnglish() ? TABS.ADDITIONAL_ATTRIBUTES : TABS.PARTICIPANTS
  )

  const isTaskUrlInvalid = formik.errors.task_parameters?.task_url?.includes('Activity link is invalid')

  const showPreviewLinkWarning = () => {
    const taskUrl = formik.values.task_parameters?.task_url
    return taskUrl && !isTaskUrlInvalid && taskUrl.includes('preview')
  }

  const showCompletionLinkWarning = () => {
    const taskUrl = formik.values.task_parameters?.task_url
    const completionLink = `${window.location.protocol}//${window.location.host}/#/f?task_id=`
    return taskUrl && !isTaskUrlInvalid && taskUrl.includes(completionLink)
  }

  // Private functions
  const validateFormAndDispatchValues = ({formValues, isLinksSame} = {}) => {
    isLinksSame = typeof isLinksSame === 'boolean' ? isLinksSame : isActivityLinkSameAsScreeningLink
    formik.validateForm().then((data) => {
      const isActivityFormValid = data && !Object.keys(data).length && !showCompletionLinkWarning()
        && !isLinksSame
      const values = formValues ? formValues : formik.values
      dispatch(runFormOverviewUpdate({...values, isActivityFormValid}))
    })
  }

  const hideGTPromo = () => {
    if (props.isUserAccountUpdating || !email || !username) return

    dispatch(accountUpdateFetch({email, username, show_gt_promo: false}, {showAlert: false}))
  }

  const openParticipantsTab = (e) => {
    e.preventDefault()
    props.setActiveTab(TABS.PARTICIPANTS)
    props.setOpenScreeningSetup(true)
  }

  const handleGTPromoLinkClick = () => {
    sendGAEvent(GA_EVENTS.CLICK_GT_PROMO, {positly_user_id})
    triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.CLICK_GT_PROMO, utm_parameters)
  }

  const gotoConfirmationTab = () => {
    sendGAEvent(GA_EVENTS.CLICK_FINAL_STEPS, {positly_user_id})
    props.setActiveTab(TABS.CONFIRMATION)
  }

  // Side effects
  useEffect(() => {
    if (props.task_parameters.instructions)
      setShowInstructionsInput(true)
    if (props.task_parameters.keywords)
      setShowKeywordsInput(true)
  }, [props.task_parameters.instructions, props.task_parameters.keywords])

  // title, description, etc validated on blur. So on duplicate, trigger the validation once form is initialized
  useEffect(() => {
    let resetValuesInRedux

    if (isFormInitialized && !props.isMturk) {
      formik.setFieldValue('task_parameters.description', '')
      formik.setFieldValue('task_parameters.instructions', '')
      formik.setFieldValue('task_parameters.keywords', [])
      setShowInstructionsInput(false)
      setShowKeywordsInput(false)

      resetValuesInRedux = {
        ...formik.values,
        task_parameters: {
          ...formik.values.task_parameters,
          description: '',
          instructions: '',
          keywords: []
        }
      }
    }

    validateFormAndDispatchValues({formValues: resetValuesInRedux})

    if (!isFormInitialized) setIsFormInitialized(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMturk])


  useEffect(() => {
    const taskUrl = formik.values.task_parameters?.task_url
    const isLinksSame = (props.agreeScreeningGuidelines && taskUrl
      && props.screeningActivityLink && taskUrl === props.screeningActivityLink
    )
    setIsActivityLinkSameAsScreeningLink(isLinksSame)
    validateFormAndDispatchValues({isLinksSame})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.agreeScreeningGuidelines, formik.values.task_parameters?.task_url, props.screeningActivityLink])


  // UI template
  return (
    <div
      className={`create ${props.isTabActive(TABS.ACTIVITY) ? 'active' : 'hide'}`}
      name="runFormActivity"
    >
      <div className="pb-3">
        {showGTPromo && (props.formattedTotalRunCost || props.estMaxBudget) && (
          <div className="box mb-3">
            <div className="gt-promo-banner">
              <div className="d-flex align-items-center mr-auto">
                <img src={guidedTrackLogo} alt="GT logo" height={40}/>
                <h2 className="ml-3 my-0">
                  {/*{positlySpend >= 29 ? "You've unlocked 30 days free access to premium GuidedTrack features!" : 'Try GuidedTrack for free'}*/}
                  <div className="try-gt">
                    <div className="font-size-16">Try GuidedTrack for free</div>
                    <div className="mt-1 po-text">
                      GuidedTrack allows you to create surveys, studies, educational modules and tools all without
                      hiring a programmer!
                    </div>
                  </div>
                </h2>
              </div>
              <a
                className="gt-cta-btn btn btn-primary no-border clickable d-inline-flex text-nowrap mr-2"
                href={gtPromoUrl}
                target="_blank"
                rel="noreferrer"
                onClick={handleGTPromoLinkClick}
              >
                Unlock my free plan
              </a>
            </div>
            <span className="gt-promo-close-btn" onClick={hideGTPromo}>×</span>
          </div>
        )}

        <div className="box">
          {props.isMturk && props.agreeScreeningGuidelines ? (
            <>
              <h2 className="text-required mb-1">Main activity title</h2>
              <div className="wrap_info_form pb-2">
                <span>
                  Participants who screen in will see this title when they are invited to continue to your main activity.
                </span>
              </div>
            </>
          ) : (
            <h2 className="text-required">Activity title for participants</h2>
          )}
          <div className="form-group">
            <input
              maxLength={titleLimit}
              name="task_parameters.title"
              placeholder="e.g., Participate in our study about new food technology"
              tabIndex="1"
              onChange={formik.handleChange}
              onBlur={handleBlur}
              value={formik.values.task_parameters.title}
              type="text"
            />
            <div className="wrap_info_form">
              <CharsLeft chars={formik.values.task_parameters.title} max={titleLimit} label="Activity title"/>
            </div>
          </div>
          {props.isMturk && (
            <div className="form-group pt-3">
              {props.agreeScreeningGuidelines ? (
                <h2 className="text-required">
                  Main activity description for participants
                </h2>
              ) : (
                <h2 className="text-required">Activity description for participants</h2>
              )}
              <div className="form-group">
                <textarea
                  data-html="true"
                  maxLength={descriptionLimit}
                  name="task_parameters.description"
                  placeholder="e.g., We're looking for your opinions on a new food technology in development.
                    During this study, you'll be asked a series of questions relating to your eating habits
                    and your opinions on this new technology."
                  tabIndex="2"
                  value={formik.values.task_parameters.description}
                  onChange={formik.handleChange}
                  onBlur={handleBlur}>
                </textarea>
                <div className="wrap_info_form">
                  <CharsLeft
                    chars={formik.values.task_parameters.description}
                    max={descriptionLimit}
                    label="Description"
                  />
                </div>
              </div>
            </div>
          )}
          <h2 className="pt-1">
            {
              `${props.isMturk && props.agreeScreeningGuidelines ? 'Main activity' : 'Activity'}
              link (the URL where participants will find your survey or study)`
            }
            <span className="text-required"/>
            {' '}
            <div className="d-inline-block po-tooltip activity-link-tooltip">
              <span className="po-tooltiptext po-tooltip-position">
                Your activity link is the URL for the activity that you want
                participants to do during this run (e.g., survey, experiment,
                task or web app).
                <br/>
                <br/>
                When we redirect participants from the pre-survey to your
                activity link we use query string parameters to dynamically pass
                details about the participant (e.g., participant_id, age and
                gender) through to your activity software. You may need to setup
                your software to accept these parameters so that you can track
                participant identifiers and demographics.
                <br/>
                <br/>
                If you would like to dynamically set custom attributes for
                participants then you can also add query string parameters onto
                the end of activity link when you redirect them at the end of
                the activity. You can then use these custom attributes to target
                participants in future runs.
              </span>
            </div>
          </h2>
          <div className="form-group">
            <input
              name="task_parameters.task_url"
              placeholder="e.g., https://www.mystudy.com/surveyurl"
              tabIndex="3"
              type="text"
              value={formik.values.task_parameters.task_url}
              onChange={handleActivityLinkChange}
              onBlur={handleBlur}
            />
            {props.gtDataId && (
              <div className="d-inline-flex notice mr-0 ml-0 no-bg pl-0">
                <span className="success-tick po-text">
                  Automatically integrated from your GuidedTrack program.
                </span>
              </div>
            )}
            {showPreviewLinkWarning() && (
              <div className="wrap_info_form inline">
                <span className="bg">
                  <span><strong className="text-orange">You may have accidentally entered a preview link instead of the real link to your run</strong></span>
                </span>
              </div>
            )}
            {isTaskUrlInvalid && (
              <div className="wrap_info_form inline po-text-error pt-1 lh-1">
                {props.isMturk && props.agreeScreeningGuidelines ? 'Main activity' : 'Activity'} link is invalid
              </div>
            )}
            {showCompletionLinkWarning() && (
              <div className="wrap_info_form inline">
                  <span>
                    <strong className="text-red">
                      Looks like you have entered the Completion link instead of the Activity link. Activity link is the link
                      where your actual survey is hosted. For example GuidedTrack, SurveyMonkey, GoogleForms are the examples
                      for the survey hosting platform.
                    </strong>
                  </span>
              </div>
            )}
            {isActivityLinkSameAsScreeningLink && (
              <div className="wrap_info_form inline po-text-error pt-1 lh-1">
                You provided the link to your screening activity. Your main activity must be a separate activity with
                its own URL. Please either change this link or&nbsp;
                <Link
                  to="#"
                  className="po-text-error text-underline"
                  onClick={openParticipantsTab}
                >
                  the link for your screening activity
                </Link>
                .
              </div>
            )}
          </div>
          {isGuidedTrackLink(formik.values.task_parameters.task_url) && props.gtDataId && (
            <>
              <h2 className="mt-3">
                <span className="text-info">(Optional) </span>
                Data page link for your GuidedTrack program
              </h2>
              <div className="form-group mb-3">
                <input
                  className="mb-0"
                  name="task_parameters.task_data_url"
                  placeholder="e.g., https://www.guidedtrack.com/programs/123456789/runs"
                  tabIndex="3"
                  type="text"
                  value={formik.values.task_parameters.task_data_url}
                  onChange={formik.handleChange}
                  onBlur={handleBlur}
                />
                {isDataLinkValid() && (
                  <div className="po-text-error mt-1 mb-0">
                    {formik.errors.task_parameters.task_data_url}
                  </div>
                )}
              </div>
            </>
          )}

          {props.isMturk && (
            <div className="wrap_info mt-4">
              <div className="wrap_item">
                <div className="radio-checkbox">
                  <label>
                    <input
                      className="checkbox"
                      tabIndex="4"
                      type="checkbox"
                      checked={showInstructionsInput}
                      onChange={handleInstructionsChange}
                    />
                    <span className="checkbox-custom"/>
                    <span className="label">
                      Extra instructions for the participant
                    </span>
                  </label>
                  {showInstructionsInput && (
                    <textarea
                      className="mb-4"
                      maxLength={1000}
                      name="task_parameters.instructions"
                      tabIndex="4"
                      onChange={formik.handleChange}
                      onBlur={handleBlur}
                      value={formik.values.task_parameters.instructions}
                    />
                  )}
                  {!props.agreeScreeningGuidelines && props.isMturk && (
                    <>
                      <label>
                        <input
                          className="checkbox"
                          tabIndex="5"
                          type="checkbox"
                          checked={showKeywordsInput}
                          onChange={handleKeywordsChange}
                        />
                        <span className="checkbox-custom"/>
                        <span className="label">
                          Search terms to help participants find your activity (optional)
                        </span>
                      </label>
                      {showKeywordsInput && (
                        <div className="keywords">
                          <Tagify
                            id="keywords"
                            name="task_parameters.keywords"
                            onChange={values => handleTagify(values)}
                            tabIndex="5"
                            className="full-width"
                            defaultValues={formik.values.task_parameters.keywords}
                            placeholder="Type the keyword and press Enter"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row run-form-buttons">
        <div className="col">
          <div className="wrap_link wrap_link_filter d-flex">
            <CancelButton
              disabled={props.isSaving || props.isPublishing}
              onClick={props.handleCancel}
            />
            <SaveAsDraftButton
              isSaving={props.isSaving}
              disabled={props.isSaving || props.isPublishing}
              onClick={props.saveAsDraft}
              isMturk={props.isMturk}
              setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
              showBudgetChangedWarning={props.showBudgetChangedWarning}
              isAnyQuotaInvalid={props.isAnyQuotaInvalid}
            />
            <button
              className="btn btn-default back clickable"
              tabIndex="17"
              disabled={props.isSaving || props.isPublishing}
              onClick={() => props.setActiveTab(getPreviousTab())}
            >
              <i className="icon ion-android-arrow-back"/>
              {getPreviousTab().navigationText}
            </button>
            <button
              className="btn btn-primary no-border clickable transition-0"
              tabIndex="18"
              disabled={props.isSaving || props.isPublishing}
              onClick={gotoConfirmationTab}
            >
              {TABS.CONFIRMATION.navigationText}
              <i className="ml-2 icon ion-android-arrow-forward"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  if (!state.runForm)
    return {task_parameters: {}}

  return {
    task_parameters: state.runForm.task_parameters || {},
    currentUser: state.currentUser,
    isUserAccountUpdating: state.userAccount?.isUpdating,
    agreeScreeningGuidelines: state.runFormOverview.agree_screening_guidelines,
    screeningActivityLink: state.runFormOverview.task_parameters.screening_activity_link,
    estMaxBudget: state.runFormOverview.task_parameters.est_max_budget,
    formattedTotalRunCost: state.runFormOverview.formattedTotalRunCost,
    spentOnPositlyInLast30Days: state.positlySpending?.spent,
    platformType: state.runFormOverview.platform?.platform_type,
  }
}

export default connect(mapStateToProps)(RunFormActivityTab)
