import React from 'react'

import ReactTooltip from 'react-tooltip'

import {successToast} from '../helpers/notification'
import {GA_EVENTS, sendGAEvent} from '../../utils/helper'
import {useSelector} from 'react-redux'

const CopyLink = ({label, idToCopy, setLinkCopied, className, isSendGAEvent = false}) => {
  const positly_user_id = useSelector(state => state.currentUser?.uid)

  const copyLink = (event) => {
    event.preventDefault()

    if (isSendGAEvent) {
      sendGAEvent(GA_EVENTS.CLICK_COPY_LINK, {positly_user_id})
    }
    let element = document.getElementById(idToCopy)
    navigator.clipboard.writeText(element.innerText)
    if (typeof setLinkCopied === 'function') setLinkCopied(true)

    successToast('Link copied!')
  }

  return (
    <a
      className={className}
      href="/"
      style={{cursor: 'pointer'}}
      onClick={copyLink}
      data-tip="Click to copy"
      data-for="copy-completion-link"
      data-testid="copy-link"
    >
      {label}
      <ReactTooltip
        className="react-tooltip-po"
        position="top"
        effect="solid"
        id="copy-completion-link"
        arrowColor="#555"
      />
    </a>
  )
}

export default CopyLink
