import React from 'react'

const Checkbox = props => (
  <input
    type='checkbox'
    {...props}
  />
)

export default Checkbox
