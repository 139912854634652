import React, {Fragment, useEffect} from 'react'
import {useSelector} from 'react-redux'

import customScreeningQuestion from '../../images/customScreeningQuestion.png'
import target from '../../images/target.png'
import quota from '../../images/quota.png'
import retarget from '../../images/retarget.png'
import bonusParticipants from '../../images/bonusParticipants.png'
import messageParticipants from '../../images/messageParticipants.png'
import tick from '../../images/tick.png'
import cross from '../../images/cross.png'
import {CINT_PLATFORMS, MTURK_PLATFORMS} from '../../utils/constants'

const PlatformSelect = (
  {
    availablePlatforms,
    fieldName,
    fieldValue,
    onPlatformChange,
    onDefaultPlatformChange,
    formik,
    defaultPlatformId,
    setDefaultPlatformId
  }
) => {
  const platforms = useSelector(state => state.platformList?.platforms) || []

  const handlePlatformChange = (e) => {
    const id = e.target.id
    setPlatform(id)

    if (typeof setDefaultPlatformId === 'function') {
      setDefaultPlatformId(null)
    }
  }

  const setPlatform = (platformId) => {
    formik.setFieldValue(fieldName, platformId)
    const platform = platforms.find(p => p.id === platformId)

    if (typeof onPlatformChange === 'function') {
      onPlatformChange(platform)
    }

    if (typeof onDefaultPlatformChange === 'function') {
      onDefaultPlatformChange(platform)
    }
  }

  useEffect(() => {
    if(defaultPlatformId){
      setPlatform(defaultPlatformId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlatformId]);

  return (
    <div className="box platform-select">
      {availablePlatforms.length > 1 && (
        <>
          <h2 className="font-size-16 mb-1 pr-3">
            How would you like to source your participants?

            <div className="d-inline-block po-tooltip activity-language-tooltip ml-1 font-size-14">
            <span className="po-tooltiptext po-tooltip-position">
              Based on the country you have chosen you have two or more platforms to choose from.
              They are similar in many ways but have important differences.
              More information about each platform{' '}
              <a
                href="https://www.positly.com/support/choosing-a-platform-on-positly/"
                target="_blank"
                rel="noopener noreferrer"
              >
                is available here
              </a>
              .
            </span>
            </div>
          </h2>
          <div className="po-text mb-3">Choose one of our participant recruitment platforms</div>
        </>
      )}

      {availablePlatforms.length === 1 && (
        <h2 className="font-size-16 mb-1 pr-3 mb-3">
          Based on the selected country the following is the available participant recruitment platform
        </h2>
      )}

      <div className="platform-features grid-container">
        <div className="grid-item no-style"></div>
        <div className="grid-item header">
          <img src={customScreeningQuestion} alt=""/>
          <span>Custom screening question</span>
        </div>
        <div className="grid-item header">
          <img src={target} alt=""/>
          <span>Target > 100 traits</span>
        </div>
        <div className="grid-item header">
          <img src={quota} alt=""/>
          <span>Quota based sampling</span>
        </div>
        <div className="grid-item header">
          <img src={retarget} alt=""/>
          <span>Retarget participants</span>
        </div>
        <div className="grid-item header">
          <img src={bonusParticipants} alt=""/>
          <span>Pay bonuses</span>
        </div>
        <div className="grid-item header">
          <img src={messageParticipants} alt=""/>
          <span>Message participants</span>
        </div>

        {availablePlatforms.map(platform => {
          if (MTURK_PLATFORMS.includes(platform.platform_type)) {
            return (
              <Fragment key={platform.id}>
                <div className="grid-item row no-style">
                  <input type="radio" id={platform.id} name={fieldName} onChange={handlePlatformChange}
                         checked={platform.id === fieldValue}/>
                  <label htmlFor={platform.id}>{platform.full_name}</label>
                </div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={cross} alt="" className="cross"/></div>
                <div className="grid-item row"><img src={cross} alt="" className="cross"/></div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
              </Fragment>
            )
          } else if (CINT_PLATFORMS.includes(platform.platform_type)) {
            return (
              <Fragment key={platform.id}>
                <div className="grid-item row no-style">
                  <input type="radio" id={platform.id} name={fieldName} onChange={handlePlatformChange}
                         checked={platform.id === fieldValue}/>
                  <label htmlFor={platform.id}>{platform.full_name}</label>
                </div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={tick} alt=""/></div>
                <div className="grid-item row"><img src={cross} alt="" className="cross"/></div>
                <div className="grid-item row"><img src={cross} alt="" className="cross"/></div>
                <div className="grid-item row"><img src={cross} alt="" className="cross"/></div>
              </Fragment>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

export default PlatformSelect
