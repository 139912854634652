import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'

import {ageFilterForCint, genderFilterForCint} from '../RunUtils'
import {capitalize} from '../../../helpers/utils'

const CintAgeFilterOverview = ({filter}) => {
  return (
    <div className="table_wrap my-3" key={filter.id}>
      <span className="run-form-overview-heading">
        {filter.filter_label}
        <i className="fas fa-filter cint-overview-icon"></i>
      </span>
      <span>
        {filter.from} - {filter.to}
      </span>
    </div>
  )
}

const CintGenderFilterOverview = ({filter}) => {
  return (
    <div className="my-3" key={filter.id}>
      <div className="table_wrap">
        <span className="heading">
          Gender
          <i className="fas fa-filter cint-overview-icon"></i>
        </span>
      </div>
      {filter.filter_options.map((filter_option, index) =>
        filter_option.is_checked ? (
          <div key={filter_option.title}>
            <div className="table_wrap mb-1">
              <span/>
              <span className="success-tick filter-list">
                {capitalize(filter_option.title)}
              </span>
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

const CintProfilingFilterOverview = ({filter}) => {
  return (
    <div className="my-3" key={filter.id}>
      <div className="table_wrap">
        <span className="heading">
          {filter.name}
          <i className="fas fa-filter cint-overview-icon"></i>
        </span>
      </div>
      {filter.variables.map(variable =>
        variable.is_checked ? (
          <div key={variable.id}>
            <div className="table_wrap mb-1">
              <span/>
              <span className="success-tick filter-list">
                {variable.name}
              </span>
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

const CintFiltersOverview = () => {
  const {cint_quotas = [], cint_filters = []} = useSelector(state => state.runFormOverview) || {}
  const cintFilters = cint_filters.filter(filter => !cint_quotas.find(quota => quota.id === filter.id))

  if (!cintFilters.length) return null

  return (
    <>
      {cintFilters.map(filter => {
          if (filter.id === ageFilterForCint.id) {
            return <CintAgeFilterOverview filter={filter}/>
          } else if (filter.id === genderFilterForCint.id && filter.filter_options.some(option => option.is_checked)) {
            return <CintGenderFilterOverview filter={filter}/>
          } else if (filter.variables && filter.variables.some(variable => variable.is_checked)) {
            return <CintProfilingFilterOverview filter={filter}/>
          } else {
            return null
          }
        }
      )}
    </>
  )
}

export default CintFiltersOverview
