import React, {useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import CharsLeft from './common/CharsLeft'

import {
  projectCreateInit,
  projectCreateFetch
} from '../redux/actionss/projectActions'
import {GA_EVENTS, sendGAEvent} from '../utils/helper'

const ProjectCreate = ({history}) => {
  const dispatch = useDispatch()

  const {isLoading, project, currentUser} = useSelector(state => ({
    isLoading: state.projectCreate?.isLoading,
    project: state.projectCreate?.project,
    currentUser: state.currentUser
  }))

  useEffect(() => {
    dispatch(projectCreateInit())

    return () => dispatch(projectCreateInit())
  }, [dispatch])


  const formik = useFormik({
    initialValues: {
      project_name: ''
    },
    validationSchema: Yup.object({
      project_name: Yup.string()
        .required('Project name is required')
    }),
    onSubmit: values => {
      if (!formik.isValid) return

      sendGAEvent(GA_EVENTS.CLICK_SAVE_AND_CREATE_FIRST_RUN, {positly_user_id: currentUser.uid})
      dispatch(projectCreateFetch(values))
    }
  })

  if (project) {
    return <Redirect to={`/runs/create?project_id=${project.id}`}/>
  }

  return (
    <div>
      <div className="row task-list-header">
        <div className="col-md-12">
          <div className="wrap_title d-flex align-items-center">
            <Link to="/projects/list" className="btn btn-default">
              <i className="icon ion-android-arrow-back"/>
              All Projects
            </Link>
          </div>
        </div>
      </div>

      <div className="metrics project_detail">
        <div className="wrap_title d-flex">
          <h1>Create Project</h1>
        </div>
      </div>

      <form className="form_style" onSubmit={formik.handleSubmit}>
        <div className="wrapper_main">
          <div className="wrap_box">
            <label className="text-required">Project name (Participants will not see this)</label>
            <input
              id="project_name"
              name="project_name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.project_name}
              autoFocus
              maxLength="110"
            />
            {formik.touched.project_name && formik.errors.project_name
              ? <p className="po-text text-red-soft">{formik.errors.project_name}</p>
              : null
            }
            <div className="wrap_info_form">
              <CharsLeft max="110" chars={formik.values.project_name} label="Project name"/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="wrap_link">
              <button
                type="reset"
                disabled={isLoading}
                onClick={() => history.push('/projects/list')}
                className="btn btn-default cursor-pointer"
              >
                Cancel
              </button>
              <button
                id="btn-publish"
                type="submit"
                className="btn btn-primary no-border"
                style={{cursor: 'pointer'}}
                disabled={isLoading}
              >
                Save And Create First Run
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProjectCreate
