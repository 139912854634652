import jquery from 'jquery'

import {errorToast, successToast} from '../components/helpers/notification'
import {
  authHeaders,
  handlePublishRunError,
  setStorageItem
} from '../components/common/Helpers'

// actions
import {projectFetch} from './actionss/projectActions'
import {getPlatforms, platformListSuccess} from './actionss/platformActions'
import {userAccountSuccess} from './actionss/userActions'
import {isPlatformBillable, triggerRunPublishedAnalyticsEvent} from '../utils/helper'
import {formatCensusData} from '../components/run/enhanced-run-form/RunUtils'

const $ = window.$ || jquery // fallback to prevent blank pre-survey issue

const researchTypesInit = () => ({
  type: 'RESEARCH_TYPES_INIT'
})

export const researchTypesFetch = () => {
  return dispatch => {
    dispatch(researchTypesInit())

    return fetch('/api/v1/getResearchTypes', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(researchTypesError(errors))
        } else {
          dispatch(researchTypesSuccess(data.research_types))
        }
      })
  }
}

const researchTypesSuccess = (researchTypes) => ({
  type: 'RESEARCH_TYPES_SUCCESS',
  researchTypes
})

const researchTypesError = (errors) => ({
  type: 'RESEARCH_TYPES_ERROR',
  errors
})

export const contactInit = () => ({
  type: 'CONTACT_INIT'
})

export const contactSubmit = () => ({
  type: 'CONTACT_SUBMIT'
})

export const contactError = errors => ({
    type: 'CONTACT_ERROR',
    errors
  }
)

export const contactSucess = () => ({
  type: 'CONTACT_SUCCESS'
})

export const contactReset = () => ({
  type: 'CONTACT_RESET'
})

export const contactFetch = contact => {
  return dispatch => {
    return fetch('/api/v1/users/contactUs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(contact)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(contactError(errors))
          errorToast('Message not sent. Please write us to hi@positly.com !', {
            autoClose: false
          })
        } else {
          dispatch(contactSucess())
          successToast('Message sent!')
        }
      })
  }
}

export const transactionListInit = () => ({
  type: 'TRANSACTION_LIST_INIT'
})

export const transactionListFetch = (pageNumber, startDate = '', endDate = '') => {
  return dispatch => {
    return fetch(`/api/v1/getTransactions?page_number=${pageNumber}&transactions_per_page=20&start_date=${startDate}&end_date=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(transactionListError(errors))
        } else {
          dispatch(transactionListSuccess(data, pageNumber, startDate, endDate))
        }
      })
  }
}

const transactionListSuccess = (transactions, pageNumber, startDate, endDate) => {
  const hasMore = transactions.length === 20

  return {
    type: 'TRANSACTION_LIST_SUCCESS',
    transactions,
    pageNumber,
    startDate,
    endDate,
    hasMore
  }
}

const transactionListError = (errors) => ({
  type: 'TRANSACTION_LIST_ERROR',
  errors
})

export const transactionPendingListInit = () => ({
  type: 'TRANSACTION_PENDING_LIST_INIT'
})

export const transactionPendingListFetch = () => {
  return dispatch => {
    return fetch('/api/v1/getPendingTransactions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(transactionPendingListError(errors))
        } else {
          dispatch(transactionPendingListSuccess(data))
        }
      })
  }
}

const transactionPendingListSuccess = (transactionsPending) => ({
  type: 'TRANSACTION_PENDING_LIST_SUCCESS',
  transactionsPending
})

const transactionPendingListError = (errors) => ({
  type: 'TRANSACTION_PENDING_LIST_ERROR',
  errors
})

export const creditAddInit = () => ({
  type: 'CREDIT_ADD_INIT'
})

export const creditAddSubmit = () => ({
  type: 'CREDIT_ADD_SUBMIT'
})

export const creditAddFetch = (credit) => {
  return dispatch => {
    return fetch('/api/v1/users/addCredit', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(credit)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(creditAddError(errors))
        } else {
          dispatch(creditAddSuccess(data))
          successToast('Credit added successfully.')
        }
      })
  }
}

const creditAddSuccess = credit => ({
  type: 'CREDIT_ADD_SUCCESS',
  credit
})

const creditAddError = errors => ({
  type: 'CREDIT_ADD_ERROR',
  errors
})

const updateSpendingLimitInit = () => ({
  type: 'SPENDING_LIMIT_UPDATE_INIT'
})

export const updateSpendingLimitSubmit = params => {
  return dispatch => {
    dispatch(updateSpendingLimitInit())

    return fetch('/api/v1/users/updateSpendingLimit', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(updateSpendingLimitError(errors))
        } else {
          dispatch(updateSpendingLimitSuccess(params.spending_limit))
          successToast('Your spending limit per run has been updated successfully.')
        }
      })
  }
}

const updateSpendingLimitSuccess = spending_limit => ({
  type: 'SPENDING_LIMIT_UPDATE_SUCCESS',
  spending_limit
})

const updateSpendingLimitError = errors => ({
    type: 'SPENDING_LIMIT_UPDATE_ERROR',
    errors
  }
)

export const updateSpendingLimitReset = () => ({
  type: 'SPENDING_LIMIT_UPDATE_RESET'
})

export const creditCardAddOrUpdateInit = () => ({
  type: 'CREDIT_CARD_ADD_OR_UPDATE_INIT'
})

export const creditCardAdd = (credit) => {
  return dispatch => {
    return fetch('/api/v1/addCard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(credit)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(creditCardAddOrUpdateError(errors))
        } else {
          dispatch(creditCardAddOrUpdateSuccess(data))
        }
      })
  }
}

export const creditCardUpdate = (credit) => {
  return dispatch => {
    return fetch('/api/v1/updateCard', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(credit)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(creditCardAddOrUpdateError(errors))
        } else {
          dispatch(creditCardAddOrUpdateSuccess(data))
        }
      })
  }
}

const creditCardAddOrUpdateSuccess = creditCard => ({
  type: 'CREDIT_CARD_ADD_OR_UPDATE_SUCCESS',
  creditCard
})

const creditCardAddOrUpdateError = errors => ({
  type: 'CREDIT_CARD_ADD_OR_UPDATE_ERROR',
  errors
})

export const creditCardAddOrUpdateReset = () => ({
  type: 'CREDIT_CARD_ADD_OR_UPDATE_RESET'
})

export const transactionListSubmit = () => ({
  type: 'TRANSACTION_LIST_SUBMIT'
})

export const transactionListClear = () => ({
  type: 'TRANSACTION_LIST_CLEAR'
})

export const runFormInit = () => ({type: 'RUN_FORM_INIT'})

const additionalAttrQuestionsListSuccess = (questions) => ({
  type: 'ADDITIONAL_ATTR_QUESTIONS_LIST_SUCCESS',
  questions
})

export const countriesListSuccess = (countries) => ({
  type: 'COUNTRIES_LIST_SUCCESS',
  ...countries
})

export const countriesListFail = errors => ({
  type: 'COUNTRIES_LIST_FAIL',
  errors
})

export const countriesListFetch = () => {
  return dispatch => {
    return getCountries()
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(countriesListFail(errors))
        } else {
          dispatch(countriesListSuccess(data))
        }
      })
  }
}

export const quoteForRunInit = () => ({
  type: 'RUN_FORM_QUOTE_INIT'
})

export const quoteForRunSuccess = (quote, isPayPerSubmissionOnFocus) => ({
  type: 'RUN_FORM_QUOTE_SUCCESS',
  quote,
  isPayPerSubmissionOnFocus
})

export const quoteForRunFail = (errors) => ({
  type: 'RUN_FORM_QUOTE_FAIL',
  errors
})

export const quoteForRunReset = () => ({
  type: 'RUN_FORM_QUOTE_RESET'
})

export const quoteForRunFetch = (params, signal, isPayPerSubmissionOnFocus = false) => {
  return dispatch => {
    dispatch(quoteForRunInit())

    const quoteParams = {
      platform: params.platform,
      estimated_activity_minutes: params.max_time_per_submission,
      desired_participants: params.number_of_submissions,
      country: params.country_name,
    }
    if (params.screenin_rate) {
      quoteParams.screenin_rate = params.screenin_rate
    }
    if (params.payment_per_submission)
      quoteParams.payment_per_submission = params.payment_per_submission
    if (params.hourly_rate)
      quoteParams.hourly_rate = params.hourly_rate
    let searchParams = new URLSearchParams(quoteParams).toString()

    return fetch(`/api/v1/getQuote?${searchParams}`, {
      signal: signal,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(quoteForRunFail(errors))
        } else {
          dispatch(quoteForRunSuccess(data, isPayPerSubmissionOnFocus))
          dispatch(getUserSpending())
        }
      })
      .catch(e => {
        if (e.name === 'AbortError') {
          console.log('Aborted previous request: getQuoteForCintRun.')
        }
      })
  }
}

export const demographicQueryStringConfigSuccess = (demographicQSAttributes) => ({
  type: 'DEMOGRAPHIC_QUERY_STRING_CONFIG_SUCCESS',
  demographicQSAttributes
})

export const runFormAdditionalAttrAdd = attributes => ({
  type: 'RUN_FORM_ADDITIONAL_ATTR_ADD',
  attributes
})

export const runFormAdditionalAttrRemove = attribute => ({
  type: 'RUN_FORM_ADDITIONAL_ATTR_REMOVE',
  attribute
})

export const runFormDemographicFilterSuccess = (filters) => ({
  type: 'RUN_FORM_DEMOGRAPHIC_FILTER_SUCCESS',
  filters
})

export const runFormCustomAttrFilterSuccess = (filters) => ({
  type: 'RUN_FORM_CUSTOM_ATTR_FILTER_SUCCESS',
  filters
})

export const runFormProfilingVariablesInit = () => ({
  type: 'RUN_FORM_PROFILING_VARIABLES_INIT'
})

export const runFormProfilingVariablesSuccess = (variables) => ({
  type: 'RUN_FORM_PROFILING_VARIABLES_SUCCESS',
  variables
})

export const runFormProfilingVariablesFail = (errors) => ({
  type: 'RUN_FORM_PROFILING_VARIABLES_FAIL',
  errors
})

export const runFormProfilingVariablesFetch = (country, platformId) => {
  return dispatch => {
    dispatch(runFormProfilingVariablesInit())

    return fetch(`/api/v1/getProfilingVariablesForCint?country_name=${country}&platform_id=${platformId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFormProfilingVariablesFail(errors))
        } else {
          dispatch(runFormProfilingVariablesSuccess(data))
        }
      })
  }
}

export const runFormCreateAttrInit = () => ({
  type: 'RUN_FORM_CREATE_ATTR_INIT'
})

export const runFormCreateAttrReset = () => ({
  type: 'RUN_FORM_CREATE_ATTR_RESET'
})

export const runFormCreateAttrSuccess = (attribute) => ({
  type: 'RUN_FORM_CREATE_ATTR_SUCCESS',
  attribute
})

export const runFormCreateAttrFail = (errors) => ({
  type: 'RUN_FORM_CREATE_ATTR_FAIL',
  errors
})

export const runFormCreateAttrFetch = (attribute_name, attribute_type) => {
  return dispatch => {
    return fetch('/api/v1/createAttributeForUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({attribute_name, attribute_type})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFormCreateAttrFail(errors))
        } else {
          dispatch(runFormCreateAttrSuccess(data))
          successToast('Custom attribute created successfully!')
        }
      })
  }
}

export const runFormQualityFilterInit = () => ({
  type: 'RUN_FORM_QUALITY_FILTER_INIT',
})

export const runFormQualityFilterAdd = () => ({
  type: 'RUN_FORM_QUALITY_FILTER_ADD',
})

export const runFormQualityFilterUpdate = (filter) => ({
  type: 'RUN_FORM_QUALITY_FILTER_UPDATE',
  filter
})

export const runFormDemographicFilterListInit = () => ({
  type: 'RUN_FORM_DEMOGRAPHIC_FILTER_LIST_INIT',
})

export const runFormDemographicFilterListAdd = (filter) => ({
  type: 'RUN_FORM_DEMOGRAPHIC_FILTER_LIST_ADD',
  filter
})

export const runFormDemographicFilterListUpdate = (filter) => ({
  type: 'RUN_FORM_DEMOGRAPHIC_FILTER_LIST_UPDATE',
  filter
})

export const runFormDemographicFilterListRemove = filterToRemove => ({
  type: 'RUN_FORM_DEMOGRAPHIC_FILTER_LIST_REMOVE',
  filterToRemove
})

export const runFormCaFilterListInit = () => ({
  type: 'RUN_FORM_CA_FILTER_LIST_INIT',
})

export const runFormCaFilterListAdd = (filter) => ({
  type: 'RUN_FORM_CA_FILTER_LIST_ADD',
  filter
})

export const runFormCaFilterListUpdate = (filter) => ({
  type: 'RUN_FORM_CA_FILTER_LIST_UPDATE',
  filter
})

export const runFormCaFilterListRemove = filterToRemove => ({
  type: 'RUN_FORM_CA_FILTER_LIST_REMOVE',
  filterToRemove
})

export const runFormCintProfilingFilterListInit = () => ({
  type: 'RUN_FORM_CINT_PROFILING_FILTER_LIST_INIT',
})

export const runFormCintProfilingFilterListAdd = (filter) => ({
  type: 'RUN_FORM_CINT_PROFILING_FILTER_LIST_ADD',
  filter
})

export const runFormCintProfilingFilterListUpdate = (filter) => ({
  type: 'RUN_FORM_CINT_PROFILING_FILTER_LIST_UPDATE',
  filter
})

export const runFormCintProfilingFilterListRemove = filterToRemove => ({
  type: 'RUN_FORM_CINT_PROFILING_FILTER_LIST_REMOVE',
  filterToRemove
})

export const runFormQualifiedWorkersCountInit = () => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_INIT'
})

export const runFormQualifiedWorkersCountSuccess = (count) => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_SUCCESS',
  count
})

export const runFormQualifiedWorkersCountFail = errors => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FAIL',
  errors
})

export const runFormQualifiedWorkersCountFetch = (params, signal) => {
  return dispatch => {
    dispatch(runFormQualifiedWorkersCountInit())

    return fetch(`/api/v1/getQualifiedWorkersCount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFormQualifiedWorkersCountFail(errors))
        } else {
          dispatch(runFormQualifiedWorkersCountSuccess(data))
        }
      })
  }
}

const runFormQualifiedWorkersCountForCintInit = () => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_INIT'
})

const runFormQualifiedWorkersCountForCintSuccess = (count) => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_SUCCESS',
  count
})

const runFormQualifiedWorkersCountForCintFail = errors => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_FAIL',
  errors
})

export const runFormQualifiedWorkersCountForCintReset = () => ({
  type: 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_RESET',
})

export const runFormQualifiedWorkersCountForCintFetch = (params, signal) => {
  return dispatch => {
    dispatch(runFormQualifiedWorkersCountForCintInit())

    return fetch(`/api/v1/getQualifiedWorkersCountForCint`, {
      method: 'POST',
      signal: signal,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFormQualifiedWorkersCountForCintFail(errors))
        } else {
          dispatch(runFormQualifiedWorkersCountForCintSuccess(data))
        }
      })
      .catch(e => {
        if (e.name === 'AbortError') {
          console.log('Aborted previous request: getQualifiedWorkersCountForCint.')
        }
      })
  }
}

export const preScreenRunsSuccess = (runs) => ({type: 'PRE_SCREEN_RUNS_SUCCESS', runs})

export const preScreenRunsFail = errors => ({type: 'PRE_SCREEN_RUNS_FAIL', errors})

export const preScreenRunsFetch = (query = '', currentRunId = '', isRunUpdate) => {
  return dispatch => {
    return fetch(`/api/v1/getRuns?for_prescreen=true&query=${query}${isRunUpdate ? `&current_run_id=${currentRunId}` : ''}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...authHeaders()
        },
      })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(preScreenRunsFail(errors))
        } else {
          dispatch(preScreenRunsSuccess(data))
        }
      })

  }
}

export const preScreenProjectsSuccess = (projects) => ({type: 'PRE_SCREEN_PROJECTS_SUCCESS', projects})

export const preScreenProjectsFail = errors => ({type: 'PRE_SCREEN_PROJECTS_FAIL', errors})

export const preScreenProjectsFetch = (query = '', currentRunId = '') => {
  return dispatch => {
    return fetch(`/api/v1/getTasks?for_prescreen=true&query=${query}&current_run_id=${currentRunId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(preScreenProjectsFail(errors))
        } else {
          dispatch(preScreenProjectsSuccess(data))
        }
      })
  }
}

export const createAndStartRunInit = () => ({type: 'CREATE_AND_START_RUN_INIT'})

export const createAndStartRunSubmit = (run, platformMode) => {
  return dispatch => {
    return fetch('/api/v1/createAndStartRun', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(run)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(createAndStartRunFail(errors))
          if (run.is_draft)
            errorToast(`Failed to save run. ${errors.join(', ')}`)
          else
            handlePublishRunError(errors, platformMode)
        } else {
          dispatch(createAndStartRunSuccess(data))
          if (run.is_draft) {
            successToast('Your run has been saved as draft!')
          } else {
            successToast('Your run is being published!')

            if (isPlatformBillable(data.platform_type)) {
              triggerRunPublishedAnalyticsEvent(data)
            }
          }
        }
      })
  }
}

const createAndStartRunSuccess = (run) => ({type: 'CREATE_AND_START_RUN_SUCCESS', run})

const createAndStartRunFail = errors => ({type: 'CREATE_AND_START_RUN_FAIL', errors})

/* Pre survey actions start */
export const markPreSurveyStartedInit = () => ({type: 'MARK_PRE_SURVEY_STARTED_INIT'})

const markPreSurveyStartedSuccess = (assignment) => ({type: 'MARK_PRE_SURVEY_STARTED_SUCCESS', assignment})

const markPreSurveyStartedFail = errors => ({type: 'MARK_PRE_SURVEY_STARTED_FAIL', errors})

export const markPreSurveyStartedSubmit = (params = {}) => {
  return dispatch => {
    return fetch(`/api/v1/markPresurveyStarted`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(markPreSurveyStartedFail(errors))
        } else {
          dispatch(markPreSurveyStartedSuccess(data))
        }
      })
  }
}

export const createAndStartRunReset = () => ({type: 'CREATE_AND_START_RUN_RESET'})

export const runUpdateInit = () => ({type: 'RUN_FORM_UPDATE_INIT'})

export const runUpdateFetch = (run, runId, platformMode) => {
  return dispatch => {
    dispatch(runUpdateInit())

    return fetch(`/api/v1/updateRun/${runId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(run)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(runUpdateError())
          if (run.is_draft)
            errorToast('Failed to save run!')
          else
            handlePublishRunError(errors, platformMode)
        } else {
          dispatch(runUpdateSuccess())
          if (run.is_draft) {
            successToast('Your run has been saved as draft!')
          } else {
            successToast('Your run is being published!')

            if (isPlatformBillable(data.platform_type)) {
              triggerRunPublishedAnalyticsEvent(data)
            }
          }
        }
      })
  }
}

const runUpdateSuccess = () => ({type: 'RUN_FORM_UPDATE_SUCCESS'})

const runUpdateError = () => ({type: 'RUN_FORM_UPDATE_ERROR'})

export const runUpdateReset = () => ({type: 'RUN_FORM_UPDATE_RESET'})

export const preSurveyQuestionsInit = () => ({type: 'PRE_SURVEY_QUESTIONS_INIT'})

const preSurveyQuestionsSuccess = (questions) => ({type: 'PRE_SURVEY_QUESTIONS_SUCCESS', questions})

const preSurveyQuestionsFail = errors => ({type: 'PRE_SURVEY_QUESTIONS_FAIL', errors})

export const preSurveyQuestionsFetch = (params = {}) => {
  return dispatch => {
    const encodedURLParams = $.param(params)

    return fetch(`/api/v1/getQuestions?${encodedURLParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.message

        if (errors && errors.length > 0) {
          dispatch(preSurveyQuestionsFail(errors))
        } else {
          dispatch(preSurveyQuestionsSuccess(data))
        }
      })
  }
}

const validateAnswerInit = (ui) => ({type: 'VALIDATE_ANSWER_INIT', ui})

const validateAnswerSuccess = (status, questionId, ui) => ({type: 'VALIDATE_ANSWER_SUCCESS', status, questionId, ui})

const validateAnswerFail = (errors, questionId, ui) => ({type: 'VALIDATE_ANSWER_FAIL', errors, questionId, ui})

export const validateAnswerReset = () => ({type: 'VALIDATE_ANSWER_RESET'})

export const validateAnswerFetch = (params = {}, signal, ui = 'PreSurvey') => {
  const questionId = params.question_id

  return dispatch => {
    dispatch(validateAnswerInit(ui))

    const encodedURLParams = $.param(params)

    return fetch(`/api/v1/validateAnswer?${encodedURLParams}`, {
      signal: signal,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(validateAnswerFail(errors, questionId, ui))
        } else {
          dispatch(validateAnswerSuccess(data, questionId, ui))
        }
      })
  }
}

export const resendConfirmationEmailInit = () => ({type: 'RESEND_CONFIRMATION_EMAIL_INIT'})

const resendConfirmationEmailSuccess = (user) => ({type: 'RESEND_CONFIRMATION_EMAIL_SUCCESS', user})

const resendConfirmationEmailError = errors => ({type: 'RESEND_CONFIRMATION_EMAIL_FAIL', errors})

export const resendConfirmationEmailFetch = () => {
  return dispatch => {
    dispatch(resendConfirmationEmailInit())
    return fetch('/api/v1/users/resendConfirmationEmail', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(resendConfirmationEmailError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(resendConfirmationEmailSuccess(data))
          successToast('Confirmation email resent!')
        }
      })
  }
}

const createAnswersInit = () => ({type: 'CREATE_ANSWERS_INIT'})

const createAnswersSuccess = ({status, assignment, redirect_to}) => ({
  type: 'CREATE_ANSWERS_SUCCESS',
  status,
  assignment,
  redirect_to
})

const createAnswersFail = (errors, redirect_to) => ({type: 'CREATE_ANSWERS_FAIL', errors, redirect_to})

export const createAnswersSubmit = (params = {}) => {
  return dispatch => {
    dispatch(createAnswersInit())

    return fetch(`/api/v1/createAnswers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(createAnswersFail(errors, data.redirect_to))
        } else {
          dispatch(createAnswersSuccess(data))
        }
      })
  }
}

const createDemographicAnswerInit = () => ({type: 'CREATE_DEMOGRAPHIC_ANSWERS_INIT'})

const createDemographicAnswerSuccess = () => ({type: 'CREATE_DEMOGRAPHIC_ANSWERS_SUCCESS'})

const createDemographicAnswerFail = (errors) => ({type: 'CREATE_DEMOGRAPHIC_ANSWERS_FAIL', errors})

export const createDemographicAnswerReset = () => ({type: 'CREATE_DEMOGRAPHIC_ANSWERS_RESET'})

export const createDemographicAnswerSubmit = (params = {}) => {
  return dispatch => {
    dispatch(createDemographicAnswerInit())

    return fetch(`/api/v1/createDemographicAnswer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(createDemographicAnswerFail(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(createDemographicAnswerSuccess())
          successToast('Answer updated successfully!')
        }
      })
  }
}

export const updatePreviousAnswers = (answer) => ({type: 'UPDATE_PREVIOUS_ANSWERS', answer})

export const confirmEmailInit = () => ({type: 'CONFIRM_EMAIL_INIT'})

export const confirmEmailFetch = (confirmationToken) => {
  return dispatch => {
    dispatch(confirmEmailInit())
    return fetch('/api/v1/users/confirmEmail', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({confirmation_token: confirmationToken})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(confirmEmailError(errors))
        } else {
          dispatch(confirmEmailSuccess(data))
        }
      })
  }
}

/* Pre survey actions end */

/* Post survey actions start */
const markFeedbackStartedInit = () => ({
  type: 'POST_SURVEY_FEEDBACK_STARTED_INIT'
})

const markFeedbackStartedSuccess = (assignment) => ({
  type: 'POST_SURVEY_FEEDBACK_STARTED_SUCCESS',
  assignment
})

const markFeedbackStartedFail = (errors, quotaFullUrl) => ({
  type: 'POST_SURVEY_FEEDBACK_STARTED_FAIL',
  errors,
  quotaFullUrl
})

export const markFeedbackStartedFetch = (params, isMturk) => {
  return dispatch => {
    dispatch(markFeedbackStartedInit())
    return fetch(`/api/v1/markFeedbackStarted/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const {errors, quota_full_url} = data
        if (errors && errors.length > 0) {
          dispatch(markFeedbackStartedFail(errors, quota_full_url))
        } else {
          dispatch(markFeedbackStartedSuccess(data))
        }
      })
  }
}

const getPlatformTypeOfAssignmentInit = () => ({
  type: 'POST_SURVEY_PLATFORM_TYPE_INIT'
})

const getPlatformTypeOfAssignmentSuccess = (platformType) => ({
  type: 'POST_SURVEY_PLATFORM_TYPE_SUCCESS',
  platformType
})

const getPlatformTypeOfAssignmentFail = (errors) => ({
  type: 'POST_SURVEY_PLATFORM_TYPE_FAIL',
  errors
})

export const getPlatformTypeOfAssignmentFetch = (assignmentId) => {
  return dispatch => {
    dispatch(getPlatformTypeOfAssignmentInit())
    return fetch(`/api/v1/getPlatformTypeOfAssignment/${assignmentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(getPlatformTypeOfAssignmentFail(errors))
        } else {
          dispatch(getPlatformTypeOfAssignmentSuccess(data.platform_type))
        }
      })
  }
}

const confirmEmailSuccess = (user) => ({type: 'CONFIRM_EMAIL_SUCCESS', user})

const confirmEmailError = errors => ({type: 'CONFIRM_EMAIL_FAIL', errors})

const copyCodeTextSuccess = (text) => ({
  type: 'COPY_CODE_TEXT_SUCCESS',
  text
})


const enableNotificationForWorkerInit = () => ({
  type: 'POST_SURVEY_ENABLE_NOTIFICATION_INIT'
})

const enableNotificationForWorkerSuccess = (payload) => ({
  type: 'POST_SURVEY_ENABLE_NOTIFICATION_SUCCESS',
  payload
})

const enableNotificationForWorkerFail = (errors) => ({
  type: 'POST_SURVEY_ENABLE_NOTIFICATION_FAIL',
  errors
})

export const enableNotificationForWorkerFetch = (params, enable = true, callBack) => {
  return dispatch => {
    dispatch(enableNotificationForWorkerInit())
    return fetch(`/api/v1/${enable ? 'enableNotificationForWorker' : 'updateNotificationSettingsForWorker'}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          if (enable) {
            errorToast('Subscription for the new HITs failed')
            dispatch(enableNotificationForWorkerFail(errors))
          }
        } else {
          const subscriptionCompleted = true
          callBack(subscriptionCompleted)
          if (enable) {
            dispatch(enableNotificationForWorkerSuccess(data))
            if (enable) {
              successToast(
                'Positly will notify you when new activities become available that you qualify for.',
                {autoClose: 4000}
              )
            }
          }
        }
      })
  }
}

export const setupRunFormFromRunSuccess = (run, isRunUpdate) => ({
  type: 'SETUP_RUN_FORM_FROM_RUN_SUCCESS',
  run,
  isRunUpdate
})

export const setupRunFormFromRunFail = (errors) => ({
  type: 'SETUP_RUN_FORM_FROM_RUN_FAIL',
  errors
})

const submitFeedbackInit = () => ({
  type: 'POST_SURVEY_SUBMIT_FEEDBACK_INIT'
})

const submitFeedbackSuccess = (payload) => ({
  type: 'POST_SURVEY_SUBMIT_FEEDBACK_SUCCESS',
  payload
})

const submitFeedbackFail = () => ({
  type: 'POST_SURVEY_SUBMIT_FEEDBACK_FAIL'
})

export const submitFeedbackFetch = (params) => {
  return dispatch => {
    dispatch(submitFeedbackInit())
    return fetch('/api/v1/createFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          errorToast('Your feedback has not been submitted.')
          dispatch(submitFeedbackFail(errors))
        } else {
          // need to update feedback_completed_at
          dispatch(submitFeedbackSuccess(data))
        }
      })
  }
}

export const setupRunFormFromProject = (project) => ({
  type: 'SETUP_RUN_FORM_FROM_PROJECT',
  project
})

const getEligibleHitsInit = () => ({
  type: 'POST_SURVEY_ELIGIBLE_HITS_INIT'
})

const getEligibleHitsSuccess = (eligibleHits) => ({
  type: 'POST_SURVEY_ELIGIBLE_HITS_SUCCESS',
  eligibleHits
})

const getEligibleHitsFail = (errors) => ({
  type: 'POST_SURVEY_ELIGIBLE_HITS_FAIL',
  errors
})

export const getEligibleHitsFetch = (params) => {
  return dispatch => {
    dispatch(getEligibleHitsInit())

    const encodedURLParams = $.param(params)

    return fetch(`/api/v1/getEligibleRuns?${encodedURLParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(getEligibleHitsFail(errors))
        } else {
          dispatch(getEligibleHitsSuccess(data.eligible_runs))
        }
      })
  }
}

/* Run Form */
export const runFormOverviewInit = () => ({
  type: 'RUN_FORM_OVERVIEW_INIT'
})

export const runFormOverviewUpdate = (values) => ({
  type: 'RUN_FORM_OVERVIEW_UPDATE',
  values
})

export const runFormOverviewAddAdditionalAttribute = (attribute) => ({
  type: 'RUN_FORM_OVERVIEW_ADD_ADDITIONAL_ATTRIBUTE',
  attribute
})

export const runFormOverviewRemoveAdditionalAttribute = (attribute) => ({
  type: 'RUN_FORM_OVERVIEW_REMOVE_ADDITIONAL_ATTRIBUTE',
  attribute
})

export const runFormReset = () => ({type: 'RUN_FORM_RESET'})

export const runFormOverviewAddOrUpdateFilter = (filter) => ({
  type: 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_FILTER',
  filter
})

export const runFormOverviewRemoveFilter = (filterName) => ({
  type: 'RUN_FORM_OVERVIEW_REMOVE_FILTER',
  filterName
})

export const runFormOverviewAddOrUpdatePrescreenData = (prescreenData, prescreenIds) => ({
  type: 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_PRESCREEN_DATA',
  prescreenData,
  prescreenIds
})

export const runFormOverviewAddOrUpdateCintFilter = (filter) => ({
  type: 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_CINT_FILTER',
  filter
})

export const runFormOverviewRemoveCintFilter = (filterId) => ({
  type: 'RUN_FORM_OVERVIEW_REMOVE_CINT_FILTER',
  filterId
})

export const runFormOverviewResetFilters = () => ({
  type: 'RUN_FORM_OVERVIEW_RESET_FILTERS'
})

export const runFormOverviewResetCintFilters = (filters = []) => ({
  type: 'RUN_FORM_OVERVIEW_RESET_CINT_FILTERS',
  filters
})

const runFormDisablePilotWarningInit = () => ({
  type: 'RUN_FORM_DISABLE_PILOT_WARNING_INIT'
})

const runFormDisablePilotWarningSuccess = () => ({
  type: 'RUN_FORM_DISABLE_PILOT_WARNING_SUCCESS'
})

const runFormDisablePilotWarningFail = (errors) => ({
  type: 'RUN_FORM_DISABLE_PILOT_WARNING_FAIL',
  errors
})

export const runFormDisablePilotWarning = () => {
  return dispatch => {
    dispatch(runFormDisablePilotWarningInit())

    return fetch('/api/v1/users/disablePilotWarning', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFormDisablePilotWarningFail(errors))
          errorToast('Failed to disable pilot warning!')
        } else {
          dispatch(runFormDisablePilotWarningSuccess())
          successToast('Pilot warning disabled successfully!')
        }
      })
  }
}

const checkGTUserRegisteredInit = (gtDataId) => ({
  type: 'CHECK_GT_USER_REGISTERED_INIT',
  gtDataId
})

const checkGTUserRegisteredSuccess = ({is_registered, project_id, email, ...activityData}) => ({
  type: 'CHECK_GT_USER_REGISTERED_SUCCESS',
  is_registered,
  project_id,
  email,
  activityData
})

const checkGTUserRegisteredFail = (errors) => ({
  type: 'CHECK_GT_USER_REGISTERED_FAIL',
  errors
})

export const checkGTUserRegistered = (gtDataId, projectId) => {
  return dispatch => {
    dispatch(checkGTUserRegisteredInit(gtDataId))

    const encodedURLParams = $.param({gt_data_id: gtDataId})

    return fetch(`/api/v1/checkGTUserRegistered?${encodedURLParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(checkGTUserRegisteredFail(errors))
        } else {
          dispatch(checkGTUserRegisteredSuccess(data))
          if (projectId) {
            dispatch(projectFetch(projectId, 'form'))
          }
        }
      })
  }
}

const workerFetchInit = () => ({
  type: 'WORKER_FETCH_INIT'
})

const workerFetchSuccess = (payload) => ({
  type: 'WORKER_FETCH_SUCCESS',
  payload
})

const workerFetchFail = (errors) => ({
  type: 'WORKER_FETCH_FAIL',
  errors
})

export const workerFetch = (id) => {
  return dispatch => {
    dispatch(workerFetchInit())

    return fetch(`/api/v1/getWorker/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          dispatch(workerFetchFail(errors))
        } else {
          dispatch(workerFetchSuccess(data))
        }
      })
  }
}

const workerUnsubscribeInit = () => ({
  type: 'WORKER_UNSUBSCRIBE_INIT'
})

const workerUnsubscribeSuccess = (payload) => ({
  type: 'WORKER_UNSUBSCRIBE_SUCCESS',
  payload
})

const workerUnsubscribeFail = (errors) => ({
  type: 'WORKER_UNSUBSCRIBE_FAIL',
  errors
})

export const workerUnsubscribeFetch = (params) => {
  return dispatch => {
    dispatch(workerUnsubscribeInit())

    return fetch(`/api/v1/updateNotificationSettingsForWorker`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          dispatch(workerUnsubscribeFail(errors))
          errorToast('Failed to update notification settings. ' + errors.join(', '))
        } else {
          dispatch(workerUnsubscribeSuccess(data))
          successToast('Notification settings updated')
        }
      })
  }
}

export const activityLanguagesListSuccess = ({languages}) => ({
  type: 'ACTIVITY_LANGUAGES_LIST_SUCCESS',
  languages
})

export const activityLanguagesListFail = errors => ({
  type: 'ACTIVITY_LANGUAGES_LIST_FAIL',
  errors
})

export const activityLanguagesListFetch = () => {
  return dispatch => {
    return getActivityLanguages()
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(activityLanguagesListFail(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(activityLanguagesListSuccess(data))
        }
      })
  }
}

export const runFormResetFiltersAndQueryStrings = () => ({
  type: 'RUN_FORM_RESET_FILTERS_AND_QUERY_STRINGS'
})

export const runFormLoadDefaultQueryStrings = () => ({
  type: 'RUN_FORM_LOAD_DEFAULT_QUERY_STRINGS'
})

const getCountries = () => {
  return fetch('/api/v1/getCountries', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getActivityLanguages = () => {
  return fetch('/api/v1/getActivityLanguages', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getDemographicQueryStringConfig = () => {
  return fetch(`/api/v1/getDemographicQueryStringConfig`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getAdditionalAttributeQuestions = () => {
  return fetch('/api/v1/getAdditionalAttributeQuestions', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getDemographicFilters = () => {
  return fetch(`/api/v1/getDemographicFilters`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getCustomAttributeFilters = () => {
  return fetch(`/api/v1/users/getFilters`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getCopyCodeText = () => {
  return fetch('/api/v1/getEndOfActivityButtonName', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
  })
}

const getRun = (id) => {
  return fetch(`/api/v1/getRun/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

const getProject = (id) => {
  return fetch(`/api/v1/getTask/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

const getGtData = (id) => {
  return fetch(`/api/v1/checkGTUserRegistered?gt_data_id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

const getUserProfile = () => {
  return fetch('/api/v1/users/getUserProfile', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const initializeRunForm = (runId, projectId, gtDataId, isRunUpdate) => {
  const promises = [
    getCountries(),
    getPlatforms(),
    getActivityLanguages(),
    getDemographicQueryStringConfig(),
    getAdditionalAttributeQuestions(),
    getDemographicFilters(),
    getCustomAttributeFilters(),
    getCopyCodeText(),
    getUserProfile(),
  ]

  if (runId)
    promises.push(getRun(runId))
  else if (projectId)
    promises.push(getProject(projectId))

  if (gtDataId)
    promises.push(getGtData(gtDataId))

  return dispatch => {
    dispatch(runFormInit())
    dispatch(runFormOverviewInit())

    Promise.all(promises)
      .then(responses => Promise.all(
        responses.map(response => response.json())
      ))
      .then(parsedResponse => {
        const errorResponse = parsedResponse.find(response => response?.errors)

        if (errorResponse) {
          const errors = errorResponse.errors

          errorToast(errors.join(', '), {autoClose: false})
        } else {
          const countries = parsedResponse[0]
          const platforms = parsedResponse[1]
          const languages = parsedResponse[2]
          const demographicQueryStringConfig = parsedResponse[3]
          const additionalAttributeQuestions = parsedResponse[4]
          const demographicFilters = parsedResponse[5]
          const customAttributeFilters = parsedResponse[6]
          const copyCodeText = parsedResponse[7]
          const userProfile = parsedResponse[8]

          dispatch(countriesListSuccess(countries))
          dispatch(platformListSuccess(platforms))
          dispatch(activityLanguagesListSuccess(languages))
          dispatch(demographicQueryStringConfigSuccess(demographicQueryStringConfig))
          dispatch(additionalAttrQuestionsListSuccess(additionalAttributeQuestions))
          dispatch(runFormDemographicFilterSuccess(demographicFilters))
          dispatch(runFormCustomAttrFilterSuccess(customAttributeFilters))
          dispatch(copyCodeTextSuccess(copyCodeText))
          dispatch(userAccountSuccess(userProfile))

          if (gtDataId) {
            const gtData = parsedResponse[10]
            dispatch(checkGTUserRegisteredSuccess(gtData))
          }

          if (runId) {
            const run = parsedResponse[9]
            dispatch(setupRunFormFromRunSuccess(run, isRunUpdate))
          } else {
            const project = parsedResponse[9]
            dispatch(setupRunFormFromProject(project))
          }
        }
      })
  }
}

const checkSlotAvailabilityInit = () => ({
  type: 'CHECK_SLOT_AVAILABILITY_INIT'
})

const checkSlotAvailabilitySuccess = (data) => ({
  type: 'CHECK_SLOT_AVAILABILITY_SUCCESS',
  redirectUrl: data.redirect_url
})

const checkSlotAvailabilityFail = (errors) => ({
  type: 'CHECK_SLOT_AVAILABILITY_FAIL',
  errors
})

export const checkSlotAvailability = (id) => {
  return dispatch => {
    dispatch(checkSlotAvailabilityInit())

    return fetch(`/api/v1/checkSlotAvailability?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          dispatch(checkSlotAvailabilityFail(errors))
        } else {
          dispatch(checkSlotAvailabilitySuccess(data))
        }
      })
  }
}

export const logCookieUsedAsFallback = params => {
  return () => {
    return fetch('/api/v1/cookieUsedAsFallback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors?.length)
          console.log('Failed to log the cookie used as fallback for localStorage', data.errors)
        else
          console.log('Logged cookie is used as fallback for localStorage')
      })
  }
}

const startLinkVerificationInit = (linkType) => ({
  type: 'START_LINK_VERIFICATION_INIT',
  linkType
})

const startLinkVerificationSuccess = (linkVerification) => ({
  type: 'START_LINK_VERIFICATION_SUCCESS',
  linkVerification
})

const startLinkVerificationFail = (linkType, errors) => ({
  type: 'START_LINK_VERIFICATION_FAIL',
  linkType,
  errors
})

export const startLinkVerificationFetch = params => {
  return dispatch => {
    dispatch(startLinkVerificationInit(params.link_type))

    return fetch('/api/v1/startLinkVerification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length) {
          errorToast(errors.join(', '))
          dispatch(startLinkVerificationFail(params.link_type, data.errors))
        } else {
          setStorageItem(`lv_${data.task_id}_${data.link_type}_id`, data.id)
          dispatch(startLinkVerificationSuccess(data))
        }
      })
  }
}

const markLinkVerifiedInit = () => ({
  type: 'MARK_FEEDBACK_LINK_VERIFIED_INIT'
})

const markLinkVerifiedSuccess = (feedbackLinkVerification) => ({
  type: 'MARK_FEEDBACK_LINK_VERIFIED_SUCCESS',
  feedbackLinkVerification
})

const markLinkVerifiedFail = (errors) => ({
  type: 'MARK_FEEDBACK_LINK_VERIFIED_FAIL',
  errors
})

export const markLinkVerifiedFetch = (params) => {
  return dispatch => {
    dispatch(markLinkVerifiedInit())

    return fetch(`/api/v1/markLinkVerified/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(markLinkVerifiedFail(errors))
        } else {
          dispatch(markLinkVerifiedSuccess(data))
        }
      })
  }
}

export const updateLinkVerification = (linkVerificationData) => ({
  type: 'UPDATE_LINK_VERIFICATION',
  linkVerificationData
})

export const resetLinkVerification = ({isActivityUrlChanged, isScreeningUrlChanged} = {}) => ({
  type: 'RESET_LINK_VERIFICATION',
  isActivityUrlChanged,
  isScreeningUrlChanged
})

const markFeedbackStartedFallbackInit = () => ({
  type: 'FEEDBACK_STARTED_FALLBACK_INIT'
})

const markFeedbackStartedFallbackSuccess = (assignment) => ({
  type: 'FEEDBACK_STARTED_FALLBACK_SUCCESS',
  assignment
})

const markFeedbackStartedFallbackFail = (errors) => ({
  type: 'FEEDBACK_STARTED_FALLBACK_FAIL',
  errors
})

export const markFeedbackStartedFallbackFetch = (params) => {
  return dispatch => {
    dispatch(markFeedbackStartedFallbackInit())

    return fetch(`/api/v1/markFeedbackStartedFallback/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(markFeedbackStartedFallbackFail(errors))
        } else {
          dispatch(markFeedbackStartedFallbackSuccess(data))
          dispatch(getPlatformTypeOfAssignmentSuccess(data.platform_type))
          dispatch(markFeedbackStartedSuccess(data))
        }
      })
  }
}

/* Mark Cint assignment as screenout */
const markIneligibleInit = () => ({
  type: 'MARK_INELIGIBLE_INIT'
})

const markIneligibleSuccess = (data) => ({
  type: 'MARK_INELIGIBLE_SUCCESS',
  cintReturnUrl: data.cint_return_url
})

const markIneligibleFail = (errors) => ({
  type: 'MARK_INELIGIBLE_FAIL',
  errors
})

export const markIneligibleFetch = (params) => {
  return dispatch => {
    dispatch(markIneligibleInit())
    return fetch(`/api/v1/markIneligible/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(markIneligibleFail(errors))
        } else {
          dispatch(markIneligibleSuccess(data))
        }
      })
  }
}

const announcementsInit = () => ({
  type: 'ANNOUNCEMENTS_INIT'
})

const announcementsSuccess = (announcements) => ({
  type: 'ANNOUNCEMENTS_SUCCESS',
  announcements
})

const announcementsFail = (errors) => ({
  type: 'ANNOUNCEMENTS_FAIL',
  errors
})

export const announcementsFetch = () => {
  return dispatch => {
    dispatch(announcementsInit())

    return fetch('/api/v1/getAnnouncements', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(announcementsFail(errors))
        } else {
          dispatch(announcementsSuccess(data))
        }
      })
  }
}

const markScreenedInInit = () => ({
  type: 'SCREENED_IN_INIT'
})

const markScreenedInSuccess = (data) => ({
  type: 'SCREENED_IN_SUCCESS',
  data
})

const markScreenedInFail = (errors) => ({
  type: 'SCREENED_IN_FAIL',
  errors
})


export const markScreenedInFetch = (params) => {
  return dispatch => {
    dispatch(markScreenedInInit())
    return fetch(`/api/v1/markScreenedIn/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          dispatch(markScreenedInFail(data.errors))
        } else {
          dispatch(markScreenedInSuccess(data))
        }
      })
  }
}


const getOpenSlotsForMainActivityInit = () => ({
  type: 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_INIT'
})

const getOpenSlotsForMainActivitySuccess = (data) => ({
  type: 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_SUCCESS',
  data
})

const getOpenSlotsForMainActivityFail = (data) => ({
  type: 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_FAIL',
  data
})


export const getOpenSlotsForMainActivityFetch = (params) => {
  return dispatch => {
    dispatch(getOpenSlotsForMainActivityInit())
    return fetch(`/api/v1/getOpenSlotsForMainActivity/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          dispatch(getOpenSlotsForMainActivityFail(data))
        } else {
          dispatch(getOpenSlotsForMainActivitySuccess(data))
        }
      })
  }
}

const createMainActivityFeedbackInit = () => ({
  type: 'MAIN_ACTIVITY_FEEDBACK_INIT'
})

const createMainActivityFeedbackSuccess = (data) => ({
  type: 'MAIN_ACTIVITY_FEEDBACK_SUCCESS',
  data
})

const createMainActivityFeedbackFail = (errors) => ({
  type: 'MAIN_ACTIVITY_FEEDBACK_FAIL',
  errors
})

export const createMainActivityFeedbackFetch = (params) => {
  return dispatch => {
    dispatch(createMainActivityFeedbackInit())

    return fetch('/api/v1/createMainActivityFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          dispatch(createMainActivityFeedbackFail(data.errors))
        } else {
          dispatch(createMainActivityFeedbackSuccess(data))
        }
      })
  }
}

export const updateScreenInAssignment = (assignment) => ({
  type: 'UPDATE_SCREEN_IN_ASSIGNMENT',
  assignment
})

const getUserSpendingInit = () => ({
  type: 'GET_POSITLY_SPENDING_INIT',
})

const getUserSpendingSuccess = (data) => ({
  type: 'GET_POSITLY_SPENDING_SUCCESS',
  data
})

const getUserSpendingFail = (errors) => ({
  type: 'GET_POSITLY_SPENDING_FAIL',
  errors
})

const getUserSpending = () => {
  return dispatch => {
    dispatch(getUserSpendingInit())

    return fetch('/api/v1/users/getUserSpending', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          dispatch(getUserSpendingFail(data.errors))
        } else {
          dispatch(getUserSpendingSuccess(data))
        }
      })
  }
}

const getQuotaFeasibilityCountInit = (additionalData) => ({
  type: 'GET_QUOTA_FEASIBILITY_COUNT_INIT',
  additionalData
})

const getQuotaFeasibilityCountSuccess = (count, additionalData) => ({
  type: 'GET_QUOTA_FEASIBILITY_COUNT_SUCCESS',
  count,
  additionalData
})

const getQuotaFeasibilityCountFail = (errors, additionalData) => ({
  type: 'GET_QUOTA_FEASIBILITY_COUNT_FAIL',
  errors,
  additionalData
})

export const getQuotaFeasibilityCount = (params, signal, additionalData = {}) => {
  return dispatch => {
    dispatch(getQuotaFeasibilityCountInit(additionalData))

    return fetch(`/api/v1/getQualifiedWorkersCountForCint`, {
      method: 'POST',
      signal: signal,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(getQuotaFeasibilityCountFail(errors, additionalData))
        } else {
          dispatch(getQuotaFeasibilityCountSuccess(data.qualified_workers_count_for_cint, additionalData))
        }
      })
      .catch(e => {
        if (e.name === 'AbortError') {
          console.log('Aborted previous request: getQualifiedWorkersCountForCint.')
        }
      })
  }
}

export const cintQuotaAddOrUpdate = (quota, shouldUnshiftArray = false) => ({
  type: 'CINT_QUOTA_ADD_OR_UPDATE',
  quota,
  shouldUnshiftArray
})

export const cintQuotaRemove = (quotaId) => ({
  type: 'CINT_QUOTA_REMOVE',
  quotaId
})

export const cintQuotaReset = (quotas = []) => ({
  type: 'CINT_QUOTA_RESET',
  quotas
})

export const updateCintInterlockedQuotas = (interlockedQuotas) => ({
  type: 'UPDATE_CINT_INTERLOCKED_QUOTAS',
  interlockedQuotas
})

export const getCensusData = (params, options) => {
  return dispatch => {
    dispatch(getCensusDataInit(options))

    return fetch(`/api/v1/getCensusData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(getCensusDataFail(errors))
        } else {
          const formattedCensusData = formatCensusData(data, options)
          dispatch(getCensusDataSuccess(formattedCensusData))
        }
      })
  }
}

const getCensusDataInit = (data) => ({
  type: 'GET_CENSUS_DATA_INIT',
  data
})

const getCensusDataSuccess = (data) => ({
  type: 'GET_CENSUS_DATA_SUCCESS',
  data
})

const getCensusDataFail = (errors) => ({
  type: 'GET_CENSUS_DATA_FAIL',
  errors
})

const createCintAssignmentInit = () => ({
  type: 'CREATE_CINT_ASSIGNMENT_INIT'
})

const createCintAssignmentSuccess = (data) => ({
  type: 'CREATE_CINT_ASSIGNMENT_SUCCESS',
  data
})

const createCintAssignmentFail = (errors, redirect_to) => ({
  type: 'CREATE_CINT_ASSIGNMENT_FAIL',
  errors,
  redirect_to
})

export const createCintAssignment = (params) => {
  return dispatch => {
    dispatch(createCintAssignmentInit())

    return fetch(`/api/v1/createCintAssignment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          if (data.status_code === 400) {
            dispatch(createCintAssignmentFail(['Bad request']))
          } else {
            dispatch(createCintAssignmentFail(data.errors, data.redirect_to))
          }
        } else {
          dispatch(createCintAssignmentSuccess(data))
        }
      })
  }
}

export const updateAssignmentStatusForCintEntry = (data) => ({
  type: 'UPDATE_ASSIGNMENT_STATUS_FOR_CINT_ENTRY',
  data
})

const createCintFeedbackInit = () => ({
  type: 'CREATE_CINT_FEEDBACK_INIT'
})

const createCintFeedbackSuccess = (data) => ({
  type: 'CREATE_CINT_FEEDBACK_SUCCESS',
  data
})

const createCintFeedbackFail = (errors) => ({
  type: 'CREATE_CINT_FEEDBACK_FAIL',
  errors
})

export const createCintFeedback = (params) => {
  return dispatch => {
    dispatch(createCintFeedbackInit())

    return fetch('/api/v1/createCintFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.errors && data.errors.length > 0) {
          if (data.toast) {
            errorToast(data.errors.join(', '))
            dispatch(createCintFeedbackFail([]))
          } else {
            dispatch(createCintFeedbackFail(data.errors))
          }
        } else {
          dispatch(createCintFeedbackSuccess(data))
        }
      })
  }
}
