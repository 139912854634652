import React, {useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'

import {openRoutes, routes} from '../routes'
import logo from '../images/logo.svg'
import menuIcon from '../images/menu-icon.svg'
import {getCurrentRoute} from './helpers/utils'
import {signOutUser} from '../redux/actionss/userActions'

const Navbar = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.currentUser)
  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
  const gtDataId = queryParams.gt_data_id
  const signInLink = gtDataId ? `${routes.SIGN_IN}?gt_data_id=${gtDataId}` : routes.SIGN_IN
  const signUpLink = gtDataId ? `${routes.SIGN_UP}?gt_data_id=${gtDataId}` : routes.SIGN_UP
  const [showMenu, setShowMenu] = useState(false)

  const route = getCurrentRoute()

  const getClass = (path) =>
    route.indexOf(path) > -1 ? 'active' : ''

  const disableMenu = openRoutes.includes(route)

  const signOut = (e) => {
    e.preventDefault()
    dispatch(signOutUser(history))
  }

  const isResearcher = () => {
    return currentUser && currentUser.account_type === 'researcher'
  }

  const isParticipant = () => {
    return currentUser && currentUser.account_type === 'participant'
  }

  const getLogoLink = () => {
    if(isResearcher()){
      return routes.PROJECT_LIST
    } else if(isParticipant()) {
      return routes.PARTICIPANT_HOME
    } else {
      return signInLink
    }
  }

  return (
    <nav className="nav">
      <div className="container">
        <Link
          to={getLogoLink()}
          className={disableMenu ? 'pe-none po-ns logo' : 'logo'}
        >
          <img alt="Positly Logo" src={logo}/>
        </Link>

        {!disableMenu && (
          <div className={`nav-menu ${showMenu ? 'visible' : ''}`}>
            <ul>
              {isResearcher() && (
                <>
                  <li>
                    <Link
                      to="/projects/list"
                      className={getClass('projects')}
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/participants"
                      className={getClass('participants')}
                    >
                      Your Participants
                    </Link>
                  </li>
                </>
              )}
              {!isResearcher() && !isParticipant() && (
                <>
                  <li><Link to={signInLink} className={getClass('sign-in')}>Sign In</Link></li>
                  <li><Link to={signUpLink} className={getClass('sign-up')}>Register</Link></li>
                </>
              )}
              {!isParticipant() && (
                <>
                  <li>
                    <Link
                      to="/users/contact-us"
                      className={getClass('contact')}
                    >
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <a href="https://positly.com/support" target="_blank" rel="noopener noreferrer">
                      Help
                    </a>
                  </li>
                </>
              )}
            </ul>

            <span className="close-icon" onClick={() => setShowMenu(false)}>&times;</span>
          </div>
        )}

        {currentUser && (
          <div className={`nav-more ${isResearcher() ? 'has_menu' : ''}`}>
            <div
              aria-expanded="false"
              data-toggle="dropdown"
              className="dropdown-toggle cursor-pointer d-flex align-items-center"
            >
              <div className="username lh-1 text-truncate">
                {currentUser.nick_name || currentUser.email}
              </div>
              <i className="zmdi zmdi-chevron-down"/>
            </div>
            <ul role="menu" className="dropdown-menu dropdown-menu-right dropdown-menu-navbar">
              {currentUser.has_own_mturk_account && (
                <li>
                  <a
                    href="https://console.aws.amazon.com/billing/home#/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon Mechanical Turk Account
                  </a>
                </li>
              )}
              <li><Link to="/users/billing">Billing</Link></li>
              <li><Link to="/platforms/list">Platforms</Link></li>
              <li><Link to="/users/profile">Account</Link></li>
              <li>
                <a
                  href="/"
                  className="cursor-pointer"
                  onClick={signOut}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        )}

        {
          !disableMenu && !isParticipant() &&
          <img src={menuIcon} alt="navigation menu toggle" className="menu-icon" onClick={() => setShowMenu(true)}/>
        }
      </div>
    </nav>
  )
}

export default Navbar
