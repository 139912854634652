import fetchIntercept from 'fetch-intercept'

import {openRoutes} from '../../routes'
import {getCurrentRoute} from './utils'
import {signOutSuccess} from '../../redux/actionss/userActions'

let store

export const injectStore = storeToInject => store = storeToInject

const fetchInterceptor = () => {
  fetchIntercept.register({
    request: function (url, config) {
      return [url, config]
    },

    requestError: function (error) {
      return Promise.reject(error)
    },

    response: function (response) {
      const route = getCurrentRoute()

      if (response.status === 401 && !openRoutes.includes(route)) {
        store.dispatch(signOutSuccess())
        window.location = '#/users/sign-in'
      } else if (response.status === 403) {
        window.location = '#/permission-denied'
      }

      return response
    },

    responseError: function (error) {
      return Promise.reject(error)
    }
  })
}

export default fetchInterceptor
