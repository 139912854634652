import Spinner from "./common/Spinner";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {routes} from '../routes'

const Home = () => {

  const currentUserAccountType = useSelector(state => state.currentUser?.account_type)
  const isTokenValidating = useSelector(state => state.isTokenValidating)

  const getFormattedPath = (path) => {
    return '/#' + path
  }

  useEffect(() => {
    if(!isTokenValidating) {
      if(currentUserAccountType === 'participant') {
        window.location = getFormattedPath(routes.PARTICIPANT_HOME)
      } else if(currentUserAccountType === 'researcher') {
        window.location = getFormattedPath(routes.PROJECT_LIST)
      } else {
        window.location = getFormattedPath(routes.SIGN_IN)
      }
    }
  }, [currentUserAccountType]);

  return (
    <>
      <Spinner/>
    </>
  )
}

export default Home
