import React from 'react'
import CustomAttributeNumericFilter from './CustomAttributeNumericFilter'
import CustomAttributeTextFilter from './CustomAttributeTextFilter'
import DemographicNumericFilter from './DemographicNumericFilter'
import DemographicCheckboxFilter from './DemographicCheckboxFilter'

export const DemographicFilter = ({filter, removeFilter, updateFilter, className}) => {
  return (
    filter.filter_type === 'numeric' ? (
      <DemographicNumericFilter
        filter={filter}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
        className={className}
      />
    ) : (
      <DemographicCheckboxFilter
        filter={filter}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
        className={className}
      />
    )
  )
}

export const CustomAttributeFilter = ({filter, removeFilter, updateFilter, className}) => {
  return (
    filter.filter_type === 'numeric' ? (
      <CustomAttributeNumericFilter
        filter={filter}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
        className={className}
      />
    ) : (
      <CustomAttributeTextFilter
        filter={filter}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
        className={className}
      />
    )
  )
}
