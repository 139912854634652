import React from "react";

export const ParticipantSearch = (props) => {

  return (
    <div className="row">
      <div className="col-md-12 task-list-header">
        <div className="pull-right search-bar">
          <form onSubmit={props.searchFormik.handleSubmit} className="participants-search">
            <div className="input-group">
              <input
                type="text"
                name="query"
                id="query"
                placeholder="Search participants by ID"
                className="form-control search-input"
                onChange={props.searchFormik.handleChange}
                value={props.searchFormik.values.query}
                onBlur={() => {props.handleClear(props.searchFormik.values.query)}}
              />
              <input
                type="submit"
                disabled={props.isAssignmentsLoading}
              />
              <span className="input-group-addon clickable"/>
            </div>
          </form>
        </div>
      </div>
    </div>
)
}
