import React from 'react'
import {connect} from 'react-redux'

import {
  resendConfirmationEmailFetch
} from '../../redux/actions'

const UnconfirmedEmail = props => {
  const resendConfirmationEmail = (e) => {
    if (props.isLoading)
      return

    e.preventDefault()
    props.resendConfirmationEmailFetch()
  }

  return (
    <>
      {props.currentUser && (!props.currentUser.confirmed_at && !props.confirmedEmail) && (
        <div className="alert alert-info-message po-text-bold">
          {!props.currentUser?.is_confirmation_time_expired && (
            <span>
              We have sent an email to <span className="text-bold">{props.currentUser.email}</span>. Open the email and follow the instructions
              to confirm your email and complete your registration. If you did not receive the verification email, then {' '}
              <u className="resend-email cursor-pointer" onClick={resendConfirmationEmail}>
                {props.isLoading ? 'resending confirmation email...' : 'click here to resend it.'}
              </u>
            </span>
          )}
          {props.currentUser?.is_confirmation_time_expired && (
            <span>
              Your email address needs to be verified.
              If you did not receive the verification email sent to <span className="text-bold">{props.currentUser.email}</span>, then {' '}
              <u className="resend-email cursor-pointer" onClick={resendConfirmationEmail}>
                {props.isLoading ? 'resending confirmation email...' : 'click here to resend it.'}
              </u>
              {' '}Within that verification email you will need to click the confirmation link.
            </span>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  ...state.resendEmail,
  confirmedEmail: state.confirmEmail?.confirmedEmail,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  resendConfirmationEmailFetch: () => dispatch(resendConfirmationEmailFetch())
})

export default connect(mapStateToProps, mapDispatchToProps)(UnconfirmedEmail)
