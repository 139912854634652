import React from 'react'

import clSetupStage1 from '../../images/cl-setup-stage-1.png'
import clSetupStage2 from '../../images/cl-setup-stage-2.png'
import clSetupStage3 from '../../images/cl-setup-stage-3.png'

const CompletionLinkIllustration = ({className = ''}) => {
  return (
    <div className={`stage-container po-text ${className}`}>
      <div className="stage-item">
        <div>
          <h2 className="mb-1">STAGE 1</h2>
          <div>A participant reads your title and description</div>
        </div>
        <div>
          <img src={clSetupStage1} alt="Book Icon"/>
        </div>
      </div>
      <div className="stage-item">
        <div>
          <h2 className="mb-1">STAGE 2</h2>
          <div>If they’re interested in participating, they’re sent to your activity</div>
        </div>
        <div>
          <img src={clSetupStage2} alt="Hands Icon"/>
        </div>
      </div>
      <div className="stage-item">
        <div>
          <h2 className="mb-1">STAGE 3</h2>
          <div>To get paid after finishing your activity, participants click the Completion Link you provided</div>
        </div>
        <div>
          <img src={clSetupStage3} alt="Money Icon"/>
        </div>
      </div>
    </div>
  )
}

export default CompletionLinkIllustration
