import React, {useRef, useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormErrors from './FormErrors'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Tagify from '@yaireo/tagify'

import CharsLeft from './common/CharsLeft'
import {VALID_UID_REGEX} from '../utils/constants'

// Redux actions
import {
  participantSendMessageSubmit,
  participantSendMessageFetch
} from '../redux/actionss/runActions'

const $ = window.$

const SendMessage = (props) => {
  const [participantIDs, setParticipantIDs] = useState([])
  const input = useRef()
  const [tagify, setTagify] = useState(null)

  const participantSendMessage = useSelector((state) => ({...state.participantSendMessage}))
  const [errorState, setErrorState] = useState({
    missingIds: [],
    freshParticipantIds: [],
    errorMessage: '',
  })

  useEffect(() => {
    const errorMessage = (participantSendMessage.errors || []).join()
    const freshParticipantIds = participantSendMessage.fresh_participant_ids || []
    const missingIds = participantSendMessage.missingIds || []

    if (missingIds.length) {
      missingIds.forEach(id => {
        $('tag[title=\'' + id + '\']').addClass('border-red')
      })
    }

    if (freshParticipantIds.length) {
      freshParticipantIds.forEach(id => {
        $('tag[title=\'' + id + '\']').addClass('border-red')
      })
    }

    setErrorState({
      missingIds,
      freshParticipantIds,
      errorMessage,
    })
  }, [
    participantSendMessage.errors,
    participantSendMessage.fresh_participant_ids,
    participantSendMessage.missingIds
  ])

  const dispatch = useDispatch()

  useEffect(() => {
    if (input.current) {
      const tagify = new Tagify(input.current, {
        delimiters: /[,\s|\r\n]+/,
        pattern: VALID_UID_REGEX,
        editTags: {
          keepInvalid: false
        },
        skipInvalid: true,
        transformTag: (tag) => {
          tag.value = tag.value.replace(/[^pa-f0-9]/g, '')
          tag.__isValid = VALID_UID_REGEX.test(tag.value) && !tagify.isTagDuplicate(tag.value)
        }
      })
      setTagify(tagify)
      tagify.on('add', (e) => {
        setParticipantIDs((ids) => {
          if (e.detail.data.value.includes('\n')) {
            return [...ids]
          } else {
            return [...ids, e.detail.data.value]
          }
        })
      })
      tagify.on('edit:updated', e => {
        const {data, tag} = e.detail
        const newValue = data.value
        const oldValue = tag.__tagifyTagData.__originalData.value

        if (oldValue !== newValue) {
          setParticipantIDs(ids => {
            const index = ids.findIndex(id => id === oldValue)
            return [...ids.slice(0, index), newValue, ...ids.slice(index + 1)]
          })
        }
      })
      tagify.on('remove', (e) => {
        setParticipantIDs((ids) => {
          setErrorState(error => {
            const removedId = e.detail.data.value
            const missingIds = error.missingIds.filter(id => id !== removedId)
            const freshParticipantIds = error.freshParticipantIds.filter(id => id !== removedId)
            let errorMessage = ''

            if (missingIds.length > 0) {
              errorMessage = `These IDs were invalid: ${missingIds.join(', ')}`
            } else if (freshParticipantIds.length > 0) {
              errorMessage = error.errorMessage.replace(/Participant ID\(s\):.*$/, `Participant ID(s): ${freshParticipantIds.join(', ')}`)
            }

            return {
              missingIds,
              freshParticipantIds,
              errorMessage
            }
          })
          return ids.filter(id => id !== e.detail.data.value)
        })
      })
    }

  }, [])

  let validations = {
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  }

  if (!props.participant_id) {
    validations = {
      ...validations,
      participant_ids: Yup.string().required('You must include participant IDs'),
    }
  }

  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
      participant_ids: '',
    },
    validationSchema: Yup.object(validations),
    onSubmit: (values) => {
      if (formik.isValid) {
        let message = {
          subject: values.subject,
          message: values.message,
          participant_ids: props.participant_id ? [props.participant_id] : participantIDs,
        }

        if(props.projectId) {
          if(props.participant_id) {
            message.run_id = props.run_id
          } else {
            message.project_id = props.projectId
            message.platform_id = props.platformId
          }
        } else {
          message.run_id = props.runId
        }

        dispatch(participantSendMessageSubmit())
        dispatch(participantSendMessageFetch(message))
      }
    },
  })

  const resetSendMessageForm = () => {
    formik.resetForm()
    setParticipantIDs([])
    if (tagify !== null) {
      tagify.removeAllTags()
    }
  }

  const clearParticipantIds = (e) => {
    e.preventDefault()
    setParticipantIDs([])
    participantIDs.forEach(id => {
      tagify.removeTag(id)
    })
  }

  const clearInvalidParticipantIds = (e) => {
    e.preventDefault()
    participantIDs.forEach(id => {
      if (errorState.errorMessage.includes(id)) {
        tagify.removeTag(id)
      }
    })
  }

  useEffect(() => {
    if (participantSendMessage.status === 'success') {
      resetSendMessageForm()
      props.closePopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantSendMessage.status])

  function handleCancel(e) {
    e.preventDefault()
    resetSendMessageForm()
    props.closePopup()
  }

  return (
    <span>
      <div id="message-popup" className="white-popup mfp-hide translate-y-35">
        <h2 className="message-header">
          {!props.participant_id
            ? 'Send message to participants'
            : `Send message to participant ${props.participant_id}`}
        </h2>
        <form
          name="messageForm"
          className="wrap_info contact-form"
          onSubmit={formik.handleSubmit}>

          {errorState.errorMessage.length > 0 && <FormErrors errors={[errorState.errorMessage]}/>}

          <div className="wrap_item">
            <label className="text-required">Subject</label>
            <input
              name="subject"
              type="text"
              maxLength="150"
              onChange={formik.handleChange}
              value={formik.values.subject}
              className="card_number"/>
              <div className="wrap_info_form">
                <CharsLeft max={150} chars={formik.values.subject} label="Subject"/>
              </div>
            {formik.touched.subject && formik.errors.subject && (
              <p className="po-text text-red-soft">
                {formik.errors.subject}
              </p>)}
          </div>
          <div className={`wrap_select ${props.participant_id ? 'd-none' : ''}`}>
            <label className="text-required">Participant IDs</label>
            <input
              name="participant_ids"
              placeholder="Add participant IDs"
              ref={input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="message-ids"
            />
            {(errorState.freshParticipantIds.length > 0 || errorState.missingIds.length > 0) && (
              <p className="float-right link-visited po-text">
                <a href="/" className="cursor-pointer" onClick={clearInvalidParticipantIds}>
                  Remove invalid participants
                </a>
              </p>
            )}
            {participantIDs.length > 0 && errorState.freshParticipantIds.length === 0 && errorState.missingIds.length === 0 && (
              <p className="float-right link-visited po-text">
                <a href="/" className="cursor-pointer" onClick={clearParticipantIds}>
                  Clear
                </a>
              </p>
            )}
            <div className="po-text">You can copy/paste multiple IDs separated by newline </div>
            {formik.touched.participant_ids && formik.errors.participant_ids && (
              <p className="po-text text-red-soft">
                {formik.errors.participant_ids}
              </p>
            )}
            {(errorState.missingIds.length > 0 || errorState.freshParticipantIds.length > 0) && (
              <p className="po-text text-red-soft">
                No message has been sent. Please fix or remove the invalid IDs before continuing.
              </p>
            )}
          </div>
          <div className="wrap_item vertical-space-md">
            <label className="text-required">Message</label>
            <textarea
              name="message"
              onChange={formik.handleChange}
              maxLength="3500"
              className="message"
              value={formik.values.message}>
            </textarea>
              <div className="wrap_info_form">
                <CharsLeft max={3500} chars={formik.values.message} label="Message"/>
              </div>
            {formik.touched.message && formik.errors.message && (
              <p className="po-text text-red-soft">
                {formik.errors.message}
              </p>)}
          </div>
          <div className="wrap_link flex-row">
            <button
              id="send-message-button"
              type="submit"
              className="btn btn-primary clickable mr-3 no-border">
                {participantSendMessage?.isLoading ? 'Sending message...' : 'Send message'}
            </button>
            <button type="button" className="btn btn-default clickable" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </span>
  )
}

export default SendMessage
