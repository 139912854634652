import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import FormErrors from '../../FormErrors'

// Redux actions
import {runFormCreateAttrInit, runFormCreateAttrFetch, runFormCreateAttrReset} from '../../../redux/actions'

const $ = window.$

const CreateAttribute = props => {
  // Variables
  const [caType, setCaType] = useState('text')
  const dispatch = useDispatch()

  // Event handlers
  const createAttribute = (e) => {
    e.preventDefault()
    dispatch(runFormCreateAttrInit())
    dispatch(runFormCreateAttrFetch(props.attributeName, caType))
  }

  const closePopup = (e) => {
    e.preventDefault()

    if (props.clearSelection)
      props.clearSelection()

    dispatch(runFormCreateAttrReset())
    $.magnificPopup.close()
  }

  // Side effects
  useEffect(() => {
    if (props.status === 'success') {
      setCaType('text')
      dispatch(runFormCreateAttrReset())
      $.magnificPopup.close()
    }
  }, [dispatch, props.status])

  return (
    <div className="white-popup mfp-hide" id="new-ca-popup">
      <h2>
        <span style={{color: 'rgba(35, 53, 57, 0.7)'}}>
          Create attribute:&nbsp;
        </span>
        {props.attributeName}
      </h2>
      <form className="right_form wrap_radio wrap_info po-text" name="newCaForm">
        {props.errors && <FormErrors errors={props.errors}/>}
        <div className="title_form">What type of attribute is this?</div>
        <label className="mt-2">
          <input
            name="filterType"
            onChange={() => setCaType('text')}
            type="radio"
            checked={caType === 'text'}
            value="text"/>
          <span>Text</span>
        </label>
        <label className="mt-2">
          <input
            name="filterType"
            onChange={() => setCaType('numeric')}
            type="radio"
            checked={caType === 'numeric'}
            value="numeric"/>
          <span>Numeric</span>
        </label>
        <div className="wrap_link mt-4">
          <button
            id="attribute-btn"
            className={`btn btn-primary no-border clickable ${props.isLoading && 'btn-grey cursor-disabled'}`}
            onClick={createAttribute}
            disabled={props.isLoading}
          >
            {props.isLoading ? 'Please wait...' : 'Create'}
          </button>
          <button
            type="button"
            className="btn btn-default cancel clickable"
            onClick={closePopup}
            disabled={props.isLoading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  if (!state.createAttribute)
    return {}

  return {
    isLoading: state.createAttribute.isLoading || false,
    status: state.createAttribute.status || '',
    errors: state.createAttribute.errors || null,
  }
}

export default connect(mapStateToProps)(CreateAttribute)
