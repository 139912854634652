import React from 'react'
import {connect} from 'react-redux'

import {
  sentMessagesInit,
  sentMessagesFetch
} from '../redux/actionss/runActions'

const $ = window.$

const Message = props => {
  return (
    <div className="row infinitely-loaded sent-message">
      <div className="col-md-3 pr-3">
        {props.subject}
      </div>
      <div className="col-md-6 pr-3">
        {props.body}
      </div>
      <div className="col-md-3">
        {props.sent_on}
      </div>
    </div>
  )
}

const SentMessages = props => {
  const openSentMessagePopup = (e) => {
    e.preventDefault()
    props.sentMessagesInit()
    const params = {
      participant_id: props.participant_id
    }

    if(props.projectId){
      params.project_id = props.projectId
    } else {
      params.run_id = props.run_id
    }

    props.sentMessagesFetch(params)
    $.magnificPopup.open({
      items: {
        src: `#messages-sent-popup-${props.assignment_id}`, type: 'inline'
      }
    })
  }

  const closeSendMessagesPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  return (
    <>
      <li className="cursor-pointer dropdown-item" onClick={openSentMessagePopup}>
        <a
          href="/"
          className="popup-content relative"
          data-mfp-src="#messages-sent-popup"
        >
          <i className="fa fa-envelope fa-icons-dropdown"/>
          Sent Messages
        </a>
      </li>

      <div className="wrap_text">
        <div className="white-popup mfp-hide translate-y-35" id={`messages-sent-popup-${props.assignment_id}`}>
          <h2 className="message-header">
            Messages sent to participant {props.participant_id}
          </h2>

          {props.messages?.length > 0 && (
            <div className="wrap_info">
              <div className="panel panel-default wrap_projects no-shadow no-margin">
                <div className="panel-body project_detail">
                  <div className="row list-item list-heading">
                    <div className="col-md-3 pr-3">Subject</div>
                    <div className="col-md-6 pr-3">Message</div>
                    <div className="col-md-3">Sent on</div>
                  </div>
                  {props.messages?.map(message => <Message key={message.id} {...message}/>)}
                </div>
              </div>
            </div>
          )}

          {props.errors && (
            <div className="wrap_info text-danger">
              {props.errors.join(', ')}
            </div>
          )}

          {props.isLoading && (
            <div className="wrap_info">
              <div className="spinner vertical-space-md"/>
            </div>
          )}

          {props.messages?.length === 0 && !props.isLoading && (
            <div className="wrap_info p-4 text-orange">
              No message sent to this participant.
            </div>
          )}

          <div className="pb-3 mt-3 pl-3">
            <button
              className="btn btn-primary clickable mr-3 no-border"
              onClick={closeSendMessagesPopup}
              type="button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.sentMessages
})

const mapDispatchToProps = dispatch => ({
  sentMessagesInit: () => dispatch(sentMessagesInit()),
  sentMessagesFetch: (params) => dispatch(sentMessagesFetch(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(SentMessages)
