import React from 'react'
import Select from 'react-select'

const ActivityLanguageSelect = (props) => {
  return (
    <div className={props.className}>
      <label>
        <h2>
          Activity language
          <span className="text-required"/>

          {props.showTooltip && (
            <div className="d-inline-block po-tooltip activity-language-tooltip ml-1">
                <span className="po-tooltiptext po-tooltip-position">
                  As part of Positly's quality controls,
                  we screen participants for English comprehension for studies conducted in English.
                  For studies conducted in all other languages, Positly does not screen for English comprehension,
                  and we recommend researchers include language screening questions as needed within their activity.
                </span>
            </div>
          )}
        </h2>
      </label>
      <Select
        name="activity_language_id"
        className="full-width run-country"
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        options={props.options}
        noOptionsMessage={() => 'No results found'}
        placeholder="Select activity language"
        classNamePrefix="react-select"
        isSearchable
        styles={{
          container: styles => ({
            ...styles,
            width: '100%',
            borderRadius: 4,
            backgroundColor: '#fff',
            appearance: 'none',
            color: 'var(--input-text-color)',
            fontSize: 14,
            letterSpacing: -0.4,
            fontFamily: 'Muli-SemiBold',
          }),
          valueContainer: (styles) => ({...styles, padding: '0 0 0 20px'}),
          control: (styles) => ({...styles, border: '1px solid #e2e9ef'}),
          indicatorSeparator: () => ({display: 'none'}),
          input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
          singleValue: (styles) => ({
              ...styles,
              color: 'var(--input-text-color)',
              width: 'calc(100% - 20px)',
          }),
          menuList: (styles) => ({...styles, maxHeight: 200}),
          option: (styles) => ({...styles, paddingLeft: 20}),
          noOptionsMessage: (styles) => ({...styles, paddingLeft: 20, textAlign: 'left'}),
          placeholder: (styles) => ({
              ...styles,
              color: 'var(--placeholder-color)',
          }),
        }}
      />
    </div>
  )
}

export default ActivityLanguageSelect
