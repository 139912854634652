import React from 'react'

export default function FormErrors(props) {
  const {errors, className} = props

  return (
    <div>
      {errors?.length > 0 && (
        <p className={`po-text error ${className ? className : ''}`}>
          {errors.join(', ')}
        </p>
      )}
    </div>
  )
}
