import React from 'react'
import {connect} from 'react-redux'

import swal from 'sweetalert'

import {runIncreaseVisibilityFetch} from '../../redux/actionss/runActions'

const IncreaseVisibility = (props) => {
  const increaseVisibility = (event) => {
    event.preventDefault()
    swal({
      title: 'Are you sure you want to increase visibility for the run?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {text: 'Increase visibility now', className: 'btn-primary'}
      }
    })
      .then((value) => {
        if (value) {
          props.runIncreaseVisibilityFetch(props.id)
        }
      })
  }

  return (
    <li className='cursor-pointer dropdown-item' onClick={increaseVisibility}>
      <span
        id="btn-run-increase-visibility">
        <i className="fa fa-play fa-fw"/>
          &nbsp;Increase Visibility Now
      </span>
    </li>
  )
}

const mapStateToProps = state => ({...state.runIncreaseVisibility})

const mapDispatchToProps = dispatch => ({
  runIncreaseVisibilityFetch: (id) => dispatch(runIncreaseVisibilityFetch(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(IncreaseVisibility)
