import React, {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {cloneDeep, isEqual} from 'lodash'

import {errorToast} from '../../helpers/notification'
import {handleScrollToQuota} from './RunUtils'
import Checkbox from '../../common/Checkbox'

const DemographicCheckboxFilter = (props) => {
  const filterId = props.filter.id
  const quotaId = `quota_${filterId}`
  const cintQuotas = useSelector(state => state.runFormOverview.cint_quotas) || []
  const quota = cintQuotas.find(quota => quota.id === filterId)

  const filterRef = useRef(null)
  const quotaRef = useRef(null)

  const className = `box gender education custom-filter ${props.className || ''}`

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.add('highlight-filter')
    setTimeout(() => {
      if (!filterRef.current) return

      filterRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  // Init
  useEffect(() => {
    let updatedFilter = {...props.filter, is_filter_valid: true}
    updateFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Event handler
  const handleCheckboxChange = (e) => {
    const selectedOptionsCount = props.filter.filter_options.filter(filterOptions => filterOptions.is_checked).length

    if (selectedOptionsCount > 14 && e.target.checked) {
      e.preventDefault()
      e.stopPropagation()
      errorToast('Maximum 15 options allowed!')
      return
    }

    const updateFilterOptions = props.filter.filter_options.map(filterOption => {
      // Note: Here deep copy is necessary, else original object will get modified
      let updateOption = {...filterOption}
      if (updateOption.title === e.target.name) {
        updateOption.is_checked = e.target.checked
      }

      return updateOption
    })

    const updatedFilter = {
      ...props.filter,
      filter_options: updateFilterOptions,
      is_filter_valid: true
    }
    updateFilter(updatedFilter)
  }

  const updateFilter = (filter) => {
    if (filter.id && filter.id.includes('cint')) {
      props.updateFilter(filter)
    } else {
      props.updateFilter('demographic', filter)
    }
  }

  const removeFilter = (filter) => {
    if (!filterRef.current) return

    filterRef.current.classList.add('remove-filter')
    setTimeout(() => {
      if (filter.id && filter.id.includes('cint')) {
        props.removeFilter(filter.id)
      } else {
        props.removeFilter('demographic', filter.name)
      }
    }, 210)
  }

  const formattedQuestionText = (filter) => {
    if (filter.id && filter.id.includes('cint')) {
      return filter.question_text
    } else {
      return 'Based on the question: ' + filter.question_text
    }
  }

  useEffect(() => {
    const isQuotaModified = !isEqual(quota, quotaRef.current)
    if (!quota || !isQuotaModified) return

    quotaRef.current = cloneDeep(quota)

    const updatedFilterOptions = props.filter.filter_options.map(filterOption => {
      // Note: Here deep copy is necessary, else original object will get modified
      let updatedOption = cloneDeep(filterOption)
      const option = quota.options.find(option => option.id === filterOption.title)
      updatedOption.is_checked = option.participants > 0

      return updatedOption
    })

    const updatedFilter = {
      ...props.filter,
      filter_options: updatedFilterOptions,
      is_filter_valid: true
    }
    updateFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quota])

  return (
    <div className={className} ref={filterRef}>
      <h2 className="big mb-2">
        {props.filter.filter_label}{' '}
        <span className="float-right">
          <i
            className="ion-close cursor-pointer"
            onClick={() => removeFilter(props.filter)}
          />
        </span>
      </h2>

      {quota ? (
        <div className="notice notice-border mb-2">
          <span>
            This filter is now being overwritten by a quota. You can configure the quota{' '}
            <a
              className="cursor-pointer text-underline text-dark"
              href="/"
              onClick={handleScrollToQuota}
              data-quota-id={quotaId}
            >
              here
            </a>
            .
          </span>
        </div>
      ) : null}

      <div className="wrap_info_form help-text">
        <span>{formattedQuestionText(props.filter)}</span>
      </div>

      <div className="wrap_info mt-3">
        <div className="wrap_item">
          <div className="radio-toolbar po-demographic-checked">
            {props.filter.filter_options.map((filterOption, index) =>
              <label key={filterOption.title}>
                <Checkbox
                  name={filterOption.title}
                  className="wid-auto cursor-pointer"
                  checked={filterOption.is_checked}
                  onChange={handleCheckboxChange}
                  disabled={quota}
                  data-is-checked={filterOption.is_checked ? 'true' : 'false'}
                />
                <span>{filterOption.label}</span>
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemographicCheckboxFilter
