import {platformActions} from '../actionss/platformActions'

const platformReducer = (state, action) => {
  switch (action.type) {
    case platformActions.PLATFORM_LIST_INIT:
      return {...state, platformList: {isLoading: true}}
    case platformActions.PLATFORM_LIST_SUCCESS:
      return {
        ...state,
        platformList: {isLoading: false, platforms: action.platforms, availablePlatforms: action.platforms}
      }
    case platformActions.PLATFORM_LIST_ERROR:
      return {...state, platformList: {isLoading: false, errors: action.errors}}

    case platformActions.PLATFORM_ADD_INIT:
      return {...state, platformAdd: {}}
    case platformActions.PLATFORM_ADD_SUBMIT:
      return {...state, platformAdd: {isLoading: true}}
    case platformActions.PLATFORM_ADD_SUCCESS:
      return {...state, platformAdd: {isLoading: false, platform: action.platform}}
    case platformActions.PLATFORM_ADD_ERROR:
      return {...state, platformAdd: {isLoading: false, errors: action.errors}}
    
    case platformActions.PLATFORM_DELETE_INIT:
      return {...state, platformDelete: {isLoading: true}}
    case platformActions.PLATFORM_DELETE_SUCCESS:
      let platforms = state.platformList.platforms
      platforms = platforms.filter((p) => (p.id !== action.id))

      let availablePlatforms = state.platformList.availablePlatforms
      availablePlatforms = availablePlatforms.filter((p) => (p.id !== action.id))

      return {
        ...state,
        platformList: {
          platforms: platforms,
          availablePlatforms: availablePlatforms,
          isLoading: false,
          deleteStatus: 'success'
        }
      }
    case platformActions.PLATFORM_DELETE_ERROR:
      return {...state, platformList: {isLoading: false, errors: action.errors}}

    default:
      return state
  }
}

export default platformReducer
