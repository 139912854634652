import React,{useRef, useEffect}from 'react'
const $ = window.$

const CircleProgress = (props) => {
  const ref = useRef()

  useEffect( () => {
    if (ref.current) {
      $(ref.current).circleProgress({
        value: props.percent / 100,
        startAngle: -1.55,
        size: props.size,
        thickness: 5,
        fill: {color: props.color}
      })
    }
  }, [props.size, props.color, props.percent])

  return (
    <div className="circle-progress" ref={ref}/>
  )
}

export default CircleProgress
