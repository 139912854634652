import React, {useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import PasswordVisibilityToggler from './common/PasswordVisibilityToggler'

import {
  passwordChangeInit,
  passwordChangeSubmit,
  passwordChangeFetch
} from '../redux/actionss/userActions'

const $ = window.$

const ChangePassword = props => {
  const [showPopup, setShowPopup] = useState(false)
  const currentPasswordRef = useRef()
  const newPasswordRef = useRef()

  const openPopup = (e) => {
    e.preventDefault()
    formik.resetForm()
    props.passwordChangeInit()
    setShowPopup(true)
    setTimeout(() => {
      $.magnificPopup.open({
        items: {src: '#text-popup'},
        modal: true
      })
    }, 50)
  }

  const closePopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
    setShowPopup(false)
  }

  const formik = useFormik({
    initialValues: {
      current_password: '',
      new_password: ''
    },
    validationSchema: Yup.object({
      current_password: Yup.string()
        .required('Current password is required')
        .min(8, 'Minimum 8 characters'),
      new_password: Yup.string()
        .required('New password is required')
        .min(8, 'Minimum 8 characters')
        .notOneOf([Yup.ref('current_password')], 'Current password and new password cannot be same')
    }),
    onSubmit: values => {
      if (formik.isValid) {
        props.passwordChangeSubmit()
        props.passwordChangeFetch(values)
      }
    }
  })

  useEffect(() => {
    if (props.status === 'success') {
      setShowPopup(false)
      $.magnificPopup.close()
      props.passwordChangeInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  return (
    <div>
      <div className="wrap_label">
        <label className="text-required">Password</label>
        <div className="wrap_pass">
          <input
            type="password"
            disabled="disabled"
            placeholder="* * * * * * * *"
          />
          <a
            href="/"
            style={{cursor: 'pointer'}}
            className="pass popup-content cursor-pointer"
            onClick={openPopup}
          >
            Change Password
          </a>
        </div>
      </div>

      {showPopup && (
        <div id="text-popup" className="white-popup mfp-hide">
          <h2>Change Password</h2>
          <form
            name="changePasswordForm"
            onSubmit={formik.handleSubmit}
            className="wrap_info"
          >
            <div className="wrap_item">
              <label className="text-required">Current password</label>
              <div className="input-group">
                <input
                  ref={currentPasswordRef}
                  type="password"
                  name="current_password"
                  autoComplete="new-password"
                  id="current_password"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.current_password}
                />
                <PasswordVisibilityToggler input={currentPasswordRef.current}/>
              </div>
              {formik.touched.current_password && formik.errors.current_password
                ? <p className="po-text text-red-soft mt-1">{formik.errors.current_password}</p>
                : null
              }
            </div>

            <div className="wrap_item">
              <label className="text-required">New password</label>
              <div className="input-group">
                <input
                  ref={newPasswordRef}
                  type="password"
                  name="new_password"
                  autoComplete="new-password"
                  id="new_password"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.new_password}
                />
                <PasswordVisibilityToggler input={newPasswordRef.current}/>
              </div>
              {formik.touched.new_password && formik.errors.new_password
                ? <p className="po-text text-red-soft mt-1">{formik.errors.new_password}</p>
                : null
              }
            </div>

            <p className="help-block">Minimum 8 characters</p>

            <div className="wrap_link">
              <button
                className="btn btn-primary no-border clickable"
                disabled={props.isLoading}
              >
                {props.isLoading ? 'Please wait...' : 'Change Password'}
              </button>
              <button
                type="button"
                onClick={closePopup}
                className="btn btn-default cansel clickable"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.changePassword
})

const mapDispatchToProps = dispatch => ({
  passwordChangeInit: () => dispatch(passwordChangeInit()),
  passwordChangeSubmit: () => dispatch(passwordChangeSubmit()),
  passwordChangeFetch: (password) => dispatch(passwordChangeFetch(password))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
