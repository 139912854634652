import React from 'react'


const ErrorFallback = () => {
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="box po-text po-text-danger">
          <h3 className="mb-3">
            We are sorry but something went wrong. Try refreshing the page. If the issue persist, please contact us at {' '}
            <a href="mailto:hi@positly.com" className="pii-link cursor-pointer">hi@positly.com</a>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
