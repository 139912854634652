import React, {useEffect} from 'react'

// 3rd party
import {connect} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import swal from 'sweetalert'

// React components
import AddCredit from '../AddCredit'
import CreditCard from '../CreditCard'
import UpdateSpendingLimit from '../UpdateSpendingLimit'
import Spinner from '../common/Spinner'
import Currency from '../common/Currency'
import TransactionFilter from './TransactionFilter'

// Redux Actions
import {
  transactionListFetch,
  transactionListInit,
  transactionPendingListFetch,
  transactionPendingListInit,
} from '../../redux/actions'
import {getDownloadURL} from '../common/Helpers'

const $ = window.$

const TransactionListItem = (props) => {
  const isPending = props.isPending
  const isDebit = () => (props.transaction_type === 'debit' || isPending)

  return (
    <div className="row list-item transactions infinitely-loaded col-gap">
      <div className="col-md-1">
          <span className="list-item-label po-text-bold pr-1">Date :</span>
        <p>
          {isPending ? '' : props.created_at_formatted}
        </p>
      </div>
      <div className="col-md-2">
          <span className="list-item-label po-text-bold pr-1">Project:</span>
        <p>
          {props.project_title}
        </p>
        <p>
          {props.project_id}
        </p>
      </div>
      <div className="col-md-3">
          <span className="list-item-label po-text-bold pr-1">Run:</span>
        <p>
          {props.run_title}
        </p>
        <p>
          {props.run_uid}
        </p>
      </div>
      <div className="col-md-1 text_center">
        {props.participant_id && (
          <span className="list-item-label po-text-bold pr-1">Participant:</span>
        )}
        <p>
          {props.participant_id}
        </p>
      </div>
      <div className="col-md-2 word-break-word">
          <span className="list-item-label po-text-bold pr-1">Description:</span>
        <p>
          {isPending ? 'Pending assignments' : props.description}
        </p>
      </div>
      <div
        className={`col-md-1 text-center ${isDebit() ? 'text-danger' : 'text-success'}`}>
          <span className="list-item-label .text-left po-text-bold pr-1">Amount:</span>
        <p>
          <Currency
            value={parseFloat(props.amount_formatted.split('$').pop())}
            symbol={isDebit() ? '-$' : '+$'}
          />
        </p>
      </div>
      <div className="col-md-1 text-center">
          <span className="list-item-label po-text-bold pr-1">Balance:</span>
        <p>
          {isPending ? 'Pending' : <Currency value={parseFloat(props.effective_cb_formatted.split('$').pop())}/>}
        </p>
      </div>
    </div>
  )
}

const TransactionList = (props) => {
  const todayDownloadPath = '/transactions/download?start_date=today&end_date=today'
  const yesterdayDownloadPath = '/transactions/download?start_date=yesterday&end_date=yesterday'
  const last7DaysDownloadPath = '/transactions/download?start_date=last_7days&end_date=last_7days'
  const last30DaysDownloadPath = '/transactions/download?start_date=last_30days&end_date=last_30days'

  useEffect(() => {
    props.transactionPendingListInit()
    props.transactionPendingListFetch()
    props.transactionListInit()
    props.transactionListFetch(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTransactions = () => props.transactionListFetch(props.pageNumber + 1, props.startDate, props.endDate)

  return (
    <div>
      <div className="row wrap_projects billing_table">
        <div className="col-xs-12 col-sm-12">
          <div className="panel-body pb-1">
            <div className="wrap_title">
              <h2>
                Transactions
              </h2>

              {!props.isLoading && (
                <div className="dropdown d-flex justify-content-between align-items-center user_block download">
                  <div className="wrap_img_user">
                    {(props.transactions?.length > 0 || props.isFilterSelected) && <TransactionFilter/>}
                    {props.transactions?.length > 0 && !props.isFilterSelected && (
                      <span>
                        <a
                          href="/"
                          id="btn-csv-download"
                          className="dropdown-toggle btn down btn-download cursor-pointer"
                          data-toggle="dropdown"
                        >
                          Download
                        </a>
                        <ul
                          aria-labelledby="btn-csv-download"
                          className="dropdown-menu dropdown-menu-right dropdown-menu-billing action dropdown-toggle"
                          role="menu"
                        >
                          <li>
                            <a
                              href={getDownloadURL(todayDownloadPath)}
                            >
                              Today
                            </a>
                          </li>
                          <li>
                            <a
                              href={getDownloadURL(yesterdayDownloadPath)}
                            >
                              Yesterday
                            </a>
                          </li>
                          <li>
                            <a
                              href={getDownloadURL(last7DaysDownloadPath)}
                            >
                              Last 7 days
                            </a>
                          </li>
                          <li>
                            <a
                              href={getDownloadURL(last30DaysDownloadPath)}
                            >
                              Last 30 days
                            </a>
                          </li>
                          <li>
                            <a href={getDownloadURL('/transactions/download')}>
                              All
                            </a>
                          </li>
                        </ul>
                      </span>
                    )}

                    {props.transactions?.length > 0 && props.isFilterSelected && (
                      <a
                        className="btn down btn-download cursor-pointer"
                        href={getDownloadURL(`/transactions/download?start_date=${props.startDate}&end_date=${props.endDate}`)}
                      >
                        Download
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>

            {!props.isLoading && (
              <>
                <div>
                  {props.transactions?.length > 0 && (
                    <div className="row list-item list-heading col-gap">
                      <div className="col-md-1">
                        Date
                      </div>
                      <div className="col-md-2">
                        Project
                      </div>
                      <div className="col-md-3">
                        Run
                      </div>
                      <div className="col-md-1">
                        Participant
                      </div>
                      <div className="col-md-2">
                        Description
                      </div>
                      <div className="col-md-1 text-center">
                        Amount
                      </div>
                      <div className="col-md-1 text-center">
                        Balance
                      </div>
                    </div>
                  )}
                </div>

                {props.transactions?.length === 0 && (
                  <div className="project_block no_data no-data-found">
                    <div className="wrap_top">
                      <p>
                        No transactions found
                      </p>
                    </div>
                  </div>
                )}

                {!props.isFilterSelected && props.transactionsPending?.map((transaction, index) =>
                  <TransactionListItem isPending={true} key={index} {...transaction} />
                )}

                <InfiniteScroll
                  dataLength={props.transactions ? props.transactions.length : 0}
                  next={fetchTransactions}
                  hasMore={props.hasMore}
                  loader={<Spinner/>}>
                  {props.transactions?.map(transaction =>
                    <TransactionListItem key={transaction.id} {...transaction} />
                  )}
                </InfiniteScroll>
              </>
            )}

            {props.isLoading && <Spinner/>}
          </div>
        </div>
      </div>
    </div>
  )
}

const UserBilling = (props) => {
  useEffect(() => {
    return () => {
      $.magnificPopup?.close()
      if (swal.getState()?.isOpen) {
        swal.close()
      }
    }
  }, [])

  return (
    <div>
      <div className="metrics project_detail">
        <div className="wrap_title d-flex">
          <h1>
            Billing
          </h1>
        </div>
      </div>

      <div className="billing_page ng-scope">
        <div className="row">
          <AddCredit/>
          <UpdateSpendingLimit/>
          <CreditCard/>
        </div>
      </div>

      <TransactionList {...props}/>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.transactionPendingList,
    ...state.transactionList,
    isLoading: state.transactionPendingList?.isLoading || state.transactionList?.isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  transactionPendingListInit: () => dispatch(transactionPendingListInit()),
  transactionPendingListFetch: () => dispatch(transactionPendingListFetch()),
  transactionListInit: () => dispatch(transactionListInit()),
  transactionListFetch: (pageNumber, startDate, endDate) => {
    dispatch(transactionListFetch(pageNumber, startDate, endDate))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBilling)
