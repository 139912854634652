import React from 'react'
import ReactTooltip from 'react-tooltip'

const TruncateText = (props) => {
  const text = props.text

  return (
    <>
      {text?.length > props.max && (
        <span data-tip={text}>
          {text.substring(0, props.max) + '...'}
          <ReactTooltip className="react-tooltip-po" place="top" effect="solid" arrowColor="#555"/>
        </span>
      )}

      {text?.length <= props.max && (
        <span>{text}</span>
      )}
    </>
  )
}

export default TruncateText
