import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'

import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

import FormErrors from '../FormErrors'
import FormField from '../common/FormField'
import {routes} from '../../routes'

import {
  userRequestPasswordInit,
  userRequestPasswordSubmit,
  userRequestPasswordFetch
} from '../../redux/actionss/userActions'

const RequestPassword = (props) => {
  const queryParams = qs.parse(props.location?.search, {ignoreQueryPrefix: true})
  const gtDataId = queryParams.gt_data_id
  const signInLink = gtDataId ? `${routes.SIGN_IN}?gt_data_id=${gtDataId}` : routes.SIGN_IN

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address')
    }),
    onSubmit: values => {
      props.userRequestPasswordSubmit()
      props.userRequestPasswordFetch(values)
    }
  })

  useEffect(() => {
    props.userRequestPasswordInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.currentUser) {
    return <Redirect to="/projects/list"/>
  }

  const errors = ['We could not find an account with this email.']

  return (
    <div className="request-password-box">
      {!props.status && (
        <div className="d-flex metrics project_detail justify-content-center m-0">
          <div className="wrap_title d-flex mt-0">
            <h1>Get New Password</h1>
          </div>
        </div>
      )}
      {!props.status && (
        <div className="d-flex add_platform justify-content-center">
          <div className="col-lg-6">
            <div className="panel panel-default">
              <div className="panel-body">
                <form onSubmit={formik.handleSubmit} className="contact-form">
                  {props.errors?.length && <FormErrors errors={props.errors[0] === "Resource not found" ? errors : props.errors}/>}
                  <FormField
                    label="Email"
                    id="email"
                    type="text"
                    className="form-control po-text"
                    required={true}
                    formik={formik}/>
                  <div className="wrap_link mt-3">
                    <button
                      className={`btn btn-primary no-border cursor-pointer ${props.isLoading ? 'btn-grey cursor-disabled' : ''}`}
                      id="btn-password-reset"
                      type="Submit"
                      disabled={props.isLoading}>
                      {props.isLoading ? 'Sending Password Reset Email...' : 'Send Password Reset Email'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-default clickable"
                      onClick={() => props.history.push(signInLink)}
                      disabled={props.isLoading}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.status && props.status === 'success' && (
        <div className="d-flex add_platform">
          <div className="w-100">
            <div className="panel panel-default">
              <div className="panel-body text-dark">
                <img alt="" src="../../images/p100.svg" className="mr-3"/>
                An email has been sent. Please follow the instructions in the email to reset your password.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    errors: state.requestPassword && state.requestPassword.errors,
    isLoading: state.requestPassword && state.requestPassword.isLoading,
    status: state.requestPassword && state.requestPassword.status
  }
}

const mapDispatchToProps = dispatch => ({
  userRequestPasswordInit: () => dispatch(userRequestPasswordInit()),
  userRequestPasswordSubmit: () => dispatch(userRequestPasswordSubmit()),
  userRequestPasswordFetch: (email) => dispatch(userRequestPasswordFetch(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPassword)
