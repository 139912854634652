import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Cookies from 'universal-cookie'
import qs from 'qs'

import ContextScreenoutMessage from './ContextScreenoutMessage'
import GenericScreenoutMessage from './GenericScreenoutMessage'
import {isCookieSupported, isLocalStorageSupported} from './common/Helpers'

import ScreenOutPlaceHolder from './run/enhanced-run-form/ScreenOutPlaceHolder'
import {markIneligibleFetch} from '../redux/actions'

const ScreenOut = ({location}) => {
  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})

  const dispatch = useDispatch()
  const cookies = new Cookies()

  const {
    isLoading,
    cintReturnUrl,
    errors
  } = useSelector(state => ({
    isLoading: state.cintRedirect?.isLoading,
    cintReturnUrl: state.cintRedirect?.cintReturnUrl,
    errors: state.cintRedirect?.error
  }))

  let assignmentId = ''
  let workerId = ''
  let isRecruiter = ''

  if (isLocalStorageSupported) {
    assignmentId = localStorage.getItem('assignment_id')
    workerId = localStorage.getItem('worker_id')
    isRecruiter = localStorage.getItem('is_recruiter')
  } else if (isCookieSupported) {
    assignmentId = cookies.get('assignment_id')
    workerId = cookies.get('worker_id')
    isRecruiter = cookies.get('is_recruiter')
  }

  const context = assignmentId
    ? queryParams.context ? atob(decodeURIComponent(queryParams.context)) : 'screenout'
    : null

  // Mark assigment as screenout
  useEffect(() => {
    if (!assignmentId || isRecruiter) return

    const params = {
      assignment_id: assignmentId,
      worker_id: workerId,
      reason: context
    }
    dispatch(markIneligibleFetch(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, assignmentId, isRecruiter])

  // Redirect to cint return url
  if (cintReturnUrl)
    window.location.href = cintReturnUrl

  if (isRecruiter) {
    return <ScreenOutPlaceHolder/>
  }

  // Show loading text during network request and redirection
  if (isLoading || cintReturnUrl) {
    return (
      <div className="row">
        <div className="box po-text-bold">
          <h3>Please wait....</h3>
        </div>
      </div>
    )
  }

  return (
    <div className={context ? 'row w-75 m-auto' : 'row'}>
      <div className="box">
        {context
          ? <ContextScreenoutMessage className="po-text p-4 text-center context-screenout-message" context={context}/>
          : <GenericScreenoutMessage errors={errors} isRecruiter={isRecruiter} assignmentId={assignmentId}/>
        }
      </div>
    </div>
  )
}

export default ScreenOut
