import React from 'react'

const Select = props => {
  return (
    <select {...props}>
      {props.options && props.options.length ?
        props.options.map(option => (
            typeof option === 'object' ? (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
              : (
                <option key={option} value={option}>
                  {option}
                </option>
              )
          )
        )
        : null}
    </select>
  )
}

export default Select
