import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import combinedReducer from './combinedReducer'

// Middlewares
const middlewares = applyMiddleware(thunk)

// Redux dev tools
const composeEnhancer = composeWithDevTools({
  name: 'Redux',
  realtime: true,
  trace: true,
  traceLimit: 25
})

// Disable redux dev tools in Production
const devTools = process.env.NODE_ENV === 'development' ? composeEnhancer(middlewares) : middlewares

const store = createStore(combinedReducer, devTools)

export {store}
