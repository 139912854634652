import React from 'react'
import {useSelector} from 'react-redux'

const SurveyEligibleParticipants = (props) => {
  const {isLoading, eligibleHits} = useSelector((state) => ({
    isLoading: state?.postSurvey?.eligibleHits?.isLoading,
    eligibleHits: state?.postSurvey?.eligibleHits?.runs
  }))

  return (
    <div className="run_detail_tabs progress">
      <div className="indicator bg-none box-shadow-none">
        <div className="row po-text">
          {props.subscriptionCompleted() && props.isMturk && (
            <div className="col">
              <h3>Thanks for participating!</h3>
              <div className="row">
                <div className={props.showReviewPanel ? 'col-md-6 pr-3' : ''}>
                  <p className="text-success">
                    {props.isScreeningEnabled
                      ? 'When your work is approved, you’ll be paid via bonus (this usually takes about 6 hours)'
                      : 'You must now submit this HIT on Mechanical Turk so that you can be paid.'
                    }
                  </p>
                </div>

                <div className="col-md-6">
                  {isLoading && (
                    <div className="eligible-hits mb-3">
                      <h3>Checking for eligible HITs...</h3>
                    </div>
                  )}

                  {(eligibleHits && eligibleHits.length > 0) && (
                    <div className="eligible-hits mb-3">
                      <h3>
                        Congratulations! You now qualify for the following activities:
                      </h3>
                      {eligibleHits.map((hit, index) => (
                        <div key={index} className="eligible-hits-item">
                          <div className="div mb-3">
                            <span className="text-dark">{hit.title}</span>
                            <span className="float-right">${hit.pay}</span>
                          </div>
                          <div className="div">
                            <a href={hit.preview_link} target="_blank" rel="noreferrer">
                              Open in new tab
                            </a>
                          </div>
                        </div>
                      ))}
                      <div className="notice">
                        In case if the HIT requires qualification, retry after 5-10 minutes. You should be qualified.
                        If not please contact us at hi@positly.com
                      </div>
                    </div>
                  )}

                  {props.showReviewPanel && (
                    <div className="review-panel">
                      <h3>Want to share rating of this HIT?</h3>
                      <p>
                        Feel free to rate us - we only ask that you {' '}
                        <span className="text-dark">
                          please keep the details of the study quiet
                        </span>
                        {' '}
                        so the study's validity isn't compromised. Thank you!
                      </p>
                      <div className="review-panel-item">
                        <span className="text-dark">TurkerView</span>
                        <a
                          className="float-right"
                          href="https://turkerview.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="pr-2" src="images/edit.svg"/>
                          Write a review
                        </a>
                      </div>
                      <div className="review-panel-item">
                        <span className="text-dark">Turkopticon</span>
                        <a
                          className="float-right"
                          href="https://turkopticon.ucsd.edu"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="pr-2" src="images/edit.svg"/>
                          Write a review
                        </a>
                      </div>
                      <div className="review-panel-item">
                        <span className="text-dark">Turkopticon 2</span>
                        <a
                          className="float-right"
                          href="https://turkopticon.info"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="edit icon" className="pr-2" src="images/edit.svg"/>
                          Write a review
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {!props.isMturk && <div className="col">
            <h3>
              Thanks for participating! Please wait while we redirect you to the Cint...
            </h3>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default SurveyEligibleParticipants
