import React, {useEffect} from 'react'

// 3rd party
import {connect, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import ReactTooltip from 'react-tooltip'
import Currency from './common/Currency'

// React components
import FormErrors from './FormErrors'
import Input from './common/Input'

// Redux Actions
import {
  creditAddInit,
  creditAddSubmit,
  creditAddFetch
} from '../redux/actions'
import {userAccountFetch} from '../redux/actionss/userActions'

const $ = window.$

const AddCredit = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userAccountFetch())
  }, [dispatch])

  function openAddCreditPopup() {
    formik.resetForm()
    props.creditAddInit()
    $.magnificPopup.open({
      items: {src: '#add_credit'},
      type: 'inline',
      modal: true
    })
  }

  function closeAddCreditPopup(e) {
    e.preventDefault()
    $.magnificPopup.close()
  }

  const formik = useFormik({
    initialValues: {
      amount: 15
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Amount is required')
        .min(15, 'Minimum amount is $15.00')
        .max(500, 'Maximum amount is $500.00')
        .typeError('')
    }),
    onSubmit: values => {
      if (formik.isValid) {
        props.creditAddSubmit()
        props.creditAddFetch(values)
      }
    }
  })

  const handleChange = (e, value) => {
    formik.setFieldValue(e.target.name, value)
  }

  if (props.credit) {
    $.magnificPopup.close()
  }

  return (
    <div className="item">
      <div className="credits">
        <div className="panel-heading">
          <h2 className="clear-margin">
            Credits
          </h2>
          {props.currentUser?.is_card_linked && (
            <button
              id="add-credit-popup"
              className="btn btn-primary popup-content"
              onClick={openAddCreditPopup}>
              Add Credit
            </button>
          )}
        </div>
        <table className="balance-table po-text">
          <tbody>
          <tr>
            <td className="brd-r-1">
              <label
                className="text-dark"
                data-tip="This is the amount of credit in your account that has been cleared. Once all pending credits have cleared this should be the same as your credits available."
                data-for="cb-tooltip"
              >
                <Currency value={props.currentUser.credit_balance_in_dollars}/>
              </label>
              <ReactTooltip className="react-tooltip-po" position="top" effect="solid" id="cb-tooltip" arrowColor="#555"/>
              <h2>
                Credit balance
              </h2>
            </td>
            <td>
              <label
                className="text-dark ng-binding"
                data-tip="This is the amount of credit in your account available to be used. This is lower than the credit balance in cases where there are pending credits."
                data-for="ca-tooltip"
              >
                <Currency value={props.currentUser.credits_unused_in_dollar}/>
              </label>
              <ReactTooltip className="react-tooltip-po" position="right" effect="solid" id="ca-tooltip" arrowColor="#555"/>
              <h2>
                Credits available
              </h2>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div id="add_credit" className="white-popup mfp-hide">
        <h2>Add Credit</h2>
        <form
          noValidate
          name="addCreditForm"
          className="wrap_info"
          onSubmit={formik.handleSubmit}
        >
          <div className="wrap_item">
            <FormErrors errors={props.errors}/>
            <label className="text-required">Credit amount</label>
            <div className="form-group input-group">
              <span className="billing-page-currency zindex-0">USD $</span>
              <Input
                name="amount"
                id="amount"
                type="text"
                className="billing_currency_input card_number"
                onChange={handleChange}
                value={formik.values.amount}
                allowOnlyNumbers
              />
            </div>
            {formik.errors.amount && (
              <p className="po-text text-red-soft">{formik.errors.amount}</p>
            )}
          </div>
          <div className="wrap_link flex-row">
            <button
              id="btn-new-credit"
              className="btn btn-primary refresh clickable no-border btn-add-card"
              disabled={props.isLoading}>
              {props.isLoading ? 'Adding...' : 'Add'}
            </button>
            <button
              id="add-new-credit"
              className="btn btn-default cansel clickable"
              onClick={closeAddCreditPopup}
              disabled={props.isLoading}>Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.creditAdd,
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  creditAddInit: () => dispatch(creditAddInit()),
  creditAddSubmit: () => dispatch(creditAddSubmit()),
  creditAddFetch: (credit) => dispatch(creditAddFetch(credit))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCredit)
