import React from 'react'

import ReactTooltip from 'react-tooltip'

import {successToast} from '../helpers/notification'
import {GA_EVENTS, sendGAEvent} from '../../utils/helper'
import {useSelector} from 'react-redux'

const CopyCode = ({label, textToCopy, setCodeCopied, className, tooltipText, isSendGAEvent}) => {
  const positly_user_id = useSelector(state => state.currentUser?.uid)

  const copyText = (event) => {
    event.preventDefault()

    if (isSendGAEvent) {
      sendGAEvent(GA_EVENTS.CLICK_COPY_CODE, {positly_user_id})
    }
    navigator.clipboard.writeText(textToCopy)
    if (typeof setCodeCopied === 'function') setCodeCopied(true)

    successToast('Code copied!')
  }

  return (
    <a
      className={className}
      href="/"
      style={{cursor: 'pointer'}}
      onClick={copyText}
      data-tip={tooltipText}
      data-testid="copy-code"
    >
      {label}
      <ReactTooltip className="react-tooltip-po" position="top" effect="solid" arrowColor="#555"/>
    </a>

  )
}

export default CopyCode
