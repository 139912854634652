import React from 'react'

const InterlockQuotaExample = () => {
  return (
    <>
      {/* Example Header */}
      <div className="row po-text mt-4 lh-1 px-4">
        <div className="col-md-5 align-center">
          <div className="bg-gray interlock-example-title">
            Example regular quotas <br/>
            (regular traits)
          </div>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-6 align-center">
          <div className="bg-dark interlock-example-title">
            Example interlocked quota <br/>
            (combined traits)
          </div>
        </div>
      </div>

      {/* Example illustration*/}
      <div className="row mt-3 align-items-center px-4">
        <div className="col-md-5">
          <div className="quota-box">
            <h2 className="po-text-bold font-size-16">
              Favorite colors
            </h2>
            <div className="quota-table compact-table">
              <table className="po-text">
                <tbody>
                <tr className="header text-dark">
                  <td></td>
                  <td>Percent</td>
                </tr>
                <tr>
                  <td>Cyan</td>
                  <td className="percent-wrapper">
                    <input type="number" value="20" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>Magenta</td>
                  <td className="percent-wrapper">
                    <input type="number" value="20" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>Yellow</td>
                  <td className="percent-wrapper">
                    <input type="number" value="60" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr className="footer">
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="quota-box mt-3">
            <h2 className="po-text-bold font-size-16">
              Gender
            </h2>
            <div className="quota-table compact-table">
              <table className="po-text">
                <tbody>
                <tr className="header text-dark">
                  <td></td>
                  <td>Percent</td>
                </tr>
                <tr>
                  <td>Male</td>
                  <td className="percent-wrapper">
                    <input type="number" value="50" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>Female</td>
                  <td className="percent-wrapper">
                    <input type="number" value="50" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr className="footer">
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-1 d-inline-flex justify-content-center align-items-center">
          <i className="fas fa-long-arrow-alt-right quota-arrow"></i>
        </div>
        <div className="col-md-6">
          <div className="quota-box">
            <h2 className="po-text-bold font-size-16">
              Interlocked favorite colors and gender quota
            </h2>

            <div className="quota-table">
              <table className="po-text">
                <tbody>
                <tr className="header text-dark">
                  <td></td>
                  <td>Percent</td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>male</span> and color: <span>cyan</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="10" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>female</span> and color: <span>cyan</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="10" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>male</span> and color: <span>magenta</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="10" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>female</span> and color: <span>magenta</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="10" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>male</span> and color: <span>yellow</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="30" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Gender: <span>female</span> and color: <span>yellow</span>
                  </td>
                  <td className="percent-wrapper">
                    <input type="number" value="30" readOnly={true}/>
                    <span>%</span>
                  </td>
                </tr>
                <tr className="footer">
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Example help text */}
      <div className="row po-text mt-3 font-size-13 px-4">
        <div className="col-md-5">
          With regular quotas, you can control ratios for each trait separately. 50% of your participants will be women,
          regardless of their favorite color. And 20% of your participants will prefer cyan, regardless of their gender.
          <br/><br/>
          If two traits are independent for your purposes, separate quotas may work best.
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5">
          With interlocked quotas, you can control two or more traits together. 50% of your participants will be female,
          and among females, 60% will prefer yellow, 20% will prefer magenta, and 20% will prefer cyan.
          <br/><br/>
          If two traits are potentially correlated for your purposes, interlocked quotas may work best.
        </div>
      </div>
    </>
  )
}

export default InterlockQuotaExample
