import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Cookies from 'universal-cookie'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

import {errorToast} from './helpers/notification'
import Countdown from './common/Countdown'
import {addMinutesToDate} from '../utils/helper'
import {SCREENOUT_CONTEXTS} from '../utils/constants'
import {fayeClient} from './helpers/FayeClient'
import {isCookieSupported, isLocalStorageSupported} from './common/Helpers'
import {urlRegex} from './run/enhanced-run-form/RunUtils'

import ScreenInPlaceHolder from './run/enhanced-run-form/ScreenInPlaceholder'
import {MAIN_ACTIVITY_FEEDBACK} from '../utils/constants'

import {
  createMainActivityFeedbackFetch,
  getOpenSlotsForMainActivityFetch,
  markScreenedInFetch,
  updateScreenInAssignment
} from '../redux/actions'
import qs from 'qs'

const BonusActivityDetails = ({bonus, title, timeNeeded, description, additionalInfo}) => {
  const index = title.lastIndexOf('(~')
  const formattedTitle = index > 0 ? title.substring(0, index - 1) : title

  return (
    <div className="text-left w-50 m-auto">
      <h2 className="po-header-bold mb-3 mt-4">Bonus Pay: ${bonus}</h2>
      <div>
        Activity title: <span className="po-text-bold">{formattedTitle}</span>
      </div>
      <div className="mt-2">
        Time needed: <span className="po-text-bold">{timeNeeded}</span>
      </div>
      <div className="my-2">Description: <br/>
        <span className="ml-3 po-text-bold">{description}</span>
      </div>
      {additionalInfo && (
        <div>Additional Info: <br/>
          <span className="ml-3 po-text-bold">{additionalInfo}</span>
        </div>
      )}
    </div>
  )
}

const ScreenIn = (props) => {
  const {
    isLoading,
    isAcceptingBonus,
    isCreatingFeedback,
    errors,
    acceptBonusActivityErrors,
    assignment,
    openSlots,
    screenedInAt,
    activityAcceptedAt,
    feedbackStartedAt,
    feedbackCompletedAt,
    activityDurationInMinutes,
    runPaused,
    runPausedAfterScreenedIn,
    mainActivityFeedbackId,
  } = useSelector(
    (state) => ({
      isLoading: state.screenedIn?.isLoading,
      isAcceptingBonus: state.screenedIn?.isAcceptingBonus,
      isCreatingFeedback: state.screenedIn?.isCreatingFeedback,
      errors: state.screenedIn?.errors,
      acceptBonusActivityErrors: state.screenedIn?.acceptBonusActivityErrors,
      assignment: state.screenedIn?.assignment,
      openSlots: state.screenedIn?.assignment?.available_slots,
      screenedInAt: state.screenedIn?.assignment?.screened_in_at,
      activityAcceptedAt: state.screenedIn?.assignment?.demographic_pre_survey_started_at,
      feedbackStartedAt: state.screenedIn?.assignment?.feedback_started_at,
      feedbackCompletedAt: state.screenedIn?.assignment?.feedback_completed_at,
      activityDurationInMinutes: state.screenedIn?.assignment?.activity_duration_in_minutes,
      runPaused: state.screenedIn?.assignment?.is_paused,
      runPausedAfterScreenedIn: state.screenedIn?.assignment?.is_paused_after_screened_in,
      mainActivityFeedbackId: state.screenedIn?.assignment?.main_activity_feedback_uid,
    })
  )

  const bonusAccepted = !!activityAcceptedAt
  const [opportunityCountDownExpired, setOpportunityCountDownExpired] = useState(false)
  const [activityCountDownExpired, setActivityCountDownExpired] = useState(false)

  const dispatch = useDispatch()
  const cookies = new Cookies()

  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const task_id = queryParams.task_id

  let worker_id = ''
  let assignment_id = ''
  let isRecruiter = ''


  if (isLocalStorageSupported) {
    worker_id = localStorage.getItem('worker_id')
    assignment_id = localStorage.getItem('assignment_id')
    isRecruiter = localStorage.getItem('is_recruiter')
  } else if (isCookieSupported) {
    worker_id = cookies.get('worker_id')
    assignment_id = cookies.get('assignment_id')
    isRecruiter = localStorage.getItem('is_recruiter')
  }

  if (assignment && !worker_id) {
    worker_id = assignment.worker_platform_uid
    assignment_id = assignment.platform_uid
  }

  const formik = useFormik({
    initialValues: {
      worker_id,
      feedback: '',
      feedback_description: '',
      error_screenshot_url: '',
      pause_run: false,
    },
    validationSchema: Yup.object().shape({
      worker_id: Yup.string().trim(),
      feedback: Yup.string().trim().required('Feedback is required'),
      feedback_description: Yup.string()
        .trim()
        .when('feedback', {
          is: (feedback) => feedback === MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE,
          then: (schema) => schema.required('Please describe the issue that you have faced'),
          otherwise: (schema) => schema
        }),
      error_screenshot_url: Yup.string()
        .trim()
        .when('feedback', {
          is: (feedback) => feedback === MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE,
          then: (schema) => schema.required('Error screenshot URL is required').matches(urlRegex, 'Invalid URL!'),
          otherwise: (schema) => schema
        }),
      pause_run: Yup.boolean(),
    }),
    validateOnMount: true,
    onSubmit: () => {
      if (!formik.isValid) return

      const params = {
        worker_id: worker_id,
        assignment_id: assignment_id,
        feedback: formik.values.feedback
      }

      if (formik.values.feedback === MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE) {
        params['feedback_description'] = formik.values.feedback_description
        params['error_screenshot_url'] = formik.values.error_screenshot_url
        params['pause_run'] = formik.values.pause_run
      }

      dispatch(createMainActivityFeedbackFetch(params))
    }
  })

  const acceptBonusActivity = () => {
    if (!assignment_id) return
    if (!bonusAccepted && opportunityCountDownExpired) return redirectToScreenoutPage(SCREENOUT_CONTEXTS.SCREENOUT)
    if (bonusAccepted && activityCountDownExpired) return redirectToScreenoutPage(SCREENOUT_CONTEXTS.ACTIVITY_TIMEOUT)

    // Mark screened in
    const params = {
      assignment_id,
      worker_id
    }
    dispatch(getOpenSlotsForMainActivityFetch(params))
  }

  const openToPreSurvey = () => {
    if (!assignment?.pre_survey_url) return

    const link = assignment.pre_survey_url + '&assignment_id=' + assignment_id + '&worker_id=' + worker_id
    window.open(link, 'demographic-pre-survey')
  }

  const redirectToScreenoutPage = (redirectionContext = SCREENOUT_CONTEXTS.DECLINED) => {
    if (!assignment?.screenout_url) return

    const context = encodeURIComponent(btoa(redirectionContext))
    window.location.href = assignment?.screenout_url + '?context=' + context
  }

  const rejectBonusActivity = () => {
    redirectToScreenoutPage(SCREENOUT_CONTEXTS.DECLINED)
  }

  const handleMainActivityFeedbackChange = (e) => {
    const type = e.target.value
    if (type !== MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE) {
      _resetFeedbackForm()
    }
    formik.handleChange(e)
  }

  const disableFeedbackSubmit = isCreatingFeedback || !formik.isValid || !formik.values.feedback
  const isBonusActivityAcceptable = assignment && !feedbackCompletedAt && !isLoading && !runPaused && !runPausedAfterScreenedIn

  const opportunityCountDownTargetDate = addMinutesToDate(screenedInAt, 10)
  const onOpportunityCountDownExpired = () => setOpportunityCountDownExpired(true)

  const activityCountDownTargetDate = addMinutesToDate(activityAcceptedAt, activityDurationInMinutes)
  const onActivityCountDownExpired = () => setActivityCountDownExpired(true)

  // Event Handlers
  // ==============
  const handlePauseRun = (e, pauseRun) => {
    e.preventDefault()
    formik.setFieldValue('pause_run', pauseRun)
  }

  const resetFeedback = (e) => {
    e.preventDefault()
    formik.setFieldValue('feedback', '')
    _resetFeedbackForm()
  }


  // Private functions
  // ================
  const _resetFeedbackForm = () => {
    formik.setFieldValue('feedback_description', '')
    formik.setFieldValue('error_screenshot_url', '')
    formik.setFieldValue('pause_run', false)
  }

  const acceptBonusButtonLabel = bonusAccepted
    ? 'Bonus Activity In Progress'
    : isAcceptingBonus
      ? 'Please wait...'
      : `Accept $${assignment?.activity_bonus} bonus`

  useEffect(() => {
    if (!assignment_id || !worker_id || isRecruiter) return

    const screenInParams = {
      assignment_id,
      worker_id,
      task_id
    }
    dispatch(markScreenedInFetch(screenInParams))

    const subscription = fayeClient.subscribe(`/assignmentStateChanged/${assignment_id}`, (data) => {
      if (data.demographic_pre_survey_started_at || data.feedback_completed_at) {
        dispatch(updateScreenInAssignment(data))
      }
    })

    return () => subscription.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAcceptingBonus || acceptBonusActivityErrors) return
    if (!openSlots) return redirectToScreenoutPage(SCREENOUT_CONTEXTS.ACTIVITY_FULL)

    openToPreSurvey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAcceptingBonus])

  useEffect(() => {
    if (!acceptBonusActivityErrors) return

    errorToast(acceptBonusActivityErrors.join(', '), {autoClose: false})
  }, [acceptBonusActivityErrors])

  // Reset feedback link missing option, if selected
  useEffect(() => {
    if (!feedbackStartedAt || formik.values.feedback !== MAIN_ACTIVITY_FEEDBACK.LINK_MISSING_VALUE)
      return

    formik.setFieldValue('feedback', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackStartedAt])

  if (isRecruiter) return <ScreenInPlaceHolder/>

  if (!task_id) {
    return (
      <div className="box po-text col-md-12">
        <h3 className="text-danger">Invalid Activity Link</h3>
      </div>
    )
  }

  if (task_id && errors) {
    return (
      <div className="box po-text col-md-12">
        <h3 className="text-danger">{errors.join(', ')}</h3>
      </div>
    )
  }

  return (
    <>
      {!isLocalStorageSupported && !isCookieSupported && (
        <div className="row">
          <div className="col-lg-12">
            <div className="metrics clear-h3-margin box">
              <h3>
                Participation in this task requires either LocalStorage or Cookie to be enabled, but your current
                browser is blocking the use of LocalStorage and Cookie. Please adjust your browser settings,
                turn off plugins that may block localStorage and Cookie, or try a different web browser
                in order to participate.
              </h3>
              <div className="po-note mt-4">
                Note: You must refresh the window after you have enabled the LocalStorage or Cookie
                in order to continue the participation.
              </div>
            </div>
          </div>
        </div>
      )}

      {(isLocalStorageSupported || isCookieSupported) && (
        <div className="box po-text col-md-12">
          {!assignment_id && !isRecruiter && (
            <div className="po-text-error">
              No activity in progress!
            </div>
          )}

          {isBonusActivityAcceptable && (
            <>
              <div className="text-center pb-3">
                {!activityCountDownExpired && (
                  <>
                    <h1 className="po-header-lite mt-2">You qualify for a bonus study from this researcher!</h1>
                    <div className="po-header-bold my-3 w-75 mx-auto">
                      You must submit the HIT titled "{assignment.screening_activity_title}" before starting the bonus
                      activity
                    </div>
                    <div className="px-5 mt-3">
                      You will be paid for the HIT you just completed. <br/>
                      If you complete the bonus activity, you will receive a
                      bonus payment of ${assignment.activity_bonus} through MTurk.
                    </div>
                  </>
                )}

                {!bonusAccepted && (
                  opportunityCountDownExpired ? (
                    <div className="mt-4 po-text font-size-18 text-danger">
                      <div className="font-size-22">Unfortunately, this bonus activity is no longer available.</div>
                      <div className="mt-3">We hope to offer you another activity soon!</div>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <span className="po-text-bold font-size-18">
                        This opportunity will expire in{' '}
                        <Countdown
                          className="text-danger"
                          targetDate={opportunityCountDownTargetDate}
                          onCountDownExpired={onOpportunityCountDownExpired}
                        />
                      </span><br/><br/>
                      After that, your slot will go to another user.
                    </div>
                  )
                )}

                {bonusAccepted && (
                  activityCountDownExpired ? (
                    <div className="mt-4 po-text font-size-18 text-danger">
                      <div className="font-size-22" style={{lineHeight: 1.25}}>
                        Unfortunately, the time limit for this bonus activity has expired, <br/>and it is no longer
                        available to complete.
                      </div>
                      <div className="mt-3"> We hope to offer you another activity soon!</div>
                    </div>
                  ) : (
                    <div className="mt-4 pb-3">
                      <span className="po-text-bold font-size-18">
                        You have{' '}
                        <Countdown
                          className="text-danger"
                          targetDate={activityCountDownTargetDate}
                          onCountDownExpired={onActivityCountDownExpired}
                        />
                        {' '} to complete the bonus activity.<br/>
                      </span>
                    </div>
                  )
                )}

                <BonusActivityDetails
                  bonus={assignment.activity_bonus}
                  title={assignment.activity_title}
                  timeNeeded={assignment.est_duration_humanized}
                  description={assignment.activity_description}
                  additionalInfo={assignment.activity_additional_info}
                />

                {!opportunityCountDownExpired && !activityCountDownExpired && (
                  <div className="wrap_link mt-4 d-inline-flex">
                    {!bonusAccepted && (
                      <button
                        type="button"
                        className="btn btn-default cancel clickable"
                        onClick={rejectBonusActivity}
                      >
                        No thanks
                      </button>
                    )}
                    <button
                      className={`btn btn-primary refresh clickable no-border ${bonusAccepted ? ' btn-grey' : ''}`}
                      onClick={acceptBonusActivity}
                    >
                      {acceptBonusButtonLabel}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {assignment && feedbackCompletedAt && (
            <>
              <BonusActivityDetails
                bonus={assignment.activity_bonus}
                title={assignment.activity_title}
                timeNeeded={assignment.est_duration_humanized}
                description={assignment.activity_description}
                additionalInfo={assignment.activity_additional_info}
              />

              <div className="po-text text-center mt-5">
                <h1 className="po-header-bold text-success mb-2">
                  You have successfully completed the bonus activity!
                </h1>
                <p className="font-size-16 pb-4 mt-3">
                  Your bonus work will be reviewed and bonus will be paid within 6 hours.
                  You are good to close this window.
                  <br/>
                  Thank you for your participation.
                </p>
              </div>
            </>
          )}

          {runPaused && !runPausedAfterScreenedIn && !bonusAccepted && (
            <div className="text-center py-4">
              <p className="font-size-16 text-bold text-dark mb-4">Thank you for completing this activity!</p>
              <p className="font-size-18 mb-4"> Please be sure to submit your open HIT so you can be paid.</p>
              <p className="font-size-16">If you ran into any problems, please let us know on the HIT page!</p>

            </div>
          )}

          {runPausedAfterScreenedIn && !bonusAccepted && (
            <div className="text-center py-5">
              <div className="font-size-22 ls mb-4 pb-2">
                Unfortunately, this bonus activity is not available <br/>due to an error.
              </div>
              <div className="font-size-18 mb-4">We apologize for the trouble!</div>
              <div className="font-size-18 po-text-bold text-dark mb-4">
                Please submit your open HIT to be paid for the activity <br/>you just completed.
              </div>
              <div className="lh">
                Our support team has been notified about this error. If you have any <br/>
                questions, please contact us at <a className="link" href="mailto:hi@positly.com">hi@positly.com</a>.
              </div>
            </div>
          )}

          {bonusAccepted && !feedbackCompletedAt && !activityCountDownExpired && !mainActivityFeedbackId && (
            <div className="light-gray-box my-4">
              <h1 className="po-header-bold font-size-18">Thanks for accepting this bonus activity!</h1>
              <p className="my-4">Please use this form to notify us if anything goes wrong.</p>
              <div className="metrics project_detail filter participants activity">
                <div className="left">
                  <div className="box screening-feedback-box">
                    <form
                      className="my-2 radio-checkbox flex-column"
                      name="screeningFeedbackForm"
                      noValidate="">

                      <div className="wrap_radio">
                        {!feedbackStartedAt && (
                          <label className="pb-2">
                            <input
                              name="feedback"
                              tabIndex="5"
                              checked={formik.values.feedback === MAIN_ACTIVITY_FEEDBACK.LINK_MISSING_VALUE}
                              onChange={handleMainActivityFeedbackChange}
                              type="radio"
                              value={MAIN_ACTIVITY_FEEDBACK.LINK_MISSING_VALUE}
                            />
                            <span className="po-text">
                                  {MAIN_ACTIVITY_FEEDBACK.LINK_MISSING_LABEL}.
                                </span>
                          </label>
                        )}

                        <label className="pb-2">
                          <input
                            name="feedback"
                            tabIndex="5"
                            checked={formik.values.feedback === MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE}
                            onChange={handleMainActivityFeedbackChange}
                            type="radio"
                            value={MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE}
                          />
                          <span className="po-text">
                                {MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_LABEL}.
                              </span>
                        </label>
                      </div>

                      {formik.values.feedback === MAIN_ACTIVITY_FEEDBACK.ENCOUNTERED_A_PROBLEM_VALUE && (
                        <>
                          <h2 className="text-required">
                            Please explain in as much detail as you can what difficulties you faced. What were you
                            doing when you had the problem? What was the problem exactly? What did you see on the
                            screen when the issue occurred?
                          </h2>
                          <textarea
                            name="feedback_description"
                            className="form-control border-grey"
                            minLength={20}
                            onChange={formik.handleChange}
                            value={formik.values.feedback_description}
                            onPaste={(e) => e.preventDefault()}
                          />

                          <h2 className="text-required">
                            Please{' '}
                            <a
                              className="link"
                              href="https://www.theverge.com/2019/11/8/20953522/how-to-take-screenshot-mac-windows-pc-iphone-android"
                              target="_blank"
                              rel="noreferrer"
                            >
                              take a screenshot
                            </a>
                            {' '}of the webpage where you are encountering the problem or error (if possible, this
                            screenshot should show the error or issue). Then upload your screen shot to{' '}
                            <a className="link" href="https://imgbb.com/" target="_blank" rel="noreferrer">ImgBB</a>
                            {' or '}
                            <a className="link" href="https://imgur.com/" target="_blank" rel="noreferrer">Imgur</a>
                            {' '}so that your screenshot has a link. Finally, paste the link to your screenshot in
                            the box below so that it can be reviewed.
                          </h2>
                          <input
                            name="error_screenshot_url"
                            type="text"
                            className="form-control border-grey"
                            value={formik.values.error_screenshot_url}
                            onChange={formik.handleChange}
                          />

                          {formik.values.pause_run ? (
                            <h2 className="pb-3">
                              Thank you for your feedback! We will consider pausing this HIT.{' '}
                              <Link
                                to="/"
                                className="link"
                                onClick={(e) => handlePauseRun(e, false)}
                              >
                                Click here
                              </Link>
                              {' '}to undo your request.
                            </h2>
                          ) : (
                            <h2 className="pb-3">
                              If the problem you had with this HIT is serious enough that you think the HIT should
                              be paused,{' '}
                              <Link
                                to="/"
                                className="link"
                                onClick={(e) => handlePauseRun(e, true)}
                              >
                                click here
                              </Link>.
                            </h2>
                          )}
                        </>
                      )}

                      <div className="wrap_link wrap_link_filter d-flex mt-0">
                        <button
                          className={
                            `btn btn-primary no-border clickable transition-0
                                ${disableFeedbackSubmit ? 'cursor-disabled btn-grey' : ''}`
                          }
                          id="submitted-hit"
                          onClick={formik.handleSubmit}
                          disabled={disableFeedbackSubmit}>
                          {isCreatingFeedback ? 'Please wait...' : 'Submit Report To Support Team'}
                        </button>
                        <button
                          className="btn btn-default back clickable btn-save-as-draft"
                          id="submitted-hit"
                          onClick={resetFeedback}>
                          Reset Feedback
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

          {mainActivityFeedbackId && (
            <div className="mx-4 my-4 text-center font-size-16 info-alert">
              <p>We are sorry to hear that you had issue with our study.</p>
              <p>
                Your feedback has been submitted to our support team.
                We will investigate the problem as soon as possible.
              </p>
              <p>Thank you!</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ScreenIn
