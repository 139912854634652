import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import InfiniteScroll from 'react-infinite-scroll-component'

import Spinner from './common/Spinner'
import FormErrors from './FormErrors'
import ApproveOrUnrejectAssignment from './ApproveOrUnrejectAssignment'
import RejectAssignment from './RejectAssignment'
import BlockParticipantAndRejectAssignment from './BlockParticipantAndRejectAssignment'

import {urlWithProtocol} from './common/Helpers'

import {
  participantApproveAssignmentFetch,
  rejectAssignment,
  blockParticipantAndRejectAssignment,
} from '../redux/actionss/runActions'
import {
  runFeedbackInit,
  runFeedbackFetch
} from '../redux/actionss/runActions'

const FeedbackComment = (props) => {
  const getClassName = () => {
    if (['Interesting and enjoyable', 'Suggestion'].indexOf(props.comment_type) > -1) {
      return 'text-info'
    }

    if (props.comment_type === 'Comment' && !props.errorScreenshotUrl) {
      return 'text-dark-blue'
    }

    return 'text-danger'
  }

  const getFeedbackType = () => {
    if (props.comment_type === 'Comment' && props.errorScreenshotUrl) {
      return props.isScreeningEnabled ? 'Issue reported in Screening Activity:' : 'Issue reported by participant:'
    }

    if (props.comment_type === 'Main Activity feedback') {
      return 'Issue reported in Main Activity:'
    }

    return props.comment_text ? `${props.comment_type}:` : props.comment_type
  }

  const getContainerClassName = () => {
    let classString= "form-group comment-group"

    if (props.totalComments.length === 1) {
      classString += "single-comment"
    }
  }

  return (
    <div className={getContainerClassName()}>
      <div>
        <label className={getClassName()}>
          {getFeedbackType()}
        </label>
        <p>
          {props.comment_text}
        </p>
        { ((props.comment_type === 'Comment' && props.errorScreenshotUrl) || (props.comment_type === 'Main Activity feedback' && props.mainActivityErrorScreenshotUrl)) && (
          <div className="mt-2">
            {props.comment_type === 'Comment' && props.errorScreenshotUrl && (
              <p className="mt-1">
                <strong className="po-text-bold">Screenshot of reported issue: </strong>
                <a
                  href={urlWithProtocol(props.errorScreenshotUrl)}
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                >
                  {props.errorScreenshotUrl}
                </a>
              </p>
            )}
            {props.comment_type === 'Main Activity feedback' && props.mainActivityErrorScreenshotUrl && (
              <p className="mt-1">
                <strong className="po-text-bold">Screenshot of reported issue: </strong>
                <a
                  href={urlWithProtocol(props.mainActivityErrorScreenshotUrl)}
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                >
                  {props.mainActivityErrorScreenshotUrl}
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const RunFeedbackListItem = (props) => {

  const getApproveAssignmentLabel = (options = {pay: false}) => {
    if (props.assignment_status === 'task_completed') {
      return 'Approve Manually'
    }

    if (props.isScreeningEnabled) {
      if (props.assignment_status === 'issue_reported_in_main_activity') {
        if(options.pay) {
          return 'Accept And Pay'
        } else {
          return 'Accept Without Pay'
        }
      } else {
        return 'Accept And Pay For Screening'
      }
    }

    return 'Accept this work and pay the participant'
  }

  return (
    <div className="row list-item infinitely-loaded feedback-item align-items-sm-center">
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Participant ID:</span>
        {props.reason_to_block_worker === 'Blocked due to spam' && (
          <div className="d-inline-block po-tooltip no-q icon po-text ml-1 mb-1 top-0">
            <i className="fas fa-user-slash text-danger"/>
            <span className="po-tooltiptext po-tooltip-position">
              {props.reason_to_block_worker}
            </span>
          </div>
        )}
        {props.worker_id}
      </div>
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Timestamp:</span>
        <span>
          {props.created_at_formatted}
        </span>
      </div>
      <div className="col-md-8">
        <span className="list-item-label po-text-bold pr-1">Comments:</span>
        {props.comments_formatted.map((comment, index) =>
          <FeedbackComment
            {...comment}
            key={index}
            totalComments={props.comments_formatted.length}
            errorScreenshotUrl={props.error_screenshot_url}
            mainActivityErrorScreenshotUrl={props.main_activity_error_screenshot_url}
            isScreeningEnabled={props.isScreeningEnabled}
          />
        )}

        {props.pause_run && (
          <p className="mt-2 pl-2">
            <strong className="text-danger">Requested to pause run</strong>
          </p>
        )}
      </div>
      {props.isMturk && (
        <div className="action user_block run-feedback-actions">
          <div className="dropdown wrap_img_user">
            <button
              id="dropdownMenu1"
              type="button"
              data-toggle="dropdown"
              className="btn btn-default dropdown-toggle">
              <span/>
            </button>

            <ul className="dropdown-menu dropdown-menu-right action action-tr menu_dashed align-menu-items">
              <li>
                <div
                  to=""
                  className="id_top"
                >
                  {props.worker_id}
                </div>
              </li>
              {props.is_messageble && (<>
                <li
                  className='cursor-pointer dropdown-item'
                  onClick={(e) => props.openPopup(
                    'message-popup',
                    {...props, participant_id: props.worker_id},
                    e
                  )}>
                  <Link
                    to=""
                    className="popup-content reply">
                    Reply
                  </Link>
                </li>
                <li
                  className='cursor-pointer dropdown-item'
                  onClick={(e) =>
                    props.openPopup('bonus-popup', {...props, participant_id: props.worker_id}, e)}>
                  <Link
                    to=""
                    className="popup-content give">
                    Give Bonus
                  </Link>
                </li>
              </>)}
              <li
                className='cursor-pointer dropdown-item'
                onClick={(e) => props.openPopup('set-attribute-popup',
                  {...props, participant_id: props.worker_id},
                  e
                )}
              >
                <Link
                  to=""
                  className="popup-content set_dashed_ca">
                  Set Custom Attribute
                </Link>
              </li>
              {props.assignment_status === 'issue_reported_by_participant' && (
                <>
                  <ApproveOrUnrejectAssignment
                    action="approve"
                    label={getApproveAssignmentLabel()}
                    workerId={props.worker_id}
                    assignmentId={props.assignment_id}
                    status={props.assignment_status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platformType}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <RejectAssignment
                    assignmentId={props.assignment_id}
                    rejectAssignment={props.rejectAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    status={props.status}/>
                  <BlockParticipantAndRejectAssignment
                    assignmentId={props.assignment_id}
                    blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}/>
                </>
              )}
              {(props.assignment_status === 'issue_reported_in_main_activity') && (
                <>
                  <ApproveOrUnrejectAssignment
                    action="approve"
                    label={getApproveAssignmentLabel()}
                    workerId={props.worker_id}
                    assignmentId={props.assignment_id}
                    status={props.assignment_status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platformType}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <ApproveOrUnrejectAssignment
                    action="approve"
                    label={getApproveAssignmentLabel({pay: true})}
                    workerId={props.worker_id}
                    assignmentId={props.assignment_id}
                    status={props.assignment_status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platformType}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    payMainActivity={true}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <RejectAssignment
                    assignmentId={props.assignment_id}
                    rejectAssignment={props.rejectAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    status={props.assignment_status}
                  />
                  <BlockParticipantAndRejectAssignment
                    assignmentId={props.assignment_id}
                    blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}/>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

const RunFeedback = props => {
  useEffect(() => {
    props.runFeedbackInit()
    props.runFeedbackFetch(props.id, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchFeedbacks = () => props.runFeedbackFetch(props.id, props.pageNumber + 1)

  return (
    <div id="complaints">
      {props.feedbacks?.length > 0 && (
        <div>
          <div className="wrap_projects feedback-list run-statistics">
            <div className="runlist">
              <div className="row list-item list-heading">
                <div className="col-md-2">
                  <strong>
                    Participant ID
                  </strong>
                </div>
                <div className="col-md-2">
                  <strong>Timestamp</strong>
                </div>
                <div className="col-md-8">
                  <strong>Comments</strong>
                </div>
              </div>
            </div>

            <InfiniteScroll
              dataLength={props.feedbacks?.length || 0}
              next={fetchFeedbacks}
              hasMore={props.hasMore}
              loader={<Spinner/>}
              style={{overflow: 'hidden'}}>
              {props.feedbacks?.map(feedback =>
                <RunFeedbackListItem
                  {...feedback}
                  key={feedback.id}
                  platformType={props.platform_type}
                  paymentPerSubmission={props.payment_per_submission}
                  isMturk={props.isMturk}
                  openPopup={props.openPopup}
                  currentUser={props.currentUser}
                  approveAssignment={props.participantApproveAssignmentFetch}
                  rejectAssignment={props.rejectAssignment}
                  blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}
                  isScreeningEnabled={props.agree_screening_guidelines}
                  serviceFeePercent={props.service_fee_percent}
                />
              )}
            </InfiniteScroll>
          </div>
        </div>
      )}
      {props.feedbacks?.length === 0 && !props.isLoading && !props.errors && (
        <div className="project_block no_data">
          <div className="wrap_top">
            <p>No feedback yet</p>
          </div>
        </div>
      )}
      {props.errors?.length > 0 && <FormErrors errors={props.errors}/>}
      {props.isLoading && <Spinner/>}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.runFeedback,
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  runFeedbackInit: () => dispatch(runFeedbackInit()),
  runFeedbackFetch: (id, pageNumber) => dispatch(runFeedbackFetch(id, pageNumber)),
  participantApproveAssignmentFetch: (id, workerId, status, payMainActivity, page = 'feedback') => dispatch(participantApproveAssignmentFetch(id, workerId, status, payMainActivity, page)),
  rejectAssignment: (id, reason = 'Spam', page = 'feedback') => dispatch(rejectAssignment(id, reason, page)),
  blockParticipantAndRejectAssignment: (id, page = 'feedback') => dispatch(blockParticipantAndRejectAssignment(id, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(RunFeedback)
