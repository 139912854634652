import React from 'react'

import {capitalize} from '../helpers/utils'

const CintAttributes = (props) => {
  const isFiltersValid = () => {
    return (props.attributes
        .map(filter => filter.variables.length)
        .some(count => count > 0) ||
      props.age_min ||
      props.age_max ||
      (props.gender && props.gender.length > 0)
    )
  }

  return (
    <>
      {isFiltersValid() && (
        <div className="mt-3">
          <h4>Demographic Attributes</h4>
          {props.age_min && props.age_max && (
            <div className="po-text mb-3 light-color-icon filter-box" key="age">
              <h4 className="mt-2 mb-1 participant-attribute-title">Age</h4>
              <span>
                  {props.age_min} - {props.age_max}
                </span>
            </div>
          )}

          {props.gender?.length > 0 && (
            <div className="po-text mb-3 light-color-icon filter-box" key="gender">
              <h4 className="mt-2 participant-attribute-title">Gender</h4>
              <div className="radio-toolbar">
                {Array.isArray(props.gender) && props.gender.map((gender) => (
                  <label className="success label-demo no-cursor">
                    {capitalize(gender)}
                  </label>
                ))}
              </div>
            </div>
          )}

          {props.attributes.map((filter) => {
            return (
              filter.variables.length > 0 ? (
                <div className="po-text mb-3 light-color-icon filter-box" key={filter.name}>
                  <span>
                    <h4 className="mt-2 participant-attribute-title">{filter.name}</h4>
                    <div className="radio-toolbar">
                      {filter.variables.map(option => (
                        <label className="success label-demo no-cursor">
                          {option?.name}
                        </label>
                      ))}
                    </div>
                  </span>
                </div>
              ) : null
            )
          })}
        </div>
      )}
    </>
  )
}

export default CintAttributes
