import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

// 3rd party components
import {useFormik} from 'formik'
import * as Yup from 'yup'

// Custom components
import FormErrors from '../../FormErrors'

// Redux actions
import {
  runFormOverviewUpdate,
} from '../../../redux/actions'

// Global variables/constants
const $ = window.$


const ConfigureAttributes = (props) => {

  // Variables
  const [errorMessage, setErrorMessage] = useState('')
  const [dispatchInitialValuesToRedux, setDispatchInitialValuesToRedux] = useState(true)
  const dispatch = useDispatch()

  const configureDynamicFormik = (type) => {
    let config
    if (props.query_string_attributes && Object.keys(props.query_string_attributes).length)
      config = props.query_string_attributes
    else
      config = props.default_query_string_attributes

    if (!config || !Object.getOwnPropertyNames(config).length) return {}

    return (
      Object.keys(config).reduce((obj, key) => {
        let value = ''
        if (type === 'initialValues') {
          value = config[key]
        } else if (type === 'initialTouched') {
          value = true
        } else if (type === 'validationSchema') {
          value = Yup.string().required('Attribute is required')
        }

        return ({...obj, [key]: value})
      }, {})
    )
  }

  const formik = useFormik({
    initialValues: configureDynamicFormik('initialValues'),
    initialTouched: configureDynamicFormik('initialTouched'),
    validationSchema: Yup.object(configureDynamicFormik('validationSchema')),
    onSubmit: () => {
      if (formik.isValid && errorMessage === '') {
        dispatch(runFormOverviewUpdate({query_string_attributes: {...formik.values}}))
        $.magnificPopup.close()
      }
    },
    enableReinitialize: true,
    validateOnMount: true
  })

  const startCase = (key) => (
    key
      .replace(/_/g, ' ') // replace _
      .split(' ')
      .map(word => word.trim()[0].toUpperCase() + word.trim().slice(1))
      .join(' ')
      .replace(/\sId$/, ' ID')
      .replace(/(\d+)/g, ' $1 ') // split numbers and words
  )

  // Event handlers
  const closeQSAttributesPopup = (e) => {
    e.preventDefault()
    formik.resetForm()
    $.magnificPopup.close()
  }

  const resetQsToDefault = (e) => {
    e.preventDefault()
    formik.setValues(props.default_query_string_attributes)
    dispatch(runFormOverviewUpdate({query_string_attributes: {...props.default_query_string_attributes}}))
  }

  // Side effects
  // Check if there are any duplicate keys
  useEffect(() => {
    const duplicateQSValues = Object.values(formik.values).filter((qsAttribute, index, self) => {
      return self.indexOf(qsAttribute) === index && index !== self.lastIndexOf(qsAttribute) && qsAttribute !== ''
    })
    if (duplicateQSValues.length > 0)
      setErrorMessage(`Duplicate ${duplicateQSValues.length > 1 ? 'keys' : 'key'} found: ${duplicateQSValues.join(', ')}`)
    else
      setErrorMessage('')
  }, [formik.values])

  // Dispatch initial values to redux
  useEffect(() => {
    if (dispatchInitialValuesToRedux && props.run_form_query_string_attributes) {
      dispatch(runFormOverviewUpdate({query_string_attributes: props.run_form_query_string_attributes}))
      setDispatchInitialValuesToRedux(false)
    }
  }, [
    dispatch,
    dispatchInitialValuesToRedux,
    props.run_form_query_string_attributes
  ])


  // UI template
  return (
    <div className="filter white-popup mfp-hide modal fade px-4 mr-t" id="configure">
      <h2 className="mb-4 justify-content-between">
        Edit Participant Attributes For Activity Link
        <span>
          <a
            href="/"
            className="btn btn-primary mr-0 qs-btns-1 clickable"
            onClick={resetQsToDefault}
          >
            Reset to default
          </a>
          </span>
      </h2>

      {errorMessage && <FormErrors errors={[errorMessage]} className="mt-4"/>}

      <form
        name="attrConfigForm"
        className="wrap_info px-0 mt-0 pt-0"
        onSubmit={formik.handleSubmit}
      >
        <div className="wrap-item row qs-scroll">
          {props.query_string_attributes && Object.keys(props.query_string_attributes).sort().map((key, index) => (
            <div key={index} className={`item my-3 qs-col`}>
              <div className={`form-group ${formik.touched[key] && formik.errors[key] && 'has-error'}`}>
                <label>{startCase(key)}</label>
                <input
                  type="text"
                  name={key}
                  onChange={formik.handleChange}
                  value={formik.values[key] || ''}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="wrap_link run-mt-3 ml-auto">
          <button
            type="submit"
            className="btn btn-primary refresh clickable no-border"
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-default cancel clickable"
            onClick={closeQSAttributesPopup}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  if (!state.runFormOverview || !state.runForm) return {}

  return {
    run_form_query_string_attributes: state.runForm.query_string_attributes,
    default_query_string_attributes: state.runFormOverview.default_query_string_attributes,
    query_string_attributes: state.runFormOverview.query_string_attributes,
  }
}

export default connect(mapStateToProps)(ConfigureAttributes)
