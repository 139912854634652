import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Tagify from '@yaireo/tagify'

import FormErrors from './FormErrors'
import {reactSelectStyles} from './run/enhanced-run-form/RunUtils'
import {VALID_UID_REGEX} from '../utils/constants'

import {participantSetAttributeFetch, participantSetAttributeInit} from '../redux/actionss/runActions'

const $ = window.$

const SetAttribute = (props) => {
  const [participantIDs, setParticipantIDs] = useState([])
  const [attributeName, setAttribute] = useState('')
  const input = useRef()
  const [tagify, setTagify] = useState(null)

  const {
    participantSetAttribute,
    participantCurrentAttributes,
    participantAttributes,
  } = useSelector((state) => ({
    participantSetAttribute: {...state.participantSetAttribute},
    participantCurrentAttributes: {...state.participantCurrentAttributes},
    participantAttributes: {...state.participantAttributes},
  }))
  const dispatch = useDispatch()
  const customAttributes = participantAttributes.attributes
  const currentAttributes = participantCurrentAttributes.attributes
  const [errorState, setErrorState] = useState({
    missingIds: [],
    errorMessage: '',
  })

  useEffect(() => {
    const errorMessage = (participantSetAttribute.errors || []).join()
    const missingIds = participantSetAttribute.missingIds || []

    if (missingIds.length) {
      missingIds.forEach(id => {
        $('tag[title=\'' + id + '\']').addClass('border-red')
      })
    }

    setErrorState({
      missingIds,
      errorMessage,
    })
  }, [
    participantSetAttribute.errors,
    participantSetAttribute.missingIds
  ])

  const createOption = (options) => {
    return options.map((option) => ({
      label: option,
      value: option.toLowerCase().replace(/\W/g, ''),
    }))
  }

  let validations = {
    attribute_value: Yup.string().required('Attribute value is required'),
    custom_attributes: Yup.string().required('Attribute name is required'),
  }

  if (!props.participant_id) {
    validations = {
      ...validations,
      participant_ids: Yup.string().required('You must include participant IDs'),
    }
  }

  const formik = useFormik({
    initialValues: {
      attribute_value: '',
      custom_attributes: '',
      participant_ids: '',
    },
    validationSchema: Yup.object(validations),
    onSubmit: (values) => {
      if (formik.isValid) {
        let participantAttributes = {
          attribute_name: values.custom_attributes.toLowerCase(),
          attribute_value: values.attribute_value,
          participant_ids: props.participant_id ? [props.participant_id] : participantIDs
        }
        dispatch(participantSetAttributeInit())
        dispatch(participantSetAttributeFetch(participantAttributes))
      }
    },
  })

  const resetAttributeForm = () => {
    formik.resetForm()
    setAttribute('')
    setParticipantIDs([])
    if (tagify !== null) {
      tagify.removeAllTags()
    }
  }

  useEffect(() => {
    if (input.current) {
      const tagify = new Tagify(input.current, {
        delimiters: /[,\s|\r\n]+/,
        pattern: VALID_UID_REGEX,
        editTags: {
          keepInvalid: false
        },
        skipInvalid: true,
        transformTag: (tag) => {
          tag.value = tag.value.replace(/[^pa-f0-9]/g, '')
          tag.__isValid = VALID_UID_REGEX.test(tag.value) && !tagify.isTagDuplicate(tag.value)
        }
      })
      setTagify(tagify)
      tagify.on('add', (e) => {
        setParticipantIDs((ids) => {
          if (e.detail.data.value.includes('\n')) {
            return [...ids]
          } else {
            return [...ids, e.detail.data.value]
          }
        })
      })
      tagify.on('edit:updated', e => {
        const {data, tag} = e.detail
        const newValue = data.value
        const oldValue = tag.__tagifyTagData.__originalData.value

        if (oldValue !== newValue) {
          setParticipantIDs(ids => {
            const index = ids.findIndex(id => id === oldValue)
            return [...ids.slice(0, index), newValue, ...ids.slice(index + 1)]
          })
        }
      })
      tagify.on('remove', (e) => {
        setParticipantIDs((ids) => {
          return ids.filter(id => id !== e.detail.data.value)
        })
        setErrorState(error => {
          const removedId = e.detail.data.value
          const missingIds = error.missingIds.filter(id => id !== removedId)
          let errorMessage = ''

          if (missingIds.length > 0) {
            errorMessage = `These IDs were invalid: ${missingIds.join(', ')}`
          }

          return {
            missingIds,
            errorMessage
          }
        })
      })
    }
  }, [])

  useEffect(() => {
    if (participantSetAttribute.status === 'success') {
      resetAttributeForm()
      props.closePopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantSetAttribute.status])

  const handleAttributeChange = option => {
    setAttribute(option)
    if (option) {
      formik.setFieldValue('custom_attributes', option.value)
    }
  }

  const handleCancel = (e) => {
    e.preventDefault()
    resetAttributeForm()
    props.closePopup()
  }

  const clearParticipantIds = (e) => {
    e.preventDefault()
    setParticipantIDs([])
    participantIDs.forEach(id => {
      tagify.removeTag(id)
    })
  }

  const clearInvalidParticipantIds = (e) => {
    e.preventDefault()
    participantIDs.forEach(id => {
      if (errorState.errorMessage.includes(id)) {
        tagify.removeTag(id)
      }
    })
  }

  const getErrors = () => {
    let errors = []
    if (participantAttributes.errors?.length) {
      errors = [...errors, ...participantAttributes.errors]
    }
    if (participantCurrentAttributes.errors?.length) {
      errors = [...errors, ...participantCurrentAttributes.errors]
    }
    return errors
  }

  const isNoAttributesSet = () => {
    return !currentAttributes?.length &&
      !participantCurrentAttributes.isLoading &&
      !participantCurrentAttributes.errors?.length
  }

  return (
    <span>
      <div
        id="set-attribute-popup"
        className={`white-popup mfp-hide translate-y-35 ${props.participant_id ? 'ca-popup' : ''}`}
      >
        <div className="row">
          <div className={`${!props.participant_id ? 'col-md-12' : 'col-md-6'}`}>
            <h2>
              {!props.participant_id
                ? 'Set custom attribute for participants'
                : `Set custom attribute for ${props.participant_id}`}
            </h2>
            <form
              className="wrap_info filter-form"
              onSubmit={formik.handleSubmit}>

              {errorState.errorMessage.length > 0 &&
                <FormErrors className="word-break-all" errors={[errorState.errorMessage]}/>}
              {!errorState.errorMessage && <FormErrors className="word-break-all" errors={getErrors()}/>}

              <div className="wrap_select">
                <label className="text-required">Attribute name</label>
                <CreatableSelect
                  placeholder="Select or create Custom attribute"
                  className="form-control filter-attributes full-width"
                  classNamePrefix="react-select"
                  isSearchable
                  isClearable
                  isLoading={participantAttributes?.isLoading}
                  name="custom_attributes"
                  onChange={handleAttributeChange}
                  onInputChange={(value) => {
                    const attribute = value.toLowerCase().replace(/[^0-9a-z_]/g, '')
                    return attribute.length > 255 ? attribute.substring(0, 255) : attribute
                  }}
                  value={attributeName}
                  styles={{
                    ...reactSelectStyles,
                  }}
                  options={customAttributes ? createOption(customAttributes) : []}
                  noOptionsMessage={() => null}
                />
                {formik.touched.custom_attributes &&
                  formik.errors.custom_attributes && (
                    <p className="po-text text-red-soft">
                      {formik.errors.custom_attributes}
                    </p>
                  )
                }
              </div>

              <div className="wrap_item vertical-space-md">
                <label className="text-required">Attribute value</label>
                <input
                  name="attribute_value"
                  className="validity"
                  value={formik.values.attribute_value}
                  onChange={formik.handleChange}
                  type="text"/>
                {formik.touched.attribute_value && formik.errors.attribute_value && (
                  <p className="po-text text-red-soft">{formik.errors.attribute_value}</p>
                )}
              </div>

              <div className={`wrap_select ${props.participant_id ? 'd-none' : ''}`}>
                <label className="text-required">Participant IDs</label>
                <input
                  ref={input}
                  name="participant_ids"
                  placeholder="Add participant IDs"
                  value={formik.values.participant_ids}
                  onChange={formik.handleChange}
                  className="participant-ids zindex-0"
                />
                <div className="po-text">You can copy/paste multiple IDs separated by newline</div>

                {formik.touched.participant_ids &&
                  formik.errors.participant_ids && (
                    <p className="po-text text-red-soft">
                      {formik.errors.participant_ids}
                    </p>
                  )}

                {(errorState.missingIds.length > 0) && (
                  <p className="po-text text-red-soft">
                    Custom attribute is not set. Please fix or remove the invalid IDs before continuing.
                  </p>
                )}

                {errorState.missingIds.length > 0 && (
                  <p className="float-right link-visited po-text">
                    <a href="/" className="cursor-pointer" onClick={clearInvalidParticipantIds}>
                      Remove invalid participants
                    </a>
                  </p>
                )}

                {participantIDs.length > 0 && errorState.missingIds.length === 0 && (
                  <p className="float-right link-visited po-text">
                    <a href="/" className="cursor-pointer" onClick={clearParticipantIds}>
                      Clear
                    </a>
                  </p>
                )}
              </div>

              <div className="wrap_link flex-row">
                <button
                  type="submit"
                  id="add-filter-button"
                  data-loading-text="Setting custom attribute..."
                  className="btn btn-primary clickable mr-3 no-border">
                  Submit
                </button>
                <button
                  className="btn btn-default clickable"
                  type="button"
                  onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
          {props.participant_id ? (
            <div className="div col-md-6">
              <h2>Current attributes</h2>
              <div className="panel panel-default wrap_projects current-attribute">
                {currentAttributes?.length ?
                  <div className="panel-body project_detail">
                    <div className="row list-item list-heading ex-attribute-header text-center">
                      <div className="col-6 px-2">Attribute</div>
                      <div className="col-6 px-2">Value</div>
                    </div>
                    <div className="set-attribute">
                      {currentAttributes?.map((data) => (
                        <div
                          className="row list-item text-center flex-row current-attributes-list-item"
                          key={data.attribute_name}
                        >
                          <div className="col-6 px-2">{data.attribute_name}</div>
                          <div className="col-6 px-2">{data.attribute_value}</div>
                        </div>
                      ))}
                    </div>
                  </div> : null
                }
                {participantCurrentAttributes?.isLoading && (
                  <div className="wrap_info">
                    <div className="spinner vertical-space-md"/>
                  </div>
                )}
                {isNoAttributesSet() && (
                  <div className="wrap_info p-4 text-orange">
                    No attributes set to this participant.
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </span>
  )
}

export default SetAttribute
