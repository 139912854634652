import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Spinner from '../../../common/Spinner'

import {getQuotaFeasibilityCount} from '../../../../redux/actions'
import {isEqual} from 'lodash'

const QuotaFeasibilityCount = ({quotaId, optionId, minAge, maxAge, gender}) => {
  const feasibility = useSelector(state => state.quotaFeasibilityCount?.[quotaId]?.[optionId]) || {}
  const {count, isLoading} = feasibility

  const {task_parameters, isDetailsFormValid, platform} = useSelector(state => state.runFormOverview)
  const {
    country_name,
    number_of_submissions,
    max_time_per_submission,
    expires_after
  } = task_parameters

  const taskParamsRef = useRef({
    country_name,
    number_of_submissions,
    max_time_per_submission,
    expires_after
  })
  const dispatch = useDispatch()

  const getFeasibilityCount = () => {
    if (isLoading || !isDetailsFormValid) return

    const taskParams = {
      country_name,
      number_of_submissions,
      max_time_per_submission,
      expires_after
    }
    const isTaskParamsSame = isEqual(taskParams, taskParamsRef.current)
    if (isTaskParamsSame && count) return

    taskParamsRef.current = taskParams
    let targetGroup = {}
    if (quotaId === 'censusQuota') {
      targetGroup['minAge'] = minAge
      targetGroup['maxAge'] = maxAge
      targetGroup['gender'] = gender === 'male' ? 1 : 2
    } else if (quotaId === 'cintAge') {
      targetGroup['minAge'] = minAge
      targetGroup['maxAge'] = maxAge
    } else if (quotaId === 'cintGender') {
      targetGroup['gender'] = optionId === 'male' ? 1 : 2
    } else {
      targetGroup['variableIds'] = [optionId]
    }

    const quota_groups = [{
      name: 'General',
      limitType: 0,
      quotas: [
        {
          name: 'General',
          limit: number_of_submissions,
          targetGroup
        }
      ]
    }]
    const params = {
      country_name,
      number_of_submissions: parseInt(number_of_submissions),
      max_time_per_submission: parseInt(max_time_per_submission),
      expires_after: parseInt(expires_after),
      quota_groups,
      platform_id: platform.id,
    }
    dispatch(getQuotaFeasibilityCount(params, null, {
      quotaId: quotaId,
      optionId: optionId
    }))
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getFeasibilityCount()
  }, [
    country_name,
    number_of_submissions,
    max_time_per_submission,
    expires_after,
    isDetailsFormValid
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      {isLoading
        ? <Spinner disablePadding={true} style={{scale: '0.8'}}/>
        : count
      }
    </>
  )
}

export default QuotaFeasibilityCount
