import Select from "react-select";
import React from "react";

export const ProjectSelection = (props) => {

  const createOption = (options) => {
    if (options) {
      if (options.length && typeof options === 'object') {
        return options.map(option => ({
          label: option.name,
          value: option.id
        }))
      } else {
        return {
          label: options,
          value: options
        }
      }
    }
  }

  return (
    <div className='d-flex flex-row align-items-center'>
      <span className='po-text-bold font-size-15 pr-2'>Project</span>
      <Select
        name="project-selection"
        className="full-width run-country"
        value={{label: props.defaultProject.name || '', value: props.defaultProject.id || ''}}
        onChange={props.handleProjectChange}
        options={createOption(props.projects)}
        noOptionsMessage={() => 'No results found'}
        placeholder="Select Project"
        classNamePrefix="react-select"
        tabIndex={props.tabIndex}
        isSearchable
        styles={{
          container: styles => ({
            ...styles,
            width: '100%',
            borderRadius: 4,
            backgroundColor: '#fff',
            appearance: 'none',
            color: 'rgba(50, 60, 71, 0.7)',
            fontSize: 14,
            letterSpacing: -0.4,
            fontFamily: 'Muli-SemiBold',
          }),
          valueContainer: (styles) => ({...styles, padding: '0 0 0 20px'}),
          control: (styles) => ({...styles, cursor: 'pointer', border: '1px solid #e2e9ef'}),
          indicatorSeparator: () => ({display: 'none'}),
          input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
          singleValue: (styles) => ({
            ...styles,
            color: 'var(--input-text-color)',
            width: 'calc(100% - 20px)',
          }),
          menuList: (styles) => ({...styles, maxHeight: 200}),
          option: (styles) => ({...styles, paddingLeft: 20}),
          noOptionsMessage: (styles) => ({...styles, paddingLeft: 20, textAlign: 'left'}),
          placeholder: (styles) => ({
            ...styles,
            color: 'var(--placeholder-color)',
          }),
        }}
      />
    </div>
  )
}
