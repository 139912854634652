import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {connect} from 'react-redux'
import swal from 'sweetalert'

// Custom components
import FormErrors from './FormErrors'
import Currency from './common/Currency'
import {formatAsCurrency} from './run/enhanced-run-form/RunUtils'
import Input from './common/Input'

// Redux actions
import {
  updateSpendingLimitReset,
  updateSpendingLimitSubmit
} from '../redux/actions'

const $ = window.$

const UpdateSpendingLimit = (props) => {
  const openUpdateSpendingLimitPopup = () => {
    formik.resetForm()
    $.magnificPopup.open({
      items: {src: '#spending_update_limit'},
      type: 'inline',
      modal: true
    })
  }

  const closeUpdateSpendingLimitPopup = (e) => {
    e.preventDefault()
    props.updateSpendingLimitReset()
    $.magnificPopup.close()
  }

  const formik = useFormik({
    initialValues: {
      spending_limit: props.currentUser?.spending_limit || 15
    },
    validationSchema: Yup.object({
      spending_limit: Yup.number()
        .required('Spending limit is required')
        .min(15, 'Spending limit must be greater than or equal to $15.00')
        .max(2500, 'Spending limit must be less than or equal to $2500.00')
        .typeError('')
    }),
    enableReinitialize: true,
    onSubmit: values => {
      if (formik.isValid) {
        if (props.needsConfirmation === false) {
          props.updateSpendingLimitSubmit(values)
        } else {
          swal({
            title: 'Are you sure?',
            text: `You are changing your spending limit per run to ${formatAsCurrency(values.spending_limit)}`,
            icon: 'warning',
            buttons: {
              cancel: 'Cancel',
              confirm: 'Confirm'
            }
          }).then((isConfirmed) => {
            if (isConfirmed) {
              props.updateSpendingLimitSubmit(values)
            }
          })
        }
      }
    }
  })

  if (props.updated) {
    props.updateSpendingLimitReset()
    $.magnificPopup.close()
  }

  const handleChange = (e, value) => {
    formik.setFieldValue(e.target.name, value)
  }

  return (
    <>
      {/* Update spending limit UI */}
      {props.ui === 'showOnlyPopup' ? (
        <button
          className="link popup-content bg-transparent border-0 clickable"
          onClick={openUpdateSpendingLimitPopup}
        >
          Click here to update your spending limit now.
        </button>
      ) : (
        <div className="item">
          <div className="credits spending">
            <div className="panel-heading">
              <h2 className="clear-margin">Spending Limit</h2>
              <button
                className="btn btn-primary popup-content"
                onClick={openUpdateSpendingLimitPopup}
              >
                Update Limit
              </button>
            </div>
            <div className="panel-body">
              <div className="wrap_center">
                <label className="label label-success ng-binding">
                  <Currency value={props.currentUser?.spending_limit}/>
                </label>
                <h2>Maximum run spend</h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Update spending limit pop-up */}
      <div id="spending_update_limit" className="white-popup mfp-hide">
        <h2>Update Limit</h2>
        <form
          noValidate
          name="spendingLimitForm"
          className="wrap_info"
          onSubmit={formik.handleSubmit}
        >
          <div className="wrap_item">
            <FormErrors errors={props.errors}/>
            <label htmlFor="spending_limit" className="text-required">Spending limit</label>
            <div className="form-group input-group">
              <div className="billing-page-currency zindex-0">USD $</div>
              <Input
                type="text"
                id="spending_limit"
                name="spending_limit"
                className="billing_currency_input"
                onChange={handleChange}
                value={formik.values.spending_limit}
                allowOnlyNumbers
              />
            </div>
            {formik.errors.spending_limit && (
              <p className="po-text text-red-soft">{formik.errors.spending_limit}</p>
            )}
          </div>
          <div className="wrap_link flex-row">
            <button
              name="update-spending-limit"
              id="btn-update-spending-limit"
              type="submit"
              className="btn btn-primary refresh clickable no-border btn-add-card"
              disabled={props.isLoading}
            >
              {props.isLoading ? 'Updating...' : 'Update'}
            </button>
            <button
              className="btn btn-default cancel clickable"
              onClick={closeUpdateSpendingLimitPopup}
              disabled={props.isLoading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.updateSpendingLimit,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  updateSpendingLimitReset: () => dispatch(updateSpendingLimitReset()),
  updateSpendingLimitSubmit: (params) => dispatch(updateSpendingLimitSubmit(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSpendingLimit)
