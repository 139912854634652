import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import swal from 'sweetalert'

import {isCintPlatform} from '../../utils/constants'

import {
  runPauseInit,
  runPauseSubmit,
  runPauseFetch,
} from '../../redux/actionss/runActions'


const PauseRun = (props) => {
  const [isPausing, setIsPausing] = useState(false)

  useEffect(() => {
    if (isPausing) {
      if (props.status === 'success') {
        swal.close()
        setIsPausing(false)
      } else if (props.status === 'error') {
        setIsPausing(false)
        swal.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  const isPausable = () => {
    const pausableMturkRun = (
      ['Positly', 'Amazon Mechanical Turk'].includes(props.platformType) &&
      ['Running', 'Hung', 'Retry Exhausted'].includes(props.labelName)
    )

    const pausableCintRun = (
      isCintPlatform(props.platformType) &&
      props.labelName === 'Running' &&
      props.totalAcceptedCount > 0
    )

    return pausableMturkRun || pausableCintRun
  }

  const onPause = (event) => {
    event.preventDefault()
    props.runPauseInit()
    swal({
      className: 'sweet-alert',
      title: 'Are you sure?',
      text: 'You are about to pause this run, participants with open assignments will be able to complete their assignments, but no new assignments will be accessible to participants',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          text: 'Pause',
          className: 'btn-primary'
        }
      }
    })
      .then((value) => {
        if (value) {
          setIsPausing(true)
          props.runPauseSubmit(props.id, props.page)
        }
      })
  }

  return (
    <>
      {isPausable() && props.pauseUI !== 'button' && (
        <li className='cursor-pointer dropdown-item' onClick={(e) => onPause(e)}>
          <span>
            <i className="fa fa-pause fa-fw"/>
            &nbsp;Pause Run
        </span>
        </li>
      )}
      {isPausable() && props.pauseUI === 'button' && (
        <Link
          to=""
          className="btn btn-primary btn-danger transition-0 clickable pull-right ml-2 mr-2"
          onClick={(e) => onPause(e)}>
          <i className="fa fa-pause"/>
          <span className="fa-text">Pause Run</span>
        </Link>
      )}
    </>
  )
}

const mapStateToProps = state => ({...(state.runPause || {})})

const mapDispatchToProps = dispatch => ({
  runPauseInit: () => dispatch(runPauseInit()),
  runPauseSubmit: (id, page) => {
    dispatch(runPauseSubmit())
    dispatch(runPauseFetch(id, page))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PauseRun)
