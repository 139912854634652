import React from 'react'
import PropTypes from 'prop-types'

import {formatAsCurrency} from '../run/enhanced-run-form/RunUtils'

const defaultProps = {
  fractionDigits: 2,
  placeholder: '',
  symbol: '$',
}

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fractionDigits: PropTypes.number,
  placeholder: PropTypes.string,
  symbol: PropTypes.string,
}

const Currency = ({value, fractionDigits, placeholder, symbol}) => {

  let result

  if (value === 0) {
    result = '$0.00'
  } else if (value > 0) {
    result = formatAsCurrency(value, fractionDigits, symbol)
  } else {
    result = placeholder
  }

  return (
    <>{result}</>
  )
}

Currency.defaultProps = defaultProps
Currency.propTypes = propTypes

export default Currency
