import React from 'react'
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Cleave from 'cleave.js/react'

// Custom components
import FormErrors from './FormErrors'
import {successToast} from './helpers/notification'

// Redux actions
import {
  creditCardAddOrUpdateInit,
  creditCardAdd,
  creditCardUpdate,
  creditCardAddOrUpdateReset
} from '../redux/actions'

// Constants
const $ = window.$

const CreditCard = (props) => {
  const formik = useFormik({
    initialValues: {
      credit_card_number: '',
      credit_card_expiry_month: '',
      credit_card_expiry_year: '',
      credit_card_cvc: '',
      cardholder_name: ''
    },
    validationSchema: Yup.object({
      credit_card_number: Yup.number()
        .required('Credit card number is required'),
      credit_card_expiry_month: Yup.number()
        .required('Month is required'),
      credit_card_expiry_year: Yup.number()
        .required('Year is required'),
      credit_card_cvc: Yup.number()
        .required('CVC is required'),
      cardholder_name: Yup.string()
        .required('Cardholder name is required')
    }),
    onSubmit: values => {
      if (formik.isValid) {
        props.creditCardAddOrUpdateInit()

        if (props.currentUser?.is_card_linked)
          props.creditCardUpdate(values)
        else
          props.creditCardAdd(values)
      }
    }
  })

  const openCreditCardPopup = (e) => {
    e.preventDefault()
    formik.resetForm()
    $.magnificPopup.open({
      items: {src: '#card-popup'},
      type: 'inline',
      modal: true
    })
  }

  const closeCreditCardPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  if (props.isAddedOrUpdated) {
    props.creditCardAddOrUpdateReset()
    $.magnificPopup.close()
    successToast(`Credit card ${props.action === 'add' ? 'added' : 'updated'} successfully.`)

    const payWithBox = document.getElementById('pay-with-box')
    if (payWithBox)
      payWithBox.classList.remove('highlight-box')

    if (props.callbackRef && typeof props.callbackRef.current === 'function')
      props.callbackRef.current(props.currentUser)
  }

  return (
    <div className={`item ${props.className}`}>
      {props.ui === 'showOnlyPopup' ? (
        <>
          {props.action === 'add' && (
            <a
              href="/"
              className={`${props.variant === 'button'
                ? 'btn btn-primary border-0 clickable'
                : 'link popup-content bg-transparent border-0 clickable'}`
              }
              onClick={openCreditCardPopup}
            >
              Add Card
            </a>
          )}
          {props.action === 'edit' && (
            <a
              href="/"
              className="clickable copy_link"
              onClick={openCreditCardPopup}
            >
              Edit Card
            </a>
          )}
        </>
      ) : (
        <>
          {props.currentUser?.is_card_linked && (
            <div className="credits cart_block">
              <div className="panel-heading">
                <h2 className="clear-margin">
                  Credit Card
                </h2>
                <button
                  className="btn btn-primary popup-content clickable"
                  onClick={openCreditCardPopup}
                >
                  Update Card
                </button>
              </div>
              <div className="panel-body">
                <div className="cart_front cart">
                  <div className="number">
                    XXXX XXXX XXXX {props.currentUser?.card_last_four_digits}
                  </div>
                  <div className="date">
                    XX/XX
                  </div>
                </div>
                <div className="cart_back cart">
                  <div className="code">
                    XXX
                  </div>
                </div>
              </div>
            </div>
          )}

          {!props.currentUser?.is_card_linked && (
            <div className="credits cart_block">
              <div className="panel-heading">
                <a
                  href="/"
                  className="btn btn-primary popup-content clickable"
                  id="btn-add-card"
                  onClick={openCreditCardPopup}
                >
                  Add Card
                </a>
              </div>
            </div>
          )}
        </>
      )}

      <div id="card-popup" className="white-popup mfp-hide top-visible">
        <h2>{props.currentUser?.is_card_linked ? 'Update' : 'Add'} Card</h2>
        <form
          name="cardForm"
          id="cardForm"
          className="wrap_info"
          onSubmit={formik.handleSubmit}
        >
          <div className="wrap_item">
            <FormErrors errors={props.errors}/>
            <div className="form-group">
              <label className="text-required">Card number</label>
              <Cleave
                type="text"
                name="credit_card_number"
                className="card_number"
                placeholder="Enter your credit card number"
                options={{creditCard: true}}
                onChange={formik.handleChange}
                value={formik.values.credit_card_number}
              />
              {(formik.touched.credit_card_number && formik.errors.credit_card_number)
                ? <p className="po-text text-red-soft">{formik.errors.credit_card_number}</p>
                : null
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="wrap_item">
                <label className="text-required">Validity</label>
                <div className="row">
                  <div className="col-md-3 month-year-input">
                    <div className="form-group">
                      <Cleave
                        type="text"
                        name="credit_card_expiry_month"
                        placeholder="MM"
                        className="validity"
                        options={{date: true, datePattern: ['m']}}
                        onChange={formik.handleChange}
                        value={formik.values.credit_card_expiry_month}
                      />
                      {formik.touched.credit_card_expiry_month && formik.errors.credit_card_expiry_month
                        ? <p className="po-text text-red-soft">{formik.errors.credit_card_expiry_month}</p>
                        : null
                      }
                    </div>
                  </div>
                  <div className="col-md-1 cc-expiry-slash cc-slash">
                    <p>/</p>
                  </div>
                  <div className="col-md-3 month-year-input">
                    <div className="form-group">
                      <Cleave
                        placeholder="YY"
                        type="text"
                        name="credit_card_expiry_year"
                        className="validity"
                        options={{date: true, datePattern: ['y']}}
                        onChange={formik.handleChange}
                        value={formik.values.credit_card_expiry_year}
                      />
                      {(formik.touched.credit_card_expiry_year && formik.errors.credit_card_expiry_year)
                        ? <p className="po-text text-red-soft">{formik.errors.credit_card_expiry_year}</p>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-5 col-md-5 cvc-field">
              <div className="wrap_item">
                <div className="form-group">
                  <label className="text-required">CVC</label>
                  <Cleave
                    placeholder="CVC"
                    type="text"
                    name="credit_card_cvc"
                    className="cvc"
                    onChange={formik.handleChange}
                    value={formik.values.credit_card_cvc}
                    options={{blocks: [4], numericOnly: true}}
                  />
                  {(formik.touched.credit_card_cvc && formik.errors.credit_card_cvc)
                    ? <p className="po-text text-red-soft">{formik.errors.credit_card_cvc}</p>
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="wrap_item">
            <div
              className="form-group">
              <label className="text-required">Cardholder name</label>
              <input
                type="text"
                name="cardholder_name"
                placeholder="Cardholder name"
                className="card_holder"
                onChange={formik.handleChange}
                value={formik.values.cardholder_name}
              />
              {(formik.touched.cardholder_name) && formik.errors.cardholder_name
                ? <p className="po-text text-red-soft">{formik.errors.cardholder_name}</p>
                : null
              }
            </div>
          </div>
          <div className="wrap_link flex-row">
            {!props.currentUser?.is_card_linked && (
              <button
                name="addCard"
                id="btn-card"
                className="btn btn-primary refresh clickable mr-3 no-border"
                disabled={props.isLoading}
              >
                {props.isLoading ? 'Adding card...' : 'Add Card'}
              </button>
            )}
            {props.currentUser?.is_card_linked && (
              <button
                name="updateCard"
                id="btn-card"
                className="btn btn-primary refresh clickable mr-3 no-border"
                disabled={props.isLoading}
              >
                {props.isLoading ? 'Updating card...' : 'Update Card'}
              </button>
            )}
            <button
              onClick={closeCreditCardPopup}
              className="btn btn-default cancel clickable"
              disabled={props.isLoading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.creditCard,
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => ({
  creditCardAddOrUpdateInit: () => dispatch(creditCardAddOrUpdateInit()),
  creditCardAdd: (creditCard) => dispatch(creditCardAdd(creditCard)),
  creditCardUpdate: (creditCard) => dispatch(creditCardUpdate(creditCard)),
  creditCardAddOrUpdateReset: () => dispatch(creditCardAddOrUpdateReset())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)
