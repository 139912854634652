import React from 'react'

const PositlyLink = ({id, className = '', path}) => {
  const {protocol, host} = window.location
  const link = `${protocol}//${host}${path}`

  return (
    <div
      id={id}
      className={`po-ns positly-link ${className}`}
      data-testid="completion-link"
    >
      {link}
    </div>
  )
}

export default PositlyLink
