import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import * as _ from 'lodash'

import {connect} from 'react-redux'
import Spinner from './common/Spinner'

import {
  platformListFetch,
  platformDeleteInit,
  platformDeleteFetch,
} from '../redux/actionss/platformActions'

const PlatformListItem = props => {
  let id = props.id

  const deletePlatform = (event) => {
    event.preventDefault()
    props.platformDelete(id)
  }

  return (
    <div className="runlist">
      <div className="row list-item">
        <div className="col-md-4">
          <span className="list-item-label po-text-bold pr-1">Platform Name:</span>
          <span className="title">
            {props.name ? props.name : props.platform_type}
          </span>
          {props.is_deprecated && (
            <span className="text-red-soft platform-deprecated">
              (Deprecated)
            </span>
          )}
        </div>
        <div className="col-md-4">
          <span className="list-item-label po-text-bold pr-1">Mode:</span>
          {props.mode === 'sandbox' && (
            <span className="color_2">Sandbox</span>
          )}
          {props.mode === 'live' && (
            <span className="color_1">Live</span>
          )}
        </div>
        <div className="col-md-3">
          <span className="list-item-label po-text-bold pr-1">Status :</span>
          <span className="title">
            {_.capitalize(props.status)}
          </span>
        </div>
        {props.platform_type === 'Amazon Mechanical Turk' && props.runs_count === 0 && (
          <div className="col-md-1">
            <div className="wrap_operation d-flex align-items-center">
              <Link
                to=""
                onClick={deletePlatform}
                title="Delete platform"
                className="delete">
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const PlatformList = props => {
  useEffect(() => {
    props.platformListFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortPlatforms = (platforms) => {
    const sortOrder = ['Positly', 'Cint', 'Amazon Mechanical Turk']

    return platforms.sort((a, b) => {
      if (a.platform_type === b.platform_type) {
        return a.platform_type.localeCompare(b.platform_type)
      } else {
        return sortOrder.indexOf(a.platform_type) - sortOrder.indexOf(b.platform_type)
      }
    })
  }

  return (
    <div>
      <div className="metrics project_detail">
        <div className="wrap_title d-flex">
          <h1>Platforms</h1>
        </div>
      </div>
      <div className="wrap_projects platform">
        <div className="runlist">
          <div className="row list-item list-heading">
            <div className="col-md-4">
              Platform Name
            </div>
            <div className="col-md-4">
              Mode
            </div>
            <div className="col-md-3">
              Status
            </div>
          </div>
        </div>
        {props.isLoading
          ? <Spinner/>
          : (
            <div>
              {props.platforms && sortPlatforms(props.platforms).map((platform, index) =>
                <PlatformListItem key={index} {...platform} platformDelete={props.platformDelete}/>)}
            </div>
          )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({...state.platformList})

const mapDispatchToProps = dispatch => ({
  platformListFetch: () => dispatch(platformListFetch()),
  platformDelete: (id) => {
    dispatch(platformDeleteInit())
    dispatch(platformDeleteFetch(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformList)
