import React from 'react'

const AddCensusQuota = ({handleAddCensusQuota, showCensusQuota}) => {
  return (
    showCensusQuota ? (
      <div className="box">
        <h2 className="big mb-2">
          Target a nationally representative sample using a census quota. &nbsp;
          <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
            <span className="po-tooltiptext po-tooltip-position">
              By using a census quota, Positly will aim to get study participants that match the age and
              gender distribution of your target country.
              <br/> <br/>
              For example, if in your targeted country, 10% of people are women aged 18-23, with this feature activated,
              Positly will aim to make 10% of your study participants be women aged 18-23.
              <br/><br/>
              Quotas are approximate, so you may not get exactly the number of people in each group that are specified.
            </span>
          </div>
        </h2>

        <div className="wrap_info_form help-text">
          <span>
            If you need a representative target audience, Positly can use official census data to create
            an interlocked age and gender quota.
          </span>
        </div>

        <div className="btn-container mt-3 d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary no-border clickable transition-0"
            onClick={handleAddCensusQuota}
          >
            Add Census Quota
          </button>
        </div>
      </div>
    ) : null
  )
}

export default AddCensusQuota
