import React from "react";
import {Alert} from '../../common/Alert'

export const NoParticipants = (props) => {
  const defaultMessage = 'Once you recruit study participants in this project, your participants will appear here.';

  return (
    <Alert message={props.message || defaultMessage}
           type="warn">
    </Alert>
  )
}
