import React, {useEffect, useState} from 'react'

const formatInputValue = (value = '', allowDecimals) => {
  let inputValue = value.toString()
  if (allowDecimals) {
    inputValue = inputValue
      .replace(/(\..*)\./g, '$1')
      .replace(/[^0-9.]/g, '')
  } else {
    inputValue = inputValue.replace(/[^0-9]/g, '')
  }

  return inputValue
}

const NumericInput = ({value, allowDecimals, min, max, onChange, ...props}) => {
  const [formattedValue, setFormattedValue] = useState()

  const handleChange = (e) => {
    e.preventDefault()
    let inputValue = e.target.value
    setFormattedValue(() => formatInputValue(inputValue, allowDecimals))
    onChange(e, inputValue)
  }

  useEffect(() => {
    if (value === formattedValue) return

    setFormattedValue(() => formatInputValue(value, allowDecimals))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      {...props}
      type="text"
      value={formattedValue}
      onChange={handleChange}
    />
  )
}

export default NumericInput
