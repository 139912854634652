import {userActions} from '../actionss/userActions'

const userReducer = (state, action) => {
  switch (action.type) {
    case userActions.USER_SIGN_UP_INIT:
      return {...state, signUp: {isLoading: true}}
    case userActions.USER_SIGN_UP_SUCCESS:
      return {...state, signUp: {isLoading: false}, confirmEmail: null}
    case userActions.USER_SIGN_UP_ERROR:
      return {...state, signUp: {isLoading: false, errors: action.errors}}

    case userActions.USER_SIGN_IN_INIT:
      return {...state, signIn: {}}
    case userActions.USER_SIGN_IN_SUBMIT:
      return {...state, signIn: {isLoading: true}}
    case userActions.USER_SIGN_IN_SUCCESS:
      return {...state, signIn: {isLoading: false}, currentUser: action.user}
    case userActions.USER_SIGN_IN_ERROR:
      return {...state, signIn: {isLoading: false, errors: action.errors}}

    case userActions.USER_SIGN_OUT_SUCCESS:
      return {...state, currentUser: null, isSignedOut: true, announcements: null}

    case userActions.USER_TOKEN_VALIDATION_SKIP:
      return {...state, isTokenValidating: false}
    case userActions.USER_TOKEN_VALIDATION_SUCCESS:
      return {...state, isTokenValidating: false, currentUser: action.user}
    case userActions.USER_TOKEN_VALIDATION_ERROR:
      return {...state, isTokenValidating: false}

    case userActions.USER_ACCOUNT_INIT:
      return {...state, userAccount: {isLoading: true}}
    case userActions.USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userAccount: {isLoading: false, profile: action.profile},
        currentUser: {...action.profile}
      }
    case userActions.USER_ACCOUNT_ERROR:
      return {...state, userAccount: {isLoading: false, errors: action.errors}}

    case userActions.USER_REQUEST_PASSWORD_INIT:
      return {...state, requestPassword: {}}
    case userActions.USER_REQUEST_PASSWORD_SUBMIT:
      return {...state, requestPassword: {isLoading: true}}
    case userActions.USER_REQUEST_PASSWORD_SUCCESS:
      return {...state, requestPassword: {status: 'success'}}
    case userActions.USER_REQUEST_PASSWORD_ERROR:
      return {
        ...state,
        requestPassword: {isLoading: false, errors: action.errors}
      }

    case userActions.USER_RESET_PASSWORD_INIT:
      return {...state, resetPassword: {}}
    case userActions.USER_RESET_PASSWORD_SUBMIT:
      return {...state, resetPassword: {isLoading: true}}
    case userActions.USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {isLoading: false, errors: action.errors}
      }
    case userActions.USER_RESET_PASSWORD_SUCCESS:
      return {...state, resetPassword: {status: 'success'}}

    case userActions.USER_PASSWORD_CHANGE_INIT:
      return {...state, changePassword: {}}
    case userActions.USER_PASSWORD_CHANGE_SUBMIT:
      return {...state, changePassword: {isLoading: true}}
    case userActions.USER_PASSWORD_CHANGE_SUCCESS:
      return {...state, changePassword: {isLoading: false, status: 'success'}}
    case userActions.USER_PASSWORD_CHANGE_ERROR:
      return {...state, changePassword: {isLoading: false, errors: action.errors}}

    case userActions.USER_ACCOUNT_UPDATE_INIT:
      return {...state, userAccount: {...state.userAccount, isUpdating: true}}
    case userActions.USER_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        userAccount: {profile: action.userAccount, isUpdating: false, isUpdated: true},
        currentUser: {
          ...action.userAccount
        }
      }
    case userActions.USER_ACCOUNT_UPDATE_ERROR:
      return {...state, userAccount: {...state.userAccount, isUpdating: false, errors: action.errors}}

    case userActions.USER_UPDATE_CURRENT_USER:
      return {...state, currentUser: {...state.currentUser, ...action.user}}

    default:
      return state
  }
}

export default userReducer
