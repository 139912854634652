import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
  participantAttributesFetch,
  participantCurrentAttributesFetch,
  participantSendMessageInit,
  sendBonusInit
} from "../../../redux/actionss/runActions";
import {userBalanceFetch} from "../../../redux/actionss/userActions";
import SendMessage from "../../SendMessage";
import SetAttribute from "../../SetAttribute";
import SendBonus from "../../SendBonus";
import SpendingLimitWarning from "../../common/SpendingLimitWarning";
import AddCardWarning from "../../common/AddCardWarning";
import Spinner from "../../common/Spinner";

import {PlatformTabItem} from "./PlatformTabItem";
import {PlatformTabContent} from "./PlatformTabContent";
import {NoParticipants} from "./NoParticipants";
import {useFormik} from "formik";
import {getQuoteForMturk} from "../../../redux/actionss/quoteActions";
import {
  fetchAbstractPlatforms,
  fetchProjectAssignments,
  fetchProjectsForParticipantsPage,
  fetchUserRecentlyUsedPlatform, fetchUserRecentlyUsedPlatformInProject,
  fetchUserRecentlyUsedProjectInPlatform
} from "../../common/fetch/ParticipantsPageFetchutils";
import qs from "qs";
import {Link} from "react-router-dom";

const $ = window.$

export const Participants = (props) => {

  const assignmentsPerPage = parseInt(((window.innerHeight - 220) / 50).toString()) + 5

  const id = ''
  const run = {}

  const [isLoading, setIsLoading] = useState(false)
  const [isAssignmentsLoading, setIsAssignmentsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [platforms, setPlatforms] = useState([])
  const [projects, setProjects] = useState([])
  const [assignments, setAssignments] = useState([])
  const [pageNumber, setPageNumber] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [recentlyUsedPlatformId, setRecentlyUsedPlatformId] = useState(null)
  const [recentlyUsedProjectIdInPlatform, setRecentlyUsedProjectIdInPlatform] = useState(null)
  const [isNoRunsPublished, setIsNoRunsPublished] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)

  const [activeTab, setActiveTab] = useState(null)
  const [defaultProject, setDefaultProject] = useState({})
  const [prioritizedPlatforms, setPrioritizedPlatforms] = useState([])
  const [searchQuery, setSearchQuery] = useState(null)
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(null)
  const [popupProps, setPopupProps] = useState(null)
  const [popupId, setPopupId] = useState(null)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [showSpendingLimitWarning, setShowSpendingLimitWarning] = useState(false)
  const [showAddCardWarning, setShowAddCardWarning] = useState(false)
  const [defaultProjectId, setDefaultProjectId] = useState(null)
  const [spendingLimitWarningMessage, setSpendingLimitWarningMessage] = useState('')
  const [publishedRunsCountInPlatform, setPublishedRunsCountInPlatform] = useState(null)
  const spendingLimitCallbackRef = useRef()
  const addCardCallbackRef = useRef()

  const dispatch = useDispatch()

  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const projectId = queryParams.project_id

  const searchFormik = useFormik({
    initialValues: {
      query: ''
    },
    onSubmit: values => {
      handleSearchSubmit(values.query)
    }
  })

  const openPopup = (id, properties, event) => {
    if (event) event.preventDefault()

    if (id === 'set-attribute-popup') {
      dispatch(participantAttributesFetch())
      if (properties?.participant_id) {
        dispatch(participantCurrentAttributesFetch(properties.participant_id))
      }
    } else if (id === 'message-popup') {
      dispatch(participantSendMessageInit())
    } else if (id === 'bonus-popup') {
      dispatch(sendBonusInit())
      dispatch(getQuoteForMturk({platform_type: getActivePlatform()?.platform_type}))
      dispatch(userBalanceFetch())
    }

    setPopupProps(properties)
    setPopupId(id)
    setPopupVisibility(true)
  }

  const closePopup = () => {
    setPopupVisibility(false)
  }

  const isActiveTab = (tab) => {
    return activeTab === tab
  }

  const onTabClick = (event) => {
    event.preventDefault()

    if(!isLoading && !isAssignmentsLoading){
      const tabText = event.target.text
      if (tabText) {
        const tab = tabText.toLowerCase()
        setActiveTab(tab)
      }
    }
  }

  const getActivePlatform = () => {
    return platforms.find((platform) => platform.full_name.toLowerCase() === activeTab)
  }

  const handleProjectChange = (e) => {
    const newProjectId = e.value
    setSelectedProjectId(newProjectId)

    if(newProjectId){
      setIsAssignmentsLoading(true)
      setAssignments([])
      const params = {
        project_id: newProjectId,
        platform_id: getActivePlatform()?.id,
        page_number: 1
      }

      if(searchQuery) {
        params.query = searchQuery
      }

      fetchAssignments(params)
    }
  }

  const loadProjectAssignments = () => {
    let newPageNumber = pageNumber + 1

    if(isSearchSubmitted){
      newPageNumber = 1
      setIsSearchSubmitted(false)
    }

    const params = {
      project_id: recentlyUsedProjectIdInPlatform,
      platform_id: getActivePlatform().id,
      page_number: newPageNumber
    }

    if(searchQuery){
      params.query = searchQuery
    }

    fetchAssignments(params)
  }

  const handleClear = (query) => {
    setIsSearchSubmitted(false)
    if (searchQuery && (searchQuery !== query || query.length === 0)) {
      handleSearchSubmit(query)
    }
  }

  const handleSearchSubmit = (query) => {
    setSearchQuery(query)
    setIsSearchSubmitted(true)
  }

  const setupListForProject = (projectId) => {
    setRecentlyUsedProjectIdInPlatform(projectId)
    fetchProjects()

    const params = {
      project_id: projectId,
      platform_id: getActivePlatform().id,
      page_number: 1
    }

    if(searchQuery) {
      params.query = searchQuery
    }

    fetchAssignments(params)
  }

  const handleRecentlyUsedPlatformResponse = (data) => {
    const errors = data.errors

    if (errors?.length) {
      setError(errors.join(', '))
      setIsLoading(false)
    } else {
      if(data.platform_id){
        setRecentlyUsedPlatformId(data.platform_id)
      } else {
        setIsNoRunsPublished(true)
        setIsLoading(false)
      }
    }
  }

  const fetchPlatforms =  () => {
    setIsLoading(true)

    fetchAbstractPlatforms()
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length) {
          setError(errors.join(', '))
          setIsLoading(false)
        } else {
          setPlatforms(data)

          if(defaultProjectId){
            const params = {
              project_id: defaultProjectId
            }

            fetchRecentlyUsedPlatformInProject(params)
          } else {
            fetchRecentlyUsedPlatform()
          }
        }
      })
  }

  const fetchRecentlyUsedPlatform = () => {
    fetchUserRecentlyUsedPlatform()
      .then(resp => resp.json())
      .then(data => {
        handleRecentlyUsedPlatformResponse(data)
      })
  }

  const fetchRecentlyUsedPlatformInProject = (params) => {
    let searchParams = new URLSearchParams(params).toString()

    fetchUserRecentlyUsedPlatformInProject(searchParams)
      .then(resp => resp.json())
      .then(data => {
        handleRecentlyUsedPlatformResponse(data)
      })
  }

  const fetchRecentlyUsedProjectInPlatform = (params) => {
    let searchParams = new URLSearchParams(params).toString()

    fetchUserRecentlyUsedProjectInPlatform(searchParams)
    .then(resp => resp.json())
    .then(data => {
      const errors = data.errors

      if (errors?.length) {
        setError(errors.join(', '))
        setIsLoading(false)
      } else {
        setupListForProject(data.project_id)
      }
    })
  }

  const fetchProjects = () => {
    fetchProjectsForParticipantsPage()
    .then(resp => resp.json())
    .then(data => {
      const errors = data.errors

      if (errors?.length) {
        setError(errors.join(', '))
        setIsLoading(false)
      } else {
        setProjects(data)
      }
    })
  }

  const fetchAssignments = (params) => {
    params.assignments_per_page = assignmentsPerPage
    let searchParams = new URLSearchParams(params).toString()

    fetchProjectAssignments(searchParams)
    .then(resp => resp.json())
    .then(data => {
      const errors = data.errors

      if (errors?.length) {
        setError(errors.join(', '))
      } else {
        if(params.page_number === 1){
          setAssignments(data.assignments)
          setPublishedRunsCountInPlatform(data.published_runs_from_platform)
        } else {
          setPublishedRunsCountInPlatform(null)
          setAssignments([...assignments, ...data.assignments])
        }

        setPageNumber(params.page_number)
        setRecentlyUsedProjectIdInPlatform(params.project_id)
        setHasMore(data.assignments.length === assignmentsPerPage)
      }

      setIsAssignmentsLoading(false)
      if(isLoading){
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    if(isSearchSubmitted){
      setAssignments([])
      setIsAssignmentsLoading(true)
      loadProjectAssignments()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isSearchSubmitted]);

  useEffect(() => {
    if (popupVisibility) {
      $.magnificPopup.open({
        items: {src: `#${popupId}`},
        type: 'inline',
        callbacks: {
          close: () => setPopupVisibility(false)
        },
        closeOnBgClick: false
      })
    } else {
      $.magnificPopup?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisibility])

  useEffect(() => {
    if(recentlyUsedPlatformId && platforms.length > 0){
      const recentPlatform = platforms.find((platform) => platform.id === recentlyUsedPlatformId)
      setActiveTab(recentPlatform.full_name.toLowerCase())
    }
  }, [recentlyUsedPlatformId, platforms]);

  useEffect(() => {
    if(recentlyUsedProjectIdInPlatform && projects.length > 0){
      const defaultProject = projects.find((project) => project.id === recentlyUsedProjectIdInPlatform)

      if(defaultProject){
        setSelectedProjectId(defaultProject.id)
        setDefaultProject(defaultProject)
      }
    }

  }, [recentlyUsedProjectIdInPlatform, projects]);

  useEffect(() => {
    if(activeTab && platforms.length > 0) {

      if (defaultProjectId) {
        setupListForProject(projectId)
        setDefaultProjectId(null)
      }else if(searchQuery){
        setupListForProject(selectedProjectId)
      } else {
        setIsAssignmentsLoading(true)
        setAssignments([])

        const platform = getActivePlatform()

        if(platform){
          const params = {
            platform_id: platform.id
          }

          if (selectedProjectId) {
            params.project_id = selectedProjectId
          }

          fetchRecentlyUsedProjectInPlatform(params)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, platforms]);

  useEffect(() => {
    if(platforms.length > 0){
      const billablePlatformNames = ['Microtaskers', 'Panel Participants']
      const billablePlatforms = platforms.filter(p => billablePlatformNames.includes(p.full_name))
      const nonBillablePlatforms = platforms.filter(p => !billablePlatformNames.includes(p.full_name))

      const prioritizedList = [...billablePlatforms, ...nonBillablePlatforms]
      setPrioritizedPlatforms(prioritizedList)
    }
  }, [platforms]);

  useEffect(() => {
    if (defaultProjectId) {
      fetchPlatforms();
    }
  }, [defaultProjectId]);

  useEffect(() => {
    if (projectId) {
      setDefaultProjectId(projectId)
    } else {
      fetchPlatforms();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(error) {
    return (
      <div className="box po-text text-center col-md-12">
        <h3 className="text-danger">{error}</h3>
      </div>
    )
  }

  return (
    <div className="mt-5">
      {isLoading ? <Spinner/> :
        <>
          {isNoRunsPublished ? (
            <NoParticipants
              message='Once you launch your first run of a study or survey, your participants will appear here.'
            />
          ) : (
            <>
              {projectId && (
                <div className='task-list-header top_line clearfix'>

                  <Link to={`/projects/show?id=${projectId}`} className="btn btn-default pull-left">
                    <i className="icon ion-android-arrow-back"/>
                    Back
                  </Link>
                </div>
              )}
              <div className="run_detail_tabs">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="tabs platform-tabs d-flex flex-wrap">
                      {prioritizedPlatforms.map((item, index) =>
                        <PlatformTabItem
                          key={index}
                          platform={item}
                          isActiveTab={isActiveTab}
                          onTabClick={onTabClick}
                        />
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                {prioritizedPlatforms.map((item, index) =>
                  <div key={index}>
                    {isActiveTab(item.full_name.toLowerCase()) && (
                      <PlatformTabContent
                        platform={item}
                        openPopup={openPopup}
                        assignments={assignments}
                        projects={projects}
                        defaultProject={defaultProject}
                        handleProjectChange={handleProjectChange}
                        loadProjectAssignments={loadProjectAssignments}
                        hasMore={hasMore}
                        isAssignmentsLoading={isAssignmentsLoading}
                        searchFormik={searchFormik}
                        searchQuery={searchQuery}
                        handleClear={handleClear}
                        projectId={selectedProjectId}
                        platformId={getActivePlatform()?.id}
                        platformName={getActivePlatform()?.full_name}
                        publishedRunsCountInPlatform={publishedRunsCountInPlatform}
                      />
                    )}
                  </div>
                )}
              </div>

              <SendMessage
                {...popupProps}
                closePopup={closePopup}
                openPopup={openPopup}
                projectId={selectedProjectId}
                platformId={getActivePlatform()?.id}
              />

              <SetAttribute
                {...popupProps}
                closePopup={closePopup}
                openPopup={openPopup}
                runId={id}
                platformType={run.platform_type}
              />

              <SendBonus
                {...popupProps}
                closePopup={closePopup}
                openPopup={openPopup}
                platformType={getActivePlatform()?.platform_type}
                projectId={selectedProjectId}
                platformId={getActivePlatform()?.id}
                setShowSpendingLimitWarning={setShowSpendingLimitWarning}
                setShowAddCardWarning={setShowAddCardWarning}
                setSpendingLimitWarningMessage={setSpendingLimitWarningMessage}
                spendingLimitCallbackRef={spendingLimitCallbackRef}
                addCardCallbackRef={addCardCallbackRef}
              />

              <SpendingLimitWarning
                showSpendingLimitWarning={showSpendingLimitWarning}
                setShowSpendingLimitWarning={setShowSpendingLimitWarning}
                message={spendingLimitWarningMessage}
              />

              <AddCardWarning
                showAddCardWarning={showAddCardWarning}
                setShowAddCardWarning={setShowAddCardWarning}
                callbackRef={addCardCallbackRef}
              />
            </>
          )}
        </>


      }
    </div>
  )
}
