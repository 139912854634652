import React from 'react'
import {Link} from 'react-router-dom'
import swal from 'sweetalert'

const RejectAssignment = (props) => {
  const handleRejectAssignment = (clickEvent) => {
    clickEvent.preventDefault()

    swal({
      title: 'Are you sure?',
      text: 'This assignment will be rejected.',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          text: 'Confirm',
          className: 'btn-primary',
        }
      }
    }).then((confirmation) => {
      if (confirmation) {
        props.rejectAssignment(props.assignmentId)
      }
    })
  }

  const getLabel = () => {
    if(props.isScreeningEnabled){
      if(props.status === 'issue_reported_in_main_activity'){
        return 'Reject this work and refuse to pay'
      } else {
        return 'Reject and refuse to pay for screening'
      }
    } else {
      return 'Reject this work and refuse to pay'
    }
  }

  return (
    <li onClick={handleRejectAssignment}>
      <Link className="popup-content relative" to="">
        <i className="fas fa-times-circle fa-icons-dropdown"/>
        {getLabel()}
      </Link>
    </li>
  )
}

export default RejectAssignment
