import {quoteActions} from '../actionss/quoteActions'

const quoteReducer = (state, action) => {
  switch (action.type) {
    case quoteActions.GET_QUOTE_FOR_BONUS_LOADING:
      return {
        ...state,
        quote: {
          isLoading: true
        }
      }
    case quoteActions.GET_QUOTE_FOR_BONUS_SUCCESS:
      return {
        ...state,
        quote: {
          isLoading: false,
          totalBonusAmount: action.data.total_bonus_amount,
          serviceFeePercent: action.data.service_fee_percent
        }
      }
    case quoteActions.GET_QUOTE_FOR_BONUS_ERROR:
      return {
        ...state,
        quote: {
          isLoading: false,
          errors: action.errors
        }
      }
    case quoteActions.GET_QUOTE_FOR_BONUS_RESET:
      return {
        ...state,
        quote: {
          isLoading: false,
        }
      }

    default:
      return state
  }
}

export default quoteReducer;
