import React, {useState, useEffect, useRef} from 'react'

// Custom components
import {allowOnlyNumbers, capitalize, preventPaste} from '../../common/Helpers'
import Select from '../../common/Select'

const CustomAttributeNumericFilter = (props) => {
  // Variables
  const numericComparators = [
    'equal to',
    'not equal to',
    'less than',
    'less than or equal to',
    'greater than',
    'greater than or equal to',
    'blank',
    'not blank',
    'between',
  ]
  const [mode, setMode] = useState(props.filter.mode || 'Include only')
  const [comparator, setComparator] = useState(props.filter.comparator || 'equal to')
  const [value, setValue] = useState(parseInt(props.filter.value))
  const [from, setFrom] = useState(parseInt(props.filter.from || props.filter.min_value))
  const [to, setTo] = useState(parseInt(props.filter.to || props.filter.max_value))
  const filterRef = useRef(null)

  // Init
  useEffect(() => {
    updateFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Event Handlers
  const handleModeChange = (e) => {
    setMode(e.target.value)
    updateFilter({mode: e.target.value})
  }

  const handleComparatorChange = (e) => {
    setComparator(e.target.value)
    updateFilter({comparator: e.target.value})
  }

  const handleValueChange = (e) => {
    if (e.target.name === 'value')
      setValue(parseInt(e.target.value))
    else if (e.target.name === 'from')
      setFrom(parseInt(e.target.value))
    else
      setTo(parseInt(e.target.value))
  }

  const handleBlur = () => {
    updateFilter()
  }


  // Private method
  const updateFilter = (updates) => {
    const updatedFilter = {
      ...props.filter,
      mode: mode,
      comparator: comparator,
      value: value,
      from: from,
      to: to,
      ...updates
    }
    const isValid = validateFilter(updatedFilter)
    props.updateFilter('custom_attribute', {...updatedFilter, is_filter_valid: isValid})
  }

  const validateFilter = (filter) => {
    if (!filter.mode || !filter.comparator)
      return false

    if (
      filter.comparator !== 'between' && !filter.comparator.includes('blank')
      && (!filter.value && filter.value !== 0)
    ) {
      return false
    }

    if (
      filter.comparator === 'between' && !filter.comparator.includes('blank')
      && (
        (filter.from > filter.to)
        || (!filter.from && filter.from !== 0)
        || (!filter.to && filter.to !== 0)
      )
    ) {
      return false
    }

    return true
  }

  const removeFilter = () => {
    if (!filterRef.current) return

    filterRef.current.classList.add('remove-filter')
    setTimeout(() => {
      props.removeFilter('custom_attribute', props.filter.name)
    }, 210)
  }

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.add('highlight-filter')
    setTimeout(() => {
      if (!filterRef.current) return

      filterRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  // UI template
  return (
    <div className={`box age ${props.className}`} ref={filterRef}>
      <h2 className="big word-break-all pr-4">
        {capitalize(props.filter.name).replace(/_/g, ' ')}
        <span className="float-right">
					<i className="ion-close text-sm" onClick={removeFilter}/>
				</span>
      </h2>
      <div className="my-2">
        <Select
          name={`${props.filter.name}.mode`}
          onChange={handleModeChange}
          value={mode}
          options={['Include only', 'Exclude all']}
          tabIndex="4"
        />

        <div className="my-2 po-text word-break-all pr-3">
          participants where {props.filter.name} is
        </div>

        <div>
          <Select
            name={`${props.filter.name}.comparator`}
            onChange={handleComparatorChange}
            value={comparator}
            options={numericComparators}
            tabIndex="4"
          />
          {comparator !== 'between' && !comparator.includes('blank') && (
            <div className="form-group mt-3">
              <input
                className="d-block full-width"
                name="value"
                type="number"
                value={value}
                onChange={handleValueChange}
                onBlur={handleBlur}
                onKeyPress={allowOnlyNumbers}
                onPaste={preventPaste}
                tabIndex="4"
                placeholder="Value"
              />
              {(!value && value !== 0) && <p className="text-red po-text ml-2 mt-2">* required</p>}
            </div>
          )}

          {comparator === 'between' && !comparator.includes('blank') && (
            <div>
              <div className="form-group mt-2">
                <input
                  className="d-block mt-2 full-width"
                  name="from"
                  type="number"
                  value={from}
                  onChange={handleValueChange}
                  onBlur={handleBlur}
                  onKeyPress={allowOnlyNumbers}
                  onPaste={preventPaste}
                  tabIndex="4"
                  placeholder="From"
                />
              </div>
              <div className="form-group mt-2">
                <input
                  className="d-block mt-2 full-width"
                  name="to"
                  type="number"
                  value={to}
                  onChange={handleValueChange}
                  onBlur={handleBlur}
                  onKeyPress={allowOnlyNumbers}
                  onPaste={preventPaste}
                  tabIndex="4"
                  placeholder="To"
                />
              </div>
              <p className="text-red po-text ml-2">
                {((!from && from !== 0) || (!to && to !== 0)) ?
                  '* both fields are required'
                  : (from > to) ?
                    '"To" value can\'t be less than the "From" value'
                    : null
                }
              </p>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}

export default CustomAttributeNumericFilter
