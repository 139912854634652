import jquery from 'jquery'

import {authHeaders} from '../../components/common/Helpers'
import {errorToast, successToast} from '../../components/helpers/notification'

// Actions
import {setupRunFormFromRunFail, setupRunFormFromRunSuccess} from '../actions'

// Constants
const $ = window.$ || jquery // fallback to prevent blank pre-survey issue

export const runActions = {
  RUN_INIT: 'RUN_INIT',
  RUN_SUCCESS: 'RUN_SUCCESS',
  RUN_ERROR: 'RUN_ERROR',

  RUN_PROGRESS_INIT: 'RUN_PROGRESS_INIT',
  RUN_PROGRESS_SUCCESS: 'RUN_PROGRESS_SUCCESS',
  RUN_PROGRESS_ERROR: 'RUN_PROGRESS_ERROR',

  RUN_FEEDBACK_INIT: 'RUN_FEEDBACK_INIT',
  RUN_FEEDBACK_SUCCESS: 'RUN_FEEDBACK_SUCCESS',
  RUN_FEEDBACK_ERROR: 'RUN_FEEDBACK_ERROR',

  RUN_PARTICIPANTS_INIT: 'RUN_PARTICIPANTS_INIT',
  RUN_PARTICIPANTS_SUCCESS: 'RUN_PARTICIPANTS_SUCCESS',
  RUN_PARTICIPANTS_ERROR: 'RUN_PARTICIPANTS_ERROR',

  RUN_REJECTED_ASSIGNMENTS_INIT: 'RUN_REJECTED_ASSIGNMENTS_INIT',
  RUN_REJECTED_ASSIGNMENTS_SUCCESS: 'RUN_REJECTED_ASSIGNMENTS_SUCCESS',
  RUN_REJECTED_ASSIGNMENTS_ERROR: 'RUN_REJECTED_ASSIGNMENTS_ERROR',

  RUN_QUALITY_REJECTED_ASSIGNMENTS_INIT: 'RUN_QUALITY_REJECTED_ASSIGNMENTS_INIT',
  RUN_QUALITY_REJECTED_ASSIGNMENTS_SUCCESS: 'RUN_QUALITY_REJECTED_ASSIGNMENTS_SUCCESS',
  RUN_QUALITY_REJECTED_ASSIGNMENTS_ERROR: 'RUN_QUALITY_REJECTED_ASSIGNMENTS_ERROR',

  RUN_REJECTED_ASSIGNMENTS_UPDATE: 'RUN_REJECTED_ASSIGNMENTS_UPDATE',

  RUN_SEND_BONUS_INIT: 'RUN_SEND_BONUS_INIT',
  RUN_SEND_BONUS_SUBMIT: 'RUN_SEND_BONUS_SUBMIT',
  RUN_SEND_BONUS_SUCCESS: 'RUN_SEND_BONUS_SUCCESS',
  RUN_SEND_BONUS_ERROR: 'RUN_SEND_BONUS_ERROR',

  RUN_BONUS_SENT_INIT: 'RUN_BONUS_SENT_INIT',
  RUN_BONUS_SENT_SUCCESS: 'RUN_BONUS_SENT_SUCCESS',
  RUN_BONUS_SENT_ERROR: 'RUN_BONUS_SENT_ERROR',

  RUN_SEND_MESSAGE_INIT: 'RUN_SEND_MESSAGE_INIT',
  RUN_SEND_MESSAGE_SUBMIT: 'RUN_SEND_MESSAGE_SUBMIT',
  RUN_SEND_MESSAGE_SUCCESS: 'RUN_SEND_MESSAGE_SUCCESS',
  RUN_SEND_MESSAGE_ERROR: 'RUN_SEND_MESSAGE_ERROR',

  RUN_SENT_MESSAGES_INIT: 'RUN_SENT_MESSAGES_INIT',
  RUN_SENT_MESSAGES_SUCCESS: 'RUN_SENT_MESSAGES_SUCCESS',
  RUN_SENT_MESSAGES_ERROR: 'RUN_SENT_MESSAGES_ERROR',

  RUN_PARTICIPANT_APPROVE_ASSIGNMENT_INIT: 'RUN_PARTICIPANT_APPROVE_ASSIGNMENT_INIT',
  RUN_PARTICIPANT_APPROVE_ASSIGNMENT_SUCCESS: 'RUN_PARTICIPANT_APPROVE_ASSIGNMENT_SUCCESS',
  RUN_PARTICIPANT_APPROVE_ASSIGNMENT_ERROR: 'RUN_PARTICIPANT_APPROVE_ASSIGNMENT_ERROR',

  RUN_ASSIGNMENT_STATUS_UPDATE: 'RUN_ASSIGNMENT_STATUS_UPDATE',

  RUN_FEEDBACK_ASSIGNMENT_STATUS_UPDATE: 'RUN_FEEDBACK_ASSIGNMENT_STATUS_UPDATE',

  RUN_REJECT_PARTICIPANTS_INIT: 'RUN_REJECT_PARTICIPANTS_INIT',
  RUN_REJECT_PARTICIPANTS_SUCCESS: 'RUN_REJECT_PARTICIPANTS_SUCCESS',
  RUN_REJECT_PARTICIPANTS_ERROR: 'RUN_REJECT_PARTICIPANTS_ERROR',

  RUN_REJECT_ASSIGNMENT_INIT: 'RUN_REJECT_ASSIGNMENT_INIT',
  RUN_REJECT_ASSIGNMENT_SUCCESS: 'RUN_REJECT_ASSIGNMENT_SUCCESS',
  RUN_REJECT_ASSIGNMENT_FAIL: 'RUN_REJECT_ASSIGNMENT_FAIL',

  RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_INIT: 'RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_INIT',
  RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_SUCCESS: 'RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_SUCCESS',
  RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_FAIL: 'RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_FAIL',

  RUN_INCREASE_VISIBILITY_INIT: 'RUN_INCREASE_VISIBILITY_INIT',
  RUN_INCREASE_VISIBILITY_SUCCESS: 'RUN_INCREASE_VISIBILITY_SUCCESS',
  RUN_INCREASE_VISIBILITY_ERROR: 'RUN_INCREASE_VISIBILITY_ERROR',

  RUN_PAUSE_INIT: 'RUN_PAUSE_INIT',
  RUN_PAUSE_SUBMIT: 'RUN_PAUSE_SUBMIT',
  RUN_PAUSE_SUCCESS: 'RUN_PAUSE_SUCCESS',
  RUN_PAUSE_ERROR: 'RUN_PAUSE_ERROR',

  RUN_UNPAUSE_INIT: 'RUN_UNPAUSE_INIT',
  RUN_UNPAUSE_SUCCESS: 'RUN_UNPAUSE_SUCCESS',
  RUN_UNPAUSE_ERROR: 'RUN_UNPAUSE_ERROR',

  RUN_EXTEND_INIT: 'RUN_EXTEND_INIT',
  RUN_EXTEND_SUBMIT: 'RUN_EXTEND_SUBMIT',
  RUN_EXTEND_SUCCESS: 'RUN_EXTEND_SUCCESS',
  RUN_EXTEND_ERROR: 'RUN_EXTEND_ERROR',

  RUN_DELETE_INIT: 'RUN_DELETE_INIT',
  RUN_DELETE_SUBMIT: 'RUN_DELETE_SUBMIT',
  RUN_DELETE_SUCCESS: 'RUN_DELETE_SUCCESS',
  RUN_DELETE_ERROR: 'RUN_DELETE_ERROR',

  RUN_STATUS_UPDATE: 'RUN_STATUS_UPDATE',

  RUN_UPDATE_STATE_CHANGE: 'RUN_UPDATE_STATE_CHANGE',

  RUN_RESET: 'RUN_RESET',

  RUN_PARTICIPANT_ATTRIBUTES_INIT: 'RUN_PARTICIPANT_ATTRIBUTES_INIT',
  RUN_PARTICIPANT_ATTRIBUTES_SUCCESS: 'RUN_PARTICIPANT_ATTRIBUTES_SUCCESS',
  RUN_PARTICIPANT_ATTRIBUTES_ERROR: 'RUN_PARTICIPANT_ATTRIBUTES_ERROR',

  RUN_PARTICIPANT_CURRENT_ATTRIBUTES_INIT: 'RUN_PARTICIPANT_CURRENT_ATTRIBUTES_INIT',
  RUN_PARTICIPANT_CURRENT_ATTRIBUTES_SUCCESS: 'RUN_PARTICIPANT_CURRENT_ATTRIBUTES_SUCCESS',
  RUN_PARTICIPANT_CURRENT_ATTRIBUTES_ERROR: 'RUN_PARTICIPANT_CURRENT_ATTRIBUTES_ERROR',

  RUN_PARTICIPANT_SET_ATTRIBUTE_INIT: 'RUN_PARTICIPANT_SET_ATTRIBUTE_INIT',
  RUN_PARTICIPANT_SET_ATTRIBUTE_SUCCESS: 'RUN_PARTICIPANT_SET_ATTRIBUTE_SUCCESS',
  RUN_PARTICIPANT_SET_ATTRIBUTE_ERROR: 'RUN_PARTICIPANT_SET_ATTRIBUTE_ERROR',
}

/* Run */
export const runInit = () => ({
  type: runActions.RUN_INIT
})

export const runFetch = (id, page, isRunUpdate = false) => {
  return dispatch => {
    dispatch(runInit())
    return fetch('/api/v1/getRun/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          if (page === 'form') {
            dispatch(setupRunFormFromRunFail(errors))
          } else {
            dispatch(runError(errors))
            errorToast(errors.join(', '))
          }
        } else {
          if (page === 'form') {
            dispatch(setupRunFormFromRunSuccess(data, isRunUpdate))
          } else {
            dispatch(runSuccess(data))
          }
        }
      })
  }
}

const runSuccess = (run, model) => ({
  type: runActions.RUN_SUCCESS,
  run,
  model
})

const runError = (errors) => ({
  type: runActions.RUN_ERROR,
  errors
})

/* Run progress */
export const runProgressInit = () => ({
  type: runActions.RUN_PROGRESS_INIT
})

export const runProgressFetch = (id) => {
  return dispatch => {
    return fetch('/api/v1/getRunProgressReport/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runProgressError(errors))
        } else {
          dispatch(runProgressSuccess(data))
        }
      })
  }
}

const runProgressSuccess = (run) => ({
  type: runActions.RUN_PROGRESS_SUCCESS,
  run
})

const runProgressError = (errors) => ({
  type: runActions.RUN_PROGRESS_ERROR,
  errors
})

/* Run feedback */
export const runFeedbackInit = () => ({
  type: runActions.RUN_FEEDBACK_INIT
})

export const runFeedbackFetch = (id, pageNumber) => {
  return dispatch => {
    return fetch(`/api/v1/getRunFeedback?page_number=${pageNumber}&run_id=${id}&feedback_per_page=20`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runFeedbackError(errors))
        } else {
          dispatch(runFeedbackSuccess(data, pageNumber))
        }
      })
  }
}

const runFeedbackSuccess = (feedbacks, pageNumber) => {
  const hasMore = feedbacks.length === 20
  return {
    type: runActions.RUN_FEEDBACK_SUCCESS,
    feedbacks,
    pageNumber,
    hasMore
  }
}

const runFeedbackError = (errors) => ({
  type: runActions.RUN_FEEDBACK_ERROR,
  errors
})

/* Run participant */
const runParticipantsInit = () => ({
  type: runActions.RUN_PARTICIPANTS_INIT
})

export const runParticipantsFetch = (runId, pageNumber, query = '') => {
  return dispatch => {
    if (pageNumber === 1) {
      dispatch(runParticipantsInit())
    }
    return fetch(`/api/v1/getRunAssignments?page_number=${pageNumber}&run_id=${runId}&query=${query}&assignments_per_page=20`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runParticipantsError(errors))
        } else {
          dispatch(runParticipantsSuccess(data, pageNumber))
        }
      })
  }
}

const runParticipantsSuccess = (assignments, pageNumber) => {
  const hasMore = assignments.length === 20
  return {
    type: runActions.RUN_PARTICIPANTS_SUCCESS,
    assignments,
    pageNumber,
    hasMore
  }
}

const runParticipantsError = (errors) => ({
  type: runActions.RUN_PARTICIPANTS_ERROR,
  errors
})

/* Run rejected assignments */
const runRejectedAssignmentsInit = () => ({
  type: runActions.RUN_REJECTED_ASSIGNMENTS_INIT
})

export const runRejectedAssignmentsFetch = (id) => {
  return dispatch => {
    dispatch(runRejectedAssignmentsInit())

    return fetch(`/api/v1/getRejectedAssignments?run_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runRejectedAssignmentsError(errors))
        } else {
          dispatch(runRejectedAssignmentsSuccess(data))
        }
      })
  }
}

const runRejectedAssignmentsSuccess = (rejectedAssignments) => ({
  type: runActions.RUN_REJECTED_ASSIGNMENTS_SUCCESS,
  rejectedAssignments
})

const runRejectedAssignmentsError = (errors) => ({
  type: runActions.RUN_REJECTED_ASSIGNMENTS_ERROR,
  errors
})

/* Run quality rejected assignments */
const runQualityRejectedAssignmentsInit = () => ({
  type: runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_INIT
})

export const runQualityRejectedAssignmentsFetch = (id) => {
  return dispatch => {
    dispatch(runQualityRejectedAssignmentsInit())

    return fetch(`/api/v1/getQualityRejectedAssignments?run_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runQualityRejectedAssignmentsError(errors))
        } else {
          dispatch(runQualityRejectedAssignmentsSuccess(data))
        }
      })
  }
}

const runQualityRejectedAssignmentsSuccess = (qualityRejectedAssignments) => ({
  type: runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_SUCCESS,
  qualityRejectedAssignments
})

const runQualityRejectedAssignmentsError = (errors) => ({
  type: runActions.RUN_QUALITY_REJECTED_ASSIGNMENTS_ERROR,
  errors
})

/* Update rejected assignments */
export const runRejectedAssignmentsUpdate = (rejectedAssignment) => ({
  type: runActions.RUN_REJECTED_ASSIGNMENTS_UPDATE,
  rejectedAssignment
})

/* Send bonus */
export const sendBonusInit = () => ({
  type: runActions.RUN_SEND_BONUS_INIT
})

export const sendBonusSubmit = () => ({
  type: runActions.RUN_SEND_BONUS_SUBMIT
})

const sendBonusSuccess = () => ({
  type: runActions.RUN_SEND_BONUS_SUCCESS
})

const sendBonusError = errors => ({
  type: runActions.RUN_SEND_BONUS_ERROR,
  ...errors
})

export const sendBonusFetch = (bonus) => {
  return dispatch => {
    return fetch('/api/v1/sendBonusToParticipants', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(bonus)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(sendBonusError(data))
        } else {
          successToast('Bonus sent successfully!')
          dispatch(sendBonusSuccess())
        }
      })
  }
}

/* Bonuses sent */
export const bonusesSentInit = () => ({
  type: runActions.RUN_BONUS_SENT_INIT
})

export const bonusesSentSuccess = bonuses => ({
  type: runActions.RUN_BONUS_SENT_SUCCESS,
  bonuses
})

export const bonusesSentError = errors => ({
  type: runActions.RUN_BONUS_SENT_ERROR,
  errors
})

export const bonusesSentFetch = (assignment_id, participant_id, run_id, project_id) => {
  return dispatch => {
    const params = {
      assignment_id,
      participant_id,
      run_id
    }

    if (project_id !== undefined) {
      params.project_id = project_id
    }

    let searchParams = new URLSearchParams(params).toString()

    return fetch(`/api/v1/getBonusesSentToParticipant?${searchParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(bonusesSentError(errors))
        } else {
          dispatch(bonusesSentSuccess(data))
        }
      })
  }
}

/*  */
export const participantSendMessageInit = () => ({
  type: runActions.RUN_SEND_MESSAGE_INIT
})

export const participantSendMessageSubmit = () => ({
  type: runActions.RUN_SEND_MESSAGE_SUBMIT
})

export const participantSendMessageSuccess = () => ( {
  type: runActions.RUN_SEND_MESSAGE_SUCCESS
})

export const participantSendMessageError = (data) => ({
  type: runActions.RUN_SEND_MESSAGE_ERROR,
  ...data
})

export const participantSendMessageFetch = message => {
  return dispatch => {
    return fetch(`/api/v1/sendMessageToParticipants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(message)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(participantSendMessageError(data))
        } else {
          dispatch(participantSendMessageSuccess())
          successToast('Message sent successfully')
        }
      })
  }
}

/* Sent message */
export const sentMessagesInit = () => ({
  type: runActions.RUN_SENT_MESSAGES_INIT
})

export const sentMessagesSuccess = sentMessages => ({
  type: runActions.RUN_SENT_MESSAGES_SUCCESS,
  sentMessages
})

export const sentMessagesError = errors => ({
  type: runActions.RUN_SENT_MESSAGES_ERROR,
  errors
})

export const sentMessagesFetch = (params) => {
  let searchParams = new URLSearchParams(params).toString()

  return dispatch => {
    return fetch(`/api/v1/getMessagesSentToParticipant?${searchParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(sentMessagesError(errors))
        } else {
          dispatch(sentMessagesSuccess(data))
        }
      })
  }
}

/* Approve assignment */
const participantApproveAssignmentInit = () => ({
  type: runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_INIT
})

const participantApproveAssignmentSuccess = (assignment) => ({
  type: runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_SUCCESS,
  assignment
})

const participantApproveAssignmentError = (errors) => ({
  type: runActions.RUN_PARTICIPANT_APPROVE_ASSIGNMENT_ERROR,
  errors
})

export const participantApproveAssignmentFetch = (id, workerId, status, payMainActivity = false, page = 'participants') => {
  return dispatch => {
    dispatch(participantApproveAssignmentInit())

    return fetch('/api/v1/approveAssignment', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({id: id, worker_id: workerId, pay_main_activity: payMainActivity})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(participantApproveAssignmentError(errors))
          errorToast('Failed to approve!')
        } else {
          if(data.status === 'screening_activity_accepted_as_complete' || (data.agree_screening_guidelines && data.status === 'approved_after_rejection')) {
            successToast('Accepted Screening Activity as completed')
          }else if(data.status === 'main_activity_accepted_as_complete') {
            successToast('Accepted Main Activity as completed')
          }else if(data.status === 'approved_after_rejection'){
            successToast('Accepted as Complete')
          } else {
            successToast('Assignment approved')
          }
          dispatch(participantApproveAssignmentSuccess(data))

          if (page === 'participants') {
            if (status === 'task_completed')
              dispatch(assignmentStatusUpdate(id, 'approved', 'Approved'))
            else if (status === 'rejected' || status === 'rejection_confirmed'){
              dispatch(assignmentStatusUpdate(id, data.status, data.status_readable))
            }
            else if(status === 'issue_reported_by_participant' || status === 'issue_reported_in_main_activity' ){
              if(data.agree_screening_guidelines) {
                dispatch(assignmentStatusUpdate(id, data.status, data.status_readable))
              } else {
                dispatch(assignmentStatusUpdate(id, 'approved', 'Approved'))
              }
            }
          } else {
            dispatch(feedbackAssignmentStatusUpdate(id, 'approved'))
          }
        }
      })
  }
}

/* Update assignment status */
const assignmentStatusUpdate = (id, status, status_readable, assignment) => ({
  type: runActions.RUN_ASSIGNMENT_STATUS_UPDATE,
  id,
  status,
  status_readable,
  assignment
})

/* Update feedback status */
const feedbackAssignmentStatusUpdate = (id, status) => ({
  type: runActions.RUN_FEEDBACK_ASSIGNMENT_STATUS_UPDATE,
  id,
  status
})

/* Reject participant */
const runRejectParticipantsInit = (is_quality_rejection = false) => ({
  type: runActions.RUN_REJECT_PARTICIPANTS_INIT,
  is_quality_rejection,
})

export const runRejectParticipantsSuccess = (run_status, rejected_assignment_count, is_quality_rejection = false) => ({
  type: runActions.RUN_REJECT_PARTICIPANTS_SUCCESS,
  run_status,
  rejected_assignment_count,
  is_quality_rejection,
})

export const runRejectParticipantsError = (errors, is_quality_rejection = false) => ({
  type: runActions.RUN_REJECT_PARTICIPANTS_ERROR,
  errors,
  is_quality_rejection,
})

export const runRejectParticipantsFetch = (assignment_ids, is_quality_rejection) => {
  return dispatch => {
    dispatch(runRejectParticipantsInit(is_quality_rejection))

    return fetch('/api/v1/confirmRejections', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({
        assignment_ids,
        is_quality_rejection
      })
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runRejectParticipantsError(errors, is_quality_rejection))
        } else {
          if (Object.keys(data).length)
            dispatch(runRejectParticipantsSuccess(data.run_status, assignment_ids.length, is_quality_rejection))
          else
            dispatch(runRejectParticipantsError('Failed!', is_quality_rejection))
        }
      })
  }
}

/* Unreject participant */
export const runUnrejectParticipantsFetch = (assignment_ids) => {
  return dispatch => {
    dispatch(runRejectParticipantsInit())

    return fetch('/api/v1/approveRejectedAssignments', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({
        assignment_ids
      })
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(runRejectParticipantsError(errors))
        } else {
          if (Object.keys(data).length) {
            dispatch(runRejectParticipantsSuccess(data.run_status, assignment_ids.length))
          } else {
            dispatch(runRejectParticipantsError('Failed!'))
          }
        }
      })
  }
}

/* Reject and replace participant */
export const runRejectAndReplaceParticipantsFetch = (assignment_ids, is_restart, is_quality_rejection) => {
  return dispatch => {
    dispatch(runRejectParticipantsInit(is_quality_rejection))

    return fetch('/api/v1/confirmAndReplaceRejections', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({
        assignment_ids,
        is_restart,
        is_quality_rejection
      })
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(runRejectParticipantsError(errors, is_quality_rejection))
        } else {
          if (Object.keys(data).length)
            dispatch(runRejectParticipantsSuccess(data.run_status, assignment_ids.length, is_quality_rejection))
          else
            dispatch(runRejectParticipantsError('Failed!', is_quality_rejection))
        }
      })
  }
}

/* Reject assignment */
const rejectAssignmentInit = () => ({
  type: runActions.RUN_REJECT_ASSIGNMENT_INIT
})

const rejectAssignmentSuccess = () => ({
  type: runActions.RUN_REJECT_ASSIGNMENT_SUCCESS
})

const rejectAssignmentFail = (errors) => ({
  type: runActions.RUN_REJECT_ASSIGNMENT_FAIL,
  errors
})

export const rejectAssignment = (assignment_id, reason = 'Spam', page = 'participants') => {
  return dispatch => {
    dispatch(rejectAssignmentInit())

    return fetch(`/api/v1/rejectAssignment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({assignment_id, reason})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          errorToast(errors.join(', '))
          dispatch(rejectAssignmentFail(errors))
        } else {
          successToast('Participant rejected')
          dispatch(rejectAssignmentSuccess())

          if (page === 'participants') {
            const assignment = data.assignments[0]
            dispatch(assignmentStatusUpdate(assignment_id, 'rejected', 'Rejected', assignment))
          } else {
            dispatch(feedbackAssignmentStatusUpdate(assignment_id, 'rejected'))
          }
        }
      })
  }
}

/* Block participant and reject assignment */
const blockParticipantAndRejectAssignmentInit = () => ({
  type: runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_INIT
})

const blockParticipantAndRejectAssignmentSuccess = () => ({
  type: runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_SUCCESS
})

const blockParticipantAndRejectAssignmentFail = (errors) => ({
  type: runActions.RUN_BLOCK_PARTICIPANT_AND_REJECT_ASSIGNMENT_FAIL,
  errors
})

export const blockParticipantAndRejectAssignment = (assignment_id, page = 'participants') => {
  return dispatch => {
    dispatch(blockParticipantAndRejectAssignmentInit())

    return fetch(`/api/v1/blockParticipantAndRejectAssignment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({assignment_id})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors?.length > 0) {
          errorToast(errors.join(', '))
          dispatch(blockParticipantAndRejectAssignmentFail(errors))
        } else {
          successToast('Participant rejected and blocked')
          dispatch(blockParticipantAndRejectAssignmentSuccess())

          if (page === 'participants') {
            const assignment = data.assignment
            dispatch(assignmentStatusUpdate(assignment_id, 'rejected', 'Rejected', assignment))
          } else {
            dispatch(feedbackAssignmentStatusUpdate(assignment_id, 'rejected'))
          }
        }
      })
  }
}


/* Run increase visibility */
export const runIncreaseVisibilityInit = () => ({
  type: runActions.RUN_INCREASE_VISIBILITY_INIT
})

const runIncreaseVisibilitySuccess = () => ({
  type: runActions.RUN_INCREASE_VISIBILITY_SUCCESS
})

const runIncreaseVisibilityError = (errors) => ({
  type: runActions.RUN_INCREASE_VISIBILITY_ERROR,
  errors
})

export const runIncreaseVisibilityFetch = (id) => {
  return dispatch => {
    dispatch(runIncreaseVisibilityInit())
    return fetch(`/api/v1/increaseVisibility/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(runIncreaseVisibilityError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(runIncreaseVisibilitySuccess())
          successToast('Your run is now being republished to increase visibility.')
        }
      })
  }
}

/* Run pause */
export const runPauseInit = () => ({
  type: runActions.RUN_PAUSE_INIT
})

export const runPauseSubmit = () => ({
  type: runActions.RUN_PAUSE_SUBMIT
})

const runPauseSuccess = (id) => ({
  type: runActions.RUN_PAUSE_SUCCESS,
  id
})

const runPauseError = (id) => ({
  type: runActions.RUN_PAUSE_ERROR,
  id
})

export const runPauseFetch = (id, page = 'run') => {
  return dispatch => {
    return fetch(`/api/v1/pauseRun/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()

      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          errorToast(errors.join(', '))
          dispatch(runPauseError(id))
        } else {
          successToast('Pausing run is in progress.')
          dispatch(runPauseSuccess(id))
          dispatch(runStatusUpdate(id, 'Pausing', page))
        }
      })
  }
}

/* Run unpause */
const runUnpauseInit = () => ({
  type: runActions.RUN_UNPAUSE_INIT
})

const runUnpauseSuccess = (id) => {
  return {
    type: runActions.RUN_UNPAUSE_SUCCESS,
    id
  }
}

const runUnpauseError = (id) => {
  return {
    type: runActions.RUN_UNPAUSE_ERROR,
    id
  }
}

export const runUnpauseFetch = (id, page = 'run') => {
  return dispatch => {
    dispatch(runUnpauseInit())
    return fetch(`/api/v1/unpauseRun/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()

      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          errorToast(errors.join(', '))
          dispatch(runUnpauseError(id))
        } else {
          successToast('Unpausing run is in progress.')
          dispatch(runUnpauseSuccess(id))
          dispatch(runStatusUpdate(id, 'Unpausing', page))
        }
      })
  }
}

/* Run Extend */
export const runExtendInit = () => ({
  type: runActions.RUN_EXTEND_INIT
})

export const runExtendSubmit = () => ({
  type: runActions.RUN_EXTEND_SUBMIT
})

const runExtendSuccess = (run, runId, updateRunDetails) => {
  $.magnificPopup.close()
  successToast('Your run is being extended')

  return {
    type: runActions.RUN_EXTEND_SUCCESS,
    run,
    runId,
    updateRunDetails
  }
}

const runExtendError = (errors) => ({
  type: runActions.RUN_EXTEND_ERROR,
  errors
})

export const runExtendFetch = (id, number_of_submissions, updateRunDetails) => {
  return dispatch => {
    dispatch(runExtendSubmit())
    return fetch(`/api/v1/extendRun/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({number_of_submissions: number_of_submissions})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(runExtendError(errors, id))
        } else {
          dispatch(runExtendSuccess(data, id, updateRunDetails))
        }
      })
  }
}

/* Run delete */
export const runDeleteInit = () => ({
  type: runActions.RUN_DELETE_INIT
})

export const runDeleteFetch = (id, projectId, history) => {
  return dispatch => {
    dispatch(runDeleteInit())
    return fetch(`/api/v1/removeRun/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(runDeleteError(errors))
          errorToast('Your run has not been removed.')
        } else {
          dispatch(runDeleteSuccess(id, projectId))
          successToast('Your run has been removed successfully.')
          if (projectId) {
            history.push(`/projects/show?id=${projectId}`)
          }
        }
      })
  }
}

const runDeleteSuccess = (id, projectId) => ({
  type: runActions.RUN_DELETE_SUCCESS,
  id,
  projectId
})

const runDeleteError = (errors) => ({
  type: runActions.RUN_DELETE_ERROR,
  errors
})

/* Run status update */
export const runStatusUpdate = (id, status, page) => ({
  type: runActions.RUN_STATUS_UPDATE,
  id,
  page,
  status
})

/* Update run state change */
export const updateRunStateChange = (run, ui) => {
  if (run.is_published) {
    successToast('Your run is published successfully!')
  } else if (run.is_hung)
    errorToast('Your run is hung due to unexpected error!')
  else if (run.is_paused)
    successToast('Your run is paused!')
  return {
    type: runActions.RUN_UPDATE_STATE_CHANGE,
    run,
    ui
  }
}

/* Run reset */
export const runReset = () => ({
  type: runActions.RUN_RESET
})

/* Get all participant attributes */
const participantAttributesInit = () => ({type: runActions.RUN_PARTICIPANT_ATTRIBUTES_INIT})

export const participantAttributesSuccess = attributes => ({
  type: runActions.RUN_PARTICIPANT_ATTRIBUTES_SUCCESS,
  attributes
})

export const participantAttributesError = errors => ({
  type: runActions.RUN_PARTICIPANT_ATTRIBUTES_ERROR,
  errors
})

export const participantAttributesFetch = () => {
  return dispatch => {
    dispatch(participantAttributesInit())
    return fetch(`/api/v1/getAttributes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(participantAttributesError(errors))
        } else {
          dispatch(participantAttributesSuccess(data))
        }
      })
  }
}

/* Get current participant attributes */
const participantCurrentAttributesInit = () => ({type: runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_INIT})

export const participantCurrentAttributesSuccess = attributes => ({
  type: runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_SUCCESS,
  attributes
})

export const participantCurrentAttributesError = errors => ({
  type: runActions.RUN_PARTICIPANT_CURRENT_ATTRIBUTES_ERROR,
  errors
})

export const participantCurrentAttributesFetch = (participantId) => {
  return dispatch => {
    dispatch(participantCurrentAttributesInit())
    return fetch(`/api/v1/getAttributesForPartcipant?participant_id=${participantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors
        if (errors && errors.length > 0) {
          dispatch(participantCurrentAttributesError(errors))
        } else {
          dispatch(participantCurrentAttributesSuccess(data))
        }
      })
  }
}

/* Set participant attribute */
export const participantSetAttributeInit = () => ({
  type: runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_INIT
})

export const participantSetAttributeSuccess = () => ({
  type: runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_SUCCESS
})

export const participantSetAttributeError = (data) => ({
  type: runActions.RUN_PARTICIPANT_SET_ATTRIBUTE_ERROR,
  ...data
})

export const participantSetAttributeFetch = participantsAttribute => {
  return dispatch => {
    return fetch('/api/v1/setAttributeNameForParticipants', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(participantsAttribute)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(participantSetAttributeError(data))
        } else {
          dispatch(participantSetAttributeSuccess())
          successToast('Setting custom attribute is in progress. It will be ready to use in few minutes!')
        }
      })
  }
}
