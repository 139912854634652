import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

import {isCookieSupported, isLocalStorageSupported} from './common/Helpers'
import {urlRegex} from './run/enhanced-run-form/RunUtils'
import {routes} from '../routes'
import {
  createCintAssignment,
  createCintFeedback,
  updateAssignmentStatusForCintEntry
} from '../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {fayeClient} from './helpers/FayeClient'
import remove from "../images/remove.svg";
import PositlyLink from "./common/PositlyLink";
import CopyLink from "./common/CopyLink";

const FEEDBACK = Object.freeze({
  EVERYTHING_WORKED_FINE: 'Yes, everything worked fine',
  FEEDBACK_SURVEY_MISSING: 'No, the feedback survey was missing',
  OTHER_PROBLEMS: 'No, there was another problem',
})

const CintEntry = ({location}) => {
  const dispatch = useDispatch()
  const {
    isLoading,
    errors,
    assignment = {},
    isCreatingFeedback,
    isFeedbackCreated,
    createFeedbackError,
    redirect_to,
  } = useSelector(state => ({
    isLoading: state.cintEntry.isLoading,
    errors: state.cintEntry.errors,
    assignment: state.cintEntry.assignment,
    isCreatingFeedback: state.cintFeedback?.isLoading,
    isFeedbackCreated: state.cintFeedback?.isFeedbackCreated,
    createFeedbackError: state.cintFeedback?.errors,
    redirect_to: state.cintEntry.redirect_to,
  }))

  const [startSurveyClicked, setStartSurveyClicked] = useState(false)
  const [pageSkipped, setPageSkipped] = useState(false)

  const errorMessage = (() => {
    if (errors) return errors.join(', ')
    if (createFeedbackError) return createFeedbackError.join(', ')

    return ''
  })()
  const activityLanguageName = assignment?.activity_language_name;

  const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true})
  const formik = useFormik({
    initialValues: {
      platform_feedback_type: '',
      platform_feedback: '',
      error_screenshot_url: '',
      pause_run: false,
    },
    validationSchema: Yup.object().shape({
      platform_feedback_type: Yup.string().trim().required('Feedback is required'),
      platform_feedback: Yup.string()
        .trim()
        .when('platform_feedback_type', {
          is: (platform_feedback_type) => platform_feedback_type === FEEDBACK.OTHER_PROBLEMS,
          then: (schema) => schema.required('Please describe the issue that you have faced'),
          otherwise: (schema) => schema
        }),
      error_screenshot_url: Yup.string()
        .trim()
        .when('platform_feedback_type', {
          is: (platform_feedback_type) => platform_feedback_type === FEEDBACK.OTHER_PROBLEMS,
          then: (schema) => schema.required('Error screenshot URL is required').matches(urlRegex, 'Invalid screenshot URL'),
          otherwise: (schema) => schema
        }),
      pause_run: Yup.boolean(),
    }),
    validateOnMount: true,
  })

  const disableFeedbackSubmit = !formik.isValid

  const handleFeedbackChange = (e) => {
    const selectedFeedback = e.target.value
    if (selectedFeedback === FEEDBACK.EVERYTHING_WORKED_FINE || selectedFeedback === FEEDBACK.FEEDBACK_SURVEY_MISSING) {
      formik.setFieldValue('platform_feedback', '')
      formik.setFieldValue('error_screenshot_url', '')
    }
    formik.handleChange(e)
  }

  const handlePauseRun = (e, pauseRun) => {
    e.preventDefault()
    formik.setFieldValue('pause_run', pauseRun)
  }

  const handleStartSurvey = (e) => {
    setStartSurveyClicked(true)
  }

  const getPreSurveyPath = () => {
    return '/#' + routes.PRE_SURVEY + location.search
  }

  const getPreSurveyUrl = () => {
    const {protocol, host} = window.location

    return protocol + '//' + host + getPreSurveyPath()
  }

  const openPreSurvey = () => {
    setStartSurveyClicked(true)
    const {protocol, host} = window.location
    const link = protocol + '//' + host + '/#' + routes.PRE_SURVEY + location.search
    window.location.href = link
  }

  const handleSubmitSurvey = (e) => {
    e.preventDefault()
    if (!formik.isValid) return

    const params = {
      worker_id: queryParams.worker_id,
      assignment_id: queryParams.assignment_id,
      platform_feedback_type: formik.values.platform_feedback_type
    }

    if (params.platform_feedback_type === FEEDBACK.FEEDBACK_SURVEY_MISSING ||params.platform_feedback_type === FEEDBACK.OTHER_PROBLEMS) {
      params['pause_run'] = formik.values.pause_run
    }

    if (params.platform_feedback_type === FEEDBACK.OTHER_PROBLEMS) {
      params['platform_feedback'] = formik.values.platform_feedback
      params['error_screenshot_url'] = formik.values.error_screenshot_url
    }

    dispatch(createCintFeedback(params))
  }

  useEffect(() => {
    dispatch(createCintAssignment({
      assignment_id: queryParams.assignment_id,
      run_id: queryParams.parent_run_id,
      worker_id: queryParams.worker_id,
    }))

    const subscription = fayeClient.subscribe(`/assignmentStateChanged/${queryParams.assignment_id}`, (data) => {
      dispatch(updateAssignmentStatusForCintEntry(data))
    })

    return () => subscription.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    if(redirect_to) {
      window.location.href = redirect_to
    }
  }, [redirect_to])

  useEffect(()=> {
    if(activityLanguageName !== undefined) {
      if (activityLanguageName !== 'English') {
        setPageSkipped(true)
        openPreSurvey()
      }
    }
  }, [activityLanguageName])

  // isPageSkipped will be true when the activity language is not English
  if (isLoading || pageSkipped) {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4">
        Please wait...
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className="box po-text col-md-12 px-5 py-4 text-danger">
        {errorMessage}
      </div>
    )
  }

    if (assignment?.status === 'issue_reported_by_participant') {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        Sorry for the inconvenience caused! We have been notified about your feedback.
        We will fix the issue in the survey as soon as possible.
      </div>
    )
  }

  if (assignment?.status === 'screenout') {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
        Thanks for participating! Unfortunately you are not eligible for this study.
      </div>
    )
  }

  if (assignment?.feedback_completed_at) {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        <label className="success-tick-filled ps-success-tick mr-1">&nbsp;</label>
        Thanks for participating! You have successfully finished this study. You can now close this window.
      </div>
    )
  }

  if (assignment?.status === 'blocked_due_to_low_quality') {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
        Sorry an unrecoverable error occurred. We have been notified about the issue.
        Feel free to close this window.
      </div>
    )
  }

  if (assignment?.status === 'blocked_due_to_suspicious_data') {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
        Thank you for participating! Unfortunately you are not eligible to participate in this study at this time. Feel free to close this window.
      </div>
    )
  }

  if (isFeedbackCreated) {
    return (
      <div className="box po-text text-dark col-md-12 px-5 py-4 font-size-16">
        <label className="success-tick-filled ps-success-tick mr-1">&nbsp;</label>
        Thanks for participating! Your feedback has been submitted. You can now close this window.
      </div>
    )
  }

  return (
    <>
      {!isLocalStorageSupported && !isCookieSupported && (
        <div className="row">
          <div className="col-lg-12">
            <div className="metrics clear-h3-margin box">
              <h3>
                Participation in this task requires either LocalStorage or Cookie to be enabled, but your current
                browser is blocking the use of LocalStorage and Cookie. Please adjust your browser settings,
                turn off plugins that may block localStorage and Cookie, or try a different web browser
                in order to participate.
              </h3>
              <div className="po-note mt-4">
                Note: You must refresh the window after you have enabled the LocalStorage or Cookie
                in order to continue the participation.
              </div>
            </div>
          </div>
        </div>
      )}

      {(isLocalStorageSupported || isCookieSupported) && (
        <>
          <div className="col-lg-12 mb-5">
            <ul className="status_bar">
              <li className="item step_1 passed">
                <div className="circle"/>
                <p>Accepted</p>
              </li>
              {assignment?.activity_language_name === 'English' && (
                <li className={`item step_2 ${assignment?.survey_started_at ? 'passed' : 'active'}`}>
                  <div className="circle"/>
                  <p>Pre-survey</p>
                </li>
              )}
              <li className={`item step_3 ${assignment?.feedback_started_at ? 'passed' : ''}`}>
                <div className="circle"/>
                <p>Main activity</p>
              </li>
              <li className={`item step_4 ${assignment?.feedback_completed_at ? 'passed' : ''}`}>
                <div className="circle"/>
                <p>Post-survey</p>
              </li>
              <li className={`item step_4 ${assignment?.feedback_completed_at ? 'passed' : ''}`}>
                <div className="circle"/>
                <p>Submitted</p>
              </li>
            </ul>
          </div>

          <div className="box po-text col-md-12">
            {!isFeedbackCreated && (
              <div className="p-3">
                <div className="align-center">
                  <a
                    className={`btn btn-primary btn-begin-survey no-border clickable transition-0 ${(startSurveyClicked || assignment?.pre_survey_started_at) ? 'btn-grey' : ''}`}
                    id="start-survey"
                    href={getPreSurveyUrl()}
                    onClick={handleStartSurvey}
                    target="_blank"
                  >
                    {(startSurveyClicked || assignment?.pre_survey_started_at) ? 'Survey In Progress' : 'Begin Survey'}
                  </a>

                  <div className="text-align-left mt-3 text-semi-bold font-size-15 po-text-dark">
                    Survey will open in new tab. If you have any problem in completing the survey, please come back to
                    this page.
                  </div>
                  <div className="text-align-left mt-3 text-semi-bold font-size-15 po-text-dark">
                    If the survey did not open when you clicked the “Begin Survey” button,
                    please use the following link to start the survey:
                  </div>

                  <div className="mt-2 mb-0 metrics filter activity">
                    <div className="col-md-12 left">
                      <div className="box no-shadow p-3 border-grey-4-side">
                        <div className="wrap_input_text">
                          <PositlyLink
                            id="pre_survey_link"
                            className="mw-none"
                            path={getPreSurveyPath()}
                          />
                          <CopyLink
                            label="Copy Survey Link"
                            idToCopy="pre_survey_link"
                            className="btn btn-clipboard copy_link feedback mx-sm-1 copy_survey_link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {assignment?.survey_started_at && !isFeedbackCreated && (
              <div className="metrics project_detail filter participants activity mt-4 px-3 px-lg-5 mx-lg-5">
                <div className="left">
                  <div className="box screening-feedback-box">
                    <div className="po-text-bold mb-2">
                      <span className="text-orange">(Optional)</span>&nbsp;
                      Were you able to complete both the activity AND the feedback survey after the activity?
                    </div>
                    <form
                      className="my-2 radio-checkbox flex-column"
                      name="cint_entry_feedback_form"
                      noValidate=""
                    >
                      <div className="wrap_radio mb-1">
                        <label className="pb-2">
                          <input
                            name="platform_feedback_type"
                            tabIndex="5"
                            checked={formik.values.platform_feedback_type === FEEDBACK.EVERYTHING_WORKED_FINE}
                            onChange={handleFeedbackChange}
                            type="radio"
                            value={FEEDBACK.EVERYTHING_WORKED_FINE}
                          />
                          <span className="po-text">{FEEDBACK.EVERYTHING_WORKED_FINE}</span>
                        </label>

                        {!assignment.feedback_started_at && (
                            <label className="pb-2">
                              <input
                                name="platform_feedback_type"
                                tabIndex="5"
                                checked={formik.values.platform_feedback_type === FEEDBACK.FEEDBACK_SURVEY_MISSING}
                                onChange={handleFeedbackChange}
                                type="radio"
                                value={FEEDBACK.FEEDBACK_SURVEY_MISSING}
                              />
                              <span className="po-text">{FEEDBACK.FEEDBACK_SURVEY_MISSING}</span>
                            </label>
                          )}

                          <label className="pb-2">
                          <input
                          name="platform_feedback_type"
                          tabIndex="5"
                          checked={formik.values.platform_feedback_type === FEEDBACK.OTHER_PROBLEMS}
                          onChange={handleFeedbackChange}
                          type="radio"
                          value={FEEDBACK.OTHER_PROBLEMS}
                          />
                          <span className="po-text">{FEEDBACK.OTHER_PROBLEMS}</span>
                    </label>
                  </div>

                      {(formik.values.platform_feedback_type === FEEDBACK.OTHER_PROBLEMS) && (
                        <>
                          <div className="text-dark text-required mt-1 mb-2">
                            Please explain in as much detail as you can what difficulties you faced. What were you
                            doing when you had the problem? What was the problem exactly? What did you see on the
                            screen when the issue occurred?
                          </div>
                          <textarea
                            name="platform_feedback"
                            className="form-control border-grey"
                            minLength={20}
                            onChange={formik.handleChange}
                            value={formik.values.platform_feedback}
                            onPaste={(e) => e.preventDefault()}
                          />

                          <div className="text-dark text-required my-2">
                            Please{' '}
                            <a
                              className="link"
                              href="https://www.theverge.com/2019/11/8/20953522/how-to-take-screenshot-mac-windows-pc-iphone-android"
                              target="_blank"
                              rel="noreferrer"
                            >
                              take a screenshot
                            </a>
                            {' '}of the webpage where you are encountering the problem or error (if possible, this
                            screenshot should show the error or issue). Then upload your screen shot to{' '}
                            <a className="link" href="https://imgbb.com/" target="_blank" rel="noreferrer">ImgBB</a>
                            {' or '}
                            <a className="link" href="https://imgur.com/" target="_blank" rel="noreferrer">Imgur</a>
                            {' '}so that your screenshot has a link. Finally, paste the link to your screenshot in
                            the box below so that it can be reviewed.
                          </div>
                          <input
                            name="error_screenshot_url"
                            type="text"
                            className="form-control border-grey"
                            value={formik.values.error_screenshot_url}
                            onChange={formik.handleChange}
                          />

                          {formik.values.pause_run ? (
                            <div className="pb-3">
                              Thank you for your feedback! We will consider pausing this Survey.{' '}
                              <Link
                                to="/"
                                className="link"
                                onClick={(e) => handlePauseRun(e, false)}
                              >
                                Click here
                              </Link>
                              {' '}to undo your request.
                            </div>
                          ) : (
                            <div className="pb-3">
                              If the problem you had with this Survey is serious enough that you think the Survey
                              should
                              be paused,{' '}
                              <Link
                                to="/"
                                className="link"
                                onClick={(e) => handlePauseRun(e, true)}
                              >
                                click here
                              </Link>.
                            </div>
                          )}
                        </>
                      )}

                      <div className="mt-2">
                        <button
                          className={`btn btn-primary no-border clickable transition-0 ${disableFeedbackSubmit ? 'cursor-disabled btn-grey' : ''}`}
                          id="submit-survey"
                          onClick={handleSubmitSurvey}
                          disabled={disableFeedbackSubmit}>
                          {isCreatingFeedback ? 'Please wait...' : 'Submit Feedback'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default CintEntry
