import React, {useEffect, useRef} from 'react'
import * as htmlToImage from 'html-to-image'

const TextAttentionCheckQuestion = ({answer, isInvalidAnswer, validateAnswer}) => {
  const questionRef = useRef(null)
  const imageRef = useRef(null)

  useEffect(() => {
    if (!answer?.question_text || !questionRef.current || !imageRef.current) return

    const h2Element = document.createElement('h2')
    h2Element.id = 'oeac-question'
    h2Element.className = 'text-required pre-survey-question'
    h2Element.innerText = answer.question_text
    questionRef.current.insertBefore(h2Element, questionRef.current.firstChild)

    const renderedH2Element = document.getElementById('oeac-question')
    htmlToImage.toSvg(renderedH2Element)
      .then(function (dataUrl) {
        if (!imageRef.current) return

        imageRef.current.src = dataUrl
        renderedH2Element.remove()
      })
      .catch()
  }, [answer?.question_text])

  return (
    <div key={answer.question_id} className="box mb-3 po-ns po-ns-select">
      <div
        className={`form-group ${isInvalidAnswer(answer.question_id) ? 'has-error' : ''}`}
      >
        <label ref={questionRef}>
          <img ref={imageRef} alt=""/>
        </label>
        <select
          className="full-width mt-2"
          id={answer.question_id}
          name={answer.question_id}
          onChange={validateAnswer}
        >
          <option value="">Select one</option>
          {answer.answer_options.map((answerOption, index) => (
            <option key={index} value={answerOption.id}>
              {answerOption.answer_text}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default TextAttentionCheckQuestion
