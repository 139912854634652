import {authHeaders} from "../Helpers";

export const fetchAbstractPlatforms = () => {
  return fetch('/api/v1/getAbstractPlatforms', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const fetchUserRecentlyUsedPlatform = () => {
  return fetch('/api/v1/users/getRecentlyUsedPlatform', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const fetchUserRecentlyUsedPlatformInProject = (searchParams) => {
  return fetch(`/api/v1/users/getRecentlyUsedPlatformInProject?${searchParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const fetchUserRecentlyUsedProjectInPlatform = (searchParams) => {
  return fetch(`/api/v1/users/getRecentProjectInPlatform?${searchParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const fetchProjectsForParticipantsPage = () => {
  return fetch(`/api/v1/getTasks?scope=participants_page`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}

export const fetchProjectAssignments = (searchParams) => {
  return fetch(`/api/v1/getProjectAssignments?${searchParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}