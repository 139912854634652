import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import qs from 'qs'

import {
  workerFetch,
  workerUnsubscribeFetch
} from '../redux/actions'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Checkbox from './common/Checkbox'

const WorkerUnsubscribe = ({location}) => {
  let {id} = qs.parse(location.search, {ignoreQueryPrefix: true})
  id = id.replace(/^p/, '')
  if (id.length > 8) {
    id = id.substr(0, 8)
  }

  const {
    isLoading,
    isUnsubscribing,
    notify_by,
    email,
    unsubscribe_reason
  } = useSelector(state => state.workerUnsubscribe || {})

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      notify_by: notify_by || '',
      email: email || '',
      unsubscribe_reason: unsubscribe_reason || []
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .when('notify_by', {
          is: (value) => value === 'email',
          then: Yup.string()
            .max(100, 'Maximun allowed char limit is 100')
            // eslint-disable-next-line
            .matches(/^([a-zA-Z0-9_+\.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/, 'Please enter valid email')
            .required('Email is required')
        }),
      unsubscribe_reason: Yup.array()
        .when('notify_by', {
          is: (value) => value === 'none',
          then: Yup.array().required('Please select the reason to unsubscribe')
        })
    }),
    onSubmit: (values) => {
      if (formik.isValid) {
        if (values.notify_by === 'none') { // Disable notification for the worker
          const params = {
            worker_id: id,
            notify: false,
            unsubscribe_reason: values.unsubscribe_reason
          }
          dispatch(workerUnsubscribeFetch(params))
        } else { // update notification setting for the worker
          const params = {
            worker_id: id,
            notify: true,
            notify_by: values.notify_by
          }

          if (params.notify_by === 'email') {
            params.email = values.email
          }

          dispatch(workerUnsubscribeFetch(params))
        }
      }
    }
  })

  const isSubscriptionFormValid = () => formik.isValid

  useEffect(() => {
    if (id) dispatch(workerFetch(id))
  }, [dispatch, id])

  return (
    <div className="account unsubscribe">
      {isLoading && (
        <p className="po-text">
          Please wait...
        </p>
      )}
      {!isLoading && (
        <div>
          <div className="top_line mt-0 mb-0">
            <h3 className="mt-0">Edit your positly task notification settings:</h3>

            <label className="mb-2">
              Notifications via
            </label>

            <form
              name="subscriptionForm"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className="my-2 wrap_radio">
                <label>
                  <input
                    name="notify_by"
                    onChange={formik.handleChange}
                    value={'email'}
                    checked={formik.values.notify_by === 'email'}
                    type="radio"
                  />
                  <span className="po-text">Email</span>
                </label>
                <label>
                  <input
                    name="notify_by"
                    onChange={formik.handleChange}
                    value={'mturk'} checked={formik.values.notify_by === 'mturk'}
                    type="radio"
                  />
                  <span className="po-text">Mechanical Turk</span>
                </label>
                <label>
                  <input
                    name="notify_by"
                    checked={formik.values.notify_by === 'none'}
                    onChange={formik.handleChange}
                    value={'none'}
                    type="radio"
                  />
                  <span className="po-text">None</span>
                </label>
              </div>
              {formik.values.notify_by === 'email' && (
                <div className={`form-group mt-3 ${!formik.isValid ? 'has-error' : ''}`}>
                  <label className="mb-0">Email</label>
                  <input
                    className="form-control subs-email mt-2 po-text"
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="username@example.com"
                    type="email"
                    value={formik.values.email}
                  />
                </div>
              )}

              {formik.values.notify_by === 'none' && (
                <div className="my-2 radio-checkbox" style={{display: 'block'}}>
                  <label className="mb-2 text-required">
                    Please tell us why you want to unsubscribe from Positly Notifications (Check all that apply)
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes('Too many notifications from Positly')}
                      value={'Too many notifications from Positly'}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">Too many notifications from Positly</span>
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes('I am not eligible for the tasks sent to me')}
                      value={'I am not eligible for the tasks sent to me'}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">I am not eligible for the tasks sent to me</span>
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes('I encounter a bug when using Positly')}
                      value={'I encounter a bug when using Positly'}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">I encounter a bug when using Positly</span>
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes('I am no longer interested in micro-tasking work')}
                      value={'I am no longer interested in micro-tasking work'}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">I am no longer interested in micro-tasking work</span>
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes(`I didn't sign up for these emails`)}
                      value={`I didn't sign up for these emails`}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">I didn't sign up for these emails</span>
                  </label>

                  <label className="my-2">
                    <Checkbox
                      className="checkbox"
                      name="unsubscribe_reason"
                      onChange={formik.handleChange}
                      checked={formik.values.unsubscribe_reason.includes(`The tasks don't pay enough`)}
                      value={`The tasks don't pay enough`}
                    />
                    <span className="checkbox-custom"/>
                    <span className="po-text label">The tasks don't pay enough</span>
                  </label>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <div className="wrap_link mt-3 d-flex">
                    <button
                      disabled={!isSubscriptionFormValid()}
                      className={`btn btn-primary cursor-pointer no-border ${!isSubscriptionFormValid() && 'btn-grey'}`}
                      type="submit">
                      {isUnsubscribing ? 'Updating...' : 'Update Settings'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default WorkerUnsubscribe
