import {cloneDeep} from 'lodash'
import {
  ageFilterForCint,
  commonQSAttributes,
  genderFilterForCint,
  getActivityLanguageById
} from '../components/run/enhanced-run-form/RunUtils'
import {checkIfFiltersAreValid, checkIfQuotasAreValid, snakeCaseToCamelCase} from '../components/common/Helpers'

// Reducers
import userReducer from './reducers/userReducer'
import projectReducer from './reducers/projectReducer'
import runReducer from './reducers/runReducer'
import platformReducer from './reducers/platformReducer'
import {successToast} from '../components/helpers/notification'
import {findLast, shuffle} from 'lodash/collection'
import quoteReducer from './reducers/quoteReducer'

const cintEntryPageInitialState = {
  cintEntry: {
    assignment: {}
  }
}

const postSurveyInitialState = {
  postSurvey: {
    feedbackStarted: {}
  }
}

const initialState = {
  isTokenValidating: true,
  ...cintEntryPageInitialState,
  ...postSurveyInitialState,
  quote: {}
}

const runFormInitialState = {
  task_parameters: {}
}

const runFormOverviewInitialState = {
  isDetailsFormValid: false,
  isQualityFilterValid: false,
  isParticipantsFormValid: true,
  isActivityFormValid: false,
  isScreeningFormValid: false,
  task_parameters: {},
  additional_attributes: [],
  filters: [],
  prescreen_data: {},
  prescreen_ids: [],
  cint_filters: [],
  platform: {},
  cint_quotas: [],
  cint_interlocked_quotas: [],
}

const combinedReducer = (state = initialState, action) => {
  const refactoredReducers = ['USER', 'PROJECT', 'RUN', 'PLATFORM', 'GET_QUOTE']

  let reducer
  if (action.type.startsWith('RUN_FORM')) {
    reducer = 'RUN_FORM';
  } else if (action.type.startsWith('GET_QUOTE')) {
    reducer = 'GET_QUOTE';
  } else {
    reducer = action.type.split('_')[0];
  }

  const actionType = refactoredReducers.includes(reducer) ? reducer : action.type

  switch (actionType) {
    case 'USER':
      return userReducer(state, action)
    case 'PROJECT':
      return projectReducer(state, action)
    case 'RUN':
      return runReducer(state, action)
    case 'PLATFORM':
      return platformReducer(state, action)
    case 'GET_QUOTE':
      return quoteReducer(state, action)

    case 'CONTACT_INIT':
      return {...state, contact: {}}
    case 'CONTACT_SUBMIT':
      return {...state, contact: {isLoading: true}}
    case 'CONTACT_ERROR':
      return {...state, contact: {isLoading: false, errors: action.errors, status: 'error'}}
    case 'CONTACT_SUCCESS':
      return {...state, contact: {isLoading: false, status: 'success'}}
    case 'CONTACT_RESET':
      return {...state, contact: {isLoading: false}}

    case 'RESEARCH_TYPES_INIT':
      return {...state, researchTypesList: {isLoading: true}}
    case 'RESEARCH_TYPES_SUCCESS':
      return {...state, researchTypesList: {isLoading: false, researchTypes: action.researchTypes}}
    case 'RESEARCH_TYPES_ERROR':
      return {...state, researchTypesList: {isLoading: false, errors: action.errors}}

    case 'TRANSACTION_LIST_INIT':
      return {...state, transactionList: {transactions: [], isLoading: true}}
    case 'TRANSACTION_LIST_SUCCESS':
      return {
        ...state,
        transactionList: {
          isLoading: false,
          transactions: action.pageNumber === 1 ? action.transactions : [...state.transactionList.transactions, ...action.transactions],
          pageNumber: action.pageNumber,
          hasMore: action.hasMore,
          isFilterSelected: state.transactionList.isFilterSelected,
          startDate: action.startDate,
          endDate: action.endDate
        }
      }
    case 'TRANSACTION_LIST_ERROR':
      return {
        ...state,
        transactionList: {
          isLoading: false,
          errors: action.errors,
          transactions: state.transactionList.transactions
        }
      }
    case 'TRANSACTION_LIST_SUBMIT':
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isFilterSelected: true,
          startDate: action.startDate,
          endDate: action.endDate
        }
      }
    case 'TRANSACTION_LIST_CLEAR':
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isFilterSelected: false
        }
      }

    case 'TRANSACTION_PENDING_LIST_INIT':
      return {...state, transactionPendingList: {isLoading: true, transactionsPending: []}}
    case 'TRANSACTION_PENDING_LIST_SUCCESS':
      return {...state, transactionPendingList: {isLoading: false, transactionsPending: action.transactionsPending}}
    case 'TRANSACTION_PENDING_LIST_ERROR':
      return {
        ...state,
        transactionPendingList: {
          isLoading: false,
          transactionsPending: state.transactionPendingList.transactionsPending,
          errors: action.errors
        }
      }

    case 'CREDIT_ADD_INIT':
      return {...state, creditAdd: {}}
    case 'CREDIT_ADD_SUBMIT':
      return {...state, creditAdd: {isLoading: true}}
    case 'CREDIT_ADD_SUCCESS':
      const transactions = [action.credit, ...(state.transactionList.transactions || [])]
      const amount = parseFloat(action.credit.amount_formatted.substr(2))
      const credit_balance_in_dollars = state.currentUser.credit_balance_in_dollars + amount
      const credits_unused_in_dollar = state.currentUser.credits_unused_in_dollar + amount
      return {
        ...state,
        creditAdd: {isLoading: false, credit: action.credit},
        transactionList: {
          ...state.transactionList,
          transactions: transactions
        },
        currentUser: {
          ...state.currentUser,
          credit_balance_in_dollars: credit_balance_in_dollars,
          credits_unused_in_dollar: credits_unused_in_dollar
        }
      }
    case 'CREDIT_ADD_ERROR':
      return {...state, creditAdd: {isLoading: false, errors: action.errors}}

    case 'SPENDING_LIMIT_UPDATE_INIT':
      return {...state, updateSpendingLimit: {isLoading: true}}
    case 'SPENDING_LIMIT_UPDATE_SUCCESS':
      return {
        ...state,
        updateSpendingLimit: {isLoading: false, updated: true},
        currentUser: {
          ...state.currentUser,
          spending_limit: action.spending_limit
        }
      }
    case 'SPENDING_LIMIT_UPDATE_ERROR':
      return {...state, updateSpendingLimit: {isLoading: false, errors: action.errors}}
    case 'SPENDING_LIMIT_UPDATE_RESET':
      return {...state, updateSpendingLimit: {isLoading: false, updated: null}}

    case 'CREDIT_CARD_ADD_OR_UPDATE_INIT':
      return {...state, creditCard: {isLoading: true}}
    case 'CREDIT_CARD_ADD_OR_UPDATE_SUCCESS':
      return {
        ...state,
        creditCard: {isLoading: false, creditCard: action.creditCard, isAddedOrUpdated: true},
        currentUser: {
          ...state.currentUser,
          is_card_linked: true,
          card_last_four_digits: action.creditCard.last_four_digits
        }
      }
    case 'CREDIT_CARD_ADD_OR_UPDATE_ERROR':
      return {...state, creditCard: {isLoading: false, errors: action.errors}}
    case 'CREDIT_CARD_ADD_OR_UPDATE_RESET':
      return {...state, creditCard: {isLoading: false, isAddedOrUpdated: null}}

    case 'RUN_FORM_INIT':
      return {
        ...state,
        runForm: {
          isLoading: true,
          ...runFormInitialState
        }
      }
    case 'ADDITIONAL_ATTR_QUESTIONS_LIST_SUCCESS':
      return {
        ...state,
        additionalAttrQuestionsList: {
          isLoading: false,
          status: 'success',
          questions: action.questions
        }
      }
    case 'COUNTRIES_LIST_SUCCESS':
      const countries = action.countries.map((country) => {
        if (country.name === 'USA')
          return {...country, name: 'United States'}
        else if (country.name === 'UK')
          return {...country, name: 'United Kingdom'}
        return country
      })

      /* Show United States & India at top */
      const topCountries = ['United States', 'India']
      const sortedCountries = countries
        .map(country => country.name)
        .filter(country => !topCountries.includes(country))
        .sort()

      return {
        ...state,
        countriesList: {
          isLoading: false,
          countries: [...topCountries, ...sortedCountries],
          maxInterviewLength: [...countries],
          status: action.status
        }
      }
    case 'COUNTRIES_LIST_FAIL':
      return {
        ...state,
        countriesList: {
          isLoading: false,
          errors: action.errors
        }
      }
    case 'RUN_FORM_QUOTE_INIT':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          quote: {
            isLoading: true,
            hourlyRate: state.runForm.quote?.hourlyRate,
          }
        }
      }

    case 'RUN_FORM_QUOTE_SUCCESS': {
      const paymentPerSubmission = action.quote.cost_per_submission.replace('$', '')
      const hourlyRate = action.quote.hourly_rate?.replace('$', '')
      let quote = {
        isLoading: false,
        paymentPerSubmission: action.isPayPerSubmissionOnFocus ? parseFloat(paymentPerSubmission) : paymentPerSubmission,
        totalRunCost: action.quote.estimated_cost.replace('$', ''),
        hourlyRate: hourlyRate ? parseFloat(hourlyRate) : null
      }
      if (action.quote.default_max_budget) {
        quote.defaultMaxBudget = action.quote.default_max_budget.replace('$', '')
      }

      return {
        ...state,
        runForm: {
          ...state.runForm,
          quote
        }
      }
    }

    case 'RUN_FORM_QUOTE_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          quote: {
            isLoading: false,
            hourlyRate: state.runForm.quote?.hourlyRate,
            errors: action.errors
          }
        }
      }
    case 'RUN_FORM_QUOTE_RESET':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          quote: {hourlyRate: state.runForm.quote?.hourlyRate}
        }
      }
    case 'DEMOGRAPHIC_QUERY_STRING_CONFIG_SUCCESS':
      const defaultQSAttributesForEnglish = {...commonQSAttributes, ...action.demographicQSAttributes}
      const defaultQSAttributesForNonEnglish = {...commonQSAttributes}

      return {
        ...state,
        defaultQueryStringAttributes: {
          english: defaultQSAttributesForEnglish,
          non_english: defaultQSAttributesForNonEnglish
        }
      }
    case 'RUN_FORM_DEMOGRAPHIC_FILTER_SUCCESS':
      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          demographic: {
            isLoading: false,
            values: action.filters
          }
        }
      }
    case 'RUN_FORM_CUSTOM_ATTR_FILTER_SUCCESS':
      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          custom_attributes: {
            isLoading: false,
            values: action.filters
          }
        }
      }
    case 'RUN_FORM_PROFILING_VARIABLES_INIT':
      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          cintProfiling: {
            isLoading: true,
          }
        }
      }
    case 'RUN_FORM_PROFILING_VARIABLES_SUCCESS':
      let values = action.variables.cint_profiling_variables
      values.push(ageFilterForCint, genderFilterForCint)

      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          cintProfiling: {
            isLoading: false,
            values: values
          }
        }
      }
    case 'RUN_FORM_PROFILING_VARIABLES_FAIL':
      return {
        ...state,
        filtersList: {
          ...state.filtersList,
          cintProfiling: {
            isLoading: false,
            errors: action.errors
          }
        }
      }
    case 'RUN_FORM_CREATE_ATTR_INIT':
      return {
        ...state,
        createAttribute: {
          isLoading: true
        }
      }
    case 'RUN_FORM_CREATE_ATTR_RESET':
      return {
        ...state,
        createAttribute: {}
      }
    case 'RUN_FORM_CREATE_ATTR_SUCCESS':
      return {
        ...state,
        createAttribute: {
          isLoading: false,
          status: 'success'
        },
        filtersList: {
          ...state.filtersList,
          custom_attributes: {
            ...state.filtersList.custom_attributes,
            values: [
              ...state.filtersList.custom_attributes.values,
              action.attribute
            ]
          }
        }
      }
    case 'RUN_FORM_CREATE_ATTR_FAIL':
      return {
        ...state,
        createAttribute: {
          isLoading: false,
          errors: action.errors
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_INIT':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          qualifiersCount: {
            isLoading: true
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          qualifiersCount: {
            isLoading: false,
            total: action.count.qualified_workers_count
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          qualifiersCount: {
            isLoading: false,
            errors: action.errors
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_INIT':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          cintQualifiersCount: {
            isLoading: true
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          cintQualifiersCount: {
            isLoading: false,
            total: action.count.qualified_workers_count_for_cint
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_RESET':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          cintQualifiersCount: {
            total: null
          }
        }
      }
    case 'RUN_FORM_QUALIFIED_WORKERS_COUNT_FOR_CINT_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          cintQualifiersCount: {
            isLoading: false,
            errors: action.errors
          }
        }
      }
    case 'PRE_SCREEN_RUNS_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          prescreenRuns: {
            isLoading: false,
            values: action.runs.prescreen_data
          }
        }
      }
    case 'PRE_SCREEN_RUNS_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          prescreenRuns: {
            isLoading: false,
            errors: action.errors
          }
        }
      }
    case 'PRE_SCREEN_PROJECTS_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          prescreenProjects: {
            isLoading: false,
            values: action.projects.prescreen_data
          }
        }
      }
    case 'PRE_SCREEN_PROJECTS_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          prescreenProjects: {
            isLoading: false,
            errors: action.errors
          }
        }
      }
    case 'CREATE_AND_START_RUN_INIT':
      return {
        ...state,
        createAndStartRun: {
          isLoading: true
        }
      }
    case 'CREATE_AND_START_RUN_SUCCESS':
      return {
        ...state,
        createAndStartRun: {
          isLoading: false,
          run: action.run,
          status: 'success'
        }
      }
    case 'CREATE_AND_START_RUN_FAIL':
      return {
        ...state,
        createAndStartRun: {
          isLoading: false,
          errors: action.errors,
          status: 'error'
        }
      }
    case 'PRE_SURVEY_QUESTIONS_INIT':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          questions: {
            isLoading: true
          }
        }
      }
    case 'PRE_SURVEY_QUESTIONS_SUCCESS': {
      const hiddenSpammerCheckQuestion = findLast(action.questions.questions, q => q.question_type === 'test_question')
      let reOrderedQuestions = shuffle(action.questions.questions)

      if (hiddenSpammerCheckQuestion) {
        reOrderedQuestions = reOrderedQuestions.filter(q => q.id !== hiddenSpammerCheckQuestion.id)
        reOrderedQuestions.push(hiddenSpammerCheckQuestion)
      }

      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          questions: {
            isLoading: false,
            data: {
              ...action.questions,
              questions: reOrderedQuestions
            },
            status: 'success'
          }
        }
      }
    }
    case 'PRE_SURVEY_QUESTIONS_FAIL':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          questions: {
            isLoading: false,
            errors: action.errors,
            status: 'error'
          }
        }
      }
    case 'MARK_PRE_SURVEY_STARTED_INIT':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          assignment: {
            isLoading: true
          }
        }
      }
    case 'MARK_PRE_SURVEY_STARTED_SUCCESS':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          assignment: {
            isLoading: false,
            data: action.assignment,
            status: 'success'
          }
        }
      }
    case 'MARK_PRE_SURVEY_STARTED_FAIL':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          assignment: {
            isLoading: false,
            errors: action.errors,
            status: 'error'
          }
        }
      }
    case 'RESEND_CONFIRMATION_EMAIL_INIT':
      return {...state, resendEmail: {isLoading: true}}
    case 'RESEND_CONFIRMATION_EMAIL_SUCCESS':
      return {...state, resendEmail: {isLoading: false, ...action.user}}
    case 'RESEND_CONFIRMATION_EMAIL_FAIL':
      return {...state, resendEmail: {isLoading: false, errors: action.errors}}

    case 'CONFIRM_EMAIL_INIT':
      return {...state, confirmEmail: {isLoading: true}}
    case 'CONFIRM_EMAIL_SUCCESS':
      let currentUser = state.currentUser
      if (state.currentUser?.email === action.user.email) {
        currentUser = {
          ...state.currentUser,
          confirmed_at: action.user?.confirmed_at
        }
      }

      successToast('Your email has been confirmed.', {autoClose: 4000})

      return {
        ...state,
        confirmEmail: {isLoading: false, confirmedEmail: true},
        currentUser: currentUser
      }
    case 'CONFIRM_EMAIL_FAIL':
      const confirmedEmail = !!action.errors.includes('Your E-mail is already verified. Please try signing in.')
      return {...state, confirmEmail: {isLoading: false, confirmedEmail, errors: action.errors}}

    case 'CREATE_AND_START_RUN_RESET':
      return {
        ...state,
        createAndStartRun: null
      }
    case 'COPY_CODE_TEXT_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          copyCodeText: {
            isLoading: false,
            text: action.text,
            status: 'success'
          }
        }
      }
    case 'VALIDATE_ANSWER_INIT':
      return {
        ...state,
        [`validateAnswerFor${action.ui}`]: {
          isLoading: true
        },
        createDemographicAnswer: {}
      }
    case 'VALIDATE_ANSWER_SUCCESS':
      return {
        ...state,
        [`validateAnswerFor${action.ui}`]: {
          isLoading: false,
          data: action.status,
          questionId: action.questionId,
          status: 'success'
        }
      }
    case 'VALIDATE_ANSWER_FAIL':
      return {
        ...state,
        [`validateAnswerFor${action.ui}`]: {
          isLoading: false,
          errors: action.errors,
          questionId: action.questionId,
          status: 'error'
        }
      }
    case 'VALIDATE_ANSWER_RESET':
      return {
        ...state,
        validateAnswerForPreviousAnswer: {}
      }
    case 'CREATE_ANSWERS_INIT':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          createAnswers: {
            isLoading: true
          }
        }
      }
    case 'CREATE_ANSWERS_SUCCESS':
      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          assignment: {
            ...state.preSurvey?.assignment,
            data: {
              ...state.preSurvey?.assignment?.data,
              ...action.assignment
            }
          },
          createAnswers: {
            isLoading: false,
            data: action.status,
            status: 'success'
          }
        }
      }
    case 'CREATE_ANSWERS_FAIL':
      const errorState = {
        createAnswers: {
          isLoading: false,
          errors: action.errors,
          status: 'error'
        },
      }

      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          ...errorState,
        }
      }
    case 'CREATE_DEMOGRAPHIC_ANSWERS_INIT':
      return {
        ...state,
        createDemographicAnswer: {
          isLoading: true,
        }
      }
    case 'CREATE_DEMOGRAPHIC_ANSWERS_SUCCESS':
      return {
        ...state,
        createDemographicAnswer: {
          isLoading: false,
          status: 'success',
        }
      }
    case 'CREATE_DEMOGRAPHIC_ANSWERS_FAIL':
      return {
        ...state,
        createDemographicAnswer: {
          isLoading: false,
          errors: action.errors,
          status: 'error',
        }
      }
    case 'CREATE_DEMOGRAPHIC_ANSWERS_RESET':
      return {
        ...state,
        createDemographicAnswer: {}
      }
    case 'UPDATE_PREVIOUS_ANSWERS': {
      const previousAnswers = state.preSurvey.questions.data.previous_answers
      const updatedAnswerIndex = previousAnswers.findIndex(answer => answer.id === action.answer.id)
      const updatedPreviousAnswers = [...previousAnswers]
      updatedPreviousAnswers[updatedAnswerIndex] = action.answer

      return {
        ...state,
        preSurvey: {
          ...state.preSurvey,
          questions: {
            ...state.preSurvey.questions,
            data: {
              ...state.preSurvey.questions.data,
              previous_answers: updatedPreviousAnswers
            }
          }
        }
      }
    }
    case 'POST_SURVEY_INIT':
      return {...state, postSurvey: {}}
    case 'POST_SURVEY_FEEDBACK_STARTED_INIT':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          feedbackStarted: {isLoading: true}
        }
      }
    case 'POST_SURVEY_FEEDBACK_STARTED_SUCCESS':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          feedbackStarted: {isLoading: false, isRunning: true, assignment: action.assignment}
        }
      }
    case 'POST_SURVEY_FEEDBACK_STARTED_FAIL':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          feedbackStarted: {isLoading: false, errors: action.errors, quotaFullUrl: action.quotaFullUrl}
        }
      }
    case 'POST_SURVEY_PLATFORM_TYPE_INIT':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          platformType: {isLoading: true}
        }
      }
    case 'POST_SURVEY_PLATFORM_TYPE_SUCCESS':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          platformType: {isLoading: false, platform: action.platformType}
        }
      }
    case 'POST_SURVEY_PLATFORM_TYPE_FAIL':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          platformType: {isLoading: false, errors: action.errors}
        }
      }
    case 'POST_SURVEY_ENABLE_NOTIFICATION_INIT':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          enableNotification: {isLoading: true}
        }
      }
    case 'POST_SURVEY_ENABLE_NOTIFICATION_SUCCESS':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          enableNotification: {isLoading: false, status: 'success'}
        }
      }
    case 'POST_SURVEY_ENABLE_NOTIFICATION_FAIL':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          enableNotification: {isLoading: false, status: 'error', errors: action.errors}
        }
      }
    case 'POST_SURVEY_SUBMIT_FEEDBACK_INIT':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          submitFeedback: {isLoading: true}
        }
      }
    case 'POST_SURVEY_SUBMIT_FEEDBACK_SUCCESS':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          submitFeedback: {isLoading: false, status: 'success', data: action.payload},
          feedbackStarted: {
            ...state.postSurvey.feedbackStarted,
            assignment: {
              ...state.postSurvey.feedbackStarted.assignment,
              feedback_completed_at: new Date()
            }
          }
        }
      }
    case 'POST_SURVEY_SUBMIT_FEEDBACK_FAIL':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          submitFeedback: {isLoading: false, status: 'error', errors: action.errors}
        }
      }
    case 'POST_SURVEY_ELIGIBLE_HITS_INIT':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          eligibleHits: {isLoading: true}
        }
      }
    case 'POST_SURVEY_ELIGIBLE_HITS_SUCCESS':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          eligibleHits: {isLoading: false, runs: action.eligibleHits, status: 'success'}
        }
      }
    case 'POST_SURVEY_ELIGIBLE_HITS_FAIL':
      return {
        ...state, postSurvey: {
          ...state.postSurvey,
          eligibleHits: {isLoading: false, status: 'error', errors: action.errors}
        }
      }
    case 'SETUP_RUN_FORM_FROM_RUN_SUCCESS': {
      const platform = state.platformList.availablePlatforms?.find((platform) => platform.full_name === action.run.platform_name)
      const name = (action.isRunUpdate)
        ? action.run.name
        : `${action.run.project_name}: Run ${action.run.task_runs_count + 1}`

      const defaultQSAttributes = (action.run.activity_language_name === 'English')
        ? state.defaultQueryStringAttributes.english
        : state.defaultQueryStringAttributes.non_english

      const runForm = {
        ...action.run,
        platform: platform || {},
        name: name,
        default_query_string_attributes: defaultQSAttributes,
      }

      return {
        ...state,
        runForm: {
          ...state.runForm,
          ...runForm,
          isLoading: false,
        },
        runFormOverview: {
          ...state.runFormOverview,
          default_query_string_attributes: {...defaultQSAttributes, ...state.runFormOverview.default_query_string_attributes},
        }
      }
    }
    case 'SETUP_RUN_FORM_FROM_RUN_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          isLoading: false,
          errors: action.errors,
        },
      }
    case 'SETUP_RUN_FORM_FROM_PROJECT': {
      const activityLanguageId = state.currentUser.default_activity_language_id
      const {name: activityLanguage} = getActivityLanguageById(state.activityLanguages.languages, activityLanguageId)

      const defaultQSAttributes = (activityLanguage === 'English')
        ? state.defaultQueryStringAttributes.english
        : state.defaultQueryStringAttributes.non_english

      const runForm = {
        ...action.project,
        task_id: action.project.id,
        platform_id: '',
        platform_name: '',
        platform: {},
        name: `${action.project.project_name}: Run ${action.project.runs_count + 1}`,
        filters: [],
        additional_attributes: [],
        task_parameters: {
          country_name: action.project.user.default_country || 'United States',
          expires_after: action.project.user.default_run_duration,
          expires_after_time_type: action.project.user.default_run_duration_type,
          max_time_type: 'minutes',
          payment_currency: '$',
          max_submissions_per_person: 1,
          task_url: state.checkGtUserRegistered?.activityData?.activity_url || '',
          task_data_url: state.checkGtUserRegistered?.activityData?.activity_data_url || ''
        },
        query_string_attributes: defaultQSAttributes,
        runs_count: action.project.runs_count
      }

      return {
        ...state,
        runForm: {
          ...state.runForm,
          ...runForm,
          isLoading: false,
        },
        runFormOverview: {
          ...state.runFormOverview,
          default_query_string_attributes: {...defaultQSAttributes, ...state.runFormOverview.default_query_string_attributes},
        }
      }
    }
    case 'RUN_FORM_UPDATE_INIT':
      return {...state, runUpdate: {isLoading: true}}
    case 'RUN_FORM_UPDATE_SUCCESS':
      return {...state, runUpdate: {isLoading: false, status: 'success'}}
    case 'RUN_FORM_UPDATE_ERROR':
      return {...state, runUpdate: {isLoading: false, status: 'error'}}
    case 'RUN_FORM_UPDATE_RESET':
      return {...state, runUpdate: null}
    // RUN FORM
    case 'RUN_FORM_RESET':
      return {
        ...state,
        runForm: {...runFormInitialState},
        runFormOverview: {...runFormOverviewInitialState},
        checkGtUserRegistered: {},
        filtersList: {
          ...state.filtersList,
          cintProfiling: {},
        },
        completionLinkVerification: {},
        screenInLinkVerification: {},
        screenOutLinkVerification: {},
        positlySpending: {}
      }
    case 'RUN_FORM_OVERVIEW_INIT':
      return {
        ...state, runFormOverview: {...runFormOverviewInitialState}
      }
    case 'RUN_FORM_OVERVIEW_UPDATE':
      let selectedPlatform = state.runFormOverview.platform
      if (state.platformList && state.platformList.platforms && action.values.platform_id) {
        selectedPlatform = state.platformList.platforms
          .find(platform => platform.id === action.values.platform_id)
      }

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          ...action.values,
          platform: selectedPlatform,
          task_parameters: {
            ...state.runFormOverview?.task_parameters,
            ...action.values.task_parameters
          },
        }
      }
    case 'RUN_FORM_OVERVIEW_ADD_ADDITIONAL_ATTRIBUTE':
      let additionalAttributes = state.runFormOverview.additional_attributes || []
      let qsAttributes = state.runFormOverview.query_string_attributes || {}
      let defaultQsAttributes = state.runFormOverview.default_query_string_attributes || {}

      additionalAttributes = [...additionalAttributes, action.attribute]

      qsAttributes = {...action.attribute.query_string_config, ...qsAttributes}
      defaultQsAttributes = {...action.attribute.query_string_config, ...defaultQsAttributes}

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          query_string_attributes: qsAttributes,
          default_query_string_attributes: defaultQsAttributes,
          additional_attributes: additionalAttributes,
        }
      }
    case 'RUN_FORM_OVERVIEW_REMOVE_ADDITIONAL_ATTRIBUTE':
      let attributes = state.runFormOverview.additional_attributes.filter((attribute) => {
        return attribute.question_text !== action.attribute.question_text
      })
      if (action.attribute.is_demographic) {
        let demographicFilter = state.runFormOverview.additional_attributes.find((attribute) => {
          return attribute.question_text === action.attribute.question_text
        })
        if (demographicFilter)
          attributes = [...attributes, {...demographicFilter, is_demographic: true}]
      }
      let qs = state.runFormOverview.query_string_attributes
      let defaultQs = state.runFormOverview.default_query_string_attributes

      const requiredConfigs = ['Age', 'Gender', 'Household Income', 'Education Level']
      const isRequiredConfig = requiredConfigs.includes(action.attribute.filter_label || action.attribute.label)

      if (!isRequiredConfig && !action.attribute.is_demographic) {
        const qsAttributesToRemove = Object.keys(action.attribute.query_string_config)
        qs = Object.fromEntries(
          Object.entries(state.runFormOverview.query_string_attributes)
            .filter(([key]) => !qsAttributesToRemove.includes(key))
        )
        defaultQs = Object.fromEntries(
          Object.entries(state.runFormOverview.default_query_string_attributes)
            .filter(([key]) => !qsAttributesToRemove.includes(key))
        )
      }

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          query_string_attributes: qs,
          default_query_string_attributes: defaultQs,
          additional_attributes: attributes,
        }
      }
    case 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_FILTER': {
      let updatedFilters
      let qsAttributes = state.runFormOverview.query_string_attributes || {}
      let defaultQsAttributes = state.runFormOverview.default_query_string_attributes || {}
      const index = state.runFormOverview.filters
        .findIndex(filter => filter.name === action.filter.name)

      if (index === -1) {
        updatedFilters = [...state.runFormOverview.filters, action.filter]

        qsAttributes = {...action.filter.query_string_config, ...qsAttributes}
        defaultQsAttributes = {...action.filter.query_string_config, ...defaultQsAttributes}
      } else {
        updatedFilters = [...state.runFormOverview.filters]
        updatedFilters[index] = action.filter
      }

      const isFiltersValid = checkIfFiltersAreValid(updatedFilters)
        && state.runFormOverview.prescreen_data.is_filter_valid

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          filters: updatedFilters,
          query_string_attributes: qsAttributes,
          default_query_string_attributes: defaultQsAttributes,
          isParticipantsFormValid: isFiltersValid
        }
      }
    }
    case 'RUN_FORM_OVERVIEW_REMOVE_FILTER': {
      const updatedFilters = state.runFormOverview.filters
        .filter(filter => filter.name !== action.filterName)

      const filterToRemove = state.runFormOverview.filters
        .find(filter => filter.name === action.filterName)
      let qs = state.runFormOverview.query_string_attributes
      let defaultQs = state.runFormOverview.default_query_string_attributes

      const requiredConfigs = ['Age', 'Gender', 'Household Income', 'Education Level']
      const isRequiredConfig = requiredConfigs.includes(filterToRemove.filter_label || filterToRemove.label)
      const isAdditionalAttribute = state.runFormOverview.additional_attributes.find(attribute => attribute.question_text === filterToRemove.question_text)

      let additionalAttributes = state.runFormOverview.additional_attributes.map((attribute) => {
        if (attribute.question_text === filterToRemove.question_text)
          delete attribute.is_demographic

        return attribute
      })

      if (!isRequiredConfig && filterToRemove.is_demographic && !isAdditionalAttribute) {
        const qsAttributesToRemove = Object.keys(filterToRemove.query_string_config)
        qs = Object.fromEntries(
          Object.entries(state.runFormOverview.query_string_attributes)
            .filter(([key]) => !qsAttributesToRemove.includes(key))
        )
        defaultQs = Object.fromEntries(
          Object.entries(state.runFormOverview.default_query_string_attributes)
            .filter(([key]) => !qsAttributesToRemove.includes(key))
        )
      }

      const isFiltersValid = checkIfFiltersAreValid(updatedFilters)
        && state.runFormOverview.prescreen_data.is_filter_valid

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          query_string_attributes: qs,
          default_query_string_attributes: defaultQs,
          filters: updatedFilters,
          additional_attributes: additionalAttributes,
          isParticipantsFormValid: isFiltersValid,
        }
      }
    }
    case 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_PRESCREEN_DATA':
      const _isFiltersValid = checkIfFiltersAreValid(state.runFormOverview.filters)

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          prescreen_data: action.prescreenData,
          prescreen_ids: action.prescreenIds,
          isParticipantsFormValid: _isFiltersValid && action.prescreenData.is_filter_valid
        }
      }
    case 'RUN_FORM_OVERVIEW_ADD_OR_UPDATE_CINT_FILTER': {
      let updatedFilters
      const index = state.runFormOverview.cint_filters
        .findIndex(filter => filter.id === action.filter.id)

      if (index === -1) {
        updatedFilters = [action.filter, ...state.runFormOverview.cint_filters]
      } else {
        updatedFilters = [...state.runFormOverview.cint_filters]
        updatedFilters[index] = action.filter
      }

      const isParticipantsFormValid = checkIfFiltersAreValid(updatedFilters) && checkIfQuotasAreValid(state.runFormOverview.cint_quotas)

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_filters: updatedFilters,
          isParticipantsFormValid
        }
      }
    }
    case 'RUN_FORM_OVERVIEW_REMOVE_CINT_FILTER': {
      const updatedFilters = state.runFormOverview.cint_filters
        .filter(filter => filter.id !== action.filterId)

      const isParticipantsFormValid = checkIfFiltersAreValid(updatedFilters) && checkIfQuotasAreValid(state.runFormOverview.cint_quotas)

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_filters: updatedFilters,
          isParticipantsFormValid
        }
      }
    }
    case 'RUN_FORM_OVERVIEW_RESET_FILTERS': {
      let qs = state.runFormOverview.query_string_attributes
      let defaultQs = state.runFormOverview.default_query_string_attributes
      const requiredConfigs = ['Age', 'Gender', 'Household Income', 'Education Level']
      const additionalAttributes = state.runFormOverview.additional_attributes

      const queryStrings = state.runFormOverview.filters
        .filter(filter => {
          const isRequiredConfig = requiredConfigs.includes(filter.filter_label || filter.label)
          const isAdditionalAttribute = additionalAttributes.find(attribute => attribute.question_text === filter.question_text)
          return filter.is_demographic && !isRequiredConfig && !isAdditionalAttribute
        }).map(filter => (
          filter.query_string_config
        )).reduce((accumulator, current) => (
          {...accumulator, ...current}
        ), {})

      const qsAttributesToRemove = Object.keys(queryStrings)
      qs = Object.fromEntries(
        Object.entries(state.runFormOverview.query_string_attributes)
          .filter(([key]) => !qsAttributesToRemove.includes(key))
      )
      defaultQs = Object.fromEntries(
        Object.entries(state.runFormOverview.default_query_string_attributes)
          .filter(([key]) => !qsAttributesToRemove.includes(key))
      )

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          filters: [],
          query_string_attributes: qs,
          default_query_string_attributes: defaultQs,
        }
      }
    }
    case 'RUN_FORM_OVERVIEW_RESET_CINT_FILTERS':
      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_filters: action.filters
        }
      }
    case 'RUN_FORM_DISABLE_PILOT_WARNING_INIT':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          disablePilotWarning: {
            isLoading: true
          }
        }
      }
    case 'RUN_FORM_DISABLE_PILOT_WARNING_SUCCESS':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          disablePilotWarning: {
            isLoading: false,
            status: 'success'
          }
        },
        currentUser: {
          ...state.currentUser,
          show_pilot_warning: false,
        }
      }
    case 'RUN_FORM_DISABLE_PILOT_WARNING_FAIL':
      return {
        ...state,
        runForm: {
          ...state.runForm,
          disablePilotWarning: {
            isLoading: false,
            status: 'error',
            errors: action.errors
          }
        }
      }
    case 'CHECK_GT_USER_REGISTERED_INIT':
      return {
        ...state,
        checkGtUserRegistered: {gtDataId: action.gtDataId, isLoading: true}
      }
    case 'CHECK_GT_USER_REGISTERED_SUCCESS':
      return {
        ...state,
        checkGtUserRegistered: {
          ...state.checkGtUserRegistered,
          isLoading: false,
          isRegistered: action.is_registered,
          projectId: action.project_id,
          email: action.email,
          activityData: action.activityData,
          status: 'success'
        }
      }
    case 'CHECK_GT_USER_REGISTERED_FAIL':
      return {
        ...state,
        checkGtUserRegistered: {
          ...state.checkGtUserRegistered,
          isLoading: false,
          errors: action.errors,
          status: 'error'
        }
      }
    case 'WORKER_FETCH_INIT':
      return {
        ...state,
        workerUnsubscribe: {isLoading: true}
      }
    case 'WORKER_FETCH_SUCCESS':
      return {
        ...state,
        workerUnsubscribe: {
          isLoading: false,
          isUnsubscribed: !action.payload.notify,
          notify_by: (action.payload.notify_by || 'none'),
          email: action.payload.email,
          unsubscribe_reason: action.payload.unsubscribe_reason
        }
      }
    case 'WORKER_FETCH_FAIL':
      return {
        ...state,
        workerUnsubscribe: {isLoading: false, errors: action.errors}
      }
    case 'WORKER_UNSUBSCRIBE_INIT':
      return {
        ...state,
        workerUnsubscribe: {...state.workerUnsubscribe, isUnsubscribing: true}
      }
    case 'WORKER_UNSUBSCRIBE_SUCCESS':
      return {
        ...state,
        workerUnsubscribe: {
          isLoading: false,
          isUnsubscribed: !action.payload.notify,
          notify_by: (action.payload.notify_by || 'none'),
          email: action.payload.email,
          unsubscribe_reason: action.payload.unsubscribe_reason
        }
      }
    case 'WORKER_UNSUBSCRIBE_FAIL':
      return {
        ...state,
        workerUnsubscribe: {isUnsubscribing: false, errors: action.errors}
      }
    case 'ACTIVITY_LANGUAGES_LIST_SUCCESS':
      return {
        ...state,
        activityLanguages: {
          isLoading: false,
          languages: action.languages,
          status: 'success'
        }
      }
    case 'ACTIVITY_LANGUAGES_LIST_FAIL':
      return {
        ...state,
        activityLanguages: {
          isLoading: false,
          status: 'error',
          errors: action.errors
        }
      }
    case 'RUN_FORM_RESET_FILTERS_AND_QUERY_STRINGS': {
      const filters = state.runFormOverview?.filters || []
      const customFilters = filters.filter(filter => !filter.is_demographic)

      const currentQueryStringAttributes = state.runFormOverview.query_string_attributes
      const updatedQueryStringAttributes = {...state.defaultQueryStringAttributes.non_english}

      Object.keys(state.defaultQueryStringAttributes.non_english).forEach(key => {
        if (currentQueryStringAttributes[key]) {
          updatedQueryStringAttributes[key] = currentQueryStringAttributes[key]
        }
      })

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          filters: customFilters,
          default_query_string_attributes: state.defaultQueryStringAttributes.non_english,
          query_string_attributes: updatedQueryStringAttributes,
          additional_attributes: []
        }
      }
    }
    case 'RUN_FORM_LOAD_DEFAULT_QUERY_STRINGS': {
      const currentQueryStringAttributes = state.runFormOverview.query_string_attributes
      const updatedQueryStringAttributes = {...state.defaultQueryStringAttributes.english}

      Object.keys(state.defaultQueryStringAttributes.english).forEach(key => {
        if (currentQueryStringAttributes[key]) {
          updatedQueryStringAttributes[key] = currentQueryStringAttributes[key]
        }
      })

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          default_query_string_attributes: state.defaultQueryStringAttributes.english,
          query_string_attributes: updatedQueryStringAttributes
        }
      }
    }
    case 'CHECK_SLOT_AVAILABILITY_INIT':
      return {
        ...state,
        checkSlotAvailability: {isLoading: true}
      }
    case 'CHECK_SLOT_AVAILABILITY_SUCCESS':
      return {
        ...state,
        checkSlotAvailability: {
          isLoading: false,
          status: 'success',
          redirectUrl: action.redirectUrl
        }
      }
    case 'CHECK_SLOT_AVAILABILITY_FAIL':
      return {
        ...state,
        checkSlotAvailability: {
          isLoading: false,
          status: 'error',
          errors: action.errors
        }
      }
    case 'START_LINK_VERIFICATION_INIT': {
      const linkType = snakeCaseToCamelCase(action.linkType)
      return {
        ...state,
        [`${linkType}Verification`]: {isLoading: true}
      }
    }
    case 'START_LINK_VERIFICATION_SUCCESS': {
      const linkType = snakeCaseToCamelCase(action.linkVerification.link_type)
      return {
        ...state,
        [`${linkType}Verification`]: {isLoading: false, data: action.linkVerification}
      }
    }
    case 'START_LINK_VERIFICATION_FAIL': {
      const linkType = snakeCaseToCamelCase(action.linkType)
      return {
        ...state,
        [`${linkType}Verification`]: {isLoading: false, errors: action.errors}
      }
    }
    case 'MARK_FEEDBACK_LINK_VERIFIED_INIT':
      return {...state, markLinkVerified: {isLoading: true}}
    case 'MARK_FEEDBACK_LINK_VERIFIED_SUCCESS':
      return {
        ...state,
        markLinkVerified: {isLoading: false, feedbackLinkVerification: action.feedbackLinkVerification}
      }
    case 'MARK_FEEDBACK_LINK_VERIFIED_FAIL':
      return {...state, markLinkVerified: {isLoading: false, errors: action.errors}}
    case 'UPDATE_LINK_VERIFICATION': {
      const linkType = snakeCaseToCamelCase(action.linkVerificationData.link_type)
      const linkVerification = state[`${linkType}Verification`] || {}
      return {
        ...state,
        [`${linkType}Verification`]: {
          ...linkVerification,
          data: {
            ...linkVerification.data,
            ...action.linkVerificationData,
            status: 'verified'
          }
        }
      }
    }
    case 'RESET_LINK_VERIFICATION': {
      let resetLinkVerificationState = {}
      if (!action.isActivityUrlChanged && !action.isScreeningUrlChanged) {
        resetLinkVerificationState = {
          completionLinkVerification: {},
          screenInLinkVerification: {},
          screenOutLinkVerification: {}
        }
      } else if (action.isActivityUrlChanged) {
        resetLinkVerificationState = {
          completionLinkVerification: {}
        }
      } else if (action.isScreeningUrlChanged) {
        resetLinkVerificationState = {
          screenInLinkVerification: {},
          screenOutLinkVerification: {}
        }
      }

      return {
        ...state,
        ...resetLinkVerificationState
      }
    }
    case 'FEEDBACK_STARTED_FALLBACK_INIT':
      return {...state, feedbackStartedFallback: {isLoading: true}}
    case 'FEEDBACK_STARTED_FALLBACK_SUCCESS':
      return {...state, feedbackStartedFallback: {isLoading: false, status: 'success', assignment: action.assignment}}
    case 'FEEDBACK_STARTED_FALLBACK_FAIL':
      return {...state, feedbackStartedFallback: {isLoading: false, status: 'error', errors: action.errors}}

    case 'MARK_INELIGIBLE_INIT':
      return {...state, cintRedirect: {isLoading: true,}}
    case 'MARK_INELIGIBLE_SUCCESS':
      return {...state, cintRedirect: {isLoading: false, status: 'success', cintReturnUrl: action.cintReturnUrl}}
    case 'MARK_INELIGIBLE_FAIL':
      return {...state, cintRedirect: {isLoading: false, status: 'error', errors: action.errors}}

    case 'ANNOUNCEMENTS_INIT':
      return {...state, announcements: {isLoading: true}}
    case 'ANNOUNCEMENTS_SUCCESS':
      return {...state, announcements: {isLoading: false, data: action.announcements}}
    case 'ANNOUNCEMENTS_FAIL':
      return {...state, announcements: {isLoading: false, errors: action.errors}}

    case 'SCREENED_IN_INIT':
      return {...state, screenedIn: {isLoading: true}}
    case 'SCREENED_IN_SUCCESS':
      return {
        ...state,
        screenedIn: {
          isLoading: false,
          assignment: action.data,
        }
      }
    case 'SCREENED_IN_FAIL':
      return {...state, screenedIn: {isLoading: false, errors: action.errors}}

    case 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_INIT':
      return {...state, screenedIn: {...state.screenedIn, isAcceptingBonus: true}}
    case 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_SUCCESS':
      return {
        ...state,
        screenedIn: {
          ...state.screenedIn,
          isAcceptingBonus: false,
          acceptBonusActivityErrors: null,
          assignment: {
            ...state.screenedIn.assignment,
            ...action.data
          },
        }
      }
    case 'GET_OPEN_SLOTS_FOR_MAIN_ACTIVITY_FAIL':
      return {
        ...state,
        screenedIn: {
          ...state.screenedIn,
          isAcceptingBonus: false,
          acceptBonusActivityErrors: action.data.errors,
          assignment: {
            ...state.screenedIn.assignment,
            is_paused_after_screened_in: action.data.is_paused_after_screened_in
          }
        }
      }

    case 'MAIN_ACTIVITY_FEEDBACK_INIT':
      return {...state, screenedIn: {...state.screenedIn, isCreatingFeedback: true}}
    case 'MAIN_ACTIVITY_FEEDBACK_SUCCESS':
      return {
        ...state,
        screenedIn: {
          ...state.screenedIn,
          isCreatingFeedback: false,
          assignment: {
            ...state.screenedIn.assignment,
            main_activity_feedback_uid: action.data.main_activity_feedback_uid
          }
        }
      }
    case 'MAIN_ACTIVITY_FEEDBACK_FAIL':
      return {...state, screenedIn: {...state.screenedIn, errors: action.errors, isCreatingFeedback: false}}

    case 'UPDATE_SCREEN_IN_ASSIGNMENT':
      return {
        ...state,
        screenedIn: {
          ...state.screenedIn,
          assignment: {...state.screenedIn.assignment, ...action.assignment}
        }
      }

    case 'GET_POSITLY_SPENDING_INIT':
      return {...state, positlySpending: {isLoading: true}}
    case 'GET_POSITLY_SPENDING_SUCCESS': {
      const {spent, duration} = action.data

      return {...state, positlySpending: {isLoading: false, spent, duration}}
    }
    case 'GET_POSITLY_SPENDING_FAIL':
      return {...state, positlySpending: {isLoading: false, errors: action.errors}}

    case 'GET_QUOTA_FEASIBILITY_COUNT_INIT': {
      const {quotaId, optionId} = action.additionalData
      const quota = {
        ...state.quotaFeasibilityCount?.[quotaId],
        [optionId]: {isLoading: true}
      }

      return {
        ...state,
        quotaFeasibilityCount: {
          ...state.quotaFeasibilityCount,
          [quotaId]: quota
        }
      }
    }
    case 'GET_QUOTA_FEASIBILITY_COUNT_SUCCESS': {
      const {quotaId, optionId} = action.additionalData
      const quota = {
        ...state.quotaFeasibilityCount?.[quotaId],
        [optionId]: {
          isLoading: false,
          count: action.count
        }
      }

      return {
        ...state,
        quotaFeasibilityCount: {
          ...state.quotaFeasibilityCount,
          [quotaId]: quota
        }
      }
    }
    case 'GET_QUOTA_FEASIBILITY_COUNT_FAIL': {
      const {quotaId, optionId} = action.additionalData
      const quota = {
        ...state.quotaFeasibilityCount?.[quotaId],
        [optionId]: {
          isLoading: false,
          errors: action.errors,
          count: null
        }
      }

      return {
        ...state,
        quotaFeasibilityCount: {
          ...state.quotaFeasibilityCount,
          [quotaId]: quota
        }
      }
    }

    case 'CINT_QUOTA_ADD_OR_UPDATE': {
      const cint_quotas = cloneDeep(state.runFormOverview?.cint_quotas || [])
      const index = cint_quotas.findIndex(quota => quota.id === action.quota.id)

      if (index === -1) {
        if (action.shouldUnshiftArray) {
          cint_quotas.unshift(action.quota)
        } else {
          cint_quotas.push(action.quota)
        }
      } else {
        cint_quotas[index] = action.quota
      }

      const isParticipantsFormValid = checkIfFiltersAreValid(state.runFormOverview.cint_filters) && checkIfQuotasAreValid(cint_quotas)

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_quotas,
          isParticipantsFormValid
        }
      }
    }
    case 'CINT_QUOTA_REMOVE': {
      const cint_quotas = state.runFormOverview?.cint_quotas || []
      const updatedCintQuotas = cint_quotas.filter(quota => quota.id !== action.quotaId)

      const isParticipantsFormValid = checkIfFiltersAreValid(state.runFormOverview.cint_filters) && checkIfQuotasAreValid(updatedCintQuotas)

      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_quotas: updatedCintQuotas,
          isParticipantsFormValid
        }
      }
    }
    case 'CINT_QUOTA_RESET':
      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_quotas: action.quotas
        }
      }

    case 'UPDATE_CINT_INTERLOCKED_QUOTAS': {
      return {
        ...state,
        runFormOverview: {
          ...state.runFormOverview,
          cint_interlocked_quotas: action.interlockedQuotas
        }
      }
    }

    case 'GET_CENSUS_DATA_INIT': {

      if (action.data['isAgeOnly']) {
        return {
          ...state,
          censusData: {
            isAgeCensusLoading: true,
            census_data: []
          }
        }
      } else if (action.data['isGenderOnly']) {
        return {
          ...state,
          censusData: {
            isGenderCensusLoading: true,
            census_data: []
          }
        }
      } else {
        return {
          ...state,
          censusData: {
            isLoading: true,
            census_data: []
          }
        }
      }
    }

    case 'GET_CENSUS_DATA_SUCCESS': {
      return {
        ...state,
        censusData: {
          isLoading: false,
          isAgeCensusLoading: false,
          isGenderCensusLoading: false,
          ...action.data
        }
      }
    }

    case 'GET_CENSUS_DATA_FAIL': {
      return {
        ...state,
        censusData: {
          isLoading: false,
          isAgeCensusLoading: false,
          isGenderCensusLoading: false,
          errors: action.errors
        }
      }
    }

    case 'CREATE_CINT_ASSIGNMENT_INIT': {
      return {
        ...state,
        cintEntry: {
          isLoading: true
        }
      }
    }
    case 'CREATE_CINT_ASSIGNMENT_SUCCESS': {
      return {
        ...state,
        cintEntry: {
          isLoading: false,
          assignment: action.data
        }
      }
    }
    case 'CREATE_CINT_ASSIGNMENT_FAIL': {
      return {
        ...state,
        cintEntry: {
          isLoading: false,
          errors: action.errors,
          redirect_to: action.redirect_to
        }
      }
    }
    case 'UPDATE_ASSIGNMENT_STATUS_FOR_CINT_ENTRY': {
      return {
        ...state,
        cintEntry: {
          ...state.cintEntry,
          assignment: {
            ...state.cintEntry.assignment,
            ...action.data
          }
        }
      }
    }
    case 'CREATE_CINT_FEEDBACK_INIT': {
      return {
        ...state,
        cintFeedback: {
          isLoading: true
        }
      }
    }
    case 'CREATE_CINT_FEEDBACK_SUCCESS': {
      return {
        ...state,
        cintFeedback: {
          isLoading: false,
          isFeedbackCreated: true
        }
      }
    }
    case 'CREATE_CINT_FEEDBACK_FAIL': {
      return {
        ...state,
        cintFeedback: {
          isLoading: false,
          errors: action.errors
        }
      }
    }

    default:
      return state
  }
}

export default combinedReducer
