import {Link} from "react-router-dom";
import React from "react";

export const PlatformTabItem = (props) => {
  return (
    <div className="platform-tab mr-4 mb-3">
      <li>
        <Link
          to=""
          onClick={props.onTabClick}
          className={props.isActiveTab(props.platform.full_name.toLowerCase()) ? 'active' : ''}
        >
          {props.platform.full_name}
        </Link>
      </li>
    </div>
  )
}
