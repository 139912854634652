import React from 'react'

const GenericScreenoutMessage = ({errors, isRecruiter, assignmentId}) => {
  let message = 'Please wait...'
  let className = 'po-text text-dark'

  if (errors) {
    message = errors.join(', ')
    className = 'po-text text-danger'
  } else if (isRecruiter) {
    message = 'This is the page where the participants are redirected when they got screened out.'
    className = 'po-text text-dark'
  } else if (!assignmentId) {
    message = 'No activity in progress!'
    className = 'po-text text-danger'
  }

  return <h3 className={className}>{message}</h3>
}

export default GenericScreenoutMessage
