import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import {
  platformAddInit,
  platformAddSubmit,
  platformAddFetch
} from '../redux/actionss/platformActions'

const $ = window.$

const PlatformAdd = (props) => {
  const openAccessKeysPopup = () => {
    $.magnificPopup.open({
      items: {src: '#aws-key-guide'}, type: 'inline'
    })
  }

  const closeAccessKeysPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  const [isDefault, setIsDefault] = useState(false)
  const [mode, setMode] = useState('live')

  const formik = useFormik({
    initialValues: {
      name: '',
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_account_email: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required'),
      aws_access_key_id: Yup.string()
        .required('Access key id is required'),
      aws_secret_access_key: Yup.string()
        .required('Secret access key is required'),
      aws_account_email: Yup.string()
        .required('Email is required')
        .email('Invalid email address')
    }),
    onSubmit: values => {
      let platform = {
        name: values.name,
        mode: mode,
        credentials: {
          aws_access_key_id: values.aws_access_key_id,
          aws_secret_access_key: values.aws_secret_access_key
        },
        aws_account_email: values.aws_account_email,
        is_default: isDefault
      }
      props.platformAddSubmit()
      props.platformAddFetch(platform)
    }
  })

  const handleModeClick = (e) => (
    setMode(e.target.value)
  )

  const redirectToContactUs = () => {
    $.magnificPopup.close()
    props.history.push('/users/contact-us')
  }

  useEffect(() => {
    props.platformAddInit()

    return () => {
      $.magnificPopup.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.platform) {
    props.platformAddInit()
    return <Redirect to="/platforms/list"/>
  }

  return (
    <div>
      <div className="row ng-scope task-list-header top_line">
        <div className="col-md-12 col-lg-6">
          <Link
            to="/platforms/list"
            className="btn btn-default"
          >
            <i className="icon ion-android-arrow-back"/>
            All Integrations
          </Link>
        </div>
      </div>
      <div className="metrics project_detail">
        <div className="wrap_title d-flex">
          <h1>Connect Mechanical Turk Account</h1>
        </div>
      </div>
      <div className="ng-scope d-flex add_platform">
        <div className="row ng-scope">
          <div className="col-lg-6">
            <div className="panel panel-default">
              <div className="panel-body">
                <form
                  name="platform.form"
                  className="ng-pristine ng-invalid ng-invalid-required ng-valid-email"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group">
                    <label className="text-required">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="po-text form-control mb-1"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name
                      ? <p className="po-text text-red-soft">{formik.errors.name}</p>
                      : null
                    }
                  </div>
                  <div>
                    <div className="form-group mt-3">
                      <label className="text-required">Mode</label>
                      <div className="wrap_radio">
                        <div className="wrap_item">
                          <label>
                            <input
                              type="radio"
                              name="mode"
                              defaultChecked
                              onClick={handleModeClick}
                              value="live"
                            />
                            <span className="live">Live - for recruiting real participants</span>
                          </label>
                        </div>
                        <div className="wrap_item">
                          <label>
                            <input
                              type="radio"
                              name="mode"
                              onClick={handleModeClick}
                              value="sandbox"
                            />
                            <span>Testing only - for testing without real participants</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label className="text-required">AWS access key ID</label>
                      <input
                        type="text"
                        placeholder="Your Amazon account access key ID"
                        name="aws_access_key_id"
                        id="aws_access_key_id"
                        className="po-text form-control mb-1"
                        onChange={formik.handleChange}
                        value={formik.values.aws_access_key_id}
                      />
                      {formik.touched.aws_access_key_id && formik.errors.aws_access_key_id
                        ? <p className="po-text text-red-soft">{formik.errors.aws_access_key_id}</p>
                        : null
                      }
                    </div>
                    <div className="form-group mt-3">
                      <label className="text-required">AWS secret access key</label>
                      <input
                        type="text"
                        name="aws_secret_access_key"
                        id="aws_secret_access_key"
                        placeholder="Your Amazon account secret access key"
                        className="po-text form-control mb-1"
                        onChange={formik.handleChange}
                        value={formik.values.aws_secret_access_key}
                      />
                      {formik.touched.aws_secret_access_key && formik.errors.aws_secret_access_key
                        ? <p className="po-text text-red-soft mb-0">{formik.errors.aws_secret_access_key}</p>
                        : null
                      }
                      <p className="help-block mt-2">
                        There is no way to get this secret key unless you saved it when created.
                      </p>
                    </div>
                    <div className="form-group mt-3">
                      <label className="text-required">Email</label>
                      <input
                        type="text"
                        placeholder="username@example.com"
                        name="aws_account_email"
                        id="aws_account_email"
                        className="po-text form-control mb-1"
                        onChange={formik.handleChange}
                        value={formik.values.aws_account_email}
                      />
                      {formik.touched.aws_account_email && formik.errors.aws_account_email
                        ? <p className="po-text text-red-soft">{formik.errors.aws_account_email}</p>
                        : null
                      }
                    </div>
                  </div>
                  <div className="mt-3">
                    <Link
                      to="#aws-key-guide"
                      className="pass popup-content no-decoration link mt-3"
                      onClick={openAccessKeysPopup}
                    >
                      Not familiar with AWS access key ID and secret access key?
                    </Link>
                  </div>
                  <div className="wrap_link">
                    <button
                      type="submit"
                      name="add-platform"
                      id="btn-add-platform"
                      className="btn btn-primary btn-add-platform no-border cursor-pointer"
                      disabled={props.isLoading}
                      onClick={() => (setIsDefault(false))}
                    >
                      {props.isLoading && !isDefault ? 'Adding...' : 'Add'}
                    </button>
                    <button
                      type="submit"
                      name="make-default-platform"
                      id="btn-add-make-default-platform"
                      className="btn btn-primary btn-add-platform no-border cursor-pointer"
                      disabled={props.isLoading}
                      onClick={() => (setIsDefault(true))}
                    >
                      {props.isLoading && isDefault ? 'Adding...' : 'Add And Make Default Participant Pool'}
                    </button>
                    <button
                      type="button"
                      disabled={props.isLoading}
                      onClick={() => props.history.push('/platforms/list')}
                      className="btn btn-default cursor-pointer"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div className="alert alert-warning link-visited">
                Amazon Mechanical Turk does not support IAM users. For more info
                visit{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.aws.amazon.com/AWSMechTurk/latest/AWSMechanicalTurkGettingStartedGuide/SetUp.html#aws-security-credentials"
                >
                  Mturk Docs.
                </a>
                {' '}Also make sure your AWS account is linked to Amazon Mechanical Turk account. Visit{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://requester.mturk.com/developer"
                >
                  https://requester.mturk.com/developer
                </a>
                {' or '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://requestersandbox.mturk.com/developer"
                >
                  https://requestersandbox.mturk.com/developer
                </a>
                {' '}based on your mode of platform to link account.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="aws-key-guide" className="white-popup mfp-hide">
        <div className="wrap_info">
          <div className="row ng-scope">
            <div className="col-lg-11">
              <h3 className="po-text">Steps to get AWS access key ID & secret access key</h3>
            </div>
            <div className="col-lg-1" style={{'textAlign': 'right'}}>
              <div
                className="cansel cursor-pointer"
                onClick={closeAccessKeysPopup}
              >
                ×
              </div>
            </div>
          </div>
        </div>
        <hr style={{display: 'block'}}/>
        <div className="wrap_info po-text">
          <ol className="link-visited">
            <li className="wrap_item">
              1. Visit <a target="_blank" rel="noreferrer" href="https://console.aws.amazon.com">https://console.aws.amazon.com</a>
            </li>
            <li className="wrap_item">
              2. Enter your AWS username and password.
            </li>
            <li className="wrap_item">
              3. Click your username in upper right corner of the screen and select 'My Security Credentials'
            </li>
            <li className="wrap_item">
              4. Click 'Access keys (access key ID and secret access key)'
            </li>
            <li className="wrap_item">
              5. Click 'Create New Access Key' button and then click 'Show Access key'.
              Copy the Access Key ID and Secret Access Key text.
              If 'Create New Access Key' button is disabled make any one of the
              unused AWS access key inactive and then try.
            </li>
            <li className="wrap_item">
              6. If you face any issues{' '}
              <Link to="/users/contact-us" onClick={redirectToContactUs} className="cansel">contact us.</Link>
              {' '}We will help you to setup the platform.
            </li>
          </ol>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = state => {
  return {
    ...state.platformAdd
  }
}

const mapDispatchToProps = dispatch => ({
  platformAddInit: () => dispatch(platformAddInit()),
  platformAddSubmit: () => dispatch(platformAddSubmit()),
  platformAddFetch: (platform) => dispatch(platformAddFetch(platform))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAdd)
