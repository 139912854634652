import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import swal from 'sweetalert'

import {runDeleteFetch} from '../../redux/actionss/runActions'

const DeleteRun = (props) => {
  const confirmAndDeleteRun = (e) => {
    e.preventDefault()
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this run',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        Delete: {className: 'btn-primary'}
      }
    }).then((value) => {
      if (value) {
        props.runDeleteFetch(props.runId, props.projectId, props.history)
      }
    })
  }

  return (
    <>
      {props.labelName === 'Draft' && (
        <li className="cursor-pointer dropdown-item" onClick={confirmAndDeleteRun}>
          <span
            id="btn-remove-run"
            title="delete">
              <i className="fa fa-trash fa-fw"/>
                &nbsp;Delete Run
          </span>
        </li>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    state: {...state},
    isRunDeleted: state.runDelete?.isRunDeleted,
    runDeleteErrors: state.runDelete?.errors,
  }
}

const mapDispatchToProps = dispatch => ({
  runDeleteFetch: (id, projectId, history) => dispatch(runDeleteFetch(id, projectId, history)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteRun))
