import React from 'react'
import swal from 'sweetalert'

const BlockParticipantAndRejectAssignment = (props) => {
  const handleBlockParticipantAndRejectAssignment = (clickEvent) => {
    clickEvent.preventDefault()

    swal({
      title: 'Are you sure you want to proceed?',
      text:
`By proceeding you are indicating that this participant is a spammer. \
Your action will cause them to be banned permanently. Please only use this \
option if you are confident this participant is really a spammer. If you proceed, \
this action cannot be undone.`,
      icon: 'warning',
      buttons: {
        cancel: {
          className: 'swal-button--confirm btn-primary swal-primary-filled',
          text: 'Cancel',
          visible: true
        },
        confirm: {
          className: 'swal-button--cancel',
          text: 'Ban forever'
        }
      },
      dangerMode: true
    }).then((confirmation) => {
      if (confirmation) {
        props.blockParticipantAndRejectAssignment(props.assignmentId)
      }
    })
  }

  return (
    <li
      onClick={handleBlockParticipantAndRejectAssignment}
      >
      <div
        className="popup-content relative cursor-pointer"
        to=""
      >
        <i className="fas fa-user-slash fa-icons-dropdown"/>
        Block this participant permanently and refuse to pay
      </div>
    </li>
  )
}

export default BlockParticipantAndRejectAssignment
