import React from 'react'
import {useDispatch} from 'react-redux'
import {cloneDeep, isEqual} from 'lodash'

import InterlockQuotaExample from './InterlockQuotaExample'
import {ageFilterForCint, censusQuota, genderFilterForCint} from '../RunUtils'

import {updateCintInterlockedQuotas} from '../../../../redux/actions'

const $ = window.$

const InterlockQuotaSelection = ({
                                   selectedQuotas = [],
                                   activeInterlockedQuotas,
                                   setActiveInterlockedQuotas,
                                   interlockedQuotaRef
                                 }) => {
  const ageFilterId = ageFilterForCint.id
  const genderFilterId = genderFilterForCint.id
  const censusQuotaId = censusQuota.id
  const FILTERS_TO_SKIP = [ageFilterId, genderFilterId, censusQuotaId]
  const eligibleQuotas = selectedQuotas.filter(quota => !FILTERS_TO_SKIP.includes(quota.id))
  const isAgeQuotaAdded = !!selectedQuotas.find(quota => quota.id === ageFilterId || quota.id === censusQuotaId)
  const isGenderQuotaAdded = !!selectedQuotas.find(quota => quota.id === genderFilterId || quota.id === censusQuotaId)
  const isCensusQuotaAdded = !!selectedQuotas.find(q => q.id === censusQuotaId)

  const activeQuotas = Object.keys(activeInterlockedQuotas).filter(k => activeInterlockedQuotas[k])
  const activeProfilingQuestions = activeQuotas.filter(k => !FILTERS_TO_SKIP.includes(k))

  const isInterlockingValid = (() => {
    if (activeInterlockedQuotas[censusQuotaId]) return activeProfilingQuestions.length === 1
    if (activeInterlockedQuotas[ageFilterId] && activeInterlockedQuotas[genderFilterId] && activeProfilingQuestions.length < 2) return true

    return (activeInterlockedQuotas[ageFilterId] || activeInterlockedQuotas[genderFilterId]) && activeProfilingQuestions.length === 1
  })()

  const ageAndGenderQuotaLabel = isCensusQuotaAdded || (isAgeQuotaAdded && isGenderQuotaAdded)
    ? 'Age and Gender'
    : isAgeQuotaAdded ? 'Age' : 'Gender'

  const dispatch = useDispatch()

  const closePopup = (e) => {
    e.preventDefault()

    if (!isEqual(interlockedQuotaRef.current, activeInterlockedQuotas)) {
      setActiveInterlockedQuotas(cloneDeep(interlockedQuotaRef.current))
    }
    $.magnificPopup.close()
  }

  const handleQuotaSelection = (e) => {
    const {id, checked} = e.target
    setActiveInterlockedQuotas(prevState => ({...prevState, [id]: checked}))
  }

  const handleInterlockQuotas = () => {
    const quotas = Object.keys(activeInterlockedQuotas).filter(id => activeInterlockedQuotas[id] === true)
    dispatch(updateCintInterlockedQuotas(quotas))
    $.magnificPopup.close()
  }

  return (
    <div
      className="metrics filter activity mfp-hide modal fade interlock-quota-selection"
      id="interlock-quota-selection"
    >
      <div className="box po-text">
        <h2 className="title mb-4">Interlock Quotas</h2>

        <p className="po-text mt-3">
          If you have two or more quotas, you can combine them to create one interlocked quota where each group is
          a combination of two(or more) traits. For example, the two quotas can be interlocked:
        </p>

        <InterlockQuotaExample/>

        <div className="po-text-bold font-size-18 mt-4">
          Choose quotas to combine into one interlocked quota
        </div>

        <div className="p-3 po-text interlock-choice">
          {isAgeQuotaAdded && (
            <div className="radio-checkbox quota-choice" key={ageFilterId}>
              <label className="d-flex align-items-center w-100">
                <input
                  name={ageFilterId}
                  id={ageFilterId}
                  className="checkbox"
                  type="checkbox"
                  checked={activeInterlockedQuotas[censusQuotaId] === true || activeInterlockedQuotas[ageFilterId] === true}
                  onChange={handleQuotaSelection}
                  disabled={activeInterlockedQuotas[censusQuotaId]}
                />
                <span className="checkbox-custom" style={{minWidth: '20px'}}/>
                <div className="quota-title">
                  Age (based on the question "What is your year of birth?")
                </div>
              </label>
            </div>
          )}

          {isGenderQuotaAdded && (
            <div className="radio-checkbox quota-choice" key={genderFilterId}>
              <label className="d-flex align-items-center w-100">
                <input
                  name={genderFilterId}
                  id={genderFilterId}
                  className="checkbox"
                  type="checkbox"
                  checked={activeInterlockedQuotas[censusQuotaId] === true || activeInterlockedQuotas[genderFilterId] === true}
                  onChange={handleQuotaSelection}
                  disabled={activeInterlockedQuotas[censusQuotaId]}
                />
                <span className="checkbox-custom" style={{minWidth: '20px'}}/>
                <div className="quota-title">
                  Gender (based on the question "Are you male or female?")
                </div>
              </label>
            </div>
          )}

          {eligibleQuotas.map((quota) => {
            return (
              <div className="radio-checkbox quota-choice" key={quota.id}>
                <label className="d-flex align-items-center w-100">
                  <input
                    name={quota.id}
                    id={quota.id}
                    className="checkbox"
                    type="checkbox"
                    checked={activeInterlockedQuotas[quota.id] === true}
                    onChange={handleQuotaSelection}
                  />
                  <span className="checkbox-custom" style={{minWidth: '20px'}}/>
                  <div className="quota-title">
                    {quota.name} (based on the question "{quota.text}")
                  </div>
                </label>
              </div>
            )
          })}
        </div>

        {activeProfilingQuestions.length > 1 && (
          <p className="text-red-soft px-5">
            You can only interlock any one of the following quotas along with {ageAndGenderQuotaLabel}
            <ul className="pl-4 mt-1 quota-name-list">
              {eligibleQuotas.map(quota => (
                <li>{quota.name}</li>
              ))}
            </ul>
          </p>
        )}

        {isCensusQuotaAdded && (
          <p className="po-text">
            Census matching creates an interlocked quota based on age and gender. You can add other quotas to that
            interlocked quota. If you have enabled census matching and you deselect age or gender above, your
            activity no longer be census matched.
          </p>
        )}

        <div className="d-flex flex-column align-items-center mt-4 mb-2">
          <div className="wrap_link">
            <button
              type="button"
              className="btn btn-default cancel clickable"
              onClick={closePopup}
            >
              Close
            </button>
            <button
              type="submit"
              className={`btn btn-primary no-border clickable ${isInterlockingValid ? '' : 'cursor-disabled btn-grey'}`}
              onClick={handleInterlockQuotas}
              disabled={!isInterlockingValid}
            >
              Interlock quotas
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InterlockQuotaSelection
