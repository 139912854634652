import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {LINK_VERIFICATION_TYPES} from '../../../utils/constants'
import {getStorageItem, setStorageItem} from '../../common/Helpers'
import TestLinkVerificationStatus from './TestLinkVerificationStatus'
import {fayeClient} from '../../helpers/FayeClient'
import {getFormattedActivityUrl} from './RunUtils'

import {
  runFormOverviewUpdate,
  startLinkVerificationFetch,
  updateLinkVerification
} from '../../../redux/actions'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'
import {errorToast} from '../../helpers/notification'

const $ = window.$

const TestLink = (
  {
    taskId,
    isMturk,
    isScreeningEnabled,
    screeningUrl,
    activityUrl,
    activityTitle,
    activityDescription,
    activityAdditionalInfo,
    activityDuration,
    activityPay
  }
) => {
  const isCint = !isMturk
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)
  const [isTestStarted, setIsTestStarted] = useState(false)
  const [isNewVerification, setIsNewVerification] = useState(false)

  const screenInLinkSubscription = useRef(null)
  const screenOutLinkSubscription = useRef(null)
  const completionLinkSubscription = useRef(null)
  const windowRef = useRef(null)

  const dispatch = useDispatch()

  const {
    screenInLinkVerificationId,
    screenInLinkVerifiedAt,
    screenOutLinkVerificationId,
    screenOutLinkVerifiedAt,
    completionLinkVerificationId,
    completionLinkVerifiedAt,
    screenInLinkVerification,
    screenOutLinkVerification,
    completionLinkVerification,
    userId
  } = useSelector(state => ({
    screenInLinkVerificationId: state.runForm.screen_in_link_verification_uid,
    screenInLinkVerifiedAt: state.runForm.screen_in_link_verified_at,
    screenOutLinkVerificationId: state.runForm.screen_out_link_verification_uid,
    screenOutLinkVerifiedAt: state.runForm.screen_out_link_verified_at,
    completionLinkVerificationId: state.runForm.completion_link_verification_uid,
    completionLinkVerifiedAt: state.runForm.completion_link_verified_at,
    screenInLinkVerification: state.screenInLinkVerification?.data,
    screenOutLinkVerification: state.screenOutLinkVerification?.data,
    completionLinkVerification: state.completionLinkVerification?.data,
    userId: state.currentUser?.uid
  }))

  const validateAndDispatch = (linkType) => {
    const linkVerificationId = getStorageItem(`lv_${taskId}_${linkType}_id`)
    if (linkVerificationId) return

    dispatch(startLinkVerificationFetch({
      task_id: taskId,
      url: '',
      link_type: linkType
    }))
  }

  const getBodyText = () => {
    if (!isScreeningEnabled)
      return 'Use this tool to check that your Positly link is included in your activity'

    if (isCint && isScreeningEnabled)
      return 'Use this tool to check that your Positly links are included in your activity'

    return 'Use this tool to check that your Positly links are included in your screening and main activity'
  }

  const openTestPositlyLinkPopup = (e) => {
    e.preventDefault()

    if (!activityUrl) {
      return errorToast('Please setup activity link')
    }

    sendGAEvent(GA_EVENTS.CLICK_START_TEST, {positly_user_id: userId})
    if (!isNewVerification) {
      dispatch(runFormOverviewUpdate({
        screen_in_link_verification_id: null,
        screen_in_link_verified_at: null,
        screen_out_link_verification_id: null,
        screen_out_link_verified_at: null,
        completion_link_verification_id: null,
        completion_link_verified_at: null,
      }))
      setIsNewVerification(true)
    }

    $.magnificPopup.open({
      items: {src: `#test-positly-link`},
      type: 'inline',
      modal: true,
      closeOnBgClick: false
    })

    const activityDetails = {
      screeningUrl,
      activityUrl,
      activityTitle,
      activityDescription,
      activityAdditionalInfo,
      activityDuration,
      activityPay
    }
    setStorageItem(`lv_task_id`, taskId)
    setStorageItem(`lv_${taskId}_activity_details`, activityDetails)
    validateAndDispatch(LINK_VERIFICATION_TYPES.COMPLETION_LINK)
    if (isScreeningEnabled) validateAndDispatch(LINK_VERIFICATION_TYPES.SCREEN_OUT_LINK)
    if (isMturk && isScreeningEnabled) validateAndDispatch(LINK_VERIFICATION_TYPES.SCREEN_IN_LINK)
  }

  const closeTestPositlyLinkPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()

    windowRef.current?.close()
  }

  const openActivity = (e) => {
    if (e) e.preventDefault()

    const url = isScreeningEnabled && isMturk ? screeningUrl : activityUrl
    const formattedUrl = getFormattedActivityUrl(url, taskId)
    windowRef.current = window.open(formattedUrl, 'test-link')
    setIsTestStarted(true)
  }

  const validateAndSubscribe = (linkVerificationId, subscription) => {
    if (!linkVerificationId || subscription.current) return

    subscription.current = fayeClient.subscribe(`/linkVerificationStateChanged/${linkVerificationId}`, (data) => {
      dispatch(updateLinkVerification(data))

      // Update run form overview
      if (data.verified_at) {
        dispatch(runFormOverviewUpdate({
          [`${data.link_type}_verification_id`]: data.id,
          [`${data.link_type}_verified_at`]: data.verified_at
        }))
      }
    })
  }

  const startTestButtonLabel = isTestStarted ? 'Restart activity from beginning' : 'Start test now'

  /* eslint-disable react-hooks/exhaustive-deps */
  // Subscribe to link verification
  useEffect(() => {
    const completionLinkId = completionLinkVerification?.id
    validateAndSubscribe(completionLinkId, completionLinkSubscription)

    if (isScreeningEnabled) {
      const screenOutLinkId = screenOutLinkVerification?.id
      validateAndSubscribe(screenOutLinkId, screenOutLinkSubscription)
    }

    if (isMturk && isScreeningEnabled) {
      const screenInLinkId = screenInLinkVerification?.id
      validateAndSubscribe(screenInLinkId, screenInLinkSubscription)
    }
  }, [
    isMturk,
    isScreeningEnabled,
    screenInLinkVerification?.id,
    screenOutLinkVerification?.id,
    completionLinkVerification?.id
  ])

  // Cancel subscriptions
  useEffect(() => {
    return () => {
      completionLinkSubscription.current?.cancel()
      screenOutLinkSubscription.current?.cancel()
      screenInLinkSubscription.current?.cancel()
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  // Initialize persisted data
  useEffect(() => {
    if (!taskId) return

    dispatch(runFormOverviewUpdate({
      screen_in_link_verification_id: screenInLinkVerificationId,
      screen_in_link_verified_at: screenInLinkVerifiedAt,
      screen_out_link_verification_id: screenOutLinkVerificationId,
      screen_out_link_verified_at: screenOutLinkVerifiedAt,
      completion_link_verification_id: completionLinkVerificationId,
      completion_link_verified_at: completionLinkVerifiedAt,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  return (
    <>
      {/* Run Form - Confirmation tab UI */}
      <div className="box integration" id="participant-id-setup">
        <h2 className="d-inline-block">
          <span className="font-size-16">Optional: {getBodyText()}</span>
        </h2>
        <div className="po-text">
          <div className="wrap_input_text mt-2 justify-content-center align-items-center">
            <Link
              className="no-bg pl-2 mr-1"
              to=""
              tabIndex="4"
              onClick={openTestPositlyLinkPopup}
            >
              Start test
            </Link>
          </div>

          <TestLinkVerificationStatus isScreeningEnabled={isScreeningEnabled} isMturk={isMturk}/>
        </div>
      </div>

      {/* Test Positly links - Modal UI */}
      <div id="test-positly-link" className="white-popup mfp-hide">
        <h2>Optional: Preview activity and test links</h2>
        <div className="wrap_info">
          <div className="po-text mb-4">
            A preview of your activity will open in another window. This simulates what participants will see after
            they complete Positly's quality checks and begin your activity.<br/><br/>

            {isScreeningEnabled ? (
              <>
                To test your links, go through your activity. <span className="po-text-bold">Positly will automatically
                mark each link as present when you visit the url.</span> If your activity requires participants
                to copy and paste Positly links into their browser, you should do the same.<br/><br/>

                <span className="po-text-bold">To test your screen-out link:</span> Answer your screening questions like
                an <span className="po-text-bold">ineligible</span> participant.<br/><br/>
              </>
            ) : (
              <>
                To test your Completion Link, go through your activity. <span className="po-text-bold">Positly will
                automatically mark the link as present when you visit the url.</span> If your activity requires
                participants to copy and paste your Completion Link into their browser, you should do the
                same.<br/><br/>
              </>
            )}

            {isMturk && isScreeningEnabled && (
              <>
                <span className="po-text-bold">To test your screen-in link:</span> Answer your screening questions like
                an <span className="po-text-bold">eligible</span> participant.<br/><br/>

                <span className="po-text-bold">To test your completion link:</span> Answer your screening questions like
                an <span className="po-text-bold">eligible</span> participant, then complete
                your <span className="po-text-bold">main activity.</span><br/>
              </>
            )}
          </div>

          <div className="wrap_link mt-4 d-flex justify-content-center mb-3">
            <button className="btn btn-default clickable mr-0" onClick={openActivity}>
              {startTestButtonLabel}
            </button>
          </div>

          <TestLinkVerificationStatus isScreeningEnabled={isScreeningEnabled} isMturk={isMturk} isModal={true}/>

          <div className="wrap_link flex-row justify-content-center mt-4 mb-2">
            {!isLoading && (
              <button
                className={`btn btn-primary refresh clickable no-border ${isLoading ? 'btn-grey cursor-disabled' : ''}`}
                onClick={closeTestPositlyLinkPopup}
              >
                Finish
              </button>
            )}
            <button className="btn btn-default cancel clickable mr-0" onClick={closeTestPositlyLinkPopup}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestLink
