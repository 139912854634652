import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

// Custom components & helpers
import CreditCard from '../CreditCard'

// Constants
const $ = window.$

const AddCardWarning = ({showAddCardWarning, setShowAddCardWarning, message, callbackRef}) => {
  const defaultMessage = 'You do not have enough credits to perform this action'

  const openAddCardWarningPopup = () => {
    $.magnificPopup.open({
      items: {src: `#add-card-warning-popup`},
      type: 'inline',
      closeOnBgClick: false
    })
  }

  const closeAddCardWarningPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  useEffect(() => {
    if (showAddCardWarning) openAddCardWarningPopup()

    return () => setShowAddCardWarning(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddCardWarning])

  return (
    <div className="white-popup alert mfp-hide" id="add-card-warning-popup">
      <div className="wrap_info po-text">
        <div className="wrap_item">
          <div className="form-group input-group">
            {message
              ? message
              : defaultMessage
            } {' '}
            <CreditCard
              ui="showOnlyPopup"
              action="add"
              className="d-inline-block"
              callbackRef={callbackRef}
            />
          </div>
        </div>
        <div className="wrap_link text-center">
          <Link
            to=""
            className="btn btn-primary clickable"
            onClick={closeAddCardWarningPopup}
          >
            Okay
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AddCardWarning
